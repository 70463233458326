import { keys as genericKeys, type as genericType } from "../generics";

const { intents } = genericKeys;

const nameKeys = {
  FIELDSOFACTION: 'FIELDSOFACTION',
  FIELDOFACTION: 'FIELDOFACTION',
};
export const entityKey = nameKeys.FIELDSOFACTION.toLowerCase();
export const duckTypes = {
  FIELDSOFACTION_GET: genericType(entityKey, nameKeys.FIELDSOFACTION, intents.GET),
  FIELDOFACTION_GET: genericType(entityKey, nameKeys.FIELDOFACTION, intents.GET),
  FIELDOFACTION_PUT: genericType(entityKey, nameKeys.FIELDOFACTION, intents.PUT),
  FIELDOFACTION_POST: genericType(entityKey, nameKeys.FIELDOFACTION, intents.POST),
  FIELDOFACTION_DELETE: genericType(entityKey, nameKeys.FIELDOFACTION, intents.DELETE)
};
