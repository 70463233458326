import { withRouter } from "react-router";
import { connect } from 'react-redux';
import FilesComponent from './FilesComponent';
import { entityKey as filesSliceKey } from '../../redux/entities/filesKeys';
import { default as filesOperations } from '../../redux/entities/filesOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { isAdmin, isInstructor } = state.authentication;
  const { errors, byId, allIds } = state.entities[filesSliceKey];

  return {
    isAdmin,
    isInstructor,
    errors,
    byId,
    allIds
  };
};

const mapDispatchToProps = dispatch => {
  const filesGetRequest = () => dispatch(filesOperations.filesGetRequest());
  const fileDeleteRequest = id => dispatch(filesOperations.fileDeleteRequest(id));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    filesGetRequest,
    fileDeleteRequest,
    notificationEnqueue
  };
};

const FilesContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FilesComponent));

export default FilesContainer;
