import { withRouter } from "react-router";
import { connect } from 'react-redux';
import UserRegistrationComponent from './UserRegistrationComponent';
import { authenticationOperations, authenticationSelectors } from '../../redux/authentication';
import { uiEntityKeys, viewKeys, uiOperations } from '../../redux/ui';
import { notificationsOperations } from "../../redux/notifications";
import { entityKey as businessesSliceKey } from '../../redux/entities/businessesKeys';
import { default as businessesOperations } from "../../redux/entities/businessesOperations";
import { entityKey as professionsSliceKey } from '../../redux/entities/professionsKeys';
import { default as professionsOperations } from '../../redux/entities/professionsOperations';
import { requestsSelectors } from "../../redux/entities/requests";
import { entityKey as facilitiesSliceKey } from '../../redux/entities/facilitiesKeys';
import { default as facilitiesOperations } from "../../redux/entities/facilitiesOperations";

const uiEntityKey = uiEntityKeys.views;
const viewKey = viewKeys.REGISTRATION.key;

const mapStateToProps = state => {
  const view = state.ui[uiEntityKey][viewKey];
  const isFetching = authenticationSelectors.checkIfFetching(state) || requestsSelectors.checkIfFetching(state.entities, businessesSliceKey) || requestsSelectors.checkIfFetching(state.entities, professionsSliceKey);
  const { errors, isAdmin, isInstructor } = state.authentication;
  const profile = authenticationSelectors.profile(state);
  const { byId, allIds } = state.entities[businessesSliceKey];
  const facilityById = state.entities[facilitiesSliceKey].byId;
  const facilityAllIds = state.entities[facilitiesSliceKey].allIds;
  const professionsById = state.entities[professionsSliceKey].byId;

  return {
    view,
    isFetching,
    errors,
    isAdmin,
    isInstructor,
    profile,
    businesses: {
      byId,
      allIds
    },
    facilityById,
    facilityAllIds,
    professionsById
  };
};

const mapDispatchToProps = dispatch => {
  const businessesGetRequest = () => dispatch(businessesOperations.businessesGetRequest());
  const businessGetRequest = id => dispatch(businessesOperations.businessGetRequest(id));
  const facilitiesGetRequest = () => dispatch(facilitiesOperations.facilitiesGetRequest());

  const professionsGetRequest = () => dispatch(professionsOperations.professionsGetRequest());

  const registrationPostRequest = (data, formikBag) => dispatch(authenticationOperations.registrationPostRequest(data, formikBag));

  const cancelAuthRequest = (cancelType, cancelMessage) => dispatch(authenticationOperations.cancelAuthRequest(cancelType, cancelMessage));

  const viewChange = value => dispatch(uiOperations.viewChangeOperationGeneric(viewKey, value));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    businessesGetRequest,
    businessGetRequest,
    professionsGetRequest,
    facilitiesGetRequest,
    registrationPostRequest,
    cancelAuthRequest,
    viewChange,
    notificationEnqueue
  };
};

const UserRegistrationContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(UserRegistrationComponent));

export default UserRegistrationContainer;