import React, { Component } from 'react';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  FormHelperText,
  Paper,
  Typography,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { Rating } from '@material-ui/lab';
import {
  Field,
  Form,
  Formik
} from "formik";
import { TextField } from 'formik-material-ui';
import competenceRatingLabels from "../../utils/competenceRatingLabels";

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    overflow: 'visible',
    width: "100%",
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
    },
  },
  cardHeader: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      flex: '0 0 30%',
    },
  },
  cardHeaderAvatar: {
    alignSelf: 'flex-start',
  },
  cardContent: {
    [theme.breakpoints.up('md')]: {
      flex: '1 1 auto',
    },
  },
  cardActions: {
    padding: theme.spacing(1, 2, 2),
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-end',
      justifyContent: 'flex-end',
      flex: '1 0 14rem',
    },
  },
  ratingPaper: {
    backgroundColor: theme.palette.grey[50],
    margin: theme.spacing(1, -1),
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      alignItems: "center",
      display: "flex",
    },
  },
  ratingLabel: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
      marginTop: 0,
    },
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class CompetenceRatingFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      ratingTooltip: '',
      ratingLabel: ''
    };
  }

  componentDidMount() {
    const { ratingItem } = this.props;
    const rating = ratingItem ? ratingItem.rating : 0;

    this.setState({
      ratingLabel: this.getRatingLabel(rating)
    });
  }

  getRatingLabel = rating => {
    const { isInstructor } = this.props;
    const ratingLabels = competenceRatingLabels[rating] || null;

    if (!ratingLabels) {
      return '';
    }

    return isInstructor ? ratingLabels.instructor : ratingLabels.apprentice;
  };

  render() {
    const { classes, isInstructor, type, specItem, ratingItem, submitHandler, resetHandler } = this.props;
    const { ratingTooltip, ratingLabel } = this.state;

    if (!specItem) {
      return null;
    }

    const rating = ratingItem ? ratingItem.rating : 0;
    const ratingDescription = ratingItem ? ratingItem.description : '';

    return (
      <Formik
        initialValues = {{
          rating: rating,
          description: ratingDescription,
        }}
        enableReinitialize={true}
        validate = {values => {
          let errors = {};
          return errors;
        }}
        onSubmit = {(values, formikBag) => {
          submitHandler(values, formikBag);
        }}>
        {({ status, isSubmitting, handleSubmit, handleReset, setFieldValue, setFieldTouched, values, touched, errors }) => (
          <Form
            autoComplete="off"
            onSubmit={evt => {
              evt.stopPropagation();
              handleSubmit(evt);
            }}
            onReset={evt => {
              evt.stopPropagation();
              handleReset(evt);
              resetHandler();
            }}
            className={classes.form}
          >
            <Card className={classes.card}>
              <CardHeader
                avatar={
                  <Tooltip title={type.label} aria-label={type.label}>
                    <Avatar>{React.createElement(type.icon)}</Avatar>
                  </Tooltip>
                }
                title={type.label}
                titleTypographyProps={{
                  color: 'textSecondary'
                }}
                subheader={isInstructor ? `Auszubildende*r kann...` : `Ich kann...`}
                subheaderTypographyProps={{
                  color: 'textPrimary'
                }}
                classes={{
                  root: classes.cardHeader,
                  avatar: classes.cardHeaderAvatar
                }}
              />
              <CardContent className={classes.cardContent}>
                {specItem.description}
                {status && <FormHelperText error>{status}</FormHelperText>}
                <FormControl fullWidth margin="none" error={!!errors.rating && touched.rating}>
                  <Paper elevation={0} classes={{
                    root: classes.ratingPaper
                  }}>
                    <Rating 
                      name={`rate-${specItem.id}`}
                      size="large"
                      max={4}
                      value={values.rating}
                      onChangeActive={(event, newValue) => {
                        this.setState({
                          ratingTooltip: this.getRatingLabel(newValue)
                        });
                      }}
                      onChange={(event, newValue) => {
                        this.setState({
                          ratingLabel: this.getRatingLabel(newValue)
                        });
                        setFieldValue("rating", newValue);
                        setFieldTouched("rating", true);
                      }}
                    />
                    <Typography variant="caption" color="textSecondary" classes={{
                      root: classes.ratingLabel
                    }}>
                      {ratingTooltip ? ratingTooltip : ratingLabel}
                    </Typography>
                  </Paper>
                  {(!!errors.rating && touched.rating) &&
                    <FormHelperText>{errors.rating}</FormHelperText>
                  }
                </FormControl>
                <Field component={TextField}
                  type="text"
                  id="description"
                  name="description"
                  label="Kommentar"
                  fullWidth
                  margin="none"
                  helperText="(optional)"
                />
              </CardContent>
              <CardActions className={classes.cardActions}>
                <div className={classes.wrapper}>
                  <Button type="reset" size="small">Abbrechen</Button>
                </div>
                <div className={classes.wrapper}>
                  <Button type="submit"
                    fullWidth
                    variant="outlined"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}>
                    Speichern
                  </Button>
                  {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress} />}
                </div>
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(CompetenceRatingFormComponent);
