
const INFO = 'info';
const SUCCESS = 'success';
const WARNING = 'warning';
const ERROR = 'error';

export default {
  INFO,
  SUCCESS,
  WARNING,
  ERROR
};