import React from 'react';
import { Field, Form, Formik } from "formik";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FilesSelection from "../common/FilesSelectionContainer";
import ReactSelect from "../common/ReactSelect";
import { compareByKey2Sort } from "../../utils/helper";

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  wrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class UserEditFormComponent extends React.Component {

  render() {
    const { classes, user, submitHandler, resetHandler, professionsById } = this.props;

    let firstName = '';
    let lastName = '';
    let username = '';
    let birthday = '';
    let placeOfBirth = '';
    let street = '';
    let zip = '';
    let city = '';
    let phone = '';
    let degree = '';
    let trainingTimeFrom = '';

    let avatar = [];
    let isApprentice = false;

    if (user) {
      firstName = user.firstName || '';
      lastName = user.lastName || '';
      username = user.username || '';
      avatar = user.avatar ? [user.avatar] : [];
      birthday = user.birthday || '';
      placeOfBirth = user.placeOfBirth || '';
      street = user.street || '';
      zip = user.zip || '';
      city = user.city || '';
      phone = user.phone || '';
      degree = user.degree || '';
      trainingTimeFrom = user.trainingTimeFrom || '';
      isApprentice = user.roles.includes('ROLE_APPRENTICE');
    }

    const optionSorter = (byId) => {
      return Object.keys(byId)
        .sort((a, b) => compareByKey2Sort(byId[a], byId[b], 'name'))
        .map(objId => {
          const obj = byId[objId];
          return {
            value: obj.id,
            label: obj.name
          };
        }, []);
    };

    const convertDate = (date) => date.toISOString().slice(0, 10).replace("T", " ");

    const professionsOptions = optionSorter(professionsById);

    return (
      <Formik
        initialValues={{
          firstName: firstName,
          lastName: lastName,
          avatar: avatar,
          profession: user.job,
          username: username,
          birthday: birthday ? convertDate(new Date(birthday)) : '',
          placeOfBirth: placeOfBirth,
          street: street,
          zip: zip,
          city: city,
          phone: phone,
          degree: degree,
          trainingTimeFrom: trainingTimeFrom ? convertDate(new Date(trainingTimeFrom)) : '',
        }}
        validate={values => {
          let errors = {};
          if ("" === values.firstName) {
            errors.firstName = "Dies ist ein Pflichtfeld";
          }
          if ('' === values.lastName) {
            errors.lastName = "Dies ist ein Pflichtfeld";
          }
          if (1 < values.avatar.length) {
            errors.avatar = 'Maximal ein Bild';
          }
          return errors;
        }}
        onSubmit={(values, formikBag) => {
          submitHandler(values, formikBag);
        }}>
        {({ status, values, touched, setFieldTouched, errors, setFieldValue, isSubmitting, handleSubmit, handleReset }) => (
          <Form
            autoComplete="off"
            onSubmit={evt => {
              evt.stopPropagation();
              handleSubmit(evt);
            }}
            onReset={evt => {
              evt.stopPropagation();
              handleReset(evt);
              resetHandler();
            }}
            className={classes.form}
          >
            {status &&
            <FormHelperText error>{status}</FormHelperText>
            }
            <Field component={TextField}
              type="text"
              id="username"
              name="username"
              label="Email / Benutzername"
              error={errors.username}
              required
              fullWidth
              margin="normal"/>
            <Field component={TextField}
              type="text"
              id="firstName"
              name="firstName"
              label="Vorname"
              error={errors.firstName}
              required
              fullWidth
              margin="normal"/>
            <Field component={TextField}
              type="text"
              id="lastName"
              name="lastName"
              label="Nachname"
              error={errors.lastName}
              required
              fullWidth
              margin="normal"/>

            <FormControl fullWidth margin="normal" error={!!errors.profession && touched.profession}>
              <ReactSelect name="profession"
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                value={values.profession}
                error={errors.profession}
                touched={touched.profession}
                options={professionsOptions}
                placeholder="Ausbildungsberuf auswählen..."
                label="Ausbildungsberuf"
                isMulti={false}
                isClearable={false}
                isDisabled={true}/>
              {(!!errors.profession && touched.profession) &&
              <FormHelperText>{errors.profession}</FormHelperText>
              }
            </FormControl>

            <FormControl fullWidth margin="normal" error={!!errors.avatar}>
              <Field component={FilesSelection}
                id="avatar"
                name="avatar"
                label="Avatar"
                value={values.avatar}
                maxNumberOfFiles={1}
                allowedFileTypes={['image/*']}
                labelAddFiles="Bild hinzufügen"
                labelNoFiles="Kein Bild ausgewählt"
                labelAllFiles="Alle Bilder"
                onChange={setFieldValue}
              />
              {(!!errors.avatar) &&
                <FormHelperText>{errors.avatar}</FormHelperText>
              }
            </FormControl>

            <Field component={TextField}
              type="date"
              id="birthday"
              name="birthday"
              label="Geburtstag"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              margin="normal"/>

            <Field component={TextField}
              type="text"
              id="placeOfBirth"
              name="placeOfBirth"
              label="Geburtsort"
              error={errors.placeOfBirth}
              fullWidth
              margin="normal"/>

            <Field component={TextField}
              type="text"
              id="street"
              name="street"
              label="Strasse"
              error={errors.street}
              fullWidth
              margin="normal"/>

            <Field component={TextField}
              type="text"
              id="zip"
              name="zip"
              label="Postleitzahl"
              error={errors.zip}
              fullWidth
              margin="normal"/>

            <Field component={TextField}
              type="text"
              id="city"
              name="city"
              label="Stadt"
              error={errors.city}
              fullWidth
              margin="normal"/>

            <Field component={TextField}
              type="text"
              id="phone"
              name="phone"
              label="Telefonnummer"
              error={errors.phone}
              fullWidth
              margin="normal"/>

            {isApprentice && <Field component={TextField}
              type="text"
              id="degree"
              name="degree"
              label="Schulabschluss der allg. bild. Schuĺe"
              error={errors.degree}
              fullWidth
              margin="normal"/>}

            {isApprentice && <Field component={TextField}
              type="date"
              id="trainingTimeFrom"
              name="trainingTimeFrom"
              label="Ausbildung von"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              margin="normal"/>}

            <div className={classes.wrapper}>
              <Button type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}>
                Speichern
              </Button>
              {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress}/>}
            </div>
            <div className={classes.wrapper}>
              <Button type="reset" color="primary">Abbrechen</Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(UserEditFormComponent);
