import { keys as genericKeys, type as genericType } from "../generics";

const { intents } = genericKeys;

const nameKeys = {
  COMPETENCECHECKS: 'COMPETENCECHECKS',
  COMPETENCECHECK: 'COMPETENCECHECK',
};
export const entityKey = nameKeys.COMPETENCECHECKS.toLowerCase();
export const duckTypes = {
  COMPETENCECHECKS_GET: genericType(entityKey, nameKeys.COMPETENCECHECKS, intents.GET),
  COMPETENCECHECK_GET: genericType(entityKey, nameKeys.COMPETENCECHECK, intents.GET),
  COMPETENCECHECK_PUT: genericType(entityKey, nameKeys.COMPETENCECHECK, intents.PUT),
  COMPETENCECHECK_POST: genericType(entityKey, nameKeys.COMPETENCECHECK, intents.POST),
  COMPETENCECHECK_COPY: genericType(entityKey, nameKeys.COMPETENCECHECK, intents.COPY),
  COMPETENCECHECK_DELETE: genericType(entityKey, nameKeys.COMPETENCECHECK, intents.DELETE),
  COMPETENCECHECK_FILTER_OPTIONS: genericType(entityKey, nameKeys.COMPETENCECHECK, intents.FETCH_FILTER_OPTIONS)
};
