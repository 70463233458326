import { withRouter } from "react-router";
import { connect } from 'react-redux';
import SubTaskComponent from './SubTaskComponent';
import { entityKey as subTasksSliceKey } from '../../redux/entities/subTasksKeys';
import { default as subTasksOperations } from '../../redux/entities/subTasksOperations';
import { entityKey as tasksSliceKey } from '../../redux/entities/tasksKeys';
import { default as tasksOperations } from '../../redux/entities/tasksOperations';
import { entityKey as usersSliceKey } from '../../redux/entities/usersKeys';
import { default as usersOperations } from '../../redux/entities/usersOperations';
import { entityKey as filesSliceKey } from "../../redux/entities/filesKeys";
import { entityKey as linksSliceKey } from "../../redux/entities/linksKeys";
import { entityKey as resultsSliceKey } from "../../redux/entities/resultsKeys";
import { notificationsOperations } from "../../redux/notifications";
import { requestsSelectors } from "../../redux/entities/requests";
import resultsOperations from "../../redux/entities/resultsOperations";
import { metaKeys, uiOperations } from "../../redux/ui";

const mapStateToProps = state => {
  const isFetching = requestsSelectors.checkIfFetching(state.entities, subTasksSliceKey) || requestsSelectors.checkIfFetching(state.entities, tasksSliceKey);
  const { isAdmin, isInstructor } = state.authentication;
  const { errors, byId, allIds } = state.entities[subTasksSliceKey];
  const tasksById = state.entities[tasksSliceKey].byId;
  const usersById = state.entities[usersSliceKey].byId;
  const filesById = state.entities[filesSliceKey].byId;
  const linksById = state.entities[linksSliceKey].byId;
  const resultsById = state.entities[resultsSliceKey].byId;

  return {
    isFetching,
    isAdmin,
    isInstructor,
    errors,
    byId,
    allIds,
    tasksById,
    usersById,
    filesById,
    linksById,
    resultsById
  };
};

const mapDispatchToProps = dispatch => {
  const subTasksGetByTaskAndNumberRequest = (taskId, subTaskNumber) => dispatch(subTasksOperations.subTasksGetByTaskAndNumberRequest(taskId, subTaskNumber));

  const subTaskPutRequest = (id, data, formikBag, appendData) => dispatch(subTasksOperations.subTaskPutRequest(id, data, formikBag, appendData));

  const subTasksCancelRequest = (cancelType, cancelMessage) => dispatch(subTasksOperations.subTasksCancelRequest(cancelType, cancelMessage));

  const subTaskDeleteRequest = (id, returnRoute) => dispatch(subTasksOperations.subTaskDeleteRequest(id, returnRoute));

  const taskWithoutRelationsGetRequest = id => dispatch(tasksOperations.taskWithoutRelationsGetRequest(id));

  const subTaskSubmitRequest = id => dispatch(subTasksOperations.subTaskSubmitRequest(id));

  const usersGetRequest = () => dispatch(usersOperations.usersGetRequest());

  const resultGetRequest = id => dispatch(resultsOperations.resultGetRequest(id));

  const titleChange = value => dispatch(uiOperations.metaChangeOperationGeneric(metaKeys.TITLE, value));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    subTasksGetByTaskAndNumberRequest,
    subTaskPutRequest,
    subTasksCancelRequest,
    taskWithoutRelationsGetRequest,
    usersGetRequest,
    resultGetRequest,
    titleChange,
    notificationEnqueue,
    subTaskSubmitRequest,
    subTaskDeleteRequest
  };
};

const SubTaskContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SubTaskComponent));

export default SubTaskContainer;