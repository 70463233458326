import { schema } from 'normalizr';
import routes from "../../utils/routes";
import getHistory from '../../utils/history';
import { requestsOperations } from "./requests";
import { entityKey, duckTypes } from "./competenceChecksKeys";
import { entityKey as competenceSpecificationsSliceKey } from './competenceSpecificationsKeys';
import { default as competenceSpecificationsOperations } from './competenceSpecificationsOperations';
import { entityKey as competenceEvaluationsSliceKey } from './competenceEvaluationsKeys';
import { default as competenceEvaluationsOperations } from './competenceEvaluationsOperations';
import { notificationsOperations } from "../notifications";
import flavors from "../notifications/flavors";

const competenceCheckSchema = new schema.Entity(entityKey);
const competenceCheckListSchema = new schema.Array(competenceCheckSchema);

const fetchRelations = [{
  relationResponseKey: 'competenceSpecifications',
  relationSliceKey: competenceSpecificationsSliceKey,
  operation: competenceSpecificationsOperations.competenceSpecificationsByCompetenceCheckGetRequest,
  noRefetch: true,
  isSingle: false,
}, {
  relationResponseKey: 'competenceEvaluations',
  relationSliceKey: competenceEvaluationsSliceKey,
  operation: competenceEvaluationsOperations.competenceEvaluationsByCompetenceCheckGetRequest,
  noRefetch: true,
  isSingle: false,
}];

/* const gotoList = () => {
  getHistory().push(routes.COMPETENCECHECKS.path);
};*/

const listGetRequest = (url, urlParameters = {}) => {
  const settings = {
    duckType: duckTypes.COMPETENCECHECKS_GET,
    requestPath: url,
    clearEntityData: true,
    normalizeSchema: competenceCheckListSchema
  };

  return requestsOperations.requestsOperationGeneric(null, settings, urlParameters);
};

const competenceChecksGetRequest = (urlParameters = {}) => listGetRequest('/competence-checks', urlParameters);

const competenceChecksFilterOptionsRequest = (urlParameters = {}) => {
  const settings = {
    duckType: duckTypes.COMPETENCECHECK_FILTER_OPTIONS,
    requestPath: '/filter_options/competence_checks',
  };

  return requestsOperations.requestsFilterOptionsGeneric(null, settings, urlParameters);
};

const associationGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.COMPETENCECHECKS_GET,
    requestPath: url,
    clearEntityData: false,
    normalizeSchema: competenceCheckListSchema,
    requestCancelling: false
  };
  return requestsOperations.requestsOperationGeneric(null, settings);
};

const competenceChecksByTaskGetRequest = (taskId) => associationGetRequest(`tasks/${taskId}/competence-checks`);

const competenceChecksByProfessionGetRequest = (professionId) => associationGetRequest(`jobs/${professionId}/competence-checks`);

const competenceChecksByReportGetRequest = (reportId) => associationGetRequest(`reports/${reportId}/competence-checks`);

const competenceCheckGetRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.COMPETENCECHECK_GET,
    requestPath: `/competence-checks/${id}`,
    idParamRequired: true,
    normalizeSchema: competenceCheckSchema,
    fetchRelations: fetchRelations,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceCheckPutRequest = (id, data, formikBag) => {
  const params = {
    id: id,
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.COMPETENCECHECK_PUT,
    requestType: 'put',
    requestPath: `/competence-checks/${id}`,
    idParamRequired: true,
    normalizeSchema: competenceCheckSchema,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceCheckPostRequest = (data, formikBag) => {
  const params = {
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.COMPETENCECHECK_POST,
    requestType: 'post',
    requestPath: '/competence-checks',
    normalizeSchema: competenceCheckSchema,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceCheckDeleteRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.COMPETENCECHECK_DELETE,
    requestType: 'delete',
    requestPath: `/competence-checks/${id}`,
    idParamRequired: true,
    normalizeSchema: competenceCheckSchema,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceCheckCopyRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.COMPETENCECHECK_COPY,
    requestType: 'post',
    requestPath: '/competence-checks/:id/copy'.replace(':id', id),
    normalizeSchema: competenceCheckSchema,
    appendOnSuccess: competenceCheckCopySuccess,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceCheckCopySuccess = (dispatch, data) => {
  dispatch(notificationsOperations.notificationEnqueue("Der Kompetenzcheck wurde erfolgreich kopiert", flavors.SUCCESS));
  if (data.result && data.result.id) {
    getHistory().push(routes.COMPETENCECHECK_DETAILS.path.replace(":id", data.result.id));
  }
};

const competenceCheckChangeArchiveRequest = (id, isCreate) => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.COMPETENCECHECK_PUT,
    requestType: isCreate ? 'post' : 'delete',
    requestPath: '/competence-checks/:id/archivings'.replace(':id', id),
    normalizeSchema: competenceCheckSchema,
    appendOnSuccess: competenceCheckChangeArchiveSuccess(
      isCreate ? 'Der Kompetenzcheck wurde erfolgreich archiviert' : 'Die Archivierung des Kompetenzchecks wurde erfolgreich aufgehoben'
    ),
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceCheckChangeArchiveSuccess = message => (dispatch) => {
  dispatch(notificationsOperations.notificationEnqueue(message, flavors.SUCCESS));
};

const competenceCheckArchiveRequest = (id) => competenceCheckChangeArchiveRequest(id, true);
const competenceCheckUnarchiveRequest = (id) => competenceCheckChangeArchiveRequest(id, false);

/**
 * copies the competence checks; assigns the copied competence to the apprentice
 * and creates competence evaluations for instructor and apprentice
 * @param {string} id
 * @param {string} userId
 * @return {function(*): *}
 */
const competenceCheckAssignRequest = (id, userId) => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.COMPETENCECHECK_PUT,
    requestType: 'post',
    requestPath: '/competence-checks/:id/assign/:userId'
      .replace(':id', id)
      .replace(':userId', userId),
    normalizeSchema: competenceCheckSchema,
    requestCancelling: false,
    appendOnSuccess: competenceCheckAssignSuccess,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceCheckAssignSuccess = (dispatch) => {
  dispatch(notificationsOperations.notificationEnqueue('Der Kompetenzcheck wurde erfolgreich zugewiesen', flavors.SUCCESS));
};

/**
 * finishes current unfinished evaluation for loggedin user
 *
 * @param {string} id
 * @return {function(*): *}
 */
const competenceCheckSubmitRequest = (id) => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.COMPETENCECHECK_PUT,
    requestType: 'post',
    requestPath: '/competence-checks/:id/submit'.replace(':id', id),
    normalizeSchema: competenceCheckSchema,
    appendOnSuccess: competenceCheckSubmitSuccess,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceCheckSubmitSuccess = (dispatch) => {
  dispatch(notificationsOperations.notificationEnqueue('Ihre Einschätzung wurde eingereicht.', flavors.SUCCESS));
};

/**
 * create new evaluations for apprentice and instructor
 * all previous evaluations must be finished
 *
 * @param {string} id
 * @return {function(*): *}
 */
const competenceCheckReopenRequest = (id) => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.COMPETENCECHECK_PUT,
    requestType: 'post',
    requestPath: '/competence-checks/:id/reopen'.replace(':id', id),
    normalizeSchema: competenceCheckSchema,
    appendOnSuccess: competenceCheckReopenSuccess,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceCheckReopenSuccess = (dispatch) => {
  dispatch(notificationsOperations.notificationEnqueue('Eine neue Einschätzung wurde gestartet.', flavors.SUCCESS));
};

const competenceChecksCancelRequest = (cancelType, cancelMessage) => {
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsCancellation(entityKey, cancelType, cancelMessage));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

export default {
  competenceChecksGetRequest,
  competenceCheckGetRequest,
  competenceCheckPutRequest,
  competenceCheckPostRequest,
  competenceCheckDeleteRequest,
  competenceCheckCopyRequest,
  competenceCheckArchiveRequest,
  competenceCheckUnarchiveRequest,
  competenceChecksCancelRequest,
  competenceChecksByTaskGetRequest,
  competenceChecksByProfessionGetRequest,
  competenceChecksByReportGetRequest,
  competenceCheckAssignRequest,
  competenceCheckSubmitRequest,
  competenceCheckReopenRequest,
  competenceChecksFilterOptionsRequest
};
