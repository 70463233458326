import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import ActivationIcon from '@material-ui/icons/Update';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { getUrlParamFromString } from "../../utils/helper";
import { authenticationTypes } from "../../redux/authentication";
import { default as PasswordResetForm } from "./PasswordResetFormComponent";
import routes from "../../utils/routes";

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: 640,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    progress: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        alignSelf: 'stretch',
    }
});

class PasswordResetComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resetToken: null,
        };
    }

    componentDidMount() {
        this.setState({ resetToken: getUrlParamFromString('reset-token', this.props.location.search) });
    }

    handleSubmit = (values, formikBag) => {
        const data = {
            resetToken: this.state.resetToken,
            newPassword: values.password
        };
        this.props.resetPostRequest(data, formikBag);
    };
    handleReset = () => {
        this.props.cancelAuthRequest(authenticationTypes.RESET_POST);
        this.props.history.push(routes.LOGIN.path);
    };

    render() {
        const { classes } = this.props;

        return (
            <Paper elevation={0} className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <ActivationIcon />
                </Avatar>
                <Typography component="h1" variant="h5">Neues Passwort</Typography>
                <PasswordResetForm submitHandler={ this.handleSubmit } resetHandler={ this.handleReset } />
            </Paper>
        );
    }
}

export default withStyles(styles)(PasswordResetComponent);