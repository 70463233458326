import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { duckTypes } from "../../redux/entities/facilitiesKeys";
import { default as FacilityForm } from "./FacilityFormComponent";
import flavors from "../../redux/notifications/flavors";
import { responseFormikValidator } from "../../utils/validations";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: 640,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});

class FacilityEditComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      facilityId: null
    };
  }

  componentDidMount() {
    this.initData();
  }

  initData = () => {
    const { match } = this.props; // https://reacttraining.com/react-router/web/api/match
    if (match.params.id) {
      this.setState({
        facilityId: match.params.id
      }, () => {
        this.props.facilityGetRequest(this.state.facilityId);
      });
    }
  };

  handleSubmit = (values, formikBag) => {
      const id = this.state.facilityId;
      const data = {
          contactPerson: values.contactPerson || '',
          name: values.name || '',
          description: values.description || '',
          email: values.email || '',
          phone: values.phone || '',
          website: values.website || '',
          area: values.area || '',
          street: values.street || '',
          zip: values.zip || '',
          city: values.city || '',
          apprentices: values.apprentices || []
      };
      if (id) {
          this.props.facilityPutRequest(id, data, formikBag).then((axiosResponse) => {
              responseFormikValidator(axiosResponse, formikBag);
          });
      } else {
          this.props.notificationEnqueue('Es ist ein Fehler aufgetreten', flavors.ERROR);
      }
  };

  handleReset = () => {
    this.props.facilitiesCancelRequest(duckTypes.FACILITY_PUT.group);
    this.props.history.goBack();
  };

  render() {
    const { classes, byId, isInstructor, usersGetRequest, usersById } = this.props;

    const item = byId[this.state.facilityId];

    return (
      <React.Fragment>

        <Paper elevation={0} className={classes.paper}>
          <FacilityForm
            item={item}
            submitHandler={this.handleSubmit}
            resetHandler={this.handleReset}
            isInstructor={isInstructor}
            usersGetRequest={usersGetRequest}
            usersById={usersById}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FacilityEditComponent);
