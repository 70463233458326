import React from "react";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from '@material-ui/icons/GetApp';
import List from "@material-ui/core/List";
import FileIcon from '@material-ui/icons/Description';
import ImageIcon from '@material-ui/icons/Image';
import VideoIcon from '@material-ui/icons/OndemandVideo';
import AudioIcon from '@material-ui/icons/RecordVoiceOver';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ErrorIcon from '@material-ui/icons/Error';
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";
import ShowMoreText from "../common/ShowMoreText";

const styles = theme => ({
  iconLocked: {
    marginRight: theme.spacing(1),
    verticalAlign: 'text-top',
    fontSize: 'inherit',
  },
});

const ListFiles = props => {
  const { classes, ids, byId, actionHandler } = props;

  if (!ids || 0 === ids.length) {
    return null;
  }

  return (
    <List dense>
      {ids.map((id, index) => {
        const item = byId[id];
        if (!item) {
          return null;
        }
        let avatarIcon = (
          <FileIcon/>
        );
        if (0 <= item.mimeType.indexOf('image')) {
          avatarIcon = (
            <ImageIcon/>
          );
        } else if (0 <= item.mimeType.indexOf('video')) {
          avatarIcon = (
            <VideoIcon/>
          );
        } else if (0 <= item.mimeType.indexOf('audio')) {
          avatarIcon = (
            <AudioIcon/>
          );
        }
        return (
          <React.Fragment key={id}>
            {0 < index && <Divider variant="inset" component="li" />}
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar>{avatarIcon}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={item.title}
                secondary={
                  <React.Fragment>
                    {item.hiddenFromApprentice ? (
                      <Tooltip title="Kein Zugriff für Auszubildende" aria-label="Kein Zugriff für Auszubildende">
                        <LockIcon color="action" classes={{
                          root: classes.iconLocked
                        }}/>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Ohne Beschränkung" aria-label="Ohne Beschränkung">
                        <LockOpenIcon color="disabled" classes={{
                          root: classes.iconLocked
                        }}/>
                      </Tooltip>
                    )}
                    <ShowMoreText maxLength={64}>{item.description}</ShowMoreText>
                  </React.Fragment>
                }
              />
              <ListItemSecondaryAction>
                {item.fileExists ? (
                  <IconButton edge="end" onClick={evt => actionHandler(evt, item.id)} aria-label="Datei laden">
                    <DownloadIcon />
                  </IconButton>
                ) : (
                  <Tooltip title="Datei fehlt" aria-label="Datei fehlt">
                    <ErrorIcon color="disabled"/>
                  </Tooltip>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default withStyles(styles)(ListFiles);
