import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import LockedIcon from '@material-ui/icons/Lock';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { viewKeys } from "../../redux/ui";
import { default as PasswordRecoveryForm } from "./PasswordRecoveryFormComponent";
import routes from "../../utils/routes";
import { authenticationTypes } from "../../redux/authentication";

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    }
});

class PasswordRecoveryComponent extends React.Component {

    handleSubmit = (values, formikBag) => {
        const data = {
            username: values.email
        };
        this.props.recoveryPostRequest(data, formikBag);
    };
    handleReset = () => {
        this.props.cancelAuthRequest(authenticationTypes.RECOVERY_POST);
        this.props.history.push(routes.LOGIN.path);
    };

    render() {
        const { classes, view } = this.props;

        if (view === viewKeys.PASSWORD_RECOVERY.values.SUCCESS) {
            return (
                <Paper elevation={0} className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" gutterBottom>Passwort vergessen</Typography>
                    <Typography variant="body1" paragraph>Eine E-Mail mit einem Bestätigungslink wurde an die angegebene E-Mail Adresse verschickt. Über diesen Bestätigungslink können Sie ein neues Passwort einstellen.</Typography>
                </Paper>
            );
        }

        return (
            <Paper elevation={0} className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" gutterBottom>Passwort vergessen</Typography>
                <PasswordRecoveryForm submitHandler={ this.handleSubmit } resetHandler={ this.handleReset } />
            </Paper>
        );
    }
}

export default withStyles(styles)(PasswordRecoveryComponent);