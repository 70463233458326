import React from 'react';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  withStyles
} from "@material-ui/core";
import {
  Close as CloseIcon,
  Done as DoneIcon,
  Edit as EditIcon
} from "@material-ui/icons";
import {
  Field,
  Form,
  Formik
} from "formik";
import clsx from "clsx";
import Input from "./Input";

const styles = theme => ({
  form: {
    position: 'relative',
    width: '100%', // Fix IE 11 issue.
  },
  formDirty: {
    backgroundColor: theme.palette.grey[100],
  },
  formIcon: {
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    top: theme.spacing(0.75),
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    backgroundColor: theme.palette.grey[100],
    borderBottomLeftRadius: theme.shape.borderRadius * 6,
    borderBottomRightRadius: theme.shape.borderRadius * 6,
    display: 'none',
    marginTop: '-2px',
    padding: theme.spacing(0.5),
    paddingTop: 0,
    position: 'absolute',
    right: 0,
    top: '100%',
    zIndex: '900',
  },
  actionsShow: {
    display: 'flex',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(0.5),
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -10,
  },
});

class AdhocInput extends React.Component {
  render() {
    const { classes, propKey, propValue, required, fieldClasses, gutterBottom, submitHandler, resetHandler } = this.props;

    return (
      <Formik
        initialValues={{
          adhoc: propValue ? propValue : ''
        }}
        enableReinitialize={true}
        validate = {values => {
          let errors = {};
          if (required && '' === values.adhoc) {
            errors.adhoc = 'Dies ist ein Pflichtfeld';
          }
          return errors;
        }}
        onSubmit = {(values, formikBag) => {
          if ("function" === typeof submitHandler) {
            if (propKey) {
              submitHandler(propKey, values.adhoc, formikBag);
            } else {
              submitHandler(values.adhoc, formikBag);
            }
          }
        }}>
        {({ status, dirty, errors, touched, isSubmitting, handleSubmit, handleReset }) => (
          <Form
            autoComplete="off"
            className={clsx(classes.form, {
              [classes.formDirty]: dirty,
              [classes.gutterBottom]: gutterBottom
            })}
            onSubmit={evt => {
              evt.stopPropagation();
              handleSubmit(evt);
            }}
            onReset={evt => {
              evt.stopPropagation();
              handleReset(evt);
              if ("function" === typeof resetHandler) {
                resetHandler();
              }
            }}>
            {!dirty && <EditIcon fontSize="small" color="disabled" classes={{ root: classes.formIcon }}/>}
            <FormControl fullWidth margin="none" error={!!errors.adhoc && touched.adhoc} >
              <Field component={Input}
                type="text"
                id="adhoc"
                name="adhoc"
                required={required}
                autoComplete="off"
                disableUnderline={!dirty}
                margin="none"
                classes={fieldClasses ? fieldClasses : {}}
              />
              {!!errors.adhoc && touched.adhoc &&
                <FormHelperText>{errors.adhoc}</FormHelperText>
              }
              {status &&
                <FormHelperText error>{status}</FormHelperText>
              }
            </FormControl>
            <div className={clsx(classes.actions, {
              [classes.actionsShow]: dirty
            })}>
              <IconButton type="reset" aria-label="Abbrechen">
                <CloseIcon fontSize="small" />
              </IconButton>
              <div className={classes.wrapper}>
                <IconButton type="submit" disabled={isSubmitting} aria-label="Speichern">
                  <DoneIcon fontSize="small" />
                </IconButton>
                {isSubmitting && <CircularProgress size={20} className={classes.progress} />}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(AdhocInput);
