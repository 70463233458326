import * as React from 'react';
import {
  Input as MuiInput
} from "@material-ui/core";
import { getIn } from 'formik';

export const fieldToInput = ({
  field,
  form,
  disabled,
  ...props
}) => {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  return {
    ...props,
    ...field,
    error: showError,
    disabled: disabled !== undefined ? disabled : isSubmitting,
  };
};

const Input = ({
  children,
  ...props
}) => (
  <MuiInput {...fieldToInput(props)}>{children}</MuiInput>
);
Input.displayName = 'FormikMaterialUIInput';

export default Input;
