import types from './types.js';
import flavors from './flavors.js';

const notificationEnqueue = (message, flavor) => {
  let variant = flavors.INFO;
  let autoHideDuration = 5000;
  if (-1 < [flavors.SUCCESS, flavors.WARNING, flavors.ERROR].indexOf(flavor)) {
    variant = flavor;
  }
  if (-1 < [flavors.WARNING, flavors.ERROR].indexOf(flavor)) {
    autoHideDuration = 10000;
  }
  return ({
    type: types.NOTIFICATION_ENQUEUE,
    payload: {
      key: new Date().getTime() + Math.random(),
      message: message,
      options: {
        variant: variant,
        autoHideDuration: autoHideDuration
      }
    }
  });
};

const notificationRemove = key => ({
  type: types.NOTIFICATION_REMOVE,
  payload: key
});


export default {
  notificationEnqueue,
  notificationRemove
};
