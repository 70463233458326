import { withRouter } from "react-router";
import { connect } from 'react-redux';
import BusinessEditComponent from './BusinessEditComponent';
import { entityKey as businessesSliceKey } from '../../redux/entities/businessesKeys';
import { default as businessesOperations } from '../../redux/entities/businessesOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { isAdmin } = state.authentication;
  const { errors, byId } = state.entities[businessesSliceKey];

  return {
    isAdmin,
    errors,
    byId
  };
};

const mapDispatchToProps = dispatch => {
  const businessGetRequest = id => dispatch(businessesOperations.businessGetRequest(id));
  const businessPutRequest = (id, data, formikBag) => dispatch(businessesOperations.businessPutRequest(id, data, formikBag));

  const businessesCancelRequest = (cancelType, cancelMessage) => dispatch(businessesOperations.businessesCancelRequest(cancelType, cancelMessage));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    businessGetRequest,
    businessPutRequest,
    businessesCancelRequest,
    notificationEnqueue
  };
};

const BusinessEditContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessEditComponent));

export default BusinessEditContainer;