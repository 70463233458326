import { withRouter } from "react-router";
import { connect } from "react-redux";
import PortfolioPdfComponent from "./PortfolioPdfComponent";
import { entityKey as fieldsOfActionSliceKey } from "../../redux/entities/fieldsOfActionKeys";
import { default as fieldsOfActionOperations } from "../../redux/entities/fieldsOfActionOperations";
import { notificationsOperations } from "../../redux/notifications";
import { authenticationSelectors } from "../../redux/authentication";

const mapStateToProps = state => {
  const { isAdmin } = state.authentication;
  const { errors, byId, allIds } = state.entities[fieldsOfActionSliceKey];
  const profile = authenticationSelectors.profile(state);

  return {
    isAdmin,
    errors,
    byId,
    allIds,
    profile
  };
};

const mapDispatchToProps = dispatch => {
  const fieldsOfActionGetRequest = () => dispatch(fieldsOfActionOperations.fieldsOfActionGetRequest());

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    fieldsOfActionGetRequest,
    notificationEnqueue,
  };
};

const PortfolioPdfContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(PortfolioPdfComponent));

export default PortfolioPdfContainer;
