import { keys as genericKeys, type as genericType } from "../generics";

const { intents } = genericKeys;

const nameKeys = {
  COMPETENCERATINGS: 'COMPETENCERATINGS',
  COMPETENCERATING: 'COMPETENCERATING',
};
export const entityKey = nameKeys.COMPETENCERATINGS.toLowerCase();
export const duckTypes = {
  COMPETENCERATINGS_GET: genericType(entityKey, nameKeys.COMPETENCERATINGS, intents.GET),
  COMPETENCERATING_GET: genericType(entityKey, nameKeys.COMPETENCERATING, intents.GET),
  COMPETENCERATING_PUT: genericType(entityKey, nameKeys.COMPETENCERATING, intents.PUT),
  COMPETENCERATING_POST: genericType(entityKey, nameKeys.COMPETENCERATING, intents.POST),
  COMPETENCERATING_COPY: genericType(entityKey, nameKeys.COMPETENCERATING, intents.COPY),
  COMPETENCERATING_DELETE: genericType(entityKey, nameKeys.COMPETENCERATING, intents.DELETE)
};
