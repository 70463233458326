import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { labelsMuiDatatables } from "../../utils/labels";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import routes from "../../utils/routes";
import DetailsIcon from "@material-ui/icons/ExitToApp";
import EditIcon from "@material-ui/icons/Edit";
import { withTableServerDataProvider } from "../../utils/tableServerDataProvider";
import uuidv4 from "uuid/v4";
import PortalToolbarMenu from "../common/navigation/PortalToolbarMenu";
import AddIcon from "@material-ui/icons/AddBox";
import MUIDataTable from "mui-datatables";
import { formatDate } from "../../utils/helper";

const styles = theme => ({
  sectionNew: {
    width: "100%",
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[50],
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

class ReportsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldOfActionId: null
    };
  }

  componentDidMount() {
    this.initData();
  }

  initData() {
    const { match } = this.props;

    if (match.params.fieldOfActionId) {
      this.setState({
        fieldOfActionId: match.params.fieldOfActionId
      }, this.getReports);
    }
  }

  getReports() {
    const {
      reportsByFieldOfActionGetRequest,
      tableServerDataProvider,
      fieldOfActionGetRequest,
      reportsFilterOptionsRequest
    } = this.props;
    const { fieldOfActionId } = this.state;

    const reportsGetFunc = urlParameters =>  {
      urlParameters.requestUuid = uuidv4();
      this.requestUuid = urlParameters.requestUuid;
      reportsByFieldOfActionGetRequest(fieldOfActionId, urlParameters);
    };

    tableServerDataProvider.init(reportsGetFunc);
    fieldOfActionGetRequest(fieldOfActionId);
    reportsFilterOptionsRequest({
      fieldOfAction: fieldOfActionId
    });
  }

  handleClick(evt, route) {
    evt.preventDefault();
    if (route) {
      this.props.history.push(route);
    }
  }

  render() {
    const { byId, allIds, match, fieldsOfActionById, totalCount, filterOptions } = this.props;

    const fieldOfAction = fieldsOfActionById[match.params.fieldOfActionId];

    if (!fieldOfAction) {
      return <></>;
    }

    const tableOptions = {
      selectableRows: "none",
      elevation: 0,
      responsive: "stacked",
      filter: true,
      print: false,
      download: false,
      viewColumns: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 20, 50],
      serverSide: true,
      serverSideFilterList: [],
      textLabels: labelsMuiDatatables,
      count: totalCount
    };

    this.props.tableServerDataProvider.bindToTableOptions(tableOptions);

    const tableColumns = [{
      name: "title",
      label: "Titel",
    },
    {
      name: "duration",
      label: "Dauer",
    },
    {
      name: "dateFromDisplay",
      label: "Start Datum",
    },
    {
      name: "learningPlaces",
      label: "Lernort",
    },
    {
      name: "actions",
      label: " ",
      options: {
        setCellProps: value => ({ align: "right" }),
        customBodyRender: id => {
          return (
            <ButtonGroup color="primary">
              <Tooltip title="Details" aria-label="Details">
                <Button onClick={evt => this.handleClick(evt, routes.REPORT.path.replace(":id", id))}>
                  <DetailsIcon/>
                </Button>
              </Tooltip>
              <Tooltip title="Bearbeiten" aria-label="Bearbeiten">
                <Button onClick={evt => this.handleClick(evt, routes.REPORT_EDIT.path.replace(":id", id))}>
                  <EditIcon/>
                </Button>
              </Tooltip>}
            </ButtonGroup>
          );
        },
        filter: false
      }
    },
    /*
     * Virtual columns for date filter not mapped to real data
     *
     * Keep them at the end such that the other values from tableData
     * are mapped to the correct columns!
     */
    {
      name: "dateFromFrom",
      label: "Start Datum von",
      options: {
        filter: true,
        display: false,
      }
    },
    {
      name: "dateFromTo",
      label: "Start Datum bis",
      options: {
        filter: true,
        display: false,
      }
    }];

    this.props.tableServerDataProvider.bindTableFilters(filterOptions, tableColumns);

    const tableData = allIds
      .filter(
        itemId => itemId in byId && byId[itemId].fetchInfos && this.requestUuid &&
          byId[itemId].fetchInfos.url.indexOf(`requestUuid=${this.requestUuid}`) >= 0
      )
      .map(itemId => {
        const item = byId[itemId];

        if (!item) {
          return [];
        }

        const learningPlaceName = item.learningPlaceName ? item.learningPlaceName : "";

        const { title, duration, dateFrom } = item;

        return [title, duration, formatDate(dateFrom, "EEE dd.MM.yyyy"), learningPlaceName, item.id];
      });

    const tableTitle = "Einträge – " + (fieldOfAction ? fieldOfAction.name : "");

    const portalToolbarMenuData = [
      {
        "title": "Neuen Eintrag anlegen",
        "action": () => {
          const createNewPath = routes.REPORT_NEW.path.replace(":fieldOfActionId", fieldOfAction.id);
          this.props.history.push(createNewPath);
        },
        "icon": AddIcon,
        "visible": true,
        "disabled": false
      }
    ];

    return <>
      <PortalToolbarMenu menuData={portalToolbarMenuData}/>
      <MUIDataTable title={tableTitle} options={tableOptions} columns={tableColumns} data={tableData}/>
    </>;
  }
}

export default withTableServerDataProvider(withStyles(styles)(ReportsComponent));
