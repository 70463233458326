import { schema } from 'normalizr';
import { requestsOperations } from "./requests";
import { entityKey, duckTypes } from "./competenceSpecificationsKeys";
import { entityKey as competenceRatingsSliceKey } from './competenceRatingsKeys';
import { default as competenceRatingsOperations } from './competenceRatingsOperations';

const competenceSpecificationSchema = new schema.Entity(entityKey);
const competenceSpecificationListSchema = new schema.Array(competenceSpecificationSchema);

const fetchRelations = [{
  relationResponseKey: 'competenceRatings',
  relationSliceKey: competenceRatingsSliceKey,
  operation: competenceRatingsOperations.competenceRatingsByCompetenceSpecificationGetRequest,
  noRefetch: false,
  isSingle: false,
}];

const listGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.COMPETENCESPECIFICATIONS_GET,
    requestPath: url,
    normalizeSchema: competenceSpecificationListSchema,
  };

  return requestsOperations.requestsOperationGeneric(null, settings);
};

const competenceSpecificationsGetRequest = () => listGetRequest('/competence-checks');

const associationGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.COMPETENCESPECIFICATIONS_GET,
    requestPath: url,
    clearEntityData: false,
    normalizeSchema: competenceSpecificationListSchema,
    requestCancelling: false,
  };
  return requestsOperations.requestsOperationGeneric(null, settings);
};

const competenceSpecificationsByCompetenceCheckGetRequest = (competenceCheckId) => associationGetRequest(`competence-checks/${competenceCheckId}/competence-specifications`);

const competenceSpecificationGetRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.COMPETENCESPECIFICATION_GET,
    requestPath: `/competence-specifications/${id}`,
    idParamRequired: true,
    normalizeSchema: competenceSpecificationSchema,
    fetchRelations: fetchRelations,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceSpecificationPutRequest = (id, data, formikBag) => {
  const params = {
    id: id,
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.COMPETENCESPECIFICATION_PUT,
    requestType: 'put',
    requestPath: `/competence-specifications/${id}`,
    idParamRequired: true,
    normalizeSchema: competenceSpecificationSchema,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceSpecificationPostRequest = (competenceCheckId, data, formikBag) => {
  const params = {
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.COMPETENCESPECIFICATION_POST,
    requestType: 'post',
    requestPath: `/competence-checks/${competenceCheckId}/competence-specifications`,
    normalizeSchema: competenceSpecificationSchema,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceSpecificationDeleteRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.COMPETENCESPECIFICATION_DELETE,
    requestType: 'delete',
    requestPath: `/competence-specifications/${id}`,
    idParamRequired: true,
    normalizeSchema: competenceSpecificationSchema,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceSpecificationsCancelRequest = (cancelType, cancelMessage) => {
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsCancellation(entityKey, cancelType, cancelMessage));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

export default {
  competenceSpecificationsGetRequest,
  competenceSpecificationGetRequest,
  competenceSpecificationPutRequest,
  competenceSpecificationPostRequest,
  competenceSpecificationDeleteRequest,
  competenceSpecificationsByCompetenceCheckGetRequest,
  competenceSpecificationsCancelRequest,
};
