import React from "react";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import OpenLinkIcon from '@material-ui/icons/OpenInNew';
import List from "@material-ui/core/List";
import LinkIcon from '@material-ui/icons/Link';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from '@material-ui/core/Typography';
import ShowMoreText from "../common/ShowMoreText";

const styles = theme => ({
    iconLocked: {
        marginRight: theme.spacing(1),
        verticalAlign: 'middle',
        fontSize: 'inherit',
    },
});

const ListFiles = props => {
    const { classes, ids, byId } = props;

    const handleLink = (evt, url) => {
        evt.preventDefault();
        if (url) {
            window.open(url);
        }
    };

    if (!ids || 0 === ids.length) {
        return null;
    }

    return (
        <List dense>
            {ids.map((id, index) => {
                const item = byId[id];
                const hasDescription = item && item.description && item.description.trim().length > 0;

                return item ? (
                    <React.Fragment key={id}>
                        {index > 0 && <Divider variant="inset" component="li" />}
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar><LinkIcon/></Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={item.title}
                                secondary={
                                    <React.Fragment>
                                        {item.hiddenFromApprentice ? (
                                            <Tooltip title="Kein Zugriff für Auszubildende" aria-label="Kein Zugriff für Auszubildende">
                                                <LockIcon color="action" classes={{
                                                    root: classes.iconLocked
                                                }}/>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Ohne Beschränkung" aria-label="Ohne Beschränkung">
                                                <LockOpenIcon color="disabled" classes={{
                                                    root: classes.iconLocked
                                                }}/>
                                            </Tooltip>
                                        )}
                                        <Typography component="span" variant="caption" className={classes.inline} color="textPrimary">
                                            {item.url}
                                        </Typography>
                                        {hasDescription &&
                                            <React.Fragment>
                                                <br/>
                                                <ShowMoreText maxLength={64}>{item.description}</ShowMoreText>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                            />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" onClick={evt => handleLink(evt, item.url)} aria-label="Link aufrufen">
                                    <OpenLinkIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </React.Fragment>
                ) : null;
            })}
        </List>
    );
};

export default withStyles(styles)(ListFiles);
