import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {AppContext} from '../../AppComponent';
import Portal from '@material-ui/core/Portal';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton/IconButton";
import MoreIcon from '@material-ui/icons/MoreVert';
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";

const styles = theme => ({
    toolbarSectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
        },
    },
    toolbarSectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    toolbarSectionAll: {
        display: 'flex',
    },
});

class PortalToolbarMenu extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.toolbarMenuRef = null;

        this.state = {
            mobileMenuAnchor: null,
        };
    }

    componentDidMount() {
        this.toolbarMenuRef = this.context.getToolbarMenuRef();
      }

    handleMobileMenuOpen = evt => {
        this.setState({ mobileMenuAnchor: evt.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMenuAnchor: null });
    };

    render() {
        const { classes, menuData } = this.props;
        const { mobileMenuAnchor } = this.state;

        if (!this.toolbarMenuRef || !menuData || menuData.length === 0) {
            return null;
        }

        return (
            <React.Fragment>
                <Portal container={this.toolbarMenuRef}>
                    <div className={clsx({
                        [classes.toolbarSectionDesktop]: menuData.length !== 1,
                        [classes.toolbarSectionAll]: menuData.length === 1
                    })}>
                        {menuData.map((item, key) => {
                            const { title, action, icon, visible, disabled } = item;
                            // NOTE: SPAN around IconButton needed for Tooltip, when IconButton is disabled
                            return visible ? ( 
                                <Tooltip key={`toolbarMenuDesktop-${key}`} title={title} aria-label={title}>
                                    <span>
                                      <IconButton onClick={action} color="inherit" children={React.createElement(icon)} disabled={disabled} />
                                    </span>
                                </Tooltip>
                            ) : null;
                        })}
                    </div>
                    {menuData.length > 1 &&
                        <div className={classes.toolbarSectionMobile}>
                            <Tooltip title="Menü" aria-label="Menü">
                                <IconButton aria-haspopup="true" onClick={evt => this.handleMobileMenuOpen(evt)} color="inherit">
                                    <MoreIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    }
                </Portal>
                {menuData.length > 1 &&
                    <Menu
                        anchorEl={mobileMenuAnchor}
                        anchorOrigin={{ vertical: 'top',
                        horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top',
                        horizontal: 'right' }}
                        open={!!mobileMenuAnchor}
                        onClose={this.handleMobileMenuClose}>
                        {menuData.map((item, key) => {
                            const { title, action, icon, visible, disabled } = item;
                            return visible ? ( 
                                <MenuItem key={`toolbarMenuMobile-${key}`} onClick={action} disabled={disabled}>
                                    <ListItemIcon children={React.createElement(icon)}/>
                                    <ListItemText primary={title} />
                                </MenuItem>
                            ) : null;
                        })}
                    </Menu>
                }
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(PortalToolbarMenu);

