import { withRouter } from "react-router";
import { connect } from 'react-redux';
import BusinessNewComponent from './BusinessNewComponent';
import { entityKey as businessesSliceKey } from '../../redux/entities/businessesKeys';
import { default as businessesOperations } from '../../redux/entities/businessesOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { isAdmin } = state.authentication;
  const { errors, byId } = state.entities[businessesSliceKey];

  return {
    isAdmin,
    errors,
    byId
  };
};

const mapDispatchToProps = dispatch => {
  const businessPostRequest = (data, formikBag) => dispatch(businessesOperations.businessPostRequest(data, formikBag));

  const businessesCancelRequest = (cancelType, cancelMessage) => dispatch(businessesOperations.businessesCancelRequest(cancelType, cancelMessage));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    businessPostRequest,
    businessesCancelRequest,
    notificationEnqueue
  };
};

const BusinessNewContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessNewComponent));

export default BusinessNewContainer;