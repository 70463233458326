import { schema } from 'normalizr';
import { requestsOperations } from "./requests";
import { entityKey, duckTypes } from "./competenceEvaluationsKeys";

const competenceEvaluationSchema = new schema.Entity(entityKey);
const competenceEvaluationListSchema = new schema.Array(competenceEvaluationSchema);

const fetchRelations = [];

const listGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.COMPETENCEEVALUATIONS_GET,
    requestPath: url,
    normalizeSchema: competenceEvaluationListSchema
  };

  return requestsOperations.requestsOperationGeneric(null, settings);
};

const competenceEvaluationsGetRequest = () => listGetRequest('/competence-evaluations');

const associationGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.COMPETENCEEVALUATIONS_GET,
    requestPath: url,
    clearEntityData: false,
    normalizeSchema: competenceEvaluationListSchema,
    requestCancelling: false,
  };
  return requestsOperations.requestsOperationGeneric(null, settings);
};

const competenceEvaluationsByCompetenceCheckGetRequest = (competenceCheckId) => associationGetRequest(`/competence-checks/${competenceCheckId}/competence-evaluations`);

const competenceEvaluationGetRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.COMPETENCEEVALUATION_GET,
    requestPath: `/competence-evaluations/${id}`,
    idParamRequired: true,
    normalizeSchema: competenceEvaluationListSchema,
    fetchRelations: fetchRelations,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceEvaluationsCancelRequest = (cancelType, cancelMessage) => {
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsCancellation(entityKey, cancelType, cancelMessage));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

export default {
  competenceEvaluationsGetRequest,
  competenceEvaluationGetRequest,
  competenceEvaluationsByCompetenceCheckGetRequest,
  competenceEvaluationsCancelRequest,
};
