import {
  Home as HomeIcon,
  Assignment as TaskIcon,
  Business as BusinessIcon,

  // PermContactCalendar as ContactIcon,
  School as ProfessionIcon,
  AttachFile as FileIcon,
  Link as LinkIcon,
  AccountBalance as FacilityIcon,
  Category as FieldOfActionIcon,
  Receipt as SubTaskIcon,
  Email as MessageIcon,
  Help as HelpIcon,
  Lock as PasswordIcon,
  LockOpen as LoginIcon,
  PersonAdd as RegistrationIcon,
  Update as ActivationIcon,
  Dashboard as DashboardIcon,
  Group as UsersIcon,
  AssignmentInd as ProfileDataIcon,
  Stars as CompetenceChecksIcon,
  Star as CompetenceCheckIcon,
  LibraryBooks as PortfolioIcon,
  PictureAsPdf as PdfIcon,
  FolderSpecial as CompetenceChecksMasterIcon,
  Folder as MasterIcon,
  Archive as ArchiveIcon,
  Build as BuildIcon
} from "@material-ui/icons";

import { Dashboard } from "../views/dashboard";
import { Businesses, BusinessNew, BusinessEdit } from "../views/businesses";
import { FieldsOfAction, FieldOfActionNew, FieldOfActionEdit } from "../views/fieldsOfAction";
import { Professions, ProfessionNew, ProfessionEdit } from "../views/professions";
import { Facilities, FacilityNew, FacilityEdit } from "../views/facilities";
import { Files, FileNew, FileEdit } from "../views/files";
import { Links, LinkNew, LinkEdit } from "../views/links";
import { Tasks, TaskNew, Task, TaskEdit, MasterTasks, ArchivedTasks } from "../views/tasks";
import { SubTaskNew, SubTask, SubTaskEdit } from "../views/subtasks";
import NotificationContainer from "../views/notifications/NotificationContainer";
import { UserRegistration } from "../views/userregistration";
import { UserActivation } from "../views/useractivation";
import { Users, UserEdit } from "../views/users";
import { Login } from "../views/login";
import { PasswordRecovery } from "../views/passwordrecovery";
import { PasswordReset } from "../views/passwordreset";
import { Terms } from "../views/terms";
import { Help } from "../views/help";
import { Home } from "../views/home";
import { Portfolio, PortfolioPdf } from "../views/portfolio";
import { Report, ReportEdit, ReportNew, Reports } from "../views/reports";
import { ResultEdit, ResultNew } from "../views/results";
import { CompetenceChecks, CompetenceChecksMaster, CompetenceChecksArchived, CompetenceCheckNew, CompetenceCheckEdit, CompetenceCheck } from "../views/competenceChecks";

// NOTE: arrange routes with :ids (i.e. "details") after static routes (i.e. "new") to clear up any ambiguous matching
// https://reacttraining.com/react-router/web/example/ambiguous-matches
const routes = {
  DASHBOARD: {
    path: "/dashboard",
    exact: true,
    title: "Dashboard",
    shortTitle: null,
    icon: DashboardIcon,
    component: Dashboard,
    isPrivate: true,
    roles: [],
    parent: null
  },
  BUSINESSES: {
    path: "/betriebe",
    exact: true,
    title: "Betriebe",
    shortTitle: null,
    icon: BusinessIcon,
    component: Businesses,
    isPrivate: true,
    roles: ["ROLE_ADMIN"],
    parent: "DASHBOARD"
  },
  BUSINESS_NEW: {
    path: "/betrieb-neu",
    exact: true,
    title: "Betrieb anlegen",
    shortTitle: null,
    icon: BusinessIcon,
    component: BusinessNew,
    isPrivate: true,
    roles: ["ROLE_ADMIN"],
    parent: "BUSINESSES"
  },
  BUSINESS_EDIT: {
    path: "/betrieb-bearbeiten/:id",
    exact: true,
    title: "Betrieb",
    shortTitle: null,
    icon: BusinessIcon,
    component: BusinessEdit,
    isPrivate: true,
    roles: ["ROLE_ADMIN"],
    parent: "BUSINESSES"
  },
  PROFESSIONS: {
    path: "/berufe",
    exact: true,
    title: "Berufe",
    shortTitle: null,
    icon: ProfessionIcon,
    component: Professions,
    isPrivate: true,
    roles: ["ROLE_ADMIN"],
    parent: "DASHBOARD"
  },
  PROFESSION_NEW: {
    path: "/beruf-neu",
    exact: true,
    title: "Beruf anlegen",
    shortTitle: null,
    icon: ProfessionIcon,
    component: ProfessionNew,
    isPrivate: true,
    roles: ["ROLE_ADMIN"],
    parent: "PROFESSIONS"
  },
  PROFESSION_EDIT: {
    path: "/beruf-bearbeiten/:id",
    exact: true,
    title: "Beruf",
    shortTitle: null,
    icon: ProfessionIcon,
    component: ProfessionEdit,
    isPrivate: true,
    roles: ["ROLE_ADMIN"],
    parent: "PROFESSIONS"
  },
  FIELDSOFACTION: {
    path: "/aufgabenbereiche",
    exact: true,
    title: "Aufgabenbereiche",
    shortTitle: null,
    icon: FieldOfActionIcon,
    component: FieldsOfAction,
    isPrivate: true,
    roles: ["ROLE_ADMIN", "ROLE_INSTRUCTOR"],
    parent: "DASHBOARD"
  },
  FIELDOFACTION_NEW: {
    path: "/aufgabenbereich-neu",
    exact: true,
    title: "Aufgabenbereich anlegen",
    shortTitle: null,
    icon: FieldOfActionIcon,
    component: FieldOfActionNew,
    isPrivate: true,
    roles: ["ROLE_ADMIN", "ROLE_INSTRUCTOR"],
    parent: "FIELDSOFACTION"
  },
  FIELDOFACTION_EDIT: {
    path: "/aufgabenbereich-bearbeiten/:id",
    exact: true,
    title: "Aufgabenbereich",
    shortTitle: null,
    icon: FieldOfActionIcon,
    component: FieldOfActionEdit,
    isPrivate: true,
    roles: ["ROLE_ADMIN", "ROLE_INSTRUCTOR"],
    parent: "FIELDSOFACTION"
  },
  FACILITIES: {
    path: "/lernorte",
    exact: true,
    title: "Lernorte",
    shortTitle: null,
    icon: FacilityIcon,
    component: Facilities,
    isPrivate: true,
    roles: [],
    parent: "DASHBOARD"
  },
  FACILITY_NEW: {
    path: "/lernort-neu",
    exact: true,
    title: "Lernort anlegen",
    shortTitle: null,
    icon: FacilityIcon,
    component: FacilityNew,
    isPrivate: true,
    roles: ["ROLE_INSTRUCTOR", "ROLE_APPRENTICE"],
    parent: "FACILITIES"
  },
  FACILITY_EDIT: {
    path: "/lernort-bearbeiten/:id",
    exact: true,
    title: "Lernort",
    shortTitle: null,
    icon: FacilityIcon,
    component: FacilityEdit,
    isPrivate: true,
    roles: [],
    parent: "FACILITIES"
  },
  LINKS: {
    path: "/links",
    exact: true,
    title: "Linksammlung",
    shortTitle: null,
    icon: LinkIcon,
    component: Links,
    isPrivate: true,
    roles: [],
    parent: "DASHBOARD"
  },
  LINK_NEW: {
    path: "/link-neu",
    exact: true,
    title: "Link anlegen",
    shortTitle: null,
    icon: LinkIcon,
    component: LinkNew,
    isPrivate: true,
    roles: [],
    parent: "LINKS"
  },
  LINK_EDIT: {
    path: "/link-bearbeiten/:id",
    exact: true,
    title: "Link",
    shortTitle: null,
    icon: LinkIcon,
    component: LinkEdit,
    isPrivate: true,
    roles: [],
    parent: "LINKS"
  },
  FILES: {
    path: "/dateien",
    exact: true,
    title: "Dateimanager",
    shortTitle: null,
    icon: FileIcon,
    component: Files,
    isPrivate: true,
    roles: [],
    parent: "DASHBOARD"
  },
  FILE_NEW: {
    path: "/datei-neu",
    exact: true,
    title: "Datei anlegen",
    shortTitle: null,
    icon: FileIcon,
    component: FileNew,
    isPrivate: true,
    roles: [],
    parent: "FILES"
  },
  FILE_EDIT: {
    path: "/datei-bearbeiten/:id",
    exact: true,
    title: "Datei",
    shortTitle: null,
    icon: FileIcon,
    component: FileEdit,
    isPrivate: true,
    roles: [],
    parent: "FILES"
  },
  TASKS: {
    path: "/aufgaben",
    exact: true,
    title: "Aufgaben",
    shortTitle: null,
    icon: TaskIcon,
    component: Tasks,
    isPrivate: true,
    roles: [],
    parent: "DASHBOARD"
  },
  MASTER_TASKS: {
    path: "/aufgaben-vorlagen",
    exact: true,
    title: "Aufgabenvorlagen",
    shortTitle: "Vorlagen",
    icon: MasterIcon,
    component: MasterTasks,
    isPrivate: true,
    roles: [],
    parent: "DASHBOARD"
  },
  ARCHIVED_TASKS: {
    path: "/archivierte-aufgaben",
    exact: true,
    title: "Aufgabenarchiv",
    shortTitle: "Archiv",
    icon: ArchiveIcon,
    component: ArchivedTasks,
    isPrivate: true,
    roles: [],
    parent: "DASHBOARD"
  },
  TASK_NEW: {
    path: "/aufgabe-neu/:master",
    exact: true,
    title: "Aufgabe anlegen",
    shortTitle: null,
    icon: TaskIcon,
    component: TaskNew,
    isPrivate: true,
    roles: ["ROLE_INSTRUCTOR", "ROLE_APPRENTICE"],
    parent: "TASKS"
  },
  TASK_DETAILS: {
    path: "/aufgabe/:id",
    exact: true,
    title: "Aufgabe",
    shortTitle: null,
    icon: TaskIcon,
    component: Task,
    isPrivate: true,
    roles: [],
    parent: "TASKS"
  },
  TASK_EDIT: {
    path: "/aufgabe-bearbeiten/:id",
    exact: true,
    title: "Aufgabe bearbeiten",
    shortTitle: null,
    icon: TaskIcon,
    component: TaskEdit,
    isPrivate: true,
    roles: ["ROLE_INSTRUCTOR", "ROLE_APPRENTICE"],
    parent: "TASK"
  },
  RESULT_NEW: {
    path: "/teilaufgabe/:id/ergebnis-neu/:taskKey",
    exact: true,
    title: "Ergebnis einreichen",
    shortTitle: null,
    icon: SubTaskIcon,
    component: ResultNew,
    isPrivate: true,
    roles: ["ROLE_INSTRUCTOR", "ROLE_APPRENTICE"],
    parent: "SUBTASK_DETAILS"
  },
  RESULT_EDIT: {
    path: "/teilaufgabe/:id/ergebnis-bearbeiten/:subTaskId",
    exact: true,
    title: "Ergebnis bearbeiten",
    shortTitle: null,
    icon: SubTaskIcon,
    component: ResultEdit,
    isPrivate: true,
    roles: ["ROLE_INSTRUCTOR", "ROLE_APPRENTICE"],
    parent: "SUBTASK_DETAILS"
  },
  SUBTASK_NEW: {
    path: "/teilaufgabe-neu/:id",
    exact: true,
    title: "Teilaufgabe anlegen",
    shortTitle: null,
    icon: SubTaskIcon,
    component: SubTaskNew,
    isPrivate: true,
    roles: ["ROLE_INSTRUCTOR", "ROLE_APPRENTICE"],
    parent: "TASK_DETAILS"
  },
  SUBTASK_DETAILS: {
    path: "/teilaufgabe/:id/:number",
    exact: true,
    title: "Teilaufgabe",
    shortTitle: null,
    icon: SubTaskIcon,
    component: SubTask,
    isPrivate: true,
    roles: [],
    parent: "TASK_DETAILS"
  },
  SUBTASK_EDIT: {
    path: "/teilaufgabe-bearbeiten/:id/:number",
    exact: true,
    title: "Teilaufgabe bearbeiten",
    shortTitle: null,
    icon: SubTaskIcon,
    component: SubTaskEdit,
    isPrivate: true,
    roles: ["ROLE_INSTRUCTOR", "ROLE_APPRENTICE"],
    parent: "SUBTASK_DETAILS"
  },
  PORTFOLIO: {
    path: "/berichtsheft",
    exact: true,
    title: "Berichtsheft",
    shortTitle: null,
    icon: PortfolioIcon,
    component: Portfolio,
    isPrivate: true,
    roles: ["ROLE_APPRENTICE"],
    parent: "DASHBOARD"
  },
  PORTFOLIO_PDF: {
    path: "/bericht-pdf-erstellen",
    exact: true,
    title: "Bericht erstellen",
    shortTitle: null,
    icon: PdfIcon,
    component: PortfolioPdf,
    isPrivate: true,
    roles: ["ROLE_APPRENTICE"],
    parent: "PORTFOLIO"
  },
  REPORTS: {
    path: "/eintraege/:fieldOfActionId",
    exact: true,
    title: "Einträge",
    shortTitle: null,
    icon: BuildIcon,
    component: Reports,
    isPrivate: true,
    roles: ["ROLE_APPRENTICE"],
    parent: "PORTFOLIO"
  },
  REPORT: {
    path: "/eintrag/:id",
    exact: true,
    title: "Eintrag",
    shortTitle: null,
    icon: BuildIcon,
    component: Report,
    isPrivate: true,
    roles: ["ROLE_APPRENTICE"],
    parent: "REPORTS"
  },
  REPORT_NEW: {
    path: "/eintrag-neu/:fieldOfActionId?",
    exact: true,
    title: "Eintrag anlegen",
    shortTitle: null,
    icon: BuildIcon,
    component: ReportNew,
    isPrivate: true,
    roles: ["ROLE_APPRENTICE"],
    parent: "REPORTS"
  },
  REPORT_EDIT: {
    path: "/eintrag-bearbeiten/:id",
    exact: true,
    title: "Eintrag bearbeiten",
    shortTitle: null,
    icon: BuildIcon,
    component: ReportEdit,
    isPrivate: true,
    roles: ["ROLE_APPRENTICE"],
    parent: "REPORTS"
  },
  COMPETENCECHECKS: {
    path: "/kompentenzchecks",
    exact: true,
    title: "Kompetenzchecks",
    shortTitle: "Checks",
    icon: CompetenceChecksIcon,
    component: CompetenceChecks,
    isPrivate: true,
    roles: ["ROLE_INSTRUCTOR", "ROLE_APPRENTICE"],
    parent: "DASHBOARD"
  },
  COMPETENCECHECKS_TEMPLATES: {
    path: "/kompentenzchecks-vorlagen",
    exact: true,
    title: "Kompetenzcheckvorlagen",
    shortTitle: "Vorlagen",
    icon: CompetenceChecksMasterIcon,
    component: CompetenceChecksMaster,
    isPrivate: true,
    roles: ["ROLE_INSTRUCTOR"],
    parent: "DASHBOARD"
  },
  COMPETENCECHECKS_ARCHIVE: {
    path: "/kompentenzchecks-archiviert",
    exact: true,
    title: "Kompetenzcheckarchiv",
    shortTitle: "Archiv",
    icon: ArchiveIcon,
    component: CompetenceChecksArchived,
    isPrivate: true,
    roles: ["ROLE_INSTRUCTOR", "ROLE_APPRENTICE"],
    parent: "DASHBOARD"
  },
  COMPETENCECHECK_NEW: {
    path: "/kompentenzcheck-neu/:master",
    exact: true,
    title: "Kompetenzcheck anlegen",
    shortTitle: "Check anlegen",
    icon: CompetenceChecksIcon,
    component: CompetenceCheckNew,
    isPrivate: true,
    roles: ["ROLE_INSTRUCTOR"],
    parent: "COMPETENCECHECKS_TEMPLATES"
  },
  COMPETENCECHECK_DETAILS: {
    path: "/kompentenzcheck/:id",
    exact: true,
    title: "Kompetenzcheck",
    shortTitle: null,
    icon: CompetenceCheckIcon,
    component: CompetenceCheck,
    isPrivate: true,
    roles: ["ROLE_INSTRUCTOR", "ROLE_APPRENTICE"],
    parent: "COMPETENCECHECKS"
  },
  COMPETENCECHECK_EDIT: {
    path: "/kompentenzcheck-bearbeiten/:id",
    exact: true,
    title: "Kompetenzcheck bearbeiten",
    shortTitle: "Check bearbeiten",
    icon: CompetenceCheckIcon,
    component: CompetenceCheckEdit,
    isPrivate: true,
    roles: ["ROLE_INSTRUCTOR"],
    parent: "COMPETENCECHECKS_TEMPLATES"
  },
  NOTIFICATIONS: {
    path: "/notifications",
    exact: true,
    title: "Benachrichtigungen",
    shortTitle: null,
    icon: MessageIcon,
    component: NotificationContainer,
    isPrivate: true,
    roles: [],
    parent: "DASHBOARD"
  },
  USERS: {
    path: "/benutzer",
    exact: true,
    title: "Benutzer",
    shortTitle: null,
    icon: UsersIcon,
    component: Users,
    isPrivate: true,
    roles: ["ROLE_ADMIN", "ROLE_INSTRUCTOR"],
    parent: "DASHBOARD"
  },
  USER_EDIT: {
    path: "/benutzer-bearbeiten/:id",
    exact: true,
    title: "Benutzer",
    shortTitle: null,
    icon: ProfileDataIcon,
    component: UserEdit,
    isPrivate: true,
    roles: ["ROLE_ADMIN", "ROLE_INSTRUCTOR"],
    parent: "USERS"
  },
  USER_REGISTRATION: {
    path: "/benutzer-registrieren",
    exact: true,
    title: "Benutzer anlegen",
    shortTitle: null,
    icon: RegistrationIcon,
    component: UserRegistration,
    isPrivate: true,
    roles: ["ROLE_ADMIN", "ROLE_INSTRUCTOR"],
    parent: "USERS"
  },
  USER_ACTIVATION: {
    path: "/benutzer-aktivieren",
    exact: true,
    title: "Benutzer aktivieren",
    shortTitle: null,
    icon: ActivationIcon,
    component: UserActivation,
    isPrivate: false,
    roles: [],
    parent: "LOGIN"
  },
  LOGIN: {
    path: "/login",
    exact: true,
    title: "Login",
    shortTitle: null,
    icon: LoginIcon,
    component: Login,
    isPrivate: false,
    roles: [],
    parent: "HOME"
  },
  PASSWORD_RECOVERY: {
    path: "/passwort-vergessen",
    exact: true,
    title: "Passwort vergessen",
    shortTitle: null,
    icon: PasswordIcon,
    component: PasswordRecovery,
    isPrivate: false,
    roles: [],
    parent: "LOGIN"
  },
  PASSWORD_RESET: {
    path: "/passwort-neu",
    exact: true,
    title: "Neues Passwort",
    shortTitle: null,
    icon: PasswordIcon,
    component: PasswordReset,
    isPrivate: false,
    roles: [],
    parent: "LOGIN"
  },
  PROFILE_EDIT: {
    path: "/profil-bearbeiten",
    exact: true,
    title: "Profil bearbeiten",
    shortTitle: null,
    icon: ProfileDataIcon,
    component: UserEdit,
    isPrivate: true,
    roles: [],
    parent: "LOGIN"
  },
  HELP: {
    path: "/hilfe",
    exact: true,
    title: "Hilfe",
    shortTitle: null,
    icon: HelpIcon,
    component: Help,
    isPrivate: false,
    roles: [],
    parent: "HOME"
  },
  TERMS: {
    path: "/datenschutz",
    exact: true,
    title: "Datenschutz",
    shortTitle: null,
    icon: null,
    component: Terms,
    isPrivate: false,
    roles: [],
    parent: "HOME"
  },
  IMPRINT: {
    path: "/impressum",
    exact: true,
    title: "Impressum",
    shortTitle: null,
    icon: null,
    component: Home,
    isPrivate: false,
    roles: [],
    parent: "HOME"
  },
  HOME: {
    path: "/",
    exact: false,
    title: "Startseite",
    shortTitle: null,
    icon: HomeIcon,
    component: Home,
    isPrivate: false,
    roles: [],
    parent: null
  },
};

export default routes;
