import { withRouter } from "react-router";
import { connect } from "react-redux";
import PortfolioComponent from "./PortfolioComponent";
import { entityKey as fieldsOfActionSliceKey } from "../../redux/entities/fieldsOfActionKeys";
import { default as fieldsOfActionOperations } from "../../redux/entities/fieldsOfActionOperations";

const mapStateToProps = state => {
  const { isAdmin, myJob } = state.authentication;
  const { errors, byId, allIds } = state.entities[fieldsOfActionSliceKey];

  return {
    isAdmin,
    errors,
    byId,
    allIds,
    myJob
  };
};

const mapDispatchToProps = dispatch => {
  const fieldOfActionsByProfessionGetRequest = parameters => dispatch(fieldsOfActionOperations.fieldOfActionsByProfessionGetRequest(parameters));

  return {
    fieldOfActionsByProfessionGetRequest,
  };
};

const PortfolioContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(PortfolioComponent));

export default PortfolioContainer;
