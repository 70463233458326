import packageJson from '../../package.json';

const config = {
  APP_NAME: packageJson.name,
  APP_TITLE: process.env.REACT_APP_SITE_TITLE,
  API_URL: process.env.REACT_APP_API_URL,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  DOWNLOAD_URL: process.env.REACT_APP_DOWNLOAD_URL,
  MESSAGE_NOTAUTHORIZED: "Error: Not logged in.",
  MESSAGE_MISSINGID: "Error: Resource ID missing.",
  MESSAGE_MISSINGSETTINGS: "Error: Request settings incomplete."
};

export default config;