import { keys as genericKeys, type as genericType } from "../generics";

const { intents } = genericKeys;

const nameKeys = {
  FACILITIES: 'FACILITIES',
  FACILITY: 'FACILITY',
};
export const entityKey = nameKeys.FACILITIES.toLowerCase();
export const duckTypes = {
  FACILITIES_GET: genericType(entityKey, nameKeys.FACILITIES, intents.GET),
  FACILITY_GET: genericType(entityKey, nameKeys.FACILITY, intents.GET),
  FACILITY_PUT: genericType(entityKey, nameKeys.FACILITY, intents.PUT),
  FACILITY_POST: genericType(entityKey, nameKeys.FACILITY, intents.POST),
  FACILITY_DELETE: genericType(entityKey, nameKeys.FACILITY, intents.DELETE)
};
