import { schema } from 'normalizr';
import routes from "../../utils/routes";
import getHistory from '../../utils/history';
import { requestsOperations } from "./requests";
import { entityKey as usersSliceKey } from './usersKeys';
import { default as usersOperations } from './usersOperations';
import { entityKey, duckTypes } from "./linksKeys";

const linkSchema = new schema.Entity(entityKey);
const linkListSchema = new schema.Array(linkSchema);

const fetchRelations = [{
  relationResponseKey: 'user',
  relationSliceKey: usersSliceKey,
  operation: usersOperations.usersByLinkGetRequest,
  subresourceOperation: true,
  noRefetch: true,
  isSingle: true,
}];

const listGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.LINKS_GET,
    requestPath: url,
    clearEntityData: true,
    normalizeSchema: linkListSchema,
    fetchRelations: [],
  };

  return requestsOperations.requestsOperationGeneric(null, settings);
};

const linksGetRequest = () => listGetRequest('/links');

const associationGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.LINKS_GET,
    requestPath: url,
    clearEntityData: false,
    normalizeSchema: linkListSchema,
    fetchRelations: fetchRelations,
    requestCancelling: false,
  };

  return requestsOperations.requestsOperationGeneric(null, settings);
};

const linksByTaskGetRequest = (taskId) => associationGetRequest(`/tasks/${taskId}/links`);

const linksBySubtaskGetRequest = (subtaskId) => associationGetRequest(`/subtasks/${subtaskId}/links`);

const linksByResultGetRequest = (resultId) => associationGetRequest(`/results/${resultId}/links`);

const linksByReportGetRequest = (reportId) => associationGetRequest(`/reports/${reportId}/links`);

const linkGetRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.LINK_GET,
    requestPath: `/links/${id}`,
    idParamRequired: true,
    normalizeSchema: linkSchema,
    fetchRelations: fetchRelations
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const linkPutRequest = (id, data, formikBag) => {
  const params = {
    id: id,
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.LINK_PUT,
    requestType: 'put',
    requestPath: `/links/${id}`,
    idParamRequired: true,
    normalizeSchema: linkSchema,
    appendOnSuccess: gotoList
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const linkPostRequest = (data, formikBag) => {
  const params = {
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.LINK_POST,
    requestType: 'post',
    requestPath: '/links',
    normalizeSchema: linkSchema
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const linkDeleteRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.LINK_DELETE,
    requestType: 'delete',
    requestPath: `/links/${id}`,
    idParamRequired: true,
    normalizeSchema: linkSchema
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const linksCancelRequest = (cancelType, cancelMessage) => {
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsCancellation(entityKey, cancelType, cancelMessage));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const gotoList = () => {
  getHistory().push(routes.LINKS.path);
};

export default {
  linksGetRequest,
  linkGetRequest,
  linkPutRequest,
  linkPostRequest,
  linkDeleteRequest,
  linksCancelRequest,
  linksByTaskGetRequest,
  linksBySubtaskGetRequest,
  linksByResultGetRequest,
  linksByReportGetRequest,
};
