import { withRouter } from "react-router";
import { connect } from 'react-redux';
import BusinessesComponent from './BusinessesComponent';
import { entityKey as businessesSliceKey } from '../../redux/entities/businessesKeys';
import { default as businessesOperations } from '../../redux/entities/businessesOperations';
import { notificationsOperations } from "../../redux/notifications";
import { withTableServerDataProvider } from "../../utils/tableServerDataProvider";

const mapStateToProps = state => {
  const { isAdmin } = state.authentication;
  const { errors, byId, allIds, totalCount, filterOptions } = state.entities[businessesSliceKey];

  return {
    isAdmin,
    errors,
    byId,
    allIds,
    totalCount,
    filterOptions
  };
};

const mapDispatchToProps = dispatch => {
  const businessesGetRequest = (urlParameters) => dispatch(businessesOperations.businessesGetRequest(urlParameters));

  const businessesFilterOptionsRequest = (urlParameters) => dispatch(businessesOperations.businessesFilterOptionsRequest(urlParameters));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    businessesGetRequest,
    notificationEnqueue,
    businessesFilterOptionsRequest
  };
};

const BusinessesContainer = withTableServerDataProvider(withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessesComponent)));

export default BusinessesContainer;