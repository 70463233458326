import React from 'react';
import AddIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import OpenLinkIcon from '@material-ui/icons/OpenInNew';
import Button from "@material-ui/core/Button/Button";
import routes from "../../utils/routes";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { labelsMuiDatatables } from "../../utils/labels";
import RemovalDialog from "../common/RemovalDialog";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ShowMoreText from "../common/ShowMoreText";
import MUIDataTable from "mui-datatables";
import PortalToolbarMenu from '../common/navigation/PortalToolbarMenu';
import Link from '@material-ui/core/Link';

class LinksComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      removalIds: [],
      removalSubject: '',
      removalDialogOpen: false
    };
  }

  componentDidMount() {
    this.initData();
  }

  initData = () => {
    this.props.linksGetRequest();
  };

  handleRoute = (evt, route) => {
    evt.preventDefault();
    if (route) {
      this.props.history.push(route);
    }
  };

  handleNew = () => {
    this.props.history.push(routes.LINK_NEW.path);
  };

  handleLink = (evt, id) => {
    evt.preventDefault();
    if (id) {
      const url = this.props.byId[id].url;
      window.open(url);
    }
  };

  render() {
    const { isAdmin, isInstructor, byId, allIds } = this.props;

    const isApprentice = !isAdmin && !isInstructor;

    const portalToolbarMenuData = [
      {
        "title": "Neuen Link anlegen",
        "action": () => this.handleNew(),
        "icon": AddIcon,
        "visible": true,
        "disabled": false
      }
    ];

    const tableOptions = {
      selectableRows: 'multiple',
      elevation: 0,
      responsive: "stacked",
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 20, 50],
      textLabels: labelsMuiDatatables,
      customSort: (data, colIndex, order) => {
        return data.sort((a, b) => {
          let valA = a.data[colIndex];
          let valB = b.data[colIndex];
          return (valA < valB ? -1 : 1) * (order === 'desc' ? -1 : 1);
        });
      },
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col !== null) {
            let cellContent = '';
            if (Array.isArray(col)) {
              col.forEach(index => {
                const value = col[index] || '';
                cellContent += `${value.toString().toLowerCase()} `;
              });
            } else {
              cellContent = col.toString();
            }
            if (cellContent.indexOf(searchQuery.toLowerCase()) >= 0) {
              isFound = true;
            }
          }
        });
        return isFound;
      },
      onRowsDelete: rowsDeleted => {
        const deleteIds = rowsDeleted.data.map(item => tableData[item.dataIndex][3]);
        if (deleteIds.length > 0) {
          this.setState({
            removalIds: deleteIds,
            removalSubject: deleteIds.length + ' Link(s)',
            removalDialogOpen: true
          });
        }
        return false; // NOTE: No row delete here b/c deletion could be blocked for some links... rows will be deleted when link deletion was successful and thus link data is removed from redux
      },
    };

    const tableColumns = [{
      name: "meta",
      label: "Titel, Beschreibung",
      options: {
        sort: false,
        customBodyRender: value => {
          return (
            <React.Fragment>
              {value[0]}
              {value[1] &&
              <React.Fragment>
                <br/>
                <ShowMoreText maxLength={64}>{value[1]}</ShowMoreText>
              </React.Fragment>
              }
            </React.Fragment>
          )
        }
      }
    }, {
      name: "url",
      label: "URL",
      options: {
        customBodyRender: value => {
          return (
            <Link href={value} color="secondary" target="_blank" rel="noopener noreferrer">{value}</Link>
          )
        }
      }
    }, {
      name: "hidden",
      label: " ",
      options: {
        display: !isApprentice,
        sort: false,
        customBodyRender: value => value ? (
          <Tooltip title="Kein Zugriff für Auszubildende" aria-label="Kein Zugriff für Auszubildende">
            <LockIcon color="action"/>
          </Tooltip>
        ) : (
          <Tooltip title="Ohne Beschränkung" aria-label="Ohne Beschränkung">
            <LockOpenIcon color="disabled"/>
          </Tooltip>
        )
      }
    }, {
      name: "actions",
      label: " ",
      options: {
        sort: false,
        setCellProps: value => ({ align: "right" }),
        customBodyRender: value => {
          return (
            <React.Fragment>
              <ButtonGroup color="primary">
                <Tooltip title="Bearbeiten" aria-label="Bearbeiten">
                  <Button onClick={evt => this.handleRoute(evt, routes.LINK_EDIT.path.replace(':id', value))}>
                    <EditIcon/>
                  </Button>
                </Tooltip>
                <Tooltip title="Link aufrufen" aria-label="Link aufrufen">
                  <Button onClick={evt => this.handleLink(evt, value)}>
                    <OpenLinkIcon/>
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </React.Fragment>
          );
        }
      }
    }];

    const tableData = allIds.map(itemId => {
      const item = byId[itemId];
      if (!item) {
        return [];
      }
      const meta = [
        item.title,
        item.description
      ];
      return [
        meta,
        item.url,
        item.hiddenFromApprentice,
        item.id
      ];
    });

    return (
      <React.Fragment>
        <PortalToolbarMenu menuData={portalToolbarMenuData}/>
        <MUIDataTable title={"Alle Links"} options={tableOptions} columns={tableColumns} data={tableData}/>
        <RemovalDialog
          open={this.state.removalDialogOpen}
          subject={this.state.removalSubject}
          onClose={remove => {
            if (remove === true) {
              this.state.removalIds.forEach(id => {
                this.props.linkDeleteRequest(id);
              });
            }
            this.setState({
              removalIds: [],
              removalSubject: '',
              removalDialogOpen: false
            });
          }}
        />
      </React.Fragment>
    );
  }
}

export default LinksComponent;
