import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import ListFiles from "./ListFiles";
import { connect } from "react-redux";
import { requestsSelectors } from "../../redux/entities/requests";
import { entityKey as filesSliceKey } from "../../redux/entities/filesKeys";
import { entityKey as linksSliceKey } from "../../redux/entities/linksKeys";
import { entityKey as usersSliceKey } from "../../redux/entities/usersKeys";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from "@material-ui/core/Typography";
import ListLinks from "./ListLinks";
import Button from "@material-ui/core/Button";
import routes from "../../utils/routes";
import { withRouter } from "react-router-dom";
import config from "../../utils/config";
import ReactHtmlParser from "react-html-parser";
import { default as filesOperations } from "../../redux/entities/filesOperations";
import { default as linksOperations } from "../../redux/entities/linksOperations";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  cardContent: {
    padding: theme.spacing(1.5, 1.5, 0),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
});

class ListResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filesRequested: {},
      filesReceived: {},
      linksRequested: {},
      linksReceived: {},
    };
  }

  handleEdit(evt, resultId, subTaskId) {
    const url = routes.RESULT_EDIT.path
      .replace(":id", resultId)
      .replace(":subTaskId", subTaskId);

    this.props.history.push(url);
  }

  handleDownload = (evt, id) => {
    evt.preventDefault();
    if (id) {
      const filename = this.props.filesById[id].filename;
      window.open(config.DOWNLOAD_URL + `/uploads/${filename}`);
    }
  };

  handleShowFiles(evt, id) {
    const { filesRequested } = this.state;
    if (!filesRequested[id]) {
      this.setState(prevState => ({
        ...prevState,
        filesRequested: {
          ...prevState.filesRequested,
          [id]: true
        }
      }));
      this.props.filesByResultGetRequest(id).then(() => {
        this.setState(prevState => ({
          ...prevState,
          filesReceived: {
            ...prevState.filesReceived,
            [id]: true
          }
        }));
      });
    }
  }

  handleShowLinks(evt, id) {
    const { linksRequested } = this.state;
    if (!linksRequested[id]) {
      this.setState(prevState => ({
        ...prevState,
        linksRequested: {
          ...prevState.linksRequested,
          [id]: true
        }
      }));
      this.props.linksByResultGetRequest(id).then(() => {
        this.setState(prevState => ({
          ...prevState,
          linksReceived: {
            ...prevState.linksReceived,
            [id]: true
          }
        }));
      });
    }
  }

  /* Why this instead of result media array?
  getFileIdsForResult(result) {
    return Object.values(this.props.filesById)
      .filter((file) => file.fetchInfos && file.fetchInfos.url === `/results/${result.id}/media`)
      .map(file => file.id);
  }
  */

  /* Why this instead of result links array?
  getLinkIdsForResult(result) {
    return Object.values(this.props.linksById)
      .filter((link) => link.fetchInfos && link.fetchInfos.url === `/results/${result.id}/links`)
      .map(link => link.id);
  }
  */

  render() {
    const { ids, byId, isFetchingFiles, isFetchingLinks, filesById, linksById, classes, canEdit, subTask } = this.props;
    const { filesRequested, filesReceived, linksRequested, linksReceived } = this.state;

    return (
      <React.Fragment>
        {ids.map((id) => {
          const item = byId[id];
          if (!item) {
            return null;
          }

          let mediaCount = 0;
          if ('mediaCount' in item) {
            mediaCount = item.mediaCount;
          } else if ('media' in item) {
            mediaCount = item.media.length;
          }

          let linksCount = 0;
          if ('linksCount' in item) {
            linksCount = item.linksCount;
          } else if ('links' in item) {
            linksCount = item.links.length;
          }

          return (
            <Card key={id} className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="caption" display="block" color="textSecondary">
                  {item.ownerName}
                </Typography>
                <div>
                  {ReactHtmlParser(item.text)}
                </div>
                {canEdit &&
                  <Button variant="outlined" size="small" color="primary" onClick={evt => this.handleEdit(evt, item.id, subTask.id)}>
                    Bearbeiten
                  </Button>
                }
                {(mediaCount > 0 && filesReceived[id]) &&
                  <ListFiles ids={item.media} byId={filesById} actionHandler={this.handleDownload}/>
                }
                {(linksCount > 0 && linksReceived[id]) &&
                  <ListLinks ids={item.links} byId={linksById}/>
                }
              </CardContent>
              {(
                (mediaCount > 0 && !filesReceived[id]) ||
                (linksCount > 0 && !linksReceived[id])
              ) &&
                <CardActions className={classes.cardActions}>
                  {(mediaCount > 0 && !filesReceived[id]) &&
                    <div className={classes.wrapper}>
                      <Button size="small" color="primary" onClick={evt => this.handleShowFiles(evt, id)}>
                        {mediaCount} Datei{mediaCount !== 1 && 'en'} anzeigen
                      </Button>
                      {filesRequested[id] && isFetchingFiles && <CircularProgress size={24} color="primary" className={classes.progress} />}
                    </div>
                  }
                  {(linksCount > 0 && !linksReceived[id]) &&
                    <div className={classes.wrapper}>
                      <Button size="small" color="primary" onClick={evt => this.handleShowLinks(evt, id)}>
                        {linksCount} Link{linksCount !== 1 && 's'} anzeigen
                      </Button>
                      {linksRequested[id] && isFetchingLinks && <CircularProgress size={24} color="primary" className={classes.progress} />}
                    </div>
                  }
                </CardActions>
              }
            </Card>
          );
        })}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const isFetchingFiles = requestsSelectors.checkIfFetching(state.entities, filesSliceKey);
  const isFetchingLinks = requestsSelectors.checkIfFetching(state.entities, linksSliceKey);
  const filesById = state.entities[filesSliceKey].byId;
  const linksById = state.entities[linksSliceKey].byId;
  const usersById = state.entities[usersSliceKey].byId;

  return {
    isFetchingFiles,
    isFetchingLinks,
    filesById,
    linksById,
    usersById,
  };
};

const mapDispatchToProps = dispatch => {
  const filesByResultGetRequest = resultId => dispatch(filesOperations.filesByResultGetRequest(resultId));
  const linksByResultGetRequest = resultId => dispatch(linksOperations.linksByResultGetRequest(resultId));
  return {
    filesByResultGetRequest,
    linksByResultGetRequest,
  };
};

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ListResults)));
