import { withRouter } from "react-router";
import { connect } from 'react-redux';
import FieldOfActionNewComponent from './FieldOfActionNewComponent';
import { entityKey as fieldsOfActionSliceKey } from '../../redux/entities/fieldsOfActionKeys';
import { default as fieldsOfActionOperations } from '../../redux/entities/fieldsOfActionOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { isAdmin } = state.authentication;
  const { errors, byId } = state.entities[fieldsOfActionSliceKey];

  return {
    isAdmin,
    errors,
    byId
  };
};

const mapDispatchToProps = dispatch => {
  const fieldOfActionPostRequest = (data, formikBag) => dispatch(fieldsOfActionOperations.fieldOfActionPostRequest(data, formikBag));

  const fieldsOfActionCancelRequest = (cancelType, cancelMessage) => dispatch(fieldsOfActionOperations.fieldsOfActionCancelRequest(cancelType, cancelMessage));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    fieldOfActionPostRequest,
    fieldsOfActionCancelRequest,
    notificationEnqueue
  };
};

const FieldOfActionNewContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FieldOfActionNewComponent));

export default FieldOfActionNewContainer;