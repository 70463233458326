import { withRouter } from "react-router";
import { connect } from 'react-redux';
import TaskComponent from './TaskComponent';
import { entityKey as tasksSliceKey } from '../../redux/entities/tasksKeys';
import { default as tasksOperations } from '../../redux/entities/tasksOperations';
import { default as professionsOperations } from '../../redux/entities/professionsOperations';
import { entityKey as usersSliceKey } from '../../redux/entities/usersKeys';
import { default as usersOperations } from '../../redux/entities/usersOperations';
import { entityKey as fieldsOfActionSliceKey } from '../../redux/entities/fieldsOfActionKeys';
import { entityKey as filesSliceKey } from '../../redux/entities/filesKeys';
import { entityKey as linksSliceKey } from '../../redux/entities/linksKeys';
import { entityKey as subTasksSliceKey } from '../../redux/entities/subTasksKeys';
import { default as subTasksOperations } from '../../redux/entities/subTasksOperations';
import { notificationsOperations } from "../../redux/notifications";
import { uiEntityKeys, viewKeys, metaKeys, uiOperations } from "../../redux/ui";

const uiEntityKey = uiEntityKeys.views;
const viewKey = viewKeys.TASK.key;

const mapStateToProps = state => {
  const view = state.ui[uiEntityKey][viewKey];
  const { myId, isAdmin, isInstructor } = state.authentication;
  const { errors, byId } = state.entities[tasksSliceKey];
  const usersById = state.entities[usersSliceKey].byId;
  const fieldsOfActionById = state.entities[fieldsOfActionSliceKey].byId;
  const filesById = state.entities[filesSliceKey].byId;
  const linksById = state.entities[linksSliceKey].byId;
  const subTasksById = state.entities[subTasksSliceKey].byId;

  return {
    view,
    myId,
    isAdmin,
    isInstructor,
    errors,
    byId,
    usersById,
    fieldsOfActionById,
    filesById,
    linksById,
    subTasksById
  };
};

const mapDispatchToProps = dispatch => {
  const taskGetRequest = id => dispatch(tasksOperations.taskGetRequest(id));
  const taskPutRequest = (id, data, formikBag, partialUpdate) => dispatch(tasksOperations.taskPutRequest(id, data, formikBag, partialUpdate));

  const taskDeleteRequest = (id, returnPath) => dispatch(tasksOperations.taskDeleteRequest(id, returnPath));
  const taskRestoreRequest = (id, returnPath) => dispatch(tasksOperations.taskRestoreRequest(id, returnPath));
  const taskSendRequest = (id, recipientId) => dispatch(tasksOperations.taskSendRequest(id, recipientId));
  const taskCopyRequest = id => dispatch(tasksOperations.taskCopyRequest(id));
  const taskArchiveRequest = id => dispatch(tasksOperations.taskArchiveRequest(id));
  const taskUnarchiveRequest = id => dispatch(tasksOperations.taskUnarchiveRequest(id));
  const taskSubmitRequest = id => dispatch(tasksOperations.taskSubmitRequest(id));
  const taskReopenRequest = id => dispatch(tasksOperations.taskReopenRequest(id));

  const tasksCancelRequest = (cancelType, cancelMessage) => dispatch(tasksOperations.tasksCancelRequest(cancelType, cancelMessage));

  const professionsGetRequest = () => dispatch(professionsOperations.professionsGetRequest());

  const usersGetRequest = () => dispatch(usersOperations.usersGetRequest());

  const subTasksByTaskGetRequest = id => dispatch(subTasksOperations.subTasksByTaskGetRequest(id));
  const subTaskGetRequest = id => dispatch(subTasksOperations.subTaskGetRequest(id));
  const subTaskPostRequest = (data, formikBag, appendData) => dispatch(subTasksOperations.subTaskPostRequest(data, formikBag, appendData));
  const subTaskPutRequest = (id, data, formikBag, appendData) => dispatch(subTasksOperations.subTaskPutRequest(id, data, formikBag, appendData));

  const subTasksCancelRequest = (cancelType, cancelMessage) => dispatch(subTasksOperations.subTasksCancelRequest(cancelType, cancelMessage));

  const viewChange = value => dispatch(uiOperations.viewChangeOperationGeneric(viewKey, value));
  const titleChange = value => dispatch(uiOperations.metaChangeOperationGeneric(metaKeys.TITLE, value));
  const parentRouteChange = value => dispatch(uiOperations.metaChangeOperationGeneric(metaKeys.PARENTROUTE, value));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    taskGetRequest,
    taskPutRequest,
    taskDeleteRequest,
    taskRestoreRequest,
    taskSendRequest,
    taskCopyRequest,
    tasksCancelRequest,
    professionsGetRequest,
    usersGetRequest,
    subTasksByTaskGetRequest,
    subTaskGetRequest,
    subTaskPostRequest,
    subTaskPutRequest,
    subTasksCancelRequest,
    viewChange,
    titleChange,
    parentRouteChange,
    notificationEnqueue,
    taskArchiveRequest,
    taskUnarchiveRequest,
    taskSubmitRequest,
    taskReopenRequest
  };
};

const TaskContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskComponent));

export default TaskContainer;
