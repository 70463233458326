import React from "react";
import { Field, Form, Formik } from "formik";
import { DateTime } from "luxon";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import { TextField } from "formik-material-ui";
import FormHelperText from "@material-ui/core/FormHelperText";
import FilesSelection from "../common/FilesSelectionContainer";
import RichTextField from "../common/RichTextField";
import FormControl from "@material-ui/core/FormControl";
import ReactSelect from "../common/ReactSelect";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  wrapper: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  ckEditor: {
    '& .ck': {
      '&.ck-editor__editable_inline': {
        minHeight: '8em',
      },
    },
  },
});

class ReportFormComponent extends React.Component {
  render() {
    const { classes, submitHandler, resetHandler, item, fieldsOfActionLocked, fieldsOfAction, fieldOfAction, facilities } = this.props;

    const convertDate = (date) => DateTime.fromISO(date).toISODate();

    const defaultDate = convertDate(DateTime.local());

    const facilityOptions = facilities && facilities.length > 0 ? facilities.map(facility => {
      return { value: facility.id, label: facility.name, isDisabled: false };
    }) : [];

    let fieldOfActionOptions = fieldsOfAction && fieldsOfAction.length > 0 ? fieldsOfAction.map(fieldOfAction => {
      return { value: fieldOfAction.id, label: fieldOfAction.name, isDisabled: false };
    }) : null;

    if (!fieldOfActionOptions && fieldOfAction) {
      fieldOfActionOptions = [
        {
          value: fieldOfAction.id,
          label: fieldOfAction.name,
          isDisabled: false
        }
      ];
    }

    const initialValues = {
      fieldOfActionId: fieldOfAction && fieldOfAction.id ? fieldOfAction.id : "",
      title: "",
      dateFrom: defaultDate,
      dateTo: defaultDate,
      duration: 0,
      text: "",
      learningPlaceId: "",
      media: [],
    };

    if (item) {
      initialValues.title = item.title || '';
      initialValues.text = item.text || '';
      initialValues.dateFrom = item.dateFrom ? convertDate(item.dateFrom) : defaultDate;
      initialValues.dateTo = item.dateTo ? convertDate(item.dateTo) : defaultDate;
      initialValues.duration = item.duration || 0;
      initialValues.learningPlaceId = item.learningPlaces && item.learningPlaces.length > 0 ? item.learningPlaces[0] : "";
      initialValues.media = item.media || [];
      if (item.fieldOfActions && item.fieldOfActions.length > 0) {
        initialValues.fieldOfActionId = item.fieldOfActions[0];
      }
    }

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={values => {
          let errors = {};

          if (values.title === "") {
            errors.title = "Dies ist ein Pflichtfeld";
          }
          if (DateTime.fromISO(values.dateFrom) > DateTime.fromISO(values.dateTo)) {
            errors.dateTo = "Enddatum vor Startdatum";
          }
          if (values.duration <= 0) {
            errors.duration = "Bitte Dauer in Stunden angeben";
          }
          if (values.learningPlaceId === "") {
            errors.learningPlaceId = "Dies ist ein Pflichtfeld";
          }

          return errors;
        }}
        onSubmit={(values, formikBag) => {
          submitHandler(values, formikBag);
        }}>
        {({ status, values, touched, errors, setFieldValue, setFieldTouched, isSubmitting, handleSubmit, handleReset }) => (
          <Form
            autoComplete="off"
            onSubmit={evt => {
              evt.stopPropagation();
              handleSubmit(evt);
            }}
            onReset={evt => {
              evt.stopPropagation();
              handleReset(evt);
              resetHandler();
            }}
            className={classes.form}
          >
            {status &&
            <FormHelperText error>{status}</FormHelperText>
            }
            {fieldOfActionOptions &&
            <FormControl fullWidth margin="normal" error={!!errors.fieldOfActionId && touched.fieldOfActionId}>
              <ReactSelect name="fieldOfActionId"
                           onChange={setFieldValue}
                           onBlur={setFieldTouched}
                           value={initialValues.fieldOfActionId}
                           error={errors.fieldOfActionId}
                           touched={touched.fieldOfActionId}
                           options={fieldOfActionOptions}
                           placeholder="Aufgabenbereich auswählen..."
                           label="Aufgabenbereich"
                           isMulti={false}
                           isClearable={false}
                           isDisabled={fieldsOfActionLocked}/>
              {(!!errors.fieldOfActionId && touched.fieldOfActionId) &&
              <FormHelperText>{errors.fieldOfActionId}</FormHelperText>
              }
            </FormControl>
            }
            <Field component={TextField}
                   type="text"
                   id="title"
                   name="title"
                   label="Titel"
                   required
                   fullWidth
                   margin="normal"/>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Field component={TextField}
                       type="date"
                       id="dateFrom"
                       name="dateFrom"
                       label="von"
                       InputLabelProps={{
                         shrink: true,
                       }}
                       fullWidth
                       margin="normal"/>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field component={TextField}
                       type="date"
                       id="dateTo"
                       name="dateTo"
                       label="bis"
                       InputLabelProps={{
                         shrink: true,
                       }}
                       fullWidth
                       margin="normal"/>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field component={TextField}
                       type="number"
                       id="duration"
                       name="duration"
                       label="Dauer der Tätigkeit in Stunden"
                       InputProps={{
                         inputProps: {
                           min: 0,
                           step: 1
                         }
                       }}
                       InputLabelProps={{
                         shrink: true,
                       }}
                       fullWidth
                       margin="normal"/>
              </Grid>
            </Grid>
            {facilityOptions &&
            <FormControl fullWidth margin="normal" error={!!errors.learningPlaceId && touched.learningPlaceId}>
              <ReactSelect name="learningPlaceId"
                           onChange={setFieldValue}
                           onBlur={setFieldTouched}
                           value={values.learningPlaceId}
                           error={errors.learningPlaceId}
                           touched={touched.learningPlaceId}
                           options={facilityOptions}
                           placeholder="Lernort auswählen..."
                           label="Lernort"
                           isMulti={false}
                           isClearable={false}/>
              {(!!errors.learningPlaceId && touched.learningPlaceId) &&
              <FormHelperText>{errors.learningPlaceId}</FormHelperText>
              }
            </FormControl>
            }
            <Field component={RichTextField}
                   type="text"
                   id="text"
                   name="text"
                   label="Beschreibung"
              // maxLength={250}
                   fullWidth
                   margin="normal"
                   richTextInputProps={{
                     className: classes.ckEditor
                   }}/>
            <Field component={FilesSelection}
                   id="media"
                   name="media"
                   label="Dateien"
                   value={values.media}
                   onChange={setFieldValue}
            />
            <div className={classes.wrapper}>
              <Button type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}>
                Speichern
              </Button>
              {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress}/>}
            </div>
            <div className={classes.wrapper}>
              <Button type="reset" color="primary">Abbrechen</Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(ReportFormComponent);
