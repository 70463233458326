import { withRouter } from "react-router";
import connect from "react-redux/es/connect/connect";
import { authenticationOperations, authenticationSelectors } from "../redux/authentication";
import withRoot from '../withRoot';
import AppComponent from "./AppComponent";
import { notificationsOperations } from "../redux/notifications";
import { default as userNotificationsOperations } from '../redux/entities/notificationsOperations';
import { entityKey as userNotificationsSliceKey } from "../redux/entities/notificationsKeys";
import { uiEntityKeys, metaKeys } from "../redux/ui";
import { requestsSelectors } from "../redux/entities/requests";
import { entityKey as filesSliceKey } from '../redux/entities/filesKeys';

const mapStateToProps = state => {
  const isFetching = authenticationSelectors.checkIfFetching(state) || requestsSelectors.checkIfFetchingAll(state);
  const { isAuthenticated, myId, isAdmin, isInstructor } = state.authentication;
  const profile = authenticationSelectors.profile(state);
  const metaTitle = state.ui[uiEntityKeys.meta][metaKeys.TITLE];
  const metaParentRoute = state.ui[uiEntityKeys.meta][metaKeys.PARENTROUTE];
  const notificationsById = state.entities[userNotificationsSliceKey].byId;
  const filesById = state.entities[filesSliceKey].byId;

  return {
    isFetching,
    isAuthenticated,
    myId,
    isAdmin,
    isInstructor,
    profile,
    metaTitle,
    metaParentRoute,
    notificationsById,
    filesById
  };
};

const mapDispatchToProps = dispatch => {
  const meGetRequest = () => dispatch(authenticationOperations.meGetRequest());
  const notificationsGetRequest = () => dispatch(userNotificationsOperations.notificationsGetRequest());

  const authRemoveRequest = () => dispatch(authenticationOperations.authRemoveRequest(true));
  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    meGetRequest,
    notificationsGetRequest,
    authRemoveRequest,
    notificationEnqueue
  };
};

const AppContainer = withRoot(withRouter(connect(mapStateToProps, mapDispatchToProps)(AppComponent)));

export default AppContainer;

