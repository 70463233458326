import { schema } from "normalizr";
import { requestsOperations } from "./requests";
import { entityKey, duckTypes } from "./reportsKeys";
import getHistory from "../../utils/history";
import routes from "../../utils/routes";
import { entityKey as filesSliceKey } from "./filesKeys";
import { default as filesOperations } from "./filesOperations";
import { entityKey as linksSliceKey } from "./linksKeys";
import { default as linksOperations } from "./linksOperations";
import { entityKey as fieldOfActionsSliceKey } from "./fieldsOfActionKeys";
import { default as fieldOfActionsOperations } from "./fieldsOfActionOperations";
import { entityKey as facilitiesSliceKey } from "./facilitiesKeys";
import { default as facilitiesOperations } from "./facilitiesOperations";

const reportSchema = new schema.Entity(entityKey);
const reportListSchema = new schema.Array(reportSchema);

const fetchRelations = [{
  relationResponseKey: "media",
  relationSliceKey: filesSliceKey,
  operation: filesOperations.filesByReportGetRequest,
  noRefetch: false
}, {
  relationResponseKey: "links",
  relationSliceKey: linksSliceKey,
  operation: linksOperations.linksByReportGetRequest,
  noRefetch: false
}, {
  relationResponseKey: "fieldOfActions",
  relationSliceKey: fieldOfActionsSliceKey,
  operation: fieldOfActionsOperations.fieldOfActionsByReportGetRequest,
  noRefetch: false
}, {
  relationResponseKey: "learningPlaces",
  relationSliceKey: facilitiesSliceKey,
  operation: facilitiesOperations.facilitiesByReportGetRequest,
  noRefetch: false
}];

const listGetRequest = (url, urlParameters = {}) => {
  const settings = {
    duckType: duckTypes.REPORTS_GET,
    requestPath: url,
    clearEntityData: true,
    normalizeSchema: reportListSchema,
    fetchRelations: [],
  };
  return requestsOperations.requestsOperationGeneric(null, settings, urlParameters);
};

const reportsGetRequest = (urlParameters = {}) => listGetRequest("/reports", urlParameters);
const reportsByFieldOfActionGetRequest = (fieldOfActionId, urlParameters = {}) => listGetRequest(`/field-of-actions/${fieldOfActionId}/reports`, urlParameters);

const reportsFilterOptionsRequest = (urlParameters = {}) => {
  const settings = {
    duckType: duckTypes.REPORT_FILTER_OPTIONS,
    requestPath: '/filter_options/reports',
  };

  return requestsOperations.requestsFilterOptionsGeneric(null, settings, urlParameters);
};

const reportGetRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.REPORT_GET,
    requestPath: `/reports/${id}`,
    idParamRequired: true,
    normalizeSchema: reportSchema,
    fetchRelations: fetchRelations,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const reportPutRequest = (id, data, formikBag) => {
  const params = {
    id: id,
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.REPORT_PUT,
    requestType: "put",
    requestPath: `/reports/${id}`,
    idParamRequired: true,
    normalizeSchema: reportSchema,
    appendOnSuccess: () => gotoList(data.fieldOfActions.length ? data.fieldOfActions[0] : null)
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const reportPostRequest = (data, formikBag) => {
  const params = {
    data: data,
    formikBag: formikBag,
  };

  const settings = {
    duckType: duckTypes.REPORT_POST,
    requestType: "post",
    requestPath: "/reports",
    normalizeSchema: reportSchema,
    appendOnSuccess: () => gotoList(data.fieldOfActions.length ? data.fieldOfActions[0] : null)
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const reportDeleteRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.REPORT_DELETE,
    requestType: "delete",
    requestPath: `/reports/${id}`,
    idParamRequired: true,
    normalizeSchema: reportSchema,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const gotoList = fieldOfActionId => {
  getHistory().push(routes.REPORTS.path.replace(":fieldOfActionId", fieldOfActionId));
};

const reportCancelRequest = (cancelType, cancelMessage) => {
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsCancellation(entityKey, cancelType, cancelMessage));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

export default {
  reportsGetRequest,
  reportGetRequest,
  reportPutRequest,
  reportPostRequest,
  reportDeleteRequest,
  reportCancelRequest,
  reportsByFieldOfActionGetRequest,
  reportsFilterOptionsRequest
};
