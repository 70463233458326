import React from 'react';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Paper,
  Tooltip,
  Typography,
  withStyles
} from "@material-ui/core";
import { Rating } from '@material-ui/lab';
import { duckTypes } from "../../redux/entities/competenceRatingsKeys";
import flavors from "../../redux/notifications/flavors";
import competenceSpecificationTypes from "../../utils/competenceSpecificationTypes";
import competenceRatingLabels from "../../utils/competenceRatingLabels";
import { default as CompetenceRatingForm } from "./CompetenceRatingFormComponent";

const styles = theme => ({
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
    },
  },
  cardHeader: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      flex: '0 0 30%',
    },
  },
  cardHeaderAvatar: {
    alignSelf: 'flex-start',
  },
  cardContent: {
    [theme.breakpoints.up('md')]: {
      flex: '1 1 auto',
    },
  },
  cardActions: {
    padding: theme.spacing(1, 2, 2),
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-end',
      justifyContent: 'flex-end',
      flex: '1 0 14rem',
    },
  },
  ratingPaper: {
    backgroundColor: theme.palette.grey[50],
    margin: theme.spacing(1, -1),
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      alignItems: "center",
      display: "flex",
    },
  },
  ratingLabel: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
      marginTop: 0,
    },
  },
});

class CompetenceRatingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestType: null,
      rateItem: false
    };
  }

  componentDidMount() {
    this.initData();
  }

  initData = () => {
    const { id } = this.props.item;

    if (id) {
      this.props.competenceRatingsByCompetenceSpecificationGetRequest(id);
    }
  };

  toggleRate = () => {
    this.setState(prevState => ({
      rateItem: !prevState.rateItem
    }));
  }

  getActiveEvaluationRating = () => {
    const { byId, item, evaluationId } = this.props;
    const { id } = item;

    const activeEvaluationRatingArray = Object.keys(byId)
      .filter(ratingId => byId[ratingId].competenceSpecification === id && byId[ratingId].competenceEvaluation === evaluationId)
      .map(id => byId[id]);
    
    return activeEvaluationRatingArray[0] || null;
  };

  getRatingLabel = rating => {
    const { isInstructor } = this.props;
    const ratingLabels = competenceRatingLabels[rating] || null;

    if (!ratingLabels) {
      return '';
    }

    return isInstructor ? ratingLabels.instructor : ratingLabels.apprentice;
  };

  handleRatingSubmit = (values, formikBag) => {
    const { item, evaluationId } = this.props;
    const { id } = item;

    const activeEvaluationRating = this.getActiveEvaluationRating();

    if (activeEvaluationRating && activeEvaluationRating.id) {
      const data = {
        rating: values.rating ? parseInt(values.rating) : 0,
        description: values.description
      };
      this.setState({
        requestType: duckTypes.COMPETENCERATING_PUT.group
      }, () => {
        this.props.competenceRatingPutRequest(activeEvaluationRating.id, data, formikBag).then(() => {
          this.setState({
            rateItem: false
          });
        });
      });
    } else if (id && evaluationId) {
      const data = {
        competenceSpecification: id,
        competenceEvaluation: evaluationId,
        rating: values.rating ? parseInt(values.rating) : 0,
        description: values.description
      };
      this.setState({
        requestType: duckTypes.COMPETENCERATING_POST.group
      }, () => {
        this.props.competenceRatingPostRequest(id, data, formikBag).then(() => {
          this.setState({
            rateItem: false
          });
        });
      });
    } else {
      this.props.notificationEnqueue('Es ist ein Fehler aufgetreten', flavors.ERROR);
    }
  };

  handleReset = () => {
    const promise = this.props.competenceRatingsCancelRequest(this.state.requestType);
    if (promise) {
      promise.then(() => {
        this.setState(prevState => ({
          rateItem: false
        }));
      });
    }
  };

  render() {
    const { classes, isInstructor, item } = this.props;
    const { id, field, description } = item;

    const type = competenceSpecificationTypes[parseInt(field)]; 

    if (!item || !type) {
      return null;
    }

    const activeEvaluationRating = this.getActiveEvaluationRating();

    if (this.state.rateItem) {
      return <CompetenceRatingForm isInstructor={isInstructor} type={type} specItem={item} ratingItem={activeEvaluationRating} submitHandler={ this.handleRatingSubmit } resetHandler={ this.handleReset } />;
    } 

    const rating = activeEvaluationRating ? activeEvaluationRating.rating : 0;
    const ratingDescription = activeEvaluationRating ? activeEvaluationRating.description : '';
    
    const ratingLabel = this.getRatingLabel(rating);

    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Tooltip title={type.label} aria-label={type.label}>
              <Avatar>{React.createElement(type.icon)}</Avatar>
            </Tooltip>
          }
          title={type.label}
          titleTypographyProps={{
            color: 'textSecondary'
          }}
          subheader={isInstructor ? `Auszubildende*r kann...` : `Ich kann...`}
          subheaderTypographyProps={{
            color: 'textPrimary'
          }}
          classes={{
            root: classes.cardHeader,
            avatar: classes.cardHeaderAvatar
          }}
        />
        <CardContent className={classes.cardContent}>
          {description}
          <Paper elevation={0} classes={{
            root: classes.ratingPaper
          }}>
            <Rating name={`rate-${id}`}
              size="large"
              max={4}
              value={rating}
              readOnly
            />
            <Typography variant="caption" color="textSecondary" classes={{
              root: classes.ratingLabel
            }}>
              {ratingLabel}
            </Typography>
          </Paper>
          {ratingDescription && `"${ratingDescription}"`}
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button variant="outlined" size="small" color="primary" onClick={() => this.toggleRate()}>Einschätzen</Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(CompetenceRatingComponent);
