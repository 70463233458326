import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import grey from '@material-ui/core/colors/grey';

const defaultTheme = createMuiTheme();
const PRIMARY_COLOR = '#0050a0'; // original #0050a0
const SECONDARY_COLOR = '#e95e26';

const theme = createMuiTheme({
  palette: {
    type: 'light',
    background: {
      default: '#fff'
    },
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    info: {
      main: PRIMARY_COLOR,
    },
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        color: PRIMARY_COLOR,
        marginRight: 0
      },
    },
    MUIDataTable: {
      paper: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    MUIDataTableToolbar: {
      root: {
        backgroundColor: grey[100]
      },
      titleText: {
        color: PRIMARY_COLOR,
      },
    },
    MUIDataTableToolbarSelect: {
      root: {
        flexBasis: 'auto',
        flexGrow: 0,
      },
    },
    MUIDataTableHeadCell: {
      root: {
        paddingRight: defaultTheme.spacing(3),
      },
      toolButton: {
        whiteSpace: 'nowrap'
      },
      data: {
        whiteSpace: 'normal'
      },
      sortAction: {
        alignItems: 'center'
      },
    },
    MUIDataTableBodyRow: {
      responsiveStacked: {
        [defaultTheme.breakpoints.down('sm')]: {
          border: 0,
          borderTopStyle: 'solid',
          borderTopWidth: '1px',
          borderTopColor: defaultTheme.palette.divider,
          display: 'flex',
          flexWrap: 'wrap',
          height: 'auto'
        },
      }
    },
    MUIDataTableBodyCell: {
      root: {
        paddingRight: defaultTheme.spacing(3),
      },
      stackedCommon: {
        [defaultTheme.breakpoints.down('sm')]: {
          borderBottom: 0,
          boxSizing: 'border-box',
          display: 'block',
          height: 'auto',
          paddingBottom: 0,
          paddingTop: 0,
          whiteSpace: 'wrap',
          width: '100%',
          '&:first-child': {
            paddingTop: defaultTheme.spacing(1),
          },
          '&:last-child': {
            paddingBottom: defaultTheme.spacing(1),
          },
        },
      },
      cellStackedSmall: {
        [defaultTheme.breakpoints.down('sm')]: {
          color: defaultTheme.palette.text.hint,
          fontSize: defaultTheme.typography.caption.fontSize,
          letterSpacing: defaultTheme.typography.caption.letterSpacing,
          lineHeight: defaultTheme.typography.caption.lineHeight,
          paddingTop: defaultTheme.spacing(0.5),
          width: '100%',
        },
      },
      responsiveStackedSmall: {
        [defaultTheme.breakpoints.down('sm')]: {
          paddingTop: 0,
          width: '100%',
        },
      },
    },
    MUIDataTablePagination: {
      root: {
        [defaultTheme.breakpoints.down('sm')]: {
          borderTopStyle: 'solid',
          borderTopWidth: '1px',
          borderTopColor: defaultTheme.palette.divider,
        },
      },
      toolbar: {
        [defaultTheme.breakpoints.down('sm')]: {
          flexWrap: 'wrap',
          justifyContent: 'center',
          paddingLeft: '2px',
          paddingRight: '2px',
        },
      },
    },
    MuiTablePagination: {
      actions: {
        [defaultTheme.breakpoints.down('sm')]: {
          marginLeft: defaultTheme.spacing(2),
          marginRight: defaultTheme.spacing(2),
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        [defaultTheme.breakpoints.up('xl')]: {
          fontSize: "0.8rem",
        }
      }
    },
  },
});

function withRoot(Component) {
  function WithRoot(props) {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
