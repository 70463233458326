import Uppy from '@uppy/core';
import German from '@uppy/locales/lib/de_DE';
import Webcam from '@uppy/webcam';
import XHRUpload from '@uppy/xhr-upload';
import ReduxStore from '@uppy/store-redux';
import ImageCompressor from 'uppy-plugin-image-compressor';
import store from "../redux/store";
import config from "./config";
import { getAuthHeader } from "./auth";

const createUppy = (
  successHandler,
  errorHandler,
  maxNumberOfFiles = 32,
  allowedFileTypes = ['image/*', 'audio/*', 'video/*', 'text/*', 'application/*']) => {
  return new Uppy({
    debug: ('development' === process.env.NODE_ENV),
    locale: German,
    autoProceed: false,
    allowMultipleUploads: 1 < maxNumberOfFiles,
    restrictions: {
      maxFileSize: 10485760, // 10 MB
      maxNumberOfFiles: maxNumberOfFiles,
      allowedFileTypes: allowedFileTypes
    },
    store: new ReduxStore({
      store: store
    }),
  })
    .use(Webcam, {
      modes: [
        'picture'
      ],
      mirror: false,
      facingMode: 'environment'
    })
    .use(XHRUpload, {
      endpoint: config.API_URL + '/media',
      headers: getAuthHeader()
    })
    .use(ImageCompressor, {
      checkOrientation: false,
      maxWidth: 1280,
      maxHeight: 1280,
      quality: 0.6
    })
    .on('upload-success', (file, response) => {
      // console.log('UPPY SUCCESS!', file, response);
      if (response && response.body && successHandler) {
        const data = response.body;
        successHandler(data);
      }
    })
    .on('upload-error', (file, error) => {
      // console.log('UPPY ERROR!', file, error, response);
      if (file && errorHandler) {
        let errorMessage = 'Uploadfehler: ' + file.name;
        if (error) {
          errorMessage += ' - ' + error;
        }
        const handledError = {
          message: errorMessage,
          violations: []
        };
        errorHandler(handledError);
      }
    });
};

export default createUppy;
