import React from 'react';
import AddIcon from "@material-ui/icons/AddBox";
import withStyles from '@material-ui/core/styles/withStyles';
import routes from "../../utils/routes";
import { default as Facility } from "./FacilityComponent";
import Grid from "@material-ui/core/Grid";
import PortalToolbarMenu from "../common/navigation/PortalToolbarMenu";
import {duckTypes} from "../../redux/entities/facilitiesKeys";
import flavors from "../../redux/notifications/flavors";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  gridContainer: {
    margin: 0,
    width: '100%',
    padding: theme.spacing(1),
  },
  sectionNew: {
    width: '100%',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[50],
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

class FacilitiesComponent extends React.Component {
  componentDidMount() {
    this.initData();
  }

  initData = () => {
    this.props.facilitiesGetRequest();
  };

  handleClick = (evt, route) => {
    evt.preventDefault();
    if (route) {
      this.props.history.push(route);
    }
  };

  handleDelete = (id) => {
    if (id) {
      this.setState({
        requestType: duckTypes.FACILITY_DELETE.group
      }, () => {
        this.props.facilityDeleteRequest(id).then(() => {
          this.props.notificationEnqueue('Der Lernort wurde gelöscht.', flavors.SUCCESS);
        });
      });
    } else {
      this.props.notificationEnqueue('Es ist ein Fehler aufgetreten', flavors.ERROR);
    }
  };

  render() {
    const { classes, isAdmin, isInstructor, byId, allIds, history } = this.props;

    const isApprentice = !isAdmin && !isInstructor;

    const distinctUserIds = [];

    allIds.forEach(facilityId => {
      const facility = byId[facilityId];
      if (!facility.apprentices) {
        return;
      }

      facility.apprentices.forEach(userId => {
        if (distinctUserIds.indexOf(userId) === -1) {
          distinctUserIds.push(userId);
        }
      });
    });

    const portalToolbarMenuData = [
      {
        "title": "Neuen Eintrag anlegen",
        "action": evt => this.handleClick(evt, routes.FACILITY_NEW.path),
        "icon": AddIcon,
        "visible": true,
        "disabled": false
      }
    ];

    return (
      <React.Fragment>
        <PortalToolbarMenu menuData={portalToolbarMenuData}/>
        <Grid
          container
          spacing={2}
          className={classes.gridContainer}
        >
          {allIds.map(itemId => {
            return (
              <Grid key={itemId} item xs={12} sm={6} md={4}>
                <Facility history={history} item={byId[itemId]} isApprentice={isApprentice} deleteHandler={this.handleDelete}/>
              </Grid>
            )
          })}
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FacilitiesComponent);
