
export const labelsMuiDatatables = {
  body: {
    noMatch: "Kein Datensätze gefunden",
    toolTip: "Sortieren",
  },
  pagination: {
    next: "Nächste Seite",
    previous: "Vorherige Seite",
    rowsPerPage: "Datensätze pro Seite:",
    displayRows: "von",
  },
  toolbar: {
    search: "Suchen",
    downloadCsv: "Download CSV",
    print: "Drucken",
    viewColumns: "Spaltenauswahl",
    filterTable: "Filtern",
  },
  filter: {
    all: "Alle",
    title: "Filter",
    reset: "Zurücksetzen",
  },
  viewColumns: {
    title: "Show Columns",
    titleAria: "Show/Hide Table Columns",
  },
  selectedRows: {
    text: "Datensätze ausgewählt",
    delete: "Löschen",
    deleteAria: "Ausgewählte Datensätze löschen",
  },
};
