import React from 'react';
import { Field, Form, Formik } from "formik";
import Button from "@material-ui/core/Button/Button";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField } from 'formik-material-ui';
import FormHelperText from '@material-ui/core/FormHelperText';
import SingleCheckbox from "../common/SingleCheckbox";
import FormControl from "@material-ui/core/FormControl";
import {DashboardModal} from "@uppy/react";
import createUppy from "../../utils/uppy";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RichTextField from '../common/RichTextField';

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    wrapper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    uppyContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
        padding: theme.spacing(2),
        paddingTop: theme.spacing(2),
        '& .uppy-Root': {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary
        },
        '& .uppy-Dashboard--modal': {
            zIndex: theme.zIndex.modal + 1,
        },
        '& .uppy-Dashboard-innerWrap': {
            borderRadius: theme.shape.borderRadius,
        },
        '& .uppy-Dashboard-dropFilesTitle': {
            fontSize: theme.typography.h6.fontSize,
        },
        '& .uppy-Dashboard-browse': {
            color: theme.palette.primary.main
        },
        '& .uppy-DashboardTab-btn': {
            color: theme.palette.primary.main
        },
        '& .uppy-DashboardTab-btn > svg': {
            fill: theme.palette.primary.main
        },
        '& .uppy-DashboardTab-name': {
            fontSize: theme.typography.button.fontSize,
        },
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
});

class FileFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.uppy = createUppy(this.props.uploadSuccessHandler, this.props.uploadErrorHandler, 1);

        this.state = {
            modalOpen: false
        };
    }

    componentDidMount() {
        this.uppy.on('complete', () => {
            this.handleUppyModalClose();
        })
    }

    componentWillUnmount () {
        this.uppy.close();
        this.uppy = null;
    }

    handleUppyModalOpen = () => {
        this.setState({
            modalOpen: true,
        });
    };

    handleUppyModalClose = () => {
        this.setState({
            modalOpen: false
        });
    };

    render() {
        const { classes, item, submitHandler, resetHandler, isApprentice } = this.props;

        let setId = null;
        let setTitle = '';
        let setDescription = '';
        let setHidden = '';
        if (item) {
            setId = item.id || null;
            setTitle = item.title || '';
            setDescription = item.description || '';
            setHidden = (item.hiddenFromApprentice) ? '1' : '';
        }

        const checkboxValues = {
            hidden: '1'
        };

        return (
            <Formik
                initialValues = {{
                    title: setTitle,
                    description: setDescription,
                    hidden: setHidden
                }}
                enableReinitialize={true}
                validate = {values => {
                    let errors = {};
                    if (values.title === '') {
                        errors.title = 'Dies ist ein Pflichtfeld';
                    }
                    return errors;
                }}
                onSubmit = {(values, formikBag) => {
                    submitHandler(values, formikBag);
                }}>
                {({ status, touched, errors, isSubmitting, handleSubmit, handleReset }) => (
                    <Form
                        autoComplete="off"
                        onSubmit={evt => {
                            evt.stopPropagation();
                            handleSubmit(evt);
                        }}
                        onReset={evt => {
                            evt.stopPropagation();
                            handleReset(evt);
                            resetHandler();
                        }}
                        className={classes.form}
                    >
                        {status &&
                            <FormHelperText error>{status}</FormHelperText>
                        }
                        {item ? (
                            <div className={classes.uppyContainer}>
                                <div>
                                    <Typography variant="subtitle2" display="block" color="textPrimary">Orig. Dateiname: {item.originalFilename}</Typography>
                                    {item.fileExists ? (
                                        <Typography variant="caption" display="block" color="textSecondary">Dateigröße: {Math.round(parseInt(item.size) / 1024)} kb</Typography>
                                    ) : (
                                        <Typography variant="caption" display="block" color="error">Achtung: Datei fehlt</Typography>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className={classes.uppyContainer}>
                                    <Button size="large" variant="contained" color="primary" onClick={this.handleUppyModalOpen}>
                                        Datei auswählen
                                        <CloudUploadIcon className={classes.rightIcon} />
                                    </Button>
                                    <DashboardModal
                                        uppy={this.uppy}
                                        plugins={['Webcam']}
                                        proudlyDisplayPoweredByUppy={false}
                                        closeModalOnClickOutside
                                        open={this.state.modalOpen}
                                        onRequestClose={this.handleUppyModalClose}
                                    />
                                </div>
                                <Typography variant="caption" display="block" color="textSecondary">Tipp: Wählen Sie zuerst eine Datei aus, da der Dateiname automatisch in den Titel übernommen wird.</Typography>
                            </>
                        )}
                        <Field component={TextField}
                            type="text"
                            id="title"
                            name="title"
                            label="Titel"
                            required
                            fullWidth
                            margin="normal"/>
                        <Field component={RichTextField}
                            type="text"
                            id="description"
                            name="description"
                            label="Beschreibung"
                            // maxLength={250}
                            fullWidth
                            margin="normal"/>
                        {!isApprentice &&
                            <FormControl fullWidth margin="normal">
                                <SingleCheckbox name="hidden" value={checkboxValues.hidden}
                                                label="Kein Zugriff für Auszubildende"/>
                            </FormControl>
                        }
                        <div className={classes.wrapper}>
                            <Button type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting || !setId}>
                                Speichern
                            </Button>
                            {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress} />}
                        </div>
                        <div className={classes.wrapper}>
                            <Button type="reset" color="primary">Abbrechen</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withStyles(styles)(FileFormComponent);
