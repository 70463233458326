import React from 'react';
import { Field, Form, Formik } from "formik";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Link } from 'react-router-dom';
import SingleCheckbox from '../common/SingleCheckbox';
import routes from '../../utils/routes';

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    wrapper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

class UserActivationFormComponent extends React.Component {

    render() {
        const { classes, submitHandler, resetHandler } = this.props;

        const termsLabel = (
            <span>
                Ich bin mit den <Link to={routes.TERMS.path}>Datenschutzbestimmungen</Link> einverstanden.
            </span>
        );

        const checkboxValues = {
            terms: '1'
        };

        return (
            <Formik
                initialValues = {{
                    password: '',
                    password2: '',
                    terms: ''
                }}
                validate = {values => {
                    let errors = {};
                    if (!values.password) {
                        errors.password = 'Dies ist ein Pflichtfeld';
                    }
                    if (!values.password2) {
                        errors.password2 = 'Dies ist ein Pflichtfeld';
                    }
                    if (values.password !== values.password2) {
                        errors.password2 = 'Passwörter stimmen nicht überein';
                    }
                    if (values.terms !== checkboxValues.terms) {
                        errors.terms = 'Sie müssen den Bedingungen zustimmen';
                    }
                    return errors;
                }}
                onSubmit = {(values, formikBag) => {
                    submitHandler(values, formikBag);
                }}>
                {({ status, touched, errors, isSubmitting, handleSubmit, handleReset }) => (
                    <Form
                        autoComplete="off"
                        onSubmit={evt => {
                            evt.stopPropagation();
                            handleSubmit(evt);
                        }}
                        onReset={evt => {
                            evt.stopPropagation();
                            handleReset(evt);
                            resetHandler();
                        }}
                        className={classes.form}
                    >
                        {status &&
                            <FormHelperText error>{status}</FormHelperText>
                        }
                        <Field component={TextField}
                            type="password"
                            id="password"
                            name="password"
                            label="Passwort"
                            required
                            fullWidth
                            margin="normal"/>
                        <Field component={TextField}
                            type="password"
                            id="password2"
                            name="password2"
                            label="Passwort wiederholen"
                            required
                            fullWidth
                            margin="normal"/>
                        <FormControl fullWidth margin="normal" error={!!errors.terms && touched.terms}>
                            <SingleCheckbox name="terms" value={checkboxValues.terms} label={termsLabel} />
                            {(!!errors.terms && touched.terms) &&
                                <FormHelperText>{errors.terms}</FormHelperText>
                            }
                        </FormControl>
                        <div className={classes.wrapper}>
                            <Button type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}>
                                Konto aktivieren
                            </Button>
                            {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress} />}
                        </div>
                        <div className={classes.wrapper}>
                            <Button type="reset" color="primary">Abbrechen</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withStyles(styles)(UserActivationFormComponent);
