import { keys as genericKeys, type as genericType } from "../generics";

const { intents } = genericKeys;

const nameKeys = {
  PROFESSIONS: 'PROFESSIONS',
  PROFESSION: 'PROFESSION',
};
export const entityKey = nameKeys.PROFESSIONS.toLowerCase();
export const duckTypes = {
  PROFESSIONS_GET: genericType(entityKey, nameKeys.PROFESSIONS, intents.GET),
  PROFESSION_GET: genericType(entityKey, nameKeys.PROFESSION, intents.GET),
  PROFESSION_PUT: genericType(entityKey, nameKeys.PROFESSION, intents.PUT),
  PROFESSION_POST: genericType(entityKey, nameKeys.PROFESSION, intents.POST),
  PROFESSION_DELETE: genericType(entityKey, nameKeys.PROFESSION, intents.DELETE)
};