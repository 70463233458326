import { withRouter } from "react-router";
import { connect } from "react-redux";
import { entityKey as fieldsOfActionSliceKey } from "../../redux/entities/fieldsOfActionKeys";
import { default as fieldsOfActionOperations } from "../../redux/entities/fieldsOfActionOperations";
import { entityKey as reportsSliceKey } from "../../redux/entities/reportsKeys";
import { default as reportsOperations } from "../../redux/entities/reportsOperations";
import ReportsComponent from "./ReportsComponent";
import { entityKey as facilitiesSliceKey } from "../../redux/entities/facilitiesKeys";
import { default as facilitiesOperations } from "../../redux/entities/facilitiesOperations";

const mapStateToProps = state => {
  const { isAdmin } = state.authentication;
  const fieldsOfActionErrors = state.entities[fieldsOfActionSliceKey].errors;
  const fieldsOfActionById = state.entities[fieldsOfActionSliceKey].byId;
  const facilitiesAllIds = state.entities[facilitiesSliceKey].allIds;
  const facilityById = state.entities[facilitiesSliceKey].byId;
  const { errors, byId, allIds, totalCount, filterOptions } = state.entities[reportsSliceKey];

  return {
    isAdmin,
    fieldsOfActionErrors,
    fieldsOfActionById,
    errors,
    byId,
    allIds,
    facilitiesAllIds,
    facilityById,
    totalCount,
    filterOptions
  };
};

const mapDispatchToProps = dispatch => {
  const fieldOfActionGetRequest = (id) => dispatch(fieldsOfActionOperations.fieldOfActionGetRequest(id));
  const reportsByFieldOfActionGetRequest = (fieldOfActionId, urlParameters) => dispatch(reportsOperations.reportsByFieldOfActionGetRequest(fieldOfActionId, urlParameters));
  const reportsFilterOptionsRequest = (urlParameters) => dispatch(reportsOperations.reportsFilterOptionsRequest(urlParameters));
  const facilitiesGetRequest = () => dispatch(facilitiesOperations.facilitiesGetRequest());

  return {
    fieldOfActionGetRequest,
    facilitiesGetRequest,
    reportsByFieldOfActionGetRequest,
    reportsFilterOptionsRequest
  };
};

const ReportsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportsComponent));

export default ReportsContainer;
