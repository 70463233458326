import axios from 'axios';
import config from './config';
import { authenticationOperations } from '../redux/authentication';
import { convertFieldViolations } from "./helper";

const request = axios.create({
  baseURL: config.API_URL
});

export default request;

export const handleSuccess = (response, formikBag) => {
  const { data } = response;
  if (formikBag) {
    formikBag.setStatus('');
    formikBag.setSubmitting(false);
  }
  return data;
};

export const handleError = (dispatch, error, formikBag) => {
  let message = 'Fehler';
  let violations = [];
  if (axios.isCancel(error)) {
    if (error.message) {
      message = error.message;
    } else {
      message = 'Vorgang abgebrochen';
    }
  } else if (error.response && 422 === error.response.status) {
    message = 'Ungültige Eingaben';
  } else if (error.response) {
    message = 'Fehlercode ' + error.response.status + ': ' + error.response.statusText.toString();
    if (401 === error.response.status) {
      dispatch(authenticationOperations.authRemoveRequest(false));
    }
    const responseData = error.response.data || null;
    if (responseData) {
      if (responseData.violations) {
        violations = responseData.violations;
      }
      const responseMessages = responseData.messages || null;
      if (responseMessages) {
        if (responseMessages.reason) {
          message = 'Fehlercode ' + error.response.status + ': ' + responseMessages.reason;
        }
        if (responseMessages.details && responseMessages.details.message) {
          message = 'Fehlercode ' + error.response.status + ': ' + responseMessages.details.message;
        }
      }
    }
  } else if (error.request) {
    message = 'Fehler Serveranfrage';
  } else {
    message = 'Fehler: ' + error.message;
  }
  if (formikBag) {
    formikBag.setErrors({
      ...convertFieldViolations(violations)
    });
    formikBag.setStatus(message);
    formikBag.setSubmitting(false);
  }
  return {
    message,
    violations
  };
};
