import { combineReducers } from 'redux';
import ReduxStore from '@uppy/store-redux';

import authenticationReducer, { authenticationTypes } from './authentication';
import uiReducer from './ui';
import notificationsReducer from './notifications';
import otherReducer from './other';
import requestsReducer from './entities/requests';

const appReducer = combineReducers({
  authentication: authenticationReducer,
  ui: uiReducer,
  notifications: notificationsReducer,
  other: otherReducer,
  entities: requestsReducer,
  uppy: ReduxStore.reducer
});

const rootReducer = (state, action) => {
  if (action.type === authenticationTypes.AUTH_REMOVE_SUCCESS) {
    const newState = {
      uppy: state.uppy
    };

    return appReducer(newState, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
