import types from './types';
import config from '../../utils/config';
import { updateCancelTokens } from '../../utils/helper';
import { default as userRoles } from "../../utils/roles";
import * as R from "ramda";

let token = JSON.parse(sessionStorage.getItem(`${config.APP_NAME}-token`));

const INITIAL_STATE = {
  isAuthenticated: null !== token,
  cancelTokens: {},
  errors: {},
  myId: null,
  isAdmin: false,
  isInstructor: false
};

const authenticationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case types.REGISTRATION_POST_STARTED: {
    const cancelProps = {
      type: types.REGISTRATION_POST,
      token: action.payload.token,
      id: action.payload.id
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens
    };
  }

  case types.REGISTRATION_POST_SUCCESS: {
    const cancelProps = {
      type: types.REGISTRATION_POST
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens,
      errors: {}
    };
  }

  case types.REGISTRATION_POST_FAILURE: {
    const cancelProps = {
      type: types.REGISTRATION_POST
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens,
      errors: {
        ...action.payload
      }
    };
  }

  case types.ACTIVATION_POST_STARTED: {
    const cancelProps = {
      type: types.ACTIVATION_POST,
      token: action.payload.token,
      id: action.payload.id
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens
    };
  }

  case types.ACTIVATION_POST_SUCCESS: {
    const cancelProps = {
      type: types.ACTIVATION_POST
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens,
      errors: {}
    };
  }

  case types.ACTIVATION_POST_FAILURE: {
    const cancelProps = {
      type: types.ACTIVATION_POST
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens,
      errors: {
        ...action.payload
      }
    };
  }

  case types.AUTH_POST_STARTED: {
    const cancelProps = {
      type: types.AUTH_POST,
      token: action.payload.token,
      id: action.payload.id
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens
    };
  }

  case types.AUTH_POST_SUCCESS: {
    const cancelProps = {
      type: types.AUTH_POST
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      isAuthenticated: true,
      cancelTokens: updatedCancelTokens,
      errors: {}
    };
  }

  case types.AUTH_POST_FAILURE: {
    const cancelProps = {
      type: types.AUTH_POST
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      isAuthenticated: false,
      cancelTokens: updatedCancelTokens,
      errors: {
        ...action.payload
      }
    };
  }

  case types.AUTH_REMOVE_SUCCESS:
    return {
      ...state,
      isAuthenticated: false,
      errors: {},
      myId: null,
      isAdmin: false,
      isInstructor: false
    };

  case types.RECOVERY_POST_STARTED: {
    const cancelProps = {
      type: types.RECOVERY_POST,
      token: action.payload.token,
      id: action.payload.id
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens
    };
  }

  case types.RECOVERY_POST_SUCCESS: {
    const cancelProps = {
      type: types.RECOVERY_POST
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens,
      errors: {}
    };
  }

  case types.RECOVERY_POST_FAILURE: {
    const cancelProps = {
      type: types.RECOVERY_POST
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens,
      errors: {
        ...action.payload
      }
    };
  }

  case types.RESET_POST_STARTED: {
    const cancelProps = {
      type: types.RESET_POST,
      token: action.payload.token,
      id: action.payload.id
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens
    };
  }

  case types.RESET_POST_SUCCESS: {
    const cancelProps = {
      type: types.RESET_POST
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens,
      errors: {}
    };
  }

  case types.RESET_POST_FAILURE: {
    const cancelProps = {
      type: types.RESET_POST
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens,
      errors: {
        ...action.payload
      }
    };
  }

  case types.ME_GET_STARTED: {
    const cancelProps = {
      type: types.ME_GET,
      token: action.payload.token,
      id: action.payload.id
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens
    };
  }

  case types.ME_GET_SUCCESS: {
    const cancelProps = {
      type: types.ME_GET
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    const { id, roles, job } = action.payload;
    const isAdmin = R.includes(userRoles.ADMIN, roles);
    const isInstructor = R.includes(userRoles.INSTRUCTOR, roles);
    return {
      ...state,
      cancelTokens: updatedCancelTokens,
      errors: {},
      myId: id,
      isAdmin: isAdmin,
      isInstructor: isInstructor,
      myJob: job,
    };
  }

  case types.ME_GET_FAILURE: {
    const cancelProps = {
      type: types.ME_GET
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens,
      errors: {
        ...action.payload
      },
      myId: null,
      isAdmin: false,
      isInstructor: false
    };
  }

  default:
    return state;
  }
};

export default authenticationReducer;
