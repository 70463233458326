import { createSelector } from 'reselect';

const checkIfFetching = createSelector([
  state => state.authentication.cancelTokens
], (cancelTokens) => {
  return Object.keys(cancelTokens).reduce((hasTokens, key) => {
    return hasTokens || 0 < cancelTokens[key].length;
  }, false);
}
);

const profile = createSelector([
  state => state.authentication.myId,
  state => state.entities.users.byId
], (id, usersById) => {
  return usersById[id];
}
);

export default {
  checkIfFetching,
  profile
};
