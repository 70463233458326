import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { duckTypes } from "../../redux/entities/professionsKeys";
import { default as ProfessionForm } from "./ProfessionFormComponent";
import flavors from "../../redux/notifications/flavors";
import { responseFormikValidator } from "../../utils/validations";

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: 640,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
});

class ProfessionEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            professionId: null
        };
    }

    componentDidMount() {
        this.initData();
    }

    initData = () => {
        const { match } = this.props; // https://reacttraining.com/react-router/web/api/match
        if (match.params.id) {
            this.setState({
                professionId: match.params.id
            }, () => {
                this.props.professionGetRequest(this.state.professionId);
                this.props.fieldsOfActionGetRequest(); // NOTE: Currently fetching all items to get new items (may be overkill)
            });
        }
    };

    handleSubmit = (values, formikBag) => {
        const id = this.state.professionId;
        const data = {
            name: values.name || '',
            fieldsOfAction: values.fieldsOfAction || []
        };
        if (id) {
            this.props.professionPutRequest(id, data, formikBag).then((axiosResponse) => {
                responseFormikValidator(axiosResponse, formikBag);
            });
        } else {
            this.props.notificationEnqueue('Es ist ein Fehler aufgetreten', flavors.ERROR);
        }
    };

    handleReset = () => {
        this.props.professionsCancelRequest(duckTypes.PROFESSION_PUT.group);
        this.props.history.goBack();
    };

    render() {
        const { classes, byId, fieldsOfActionById } = this.props;

        const item = byId[this.state.professionId];

        return (
            <React.Fragment>
                <Paper elevation={0} className={classes.paper}>
                    <ProfessionForm item={item} fieldsOfAction={fieldsOfActionById} submitHandler={ this.handleSubmit } resetHandler={ this.handleReset } />
                </Paper>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ProfessionEditComponent);