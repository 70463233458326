import React from 'react';
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Avatar,
  Button,
  withStyles
} from "@material-ui/core";
import { duckTypes } from "../../redux/entities/competenceSpecificationsKeys";
import flavors from "../../redux/notifications/flavors";
import competenceSpecificationTypes from "../../utils/competenceSpecificationTypes";
import RemovalDialog from '../common/RemovalDialog';
import { default as CompetenceSpecificationForm } from "./CompetenceSpecificationFormComponent";

const styles = theme => ({
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
    },
  },
  cardHeader: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      flex: '0 0 30%',
    },
  },
  cardHeaderAvatar: {
    alignSelf: 'flex-start',
  },
  cardHeaderAvatarCircle: {
    backgroundColor: theme.palette.primary.main,
  },
  cardContent: {
    [theme.breakpoints.up('md')]: {
      flex: '1 1 auto',
    },
  },
  cardActions: {
    padding: theme.spacing(1, 2, 2),
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-end',
      justifyContent: 'flex-end',
      flex: '1 0 14rem',
    },
  },
  separator: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2.5),
    border: 0,
    borderColor: theme.palette.grey[300],
    borderStyle: 'dashed',
    borderTopWidth: '1px',
  }
});

class CompetenceSpecificationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestType: null,
      editItem: false,
      removalDialogOpen: false,
    };
  }

  toggleEdit = () => {
    this.setState(prevState => ({
      editItem: !prevState.editItem
    }));
  }

  openRemovalDialog = () => {
    this.setState({
      removalDialogOpen: true,
    });
  }

  handleNewSubmit = (values, formikBag) => {
    const { competenceCheckId, phaseId } = this.props;
    if (competenceCheckId && phaseId) {
      const data = {
        phase: phaseId,
        field: values.specType ? parseInt(values.specType) : 1,
        description: values.description
      };
      this.setState({
        requestType: duckTypes.COMPETENCESPECIFICATION_POST.group
      }, () => {
        this.props.competenceSpecificationPostRequest(competenceCheckId, data, formikBag).then(() => {
          formikBag.resetForm();
          this.props.competenceCheckGetRequest(competenceCheckId);
        });
      });
    } else {
      this.props.notificationEnqueue('Es ist ein Fehler aufgetreten', flavors.ERROR);
    }
  };

  handleEditSubmit = (values, formikBag) => {
    const { id } = this.props.item;
    if (id) {
      const data = {
        field: values.specType ? parseInt(values.specType) : 1,
        description: values.description
      };
      this.setState({
        requestType: duckTypes.COMPETENCESPECIFICATION_PUT.group
      }, () => {
        this.props.competenceSpecificationPutRequest(id, data, formikBag).then(() => {
          this.setState({
            editItem: false
          });
        });
      });
    } else {
      this.props.notificationEnqueue('Es ist ein Fehler aufgetreten', flavors.ERROR);
    }
  };

  handleDelete = () => {
    const { id } = this.props.item;
    if (id) {
      this.setState({
        requestType: duckTypes.COMPETENCESPECIFICATION_DELETE.group
      }, () => {
        this.props.competenceSpecificationDeleteRequest(id).then(() => {
          this.props.notificationEnqueue('Die Kompetenzbeschreibung wurde gelöscht.', flavors.SUCCESS);
        });
      });
    } else {
      this.props.notificationEnqueue('Es ist ein Fehler aufgetreten', flavors.ERROR);
    }
  };

  handleReset = () => {
    const promise = this.props.competenceSpecificationsCancelRequest(this.state.requestType);
    if (promise) {
      promise.then(() => {
        this.setState(prevState => ({
          editItem: false
        }));
      });
    }
  };

  render() {
    const { classes, isInstructor, phaseId, item, canEdit } = this.props;

    if (canEdit && !item) {
      return (
        <React.Fragment>
          <hr className={classes.separator}/>
          <CompetenceSpecificationForm isInstructor={isInstructor} phaseId={phaseId} submitHandler={ this.handleNewSubmit } resetHandler={ this.handleReset } />
        </React.Fragment>
      );
    }

    if (!item) {
      return null;
    }

    if (this.state.editItem) {
      return <CompetenceSpecificationForm isInstructor={isInstructor} phaseId={phaseId} item={item} submitHandler={ this.handleEditSubmit } resetHandler={ this.handleReset } />;
    } 

    const type = competenceSpecificationTypes[parseInt(item.field)];
    
    if (!type) {
      return null;
    }

    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={<Avatar className={classes.cardHeaderAvatarCircle}>{React.createElement(type.icon)}</Avatar>}
          title={type.label}
          titleTypographyProps={{
            color: 'textSecondary'
          }}
          subheader={isInstructor ? "Auszubildende*r kann..." : "Ich kann..."}
          subheaderTypographyProps={{
            color: 'textPrimary'
          }}
          classes={{
            root: classes.cardHeader,
            avatar: classes.cardHeaderAvatar
          }}
        />
        <CardContent className={classes.cardContent}>
          {item.description}
        </CardContent>
        {canEdit &&
          <CardActions className={classes.cardActions}>
            <Button size="small" onClick={() => this.openRemovalDialog()}>Löschen</Button>
            <RemovalDialog
              open={this.state.removalDialogOpen}
              subject={"die Kompetenzbeschreibung"}
              onClose={(remove) => {
                this.setState({ removalDialogOpen: false });

                if (remove === true) {
                  this.handleDelete();
                }
              }}
            />
            <Button variant="outlined" size="small" color="primary" onClick={() => this.toggleEdit()}>Bearbeiten</Button>
          </CardActions>
        }
      </Card>
    );
  }
}

export default withStyles(styles)(CompetenceSpecificationComponent);
