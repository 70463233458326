import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';
import { App } from './views';
// import 'typeface-roboto'; // TODO: Check, if bundler imports only necessary font weights! (https://material-ui.com/style/typography/#install-with-npm)

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <SnackbarProvider
                dense={true}
                hideIconVariant={true}>
                <App/>
            </SnackbarProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('react-root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
