import { withRouter } from "react-router";
import { connect } from "react-redux";
import DashboardApprenticeComponent from "./DashboardApprenticeComponent";
import { entityKey as reportsSliceKey } from "../../redux/entities/reportsKeys";
import { default as reportsOperations } from "../../redux/entities/reportsOperations";
import { metaKeys, uiOperations } from "../../redux/ui";
import { default as tasksOperations } from "../../redux/entities/tasksOperations";
import { entityKey as tasksSliceKey } from "../../redux/entities/tasksKeys";
import { default as competenceChecksOperations } from "../../redux/entities/competenceChecksOperations";
import { entityKey as competenceChecksSliceKey } from "../../redux/entities/competenceChecksKeys";

const mapStateToProps = state => {
  const reportsById = state.entities[reportsSliceKey].byId;
  const tasksById = state.entities[tasksSliceKey].byId;
  const competenceChecksById = state.entities[competenceChecksSliceKey].byId;

  return {
    reportsById,
    tasksById,
    competenceChecksById
  };
};

const mapDispatchToProps = dispatch => {
  const reportsGetRequest = urlParameters => dispatch(reportsOperations.reportsGetRequest(urlParameters));
  const tasksGetRequest = urlParameters => dispatch(tasksOperations.tasksGetRequest(urlParameters));
  const competenceChecksGetRequest = (urlParameters) => dispatch(competenceChecksOperations.competenceChecksGetRequest(urlParameters));

  const titleChange = value => dispatch(uiOperations.metaChangeOperationGeneric(metaKeys.TITLE, value));
  const parentRouteChange = value => dispatch(uiOperations.metaChangeOperationGeneric(metaKeys.PARENTROUTE, value));

  return {
    reportsGetRequest,
    tasksGetRequest,
    competenceChecksGetRequest,
    titleChange,
    parentRouteChange
  };
};

const DashboardApprenticeContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardApprenticeComponent));

export default DashboardApprenticeContainer;
