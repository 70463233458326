import types from './types.js';

const feedbackPostStarted = (cancelToken, id) => ({
  type: types.FEEDBACK_POST_STARTED,
  payload: {
    token: cancelToken,
    id: id || null
  }
});

const feedbackPostSuccess = data => ({
  type: types.FEEDBACK_POST_SUCCESS,
  payload: data
});

const feedbackPostFailure = error => ({
  type: types.FEEDBACK_POST_FAILURE,
  payload: error
});

export default {
  feedbackPostStarted,
  feedbackPostSuccess,
  feedbackPostFailure,
};
