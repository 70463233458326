import { withRouter } from "react-router";
import { connect } from 'react-redux';
import DashboardInstructorComponent from './DashboardInstructorComponent';
import { entityKey as usersSliceKey } from '../../redux/entities/usersKeys';
import { default as usersOperations } from '../../redux/entities/usersOperations';
import { withTableServerDataProvider } from "../../utils/tableServerDataProvider";
import { entityKey as tasksSliceKey } from "../../redux/entities/tasksKeys";
import { default as tasksOperations } from "../../redux/entities/tasksOperations";
import { default as competenceChecksOperations } from "../../redux/entities/competenceChecksOperations";
import { entityKey as competenceChecksSliceKey } from "../../redux/entities/competenceChecksKeys";
import { entityKey as filesSliceKey } from "../../redux/entities/filesKeys";

const mapStateToProps = state => {
  const { isAdmin, isInstructor } = state.authentication;
  const usersById = state.entities[usersSliceKey].byId;
  const tasksById = state.entities[tasksSliceKey].byId;
  const competenceChecksById = state.entities[competenceChecksSliceKey].byId;
  const filesById = state.entities[filesSliceKey].byId;

  return {
    isAdmin,
    isInstructor,
    usersById,
    tasksById,
    competenceChecksById,
    filesById
  };
};

const mapDispatchToProps = dispatch => {
  const usersGetRequest = (urlParameters) => dispatch(usersOperations.usersGetRequest(urlParameters));
  const tasksGetRequest = urlParameters => dispatch(tasksOperations.tasksGetRequest(urlParameters));
  const competenceChecksGetRequest = (urlParameters) => dispatch(competenceChecksOperations.competenceChecksGetRequest(urlParameters));

  return {
    usersGetRequest,
    tasksGetRequest,
    competenceChecksGetRequest
  };
};

const DashboardInstructorContainer = withTableServerDataProvider(withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardInstructorComponent)));

export default DashboardInstructorContainer;
