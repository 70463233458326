import { keys as genericKeys, type as genericType } from "../generics";

const { intents } = genericKeys;

const nameKeys = {
  NOTIFICATIONS: 'NOTIFICATIONS',
  NOTIFICATION: 'NOTIFICATION',
};

export const entityKey = 'notifications';
export const duckTypes = {
  NOTIFICATIONS_GET: genericType(entityKey, nameKeys.NOTIFICATIONS, intents.GET),
  NOTIFICATION_GET: genericType(entityKey, nameKeys.NOTIFICATION, intents.GET),
  NOTIFICATION_PUT_READ_FLAG: genericType(entityKey, nameKeys.NOTIFICATION, intents.PUT),
};
