import { withRouter } from "react-router";
import { connect } from 'react-redux';
import PasswordRecoveryComponent from './PasswordRecoveryComponent';
import { authenticationOperations } from '../../redux/authentication';
import { uiEntityKeys, viewKeys, uiOperations } from '../../redux/ui';
import { notificationsOperations } from '../../redux/notifications';

const uiEntityKey = uiEntityKeys.views;
const viewKey = viewKeys.PASSWORD_RECOVERY.key;

const mapStateToProps = state => {
  const { errors } = state.authentication;
  const view = state.ui[uiEntityKey][viewKey];

  return {
    errors,
    view
  };
};

const mapDispatchToProps = dispatch => {
  const recoveryPostRequest = (data, formikBag) => dispatch(authenticationOperations.recoveryPostRequest(data, formikBag));

  const cancelAuthRequest = (cancelType, cancelMessage) => dispatch(authenticationOperations.cancelAuthRequest(cancelType, cancelMessage));

  const viewChange = value => dispatch(uiOperations.viewChangeOperationGeneric(viewKey, value));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    recoveryPostRequest,
    cancelAuthRequest,
    viewChange,
    notificationEnqueue
  };
};

const PasswordRecoveryContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordRecoveryComponent));

export default PasswordRecoveryContainer;