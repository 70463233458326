import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import ProfileDataIcon from '@material-ui/icons/AssignmentInd';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { default as UserEditForm } from "./UserEditFormComponent";
import { duckTypes } from "../../redux/entities/usersKeys";
import flavors from "../../redux/notifications/flavors";
import routes from "../../utils/routes";
import { responseFormikValidator } from "../../utils/validations";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";
import PortalToolbarMenu from "../common/navigation/PortalToolbarMenu";
import RemovalDialog from "../common/RemovalDialog";
import RestoreDialog from "../common/RestoreDialog";

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: 640,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    }
});

class UserEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMyProfile: true,
            userId: null,
            removalDialogOpen: false,
            restoreDialogOpen: false,
        };
    }

    componentDidMount() {
        this.isProfilePage = this.props.location.pathname === routes.PROFILE_EDIT.path;

        this.initData();
        this.updateTitle();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.myId !== this.props.myId) {
            this.initData();
        }
    }

    componentWillUnmount() {
        this.props.titleChange(null);
    }


    initData = () => {
        const { match, myId, isAdmin, isInstructor, professionsGetRequest } = this.props; // https://reacttraining.com/react-router/web/api/match
        professionsGetRequest();

        if (match.params.id && (isAdmin || isInstructor)) {
            this.setState({
                isMyProfile: false,
                userId: match.params.id
            }, () => {
                this.updateTitle();
                this.props.userGetRequest(this.state.userId).then(() => {
                    this.updateTitle();
                });
            });
        } else {
            this.setState({
                userId: myId
            });
        }
    };

    updateTitle = () => {
        const { byId } = this.props;
        const { userId } = this.state;
    
        const user = byId ? byId[userId] : null;

        if (user) {
            this.props.titleChange(`${user.firstName} ${user.lastName} bearbeiten`);
        } else {
            this.props.titleChange(null);
        }
    };

    handleSubmit = (values, formikBag) => {
        const { userId } = this.state;
        const data = {
            firstName: values.firstName,
            lastName: values.lastName,
            username: values.username,
            avatar: values.avatar.length ? values.avatar[0] : null,
            job: values.profession,
            birthday: values.birthday !== '' ? values.birthday : null,
            placeOfBirth: values.placeOfBirth,
            street: values.street,
            zip: values.zip,
            city: values.city,
            phone: values.phone,
            degree: values.degree,
            trainingTimeFrom: values.trainingTimeFrom !== '' ? values.trainingTimeFrom : null
        };
        if (userId) {
            this.props.userPutRequest(userId, data, formikBag, this.isProfilePage).then((axiosResponse) => {
                responseFormikValidator(axiosResponse, formikBag);
            });
        } else {
            this.props.notificationEnqueue('Es ist ein Fehler aufgetreten', flavors.ERROR);
        }
    };

    handleReset = () => {
        this.props.usersCancelRequest(duckTypes.USER_PUT.group);
        if (this.state.isMyProfile) {
            this.props.history.push(routes.DASHBOARD.path);
        } else {
            this.props.history.push(routes.USERS.path);
        } 
    };

    handleUserRemove(id) {
        this.props.userDeleteRequest(id);
    };

    handleUserRestore(id) {
        this.props.userRestoreRequest(id);
    };

    render() {
        const { classes, byId, professionsById } = this.props;
        const { isMyProfile, userId, removalDialogOpen, restoreDialogOpen } = this.state;

        const user = byId ? byId[userId] : null;

        const portalToolbarMenuData = [{
                "title": "Löschen",
                "action": () => this.setState({ removalDialogOpen: true }),
                "icon": DeleteIcon,
                "visible": user && !user.deleted,
                "disabled": false
            }, {
                "title": "Wiederherstellen",
                "action": () => this.setState({ restoreDialogOpen: true }),
                "icon": RestoreFromTrashIcon,
                "visible": user && user.deleted,
                "disabled": false
            }
        ];

        const renderToolbarMenu = () => {
            return  (
              <React.Fragment>
                  <PortalToolbarMenu menuData={portalToolbarMenuData}/>
                  <RemovalDialog
                    open={removalDialogOpen}
                    subject={user.username}
                    onClose={(remove) => {
                        this.setState({ removalDialogOpen: false });

                        if (remove === true) {
                            this.handleUserRemove(user.id);
                        }
                    }}
                  />
                  <RestoreDialog
                    open={restoreDialogOpen}
                    subject={user.username}
                    onClose={(remove) => {
                        this.setState({ restoreDialogOpen: false });

                        if (remove === true) {
                            this.handleUserRestore(user.id);
                        }
                    }}
                  />
              </React.Fragment>
            )
        };

        return user ? (
              <>
                {renderToolbarMenu()}
                <Paper elevation={0} className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <ProfileDataIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" gutterBottom>{isMyProfile ? 'Profil' : 'Benutzer'} bearbeiten{user.deleted ? ' (gelöscht)' : ''}</Typography>
                    <UserEditForm user={user} submitHandler={ this.handleSubmit } resetHandler={ this.handleReset } professionsById={ professionsById } />
                </Paper>
              </>
        ) : null;
    }
}

export default withStyles(styles)(UserEditComponent);