import { schema } from 'normalizr';
import routes from "../../utils/routes";
import getHistory from '../../utils/history';
import { requestsOperations } from "./requests";
import { entityKey, duckTypes } from "./usersKeys";
import { notificationsOperations } from "../notifications";
import flavors from "../notifications/flavors";

export const userSchema = new schema.Entity(entityKey);
export const userListSchema = new schema.Array(userSchema);

const listGetRequest = (url, urlParameters = {}) => {
  const settings = {
    duckType: duckTypes.USERS_GET,
    requestPath: url,
    clearEntityData: false,
    normalizeSchema: userListSchema
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(null, settings, urlParameters));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const usersGetRequest = filterParams => listGetRequest('/users', filterParams);

const usersFilterOptionsRequest = (urlParameters = {}) => {
  const settings = {
    duckType: duckTypes.USER_FILTER_OPTIONS,
    requestPath: '/filter_options/users',
  };

  return requestsOperations.requestsFilterOptionsGeneric(null, settings, urlParameters);
};

const associationGetRequest = (url, filterParams) => {
  const params = {
    ...filterParams
  };
  const settings = {
    duckType: duckTypes.USERS_GET,
    requestPath: url,
    clearEntityData: false,
    normalizeSchema: userListSchema,
    requestCancelling: false,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const apprenticesByTaskGetRequest = (taskId, filterParams = {}) => associationGetRequest(`/tasks/${taskId}/apprentices`, filterParams);

const cooperatorsByTaskGetRequest = (taskId, filterParams = {}) => associationGetRequest(`/tasks/${taskId}/cooperators`, filterParams);

const ownersByTaskGetRequest = (taskId, filterParams = {}) => associationGetRequest(`/tasks/${taskId}/owners`, filterParams);

const usersByNotificationGetRequest = (notificationId, filterParams = {}) => associationGetRequest(`/notifications/${notificationId}/users`, filterParams);

const apprenticesByLearningPlaceGetRequest = (notificationId, filterParams = {}) => associationGetRequest(`/learning-places/${notificationId}/apprentices`, filterParams);

const usersByLinkGetRequest = (linkId, filterParams = {}) => associationGetRequest(`/links/${linkId}/users`, filterParams);

const usersByFileGetRequest = (linkId, filterParams = {}) => associationGetRequest(`/media/${linkId}/users`, filterParams);

const usersByResultGetRequest = (resultId, filterParams = {}) => associationGetRequest(`/results/${resultId}/users`, filterParams);

const userGetRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.USER_GET,
    requestPath: `/users/${id}`,
    idParamRequired: true,
    normalizeSchema: userSchema
  };
  return (dispatch) => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const userPutRequest = (id, data, formikBag, noRedirect = false) => {
  const params = {
    id: id,
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.USER_PUT,
    requestType: 'put',
    requestPath: `/users/${id}`,
    idParamRequired: true,
    normalizeSchema: userSchema,
    appendOnSuccess: noRedirect ? userChangeSuccess('Benutzerdaten wurden aktualisiert') : gotoList,
  };

  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const userPostRequest = (data, formikBag) => {
  const params = {
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.USER_POST,
    requestType: 'post',
    requestPath: '/users',
    normalizeSchema: userSchema,
    appendOnSuccess: gotoList
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const userDeleteRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.USER_DELETE,
    requestType: 'delete',
    requestPath: `/users/${id}`,
    idParamRequired: true,
    normalizeSchema: userSchema,
    appendOnSuccess: gotoList
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const userRestoreRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.USER_RESTORE,
    requestType: 'delete',
    requestPath: `/users/${id}/undo`,
    idParamRequired: true,
    normalizeSchema: userSchema,
    appendOnSuccess: gotoList
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const usersCancelRequest = (cancelType, cancelMessage) => {
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsCancellation(entityKey, cancelType, cancelMessage));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const userChangeSuccess = message => (dispatch) => {
  dispatch(notificationsOperations.notificationEnqueue(message, flavors.SUCCESS));
};

const gotoList = () => {
  getHistory().push(routes.USERS.path);
};

export default {
  usersGetRequest,
  userGetRequest,
  userPutRequest,
  userPostRequest,
  userDeleteRequest,
  usersCancelRequest,
  apprenticesByTaskGetRequest,
  cooperatorsByTaskGetRequest,
  ownersByTaskGetRequest,
  usersByNotificationGetRequest,
  apprenticesByLearningPlaceGetRequest,
  usersByLinkGetRequest,
  usersByFileGetRequest,
  usersByResultGetRequest,
  usersFilterOptionsRequest,
  userRestoreRequest
};
