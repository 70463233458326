import { withRouter } from "react-router";
import { connect } from 'react-redux';
import FacilityEditComponent from './FacilityEditComponent';
import { entityKey as facilitiesSliceKey } from '../../redux/entities/facilitiesKeys';
import { default as facilitiesOperations } from '../../redux/entities/facilitiesOperations';
import { notificationsOperations } from "../../redux/notifications";
import { default as usersOperations } from "../../redux/entities/usersOperations";
import { entityKey as usersSliceKey } from '../../redux/entities/usersKeys';

const mapStateToProps = state => {
  const { isAdmin, isInstructor } = state.authentication;
  const { errors, byId } = state.entities[facilitiesSliceKey];
  const usersById = state.entities[usersSliceKey].byId;

  return {
    isAdmin,
    errors,
    byId,
    isInstructor,
    usersById
  };
};

const mapDispatchToProps = dispatch => {
  const facilityGetRequest = id => dispatch(facilitiesOperations.facilityGetRequest(id));
  const facilityPutRequest = (id, data, formikBag) => dispatch(facilitiesOperations.facilityPutRequest(id, data, formikBag));
  const facilitiesCancelRequest = (cancelType, cancelMessage) => dispatch(facilitiesOperations.facilitiesCancelRequest(cancelType, cancelMessage));

  const usersGetRequest = () => dispatch(usersOperations.usersGetRequest());

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    facilityGetRequest,
    facilityPutRequest,
    facilitiesCancelRequest,
    usersGetRequest,
    notificationEnqueue
  };
};

const FacilityEditContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FacilityEditComponent));

export default FacilityEditContainer;