import React, { Component } from 'react';
import { matchPath } from "react-router";
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { PrivateRoute } from './common/PrivateRoute';
import Notifier from './common/Notifier';
import DrawerNav from './common/navigation/DrawerNav';
import { GlobalHistory } from "../utils/history";
import config from '../utils/config';
import routes from '../utils/routes';
import { notificationsFlavors } from "../redux/notifications";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tooltip from "@material-ui/core/Tooltip";
import * as R from "ramda";
import { isIOS } from 'react-device-detect';
import {ReactComponent as AppLogo} from "./../assets/app-logo.svg";

const styles = theme => ({
    appBar: {
        boxShadow: 'none',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: theme.palette.grey[300],
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        [theme.breakpoints.up('lg')]: {
            zIndex: theme.zIndex.drawer + 1,
        },
    },
    menuButton: {
        marginLeft: -theme.spacing(1),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    parentButton: {
        marginLeft: -theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    toolbarAvatar: {
        display: 'none',
        backgroundColor: theme.palette.secondary.main,
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            marginLeft: -theme.spacing(1),
            marginRight: theme.spacing(2),
        },
    },
    toolbarTitle: {
        flexGrow: 1,
        color: theme.palette.text.primary,
    },
    contentWrapper: {
        display: 'flex'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        flexShrink: 1,
        overflow: 'auto',
        height: '100vh',
    },
});

export const AppContext = React.createContext();
AppContext.displayName = 'AppContext';

class AppComponent extends Component {
    constructor(props) {
        super(props);

        this.toolbarMenuRef = React.createRef();

        this.state = {
            drawerOpen: false,
            matchResult: null,
            matchingRoute: null,
            navParent: null,
            appContext: {
                getToolbarMenuRef: () => this.toolbarMenuRef.current,
            }
        };
    }

    componentDidMount() {
        this.initUserData();
        this.updateToolbarTitle();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isAuthenticated) {
            this.initUserData();
        }
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.updateToolbarTitle();
        }
        if (prevProps.metaTitle !== this.props.metaTitle) {
            this.setDocumentTitle();
        }
        if (prevProps.metaParentRoute !== this.props.metaParentRoute) {
          this.updateNavParent();
      }
    }

    initUserData = () => {
        if (this.props.isAuthenticated && !this.props.isFetching && !this.props.myId) {
            this.props.meGetRequest();
            this.props.notificationsGetRequest();
        }
    };

    updateToolbarTitle = () => {
        const { pathname } = this.props.location;
        if (pathname) {
            Object.keys(routes).some(key => {
                const route = routes[key];
                // https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/matchPath.md
                const match = matchPath(pathname, {
                    path: route.path,
                    exact: true,
                    strict: true
                });
                if (match) {
                    this.setState({
                        matchResult: match,
                        matchingRoute: route
                    }, () => {
                        this.setDocumentTitle();
                        this.updateNavParent();
                    });
                    return true;
                }
                return false;
            });
        }
    };

    setDocumentTitle = () => {
        const { matchingRoute } = this.state;
        const { metaTitle } = this.props;

        let title = config.APP_TITLE;

        if (metaTitle) {
            title = `${metaTitle} ‧ ${config.APP_TITLE}`;
        } else if (matchingRoute && matchingRoute.title) {
            title = `${matchingRoute.title} ‧ ${config.APP_TITLE}`;
        }

        document.title = title;
    };

    updateNavParent = () => {
        const { matchingRoute } = this.state;
        const { metaParentRoute } = this.props;

        let navParent = matchingRoute && matchingRoute.parent && !R.isEmpty(routes[matchingRoute.parent]) ? routes[matchingRoute.parent] : null;
        
        if (metaParentRoute && metaParentRoute.path) {
          navParent = metaParentRoute;
        }

        this.setState({ 
            navParent: navParent
        }, () => {
            this.updateNavParentPath();
        });
    };

    updateNavParentPath = () => {
        const { navParent } = this.state;
        
        this.setState({
            navParentPath: navParent && navParent.path ? navParent.path : null
        }, () => {
            this.updateNavParentParams();
        });
    };

    updateNavParentParams = () => {
        const { matchResult, navParent } = this.state;

        if (!navParent || !matchResult || !matchResult.params) {
            return;
        }

        const navParentParams = navParent.path.split('/').filter(segment => segment.indexOf(':') === 0);

        if (navParentParams.length === 0) {
            return;
        }
        
        let navParentPathHydrated = navParent.path;
        navParentParams.forEach(param => {
            const resultParam = matchResult.params[param.substring(1)] || null;
            if (resultParam) {
                navParentPathHydrated = navParentPathHydrated.replace(`${param}`, resultParam);
            } else {
                navParentPathHydrated = navParentPathHydrated.replace(`/${param}`, '');
            }
        });
        this.setState({
            navParentPath: navParentPathHydrated
        });
    };

    toggleDrawer = toggle => {
        const newDrawerState = (typeof toggle !== 'undefined') ? toggle : !this.state.drawerOpen;
        this.setState({
            drawerOpen: newDrawerState
        });
    };

    handleRolesFallbackNotification = () => {
        this.props.notificationEnqueue('Sie haben keine Berechtigung für die angeforderte Seite und wurden umgeleitet.', notificationsFlavors.ERROR);
    };

    handleLogout = () => {
        this.props.authRemoveRequest();
    };

    render() {
        const { classes, isFetching, isAuthenticated, isAdmin, isInstructor, myId, profile, metaTitle, notificationsById, filesById } = this.props;
        const { drawerOpen, matchingRoute, navParent, navParentPath, appContext } = this.state;

        const avatarUrl = profile && profile.avatar && filesById && filesById[profile.avatar] ? config.DOWNLOAD_URL + `/uploads/${filesById[profile.avatar].filename}` : null;

        const unreadNotificationsCount = Object.keys(notificationsById).filter(id => notificationsById[id] && notificationsById[id].readFlag === false).length || 0;

        return (
            <React.Fragment>
                <GlobalHistory />
                <Notifier />
                <AppContext.Provider value={appContext}>
                    <AppBar color="default" position="fixed" className={classes.appBar}>
                        <Toolbar>
                            {navParent && navParentPath ? (
                                <Tooltip title={navParent.title} aria-label={navParent.title}>
                                    <IconButton className={classes.parentButton} component={Link} to={navParentPath} color="inherit">
                                        {isIOS ? <ArrowBackIos/> : <ArrowBack />}
                                    </IconButton>
                                </Tooltip>
                            ):(
                                <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer" onClick={() => this.toggleDrawer()}>
                                    <MenuIcon />
                                </IconButton>
                            )}
                            <AppLogo height='24' style={{marginRight: '1rem'}} />
                            <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>{
                                metaTitle ? metaTitle : (
                                    matchingRoute && matchingRoute.title ? matchingRoute.title : config.APP_TITLE
                                )
                            }</Typography>
                            <div ref={this.toolbarMenuRef} />
                        </Toolbar>
                        {isFetching && <LinearProgress />}
                    </AppBar>
                    <div className={classes.contentWrapper}>
                        <DrawerNav isAuthenticated={isAuthenticated} isAdmin={isAdmin} isInstructor={isInstructor} container={this.props.container} drawerOpen={drawerOpen} toggleDrawerHandler={this.toggleDrawer} logoutHandler={this.handleLogout} myId={myId} profile={profile} avatarUrl={avatarUrl} unreadNotificationsCount={unreadNotificationsCount}/>
                        <main className={classes.content}>
                            <div className={classes.appBarSpacer} />
                            <Switch>
                                {Object.keys(routes).map((key, index) => {
                                    const route = routes[key];
                                    return route.isPrivate
                                        ? <PrivateRoute key={`route-${index}`} exact={route.exact} path={route.path} component={route.component} roles={route.roles} parent={route.parent} rolesFallbackNotification={this.handleRolesFallbackNotification} profile={profile} />
                                        : <Route key={`route-${index}`} exact={route.exact} path={route.path} component={route.component} />;
                                })}
                            </Switch>
                        </main>
                    </div>
                </AppContext.Provider>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withRouter(AppComponent));
