import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmDialog = ({ open, title, content, onClose }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-confirm-title"
      aria-describedby="alert-dialog-confirm-description"
    >
      <DialogTitle id="alert-dialog-confirm-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-confirm-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">Nein</Button>
        <Button onClick={() => onClose(true)} color="primary" autoFocus>Ja</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;