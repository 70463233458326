import React from 'react';
import { Field, Form, Formik } from "formik";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ReactSelect from "../common/ReactSelect";
import FilesSelection from "../common/FilesSelectionContainer";
import LinksSelection from "../common/LinksSelectionContainer";
import RichTextField from '../common/RichTextField';
import SingleCheckbox from "../common/SingleCheckbox";
import { Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    wrapper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
});

class TaskNewFormComponent extends React.Component {
    render() {
        const { classes, fieldsOfAction, cooperators, submitHandler, resetHandler, defaultMaster } = this.props;

        const fieldsOfActionOptions = Object.keys(fieldsOfAction).map(itemId => {
            const item = fieldsOfAction[itemId];
            return {
                value: item.id,
                label: item.name
            };
        }, []);

        const cooperatorsOptions = Object.keys(cooperators).map(itemId => {
            const cooperator = cooperators[itemId];
            return {
                value: cooperator.id,
                label: `${cooperator.firstName} ${cooperator.lastName}`
            };
        }, []);

        return (
            <Formik
                initialValues = {{
                    title: '',
                    fieldOfAction: '',
                    description: '',
                    cooperators: [],
                    media: [],
                    links: [],
                    master: defaultMaster ? '1' : ''
                }}
                enableReinitialize={true}
                validate = {values => {
                    let errors = {};
                    if (values.title === '') {
                        errors.title = 'Dies ist ein Pflichtfeld';
                    }
                    if (values.fieldOfAction === '') {
                        errors.fieldOfAction = 'Bitte Auswahl treffen';
                    }
                    return errors;
                }}
                onSubmit = {(values, formikBag) => {
                    submitHandler(values, formikBag);
                }}>
                {({ status, values, touched, errors, setFieldValue, setFieldTouched, isSubmitting, handleSubmit, handleReset }) => (
                    <Form
                        autoComplete="off"
                        onSubmit={evt => {
                            evt.stopPropagation();
                            handleSubmit(evt);
                        }}
                        onReset={evt => {
                            evt.stopPropagation();
                            handleReset(evt);
                            resetHandler();
                        }}
                        className={classes.form}
                    >
                        {status &&
                            <FormHelperText error>{status}</FormHelperText>
                        }
                        <Field component={TextField}
                            type="text"
                            id="title"
                            name="title"
                            label="Titel"
                            required
                            fullWidth
                            margin="normal"/>
                        <FormControl fullWidth margin="normal" error={!!errors.fieldOfAction && touched.fieldOfAction}>
                            <ReactSelect name="fieldOfAction"
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                value={values.fieldOfAction}
                                error={errors.fieldOfAction}
                                touched={touched.fieldOfAction}
                                options={fieldsOfActionOptions}
                                placeholder="Aufgabenbereich auswählen..."
                                label="Aufgabenbereich"
                                isMulti={false}
                                isClearable={true}/>
                            {(!!errors.fieldOfAction && touched.fieldOfAction) &&
                                <FormHelperText>{errors.fieldOfAction}</FormHelperText>
                            }
                        </FormControl>
                        <Field component={RichTextField}
                            type="text"
                            id="description"
                            name="description"
                            label="Beschreibung"
                            // maxLength={250}
                            fullWidth
                            margin="normal"/>
                        <FormControl fullWidth margin="normal" error={!!errors.cooperators && touched.cooperators}>
                            <ReactSelect name="cooperators"
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                value={values.cooperators}
                                error={errors.cooperators}
                                touched={touched.cooperators}
                                options={cooperatorsOptions}
                                placeholder="Mitwirkende Ausbilder*innen auswählen..."
                                label="Mitwirkende Ausbilder*innen"
                                isMulti={true}
                                isClearable={false}/>
                            {(!!errors.cooperators && touched.cooperators) &&
                                <FormHelperText>{errors.cooperators}</FormHelperText>
                            }
                        </FormControl>
                        <Field component={FilesSelection}
                            id="media"
                            name="media"
                            label="Dateien"
                            value={values.media}
                            onChange={setFieldValue}
                        />
                        <Field component={LinksSelection}
                            id="links"
                            name="links"
                            label="Links"
                            value={values.links}
                            onChange={setFieldValue}
                        />
                        <Tooltip
                          title={
                              <React.Fragment>
                                  <Typography color="inherit">
                                    Aufgabenvorlagen dienen als Vorlage für neue Aufgaben. Es dürfen deswegen zunächst keine mitwirkende Ausbilder*innen zugewiesen werden.
                                  </Typography>
                              </React.Fragment>
                          }>
                            <FormControl fullWidth margin="normal">
                                {values.cooperators.length === 0 && <SingleCheckbox name="master" value="1" label="Als Aufgabenvorlage anlegen" />}
                                {values.cooperators.length > 0 && <Typography>Hinweis: Diese Aufgabe kann aktuell nicht als Vorlage angelegt werden, da bereits mitwirkende Ausbilder*innen zugewiesen wurden.</Typography>}
                            </FormControl>
                        </Tooltip>
                        <div className={classes.wrapper}>
                            <Button type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}>
                                Speichern
                            </Button>
                            {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress} />}
                        </div>
                        <div className={classes.wrapper}>
                            <Button type="reset" color="primary">Abbrechen</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withStyles(styles)(TaskNewFormComponent);
