import React from 'react';
import { connect } from "react-redux";
import { default as DashboardApprentice } from './DashboardApprenticeContainer';
import { default as DashboardInstructor } from './DashboardInstructorContainer';


const DashboardComponent = (props) => {
  const { isAdmin, isInstructor, myId } = props;

  const isApprentice = myId && !isAdmin && !isInstructor;

  return (
    <React.Fragment>
      {isInstructor && <DashboardInstructor/>}
      {isApprentice && <DashboardApprentice/>}
    </React.Fragment>
  );
};


const mapStateToProps = state => {
  const { isAdmin, isInstructor, myId } = state.authentication;

  return {
    isAdmin,
    isInstructor,
    myId
  };
};

export default connect(mapStateToProps)(DashboardComponent);

