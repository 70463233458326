import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { duckTypes } from "../../redux/entities/businessesKeys";
import { default as BusinessForm } from "./BusinessFormComponent";
import { responseFormikValidator } from "../../utils/validations";

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: 640,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    }
});

class BusinessNewComponent extends React.Component {
    handleSubmit = (values, formikBag) => {
        const data = {
            name: values.name || '',
            street: values.street || '',
            postcode: values.postcode || '',
            city: values.city || ''
        };
        this.props.businessPostRequest(data, formikBag).then((axiosResponse) => {
            responseFormikValidator(axiosResponse, formikBag);
        });
    };

    handleReset = () => {
        this.props.businessesCancelRequest(duckTypes.BUSINESS_POST.group);
        this.props.history.goBack();
    };

    render() {
        const { classes } = this.props;

        return (
            <Paper elevation={0} className={classes.paper}>
                <BusinessForm submitHandler={ this.handleSubmit } resetHandler={ this.handleReset } />
            </Paper>
        );
    }
}

export default withStyles(styles)(BusinessNewComponent);