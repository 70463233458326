import { connect } from 'react-redux';
import CompetenceSpecificationComponent from './CompetenceSpecificationComponent';
import { entityKey as competenceSpecificationsSliceKey } from '../../redux/entities/competenceSpecificationsKeys';
import { default as competenceSpecificationsOperations } from '../../redux/entities/competenceSpecificationsOperations';
import { default as competenceChecksOperations } from '../../redux/entities/competenceChecksOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { myId, isAdmin, isInstructor } = state.authentication;
  const { errors, byId } = state.entities[competenceSpecificationsSliceKey];

  return {
    myId,
    isAdmin,
    isInstructor,
    errors,
    byId
  };
};

const mapDispatchToProps = dispatch => {
  const competenceSpecificationGetRequest = id => dispatch(competenceSpecificationsOperations.competenceSpecificationGetRequest(id));
  const competenceSpecificationPutRequest = (id, data, formikBag) => dispatch(competenceSpecificationsOperations.competenceSpecificationPutRequest(id, data, formikBag));
  const competenceSpecificationPostRequest = (competenceCheckId, data, formikBag) => dispatch(competenceSpecificationsOperations.competenceSpecificationPostRequest(competenceCheckId, data, formikBag));
  const competenceSpecificationDeleteRequest = id => dispatch(competenceSpecificationsOperations.competenceSpecificationDeleteRequest(id));

  const competenceSpecificationsCancelRequest = (cancelType, cancelMessage) => dispatch(competenceSpecificationsOperations.competenceSpecificationsCancelRequest(cancelType, cancelMessage));

  const competenceCheckGetRequest = id => dispatch(competenceChecksOperations.competenceCheckGetRequest(id));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    competenceSpecificationGetRequest,
    competenceSpecificationPutRequest,
    competenceSpecificationPostRequest,
    competenceSpecificationDeleteRequest,
    competenceSpecificationsCancelRequest,
    competenceCheckGetRequest,
    notificationEnqueue
  };
};

const CompetenceSpecificationContainer = connect(mapStateToProps, mapDispatchToProps)(CompetenceSpecificationComponent);

export default CompetenceSpecificationContainer;