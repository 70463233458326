import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import ResultFormComponent from "./ResultFormComponent";
import routes from "../../utils/routes";
import resultContainerWrapper from "./ResultContainerWrapper";
import { responseFormikValidator } from "../../utils/validations";

const styles = theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      width: 640,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
});

class ResultNewComponent extends Component {
  componentDidMount() {
    this.initData();
  }

  initData = () => {
    const { match, subTaskGetRequest } = this.props;
    const subTaskId = match.params.id;
    subTaskGetRequest(subTaskId);
  };

  handleSubmit(values, formikBag) {
    const data = {
      owner: this.props.myId,
      text: values.text,
      media: values.media,
      links: values.links,
      submitted: values.submitted,
      subTask: this.props.match.params.id,
    };

    const appendType = "updateResult";
    const appendData = { appendType };
    this.props.resultPostRequest(data, formikBag, appendData).then((axiosResponse) => {
      responseFormikValidator(axiosResponse, formikBag);
    });

    setTimeout(() => this.goBackToSubTask(), 800);
  }

  goBackToSubTask() {
    const { match, subTasksById } = this.props;
    const taskKey = match.params.taskKey;
    const subTaskId = match.params.id;
    const subTask = subTasksById[subTaskId];

    if (!subTask) {
      this.props.history.goBack();
    }

    this.props.history.push(routes.SUBTASK_DETAILS.path
      .replace(':number', subTask.subTaskNumber)
      .replace(':id', taskKey));
  }

  handleReset() {
    this.goBackToSubTask();
  }

  render() {
    const { classes, subTasksById, match } = this.props;
    const subTaskId = match.params.id;

    const item = {};
    const subTask = subTasksById[subTaskId];

    if (!subTask) {
      return <></>;
    }

    return (
      <Paper elevation={0} className={classes.paper}>
        <ResultFormComponent
          item={item}
          submitHandler={this.handleSubmit.bind(this)}
          resetHandler={this.handleReset.bind(this)}
        />
      </Paper>
    );
  }
}

export default resultContainerWrapper(withStyles(styles)(ResultNewComponent));
