import { withRouter } from "react-router";
import { connect } from 'react-redux';
import CompetenceChecksComponent from './CompetenceChecksComponent';
import { entityKey as competenceChecksSliceKey } from '../../redux/entities/competenceChecksKeys';
import { default as competenceChecksOperations } from '../../redux/entities/competenceChecksOperations';
import { notificationsOperations } from "../../redux/notifications";
import { withTableServerDataProvider } from "../../utils/tableServerDataProvider";

const mapStateToProps = state => {
  const { isAdmin, isInstructor } = state.authentication;
  const { errors, allIds, byId, totalCount, filterOptions } = state.entities[competenceChecksSliceKey];

  return {
    isAdmin,
    isInstructor,
    errors,
    allIds,
    byId,
    totalCount,
    filterOptions
  };
};

const mapDispatchToProps = dispatch => {
  const competenceChecksGetRequest = (urlParameters) => dispatch(competenceChecksOperations.competenceChecksGetRequest(urlParameters));
  const competenceChecksFilterOptionsRequest = (urlParameters) => dispatch(competenceChecksOperations.competenceChecksFilterOptionsRequest(urlParameters));

  const competenceCheckCopyRequest = id => dispatch(competenceChecksOperations.competenceCheckCopyRequest(id));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    competenceChecksGetRequest,
    competenceCheckCopyRequest,
    notificationEnqueue,
    competenceChecksFilterOptionsRequest
  };
};

const CompetenceChecksContainer = withTableServerDataProvider(withRouter(connect(mapStateToProps, mapDispatchToProps)(CompetenceChecksComponent)));

export default CompetenceChecksContainer;