import types from './types.js';

const registrationPostStarted = (cancelToken, id) => ({
  type: types.REGISTRATION_POST_STARTED,
  payload: {
    token: cancelToken,
    id: id || null
  }
});

const registrationPostSuccess = data => ({
  type: types.REGISTRATION_POST_SUCCESS,
  payload: data
});

const registrationPostFailure = error => ({
  type: types.REGISTRATION_POST_FAILURE,
  payload: error
});

const activationPostStarted = (cancelToken, id) => ({
  type: types.ACTIVATION_POST_STARTED,
  payload: {
    token: cancelToken,
    id: id || null
  }
});

const activationPostSuccess = data => ({
  type: types.ACTIVATION_POST_SUCCESS,
  payload: data
});

const activationPostFailure = error => ({
  type: types.ACTIVATION_POST_FAILURE,
  payload: error
});

const authPostStarted = (cancelToken, id) => ({
  type: types.AUTH_POST_STARTED,
  payload: {
    token: cancelToken,
    id: id || null
  }
});

const authPostSuccess = data => ({
  type: types.AUTH_POST_SUCCESS,
  payload: data
});

const authPostFailure = error => ({
  type: types.AUTH_POST_FAILURE,
  payload: error
});

const authRemoveSuccess = () => ({
  type: types.AUTH_REMOVE_SUCCESS,
  payload: null
});

const recoveryPostStarted = (cancelToken, id) => ({
  type: types.RECOVERY_POST_STARTED,
  payload: {
    token: cancelToken,
    id: id || null
  }
});

const recoveryPostSuccess = data => ({
  type: types.RECOVERY_POST_SUCCESS,
  payload: data
});

const recoveryPostFailure = error => ({
  type: types.RECOVERY_POST_FAILURE,
  payload: error
});

const resetPostStarted = (cancelToken, id) => ({
  type: types.RESET_POST_STARTED,
  payload: {
    token: cancelToken,
    id: id || null
  }
});

const resetPostSuccess = data => ({
  type: types.RESET_POST_SUCCESS,
  payload: data
});

const resetPostFailure = error => ({
  type: types.RESET_POST_FAILURE,
  payload: error
});

const meGetStarted = (cancelToken, id) => ({
  type: types.ME_GET_STARTED,
  payload: {
    token: cancelToken,
    id: id || null
  }
});

const meGetSuccess = data => ({
  type: types.ME_GET_SUCCESS,
  payload: data
});

const meGetFailure = error => ({
  type: types.ME_GET_FAILURE,
  payload: error
});

export default {
  registrationPostStarted,
  registrationPostSuccess,
  registrationPostFailure,
  activationPostStarted,
  activationPostSuccess,
  activationPostFailure,
  authPostStarted,
  authPostSuccess,
  authPostFailure,
  authRemoveSuccess,
  recoveryPostStarted,
  recoveryPostSuccess,
  recoveryPostFailure,
  resetPostStarted,
  resetPostSuccess,
  resetPostFailure,
  meGetStarted,
  meGetSuccess,
  meGetFailure
};
