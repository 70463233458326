import React from 'react';
import Box from '@material-ui/core/Box';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import taskPhases from "../../utils/taskPhases";
import taskStatus from "../../utils/taskStatus";
import Typography from "@material-ui/core/Typography";
import routes from "../../utils/routes";
import config from "../../utils/config";
import ListFiles from "../common/ListFiles";
import ListLinks from "../common/ListLinks";
import Chip from "@material-ui/core/Chip";
import SubTaskIcon from "@material-ui/icons/Receipt";
import ShowMoreHtml from "../common/ShowMoreHtml";
import ReactHtmlParser from "react-html-parser";
import ListResults from "../common/ListResults";
import SubmitIcon from "@material-ui/icons/AssignmentTurnedIn";
import EditIcon from "@material-ui/icons/Edit";
import PortalToolbarMenu from "../common/navigation/PortalToolbarMenu";
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ConfirmDialog from "../common/ConfirmDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import RemovalDialog from "../common/RemovalDialog";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  box: {
    padding: theme.spacing(2),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.grey[50],
  },
  button: {
    marginRight: '10px',
  },
});

class SubTaskComponent extends React.Component {
  //static contextType = LayoutContext;

  constructor(props) {
    super(props);
    this.state = {
      submitDialogOpen: false,
      removalDialogOpen: false,
    };
  }

  componentDidMount() {
    this.initData();
  }

  componentWillUnmount() {
    this.props.titleChange(null);
  }

  initData = () => {
    const { match } = this.props;

    this.props.taskWithoutRelationsGetRequest(match.params.id).then(() => {
      const task = this.getTask();
      this.props.subTasksGetByTaskAndNumberRequest(task.id, match.params.number).then(() => {
        this.updateTitle();
      });
    });
  };

  updateTitle = () => {
    const subTask = this.getSubTask();

    if (null !== subTask) {
      this.props.titleChange(`Teilaufgabe: ${subTask.title}`);
    } else {
      this.props.titleChange(null);
    }
  };

  getTask = () => {
    const { match } = this.props;
    let task = Object.values(this.props.tasksById).filter(t => t.taskKey === match.params.id);
    task = task.length ? task[0] : null;

    return task;
  };

  getSubTask = () => {
    const { match } = this.props;
    let subTask = Object.values(this.props.byId)
      .filter(subTask => subTask.subTaskNumber === parseInt(match.params.number));
    subTask = subTask.length ? subTask[0] : null;

    return subTask;
  };

  handleDownload = (evt, id) => {
    evt.preventDefault();
    if (id) {
      const filename = this.props.filesById[id].filename;
      window.open(config.DOWNLOAD_URL + `/uploads/${filename}`);
    }
  };

  handleEditInit = () => {
    const task = this.getTask();
    const subTask = this.getSubTask();

    if (!subTask || !task) {
      return;
    }

    this.props.history.push(routes.SUBTASK_EDIT.path.replace(':id', task.taskKey).replace(':number', subTask.subTaskNumber));
  };

  handleResultUploadButton = () => {
    const task = this.getTask();
    const subTask = this.getSubTask();

    this.props.history.push(routes.RESULT_NEW.path
      .replace(':id', subTask.id)
      .replace(':taskKey', task.taskKey));
  };

  handleSubTaskSubmit(item) {
    this.props.subTaskSubmitRequest(item.id).then(() => this.initData());
  }

  handleSubTaskRemove = (id) => {
    const task = this.getTask();
    const returnRoute = routes.TASK_DETAILS.path.replace(":id", task.taskKey);

    this.props.subTaskDeleteRequest(id, returnRoute);
  };

  render() {
    const { classes, filesById, linksById, resultsById } = this.props;
    const { submitDialogOpen, removalDialogOpen } = this.state;
    const task = this.getTask();
    const subTask = this.getSubTask();

    if (!subTask || !task) {
      return <></>;
    }

    const item = subTask;

    const subTaskNumber = item ? item.subTaskNumber : null;
    const fullKey = (task.taskKey && subTaskNumber) ? `${task.taskKey} / ${subTaskNumber} ` : '-';

    const hasDescription = item && item.description && item.description.trim().length > 0;

    const { canSubmit, canEdit, canCreateResult, canDelete } = item.permissions;

    const portalToolbarMenuData = [
      {
        "title": "Bearbeiten",
        "action": () => this.handleEditInit(),
        "icon": EditIcon,
        "visible": canEdit,
        "disabled": false
      }, {
        "title": "Löschen",
        "action": () => this.setState({ removalDialogOpen: true }),
        "icon": DeleteIcon,
        "visible": canDelete,
        "disabled": false
      }, {
        "title": "Arbeitsergebnis hochladen",
        "action": () =>  this.handleResultUploadButton(),
        "icon": NoteAddIcon,
        "visible": canCreateResult,
        "disabled": false
      }, {
        "title": "Einreichen",
        "action": () => this.setState({...this.state, submitDialogOpen: true }),
        "icon": SubmitIcon,
        "visible": canSubmit,
        "disabled": false
      }
    ];

    return (
      <React.Fragment>
        <PortalToolbarMenu menuData={portalToolbarMenuData}/>
        <ConfirmDialog
          open={submitDialogOpen}
          title={`Unteraufgabe einreichen`}
          content={`Möchten Sie die aktuelle Unteraufgabe einreichen?`}
          onClose={confirm => {
            this.setState({...this.state, submitDialogOpen: false });

            if (confirm === true) {
              this.handleSubTaskSubmit(item);
            }
          }}
        />
        <RemovalDialog
          open={removalDialogOpen}
          subject={item ? item.title : '-'}
          onClose={(remove) => {
            this.setState({ removalDialogOpen: false });

            if (remove === true) {
              this.handleSubTaskRemove(item.id);
            }
          }}
        />
        <Grid container>
          <Grid item xs={12} sm={12} md={8}>
            <Box className={classes.box}>
              <Typography component="h2" variant="overline" gutterBottom>Aufgabe / Teilaufgabe Nr., Titel</Typography>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Chip
                    color="primary"
                    icon={<SubTaskIcon/>}
                    label={fullKey}
                  />
                </Grid>
                <Grid item xs>
                  <Typography component="h1" variant="h5" gutterBottom>{item ? item.title : '-'}</Typography>
                </Grid>
              </Grid>
              <Divider/>
              <Typography component="h2" variant="overline" gutterBottom>Beschreibung</Typography>
              {hasDescription ? (
                <ShowMoreHtml maxHeight={250}>
                  {ReactHtmlParser(item.description)}
                </ShowMoreHtml>
              ) : (
                <Typography component="p" variant="body1" gutterBottom>-</Typography>
              )}
              <Divider/>
              <Typography component="h2" variant="overline" gutterBottom>Dateien</Typography>
              {item && item.media && item.media.length > 0 ? (
                <ListFiles ids={item.media} byId={filesById} actionHandler={this.handleDownload}/>
              ) : (
                <Typography component="p" variant="body1" gutterBottom>-</Typography>
              )}
              <Divider/>
              <Typography component="h2" variant="overline" gutterBottom>Links</Typography>
              {item && item.links && item.links.length > 0 ? (
                <ListLinks ids={item.links} byId={linksById}/>
              ) : (
                <Typography component="p" variant="body1" gutterBottom>-</Typography>
              )}
              <Divider/>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box className={classes.box}>
              <Typography component="h2" variant="overline" gutterBottom>Status</Typography>
              <Typography component="p" variant="body1" gutterBottom>{item ? taskStatus[item.status] : '-'}</Typography>
              <Divider/>
              <Typography component="h2" variant="overline" gutterBottom>Phase</Typography>
              <Typography component="p" variant="body1" gutterBottom>{item ? taskPhases[item.phase].label : '-'}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={8}>
            <Box className={classes.box}>
              <Typography component="h2" variant="overline" gutterBottom>Arbeitsergebnisse</Typography>
              {item && item.results && item.results.length > 0 ? (
                <ListResults ids={item.results} byId={resultsById} canEdit={item.permissions.canEdit} subTask={item}/>
              ) : (
                <Typography component="p" variant="body1" gutterBottom>-</Typography>
              )}
              {canCreateResult &&
                <Button variant="contained" size="small" color="primary" startIcon={<NoteAddIcon/>} onClick={() => this.handleResultUploadButton()}>
                  Arbeitsergebnis hochladen
                </Button>
              }
            </Box>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SubTaskComponent);
