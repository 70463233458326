import { withRouter } from "react-router";
import { connect } from 'react-redux';
import LinksSelectionComponent from './LinksSelectionComponent';
import { entityKey as linksSliceKey } from '../../redux/entities/linksKeys';
import { default as linksOperations } from '../../redux/entities/linksOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { isAdmin, isInstructor } = state.authentication;
  const { errors, byId, allIds } = state.entities[linksSliceKey];

  return {
    isAdmin,
    isInstructor,
    errors,
    byId,
    allIds
  };
};

const mapDispatchToProps = dispatch => {
  const linksGetRequest = () => dispatch(linksOperations.linksGetRequest());
  const linkPostRequest = (data, formikBag) => dispatch(linksOperations.linkPostRequest(data, formikBag));

  const linksCancelRequest = (cancelType, cancelMessage) => dispatch(linksOperations.linksCancelRequest(cancelType, cancelMessage));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    linksGetRequest,
    linkPostRequest,
    linksCancelRequest,
    notificationEnqueue
  };
};

const LinksSelectionContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(LinksSelectionComponent));

export default LinksSelectionContainer;
