import { withRouter } from "react-router";
import { connect } from 'react-redux';
import FieldOfActionEditComponent from './FieldOfActionEditComponent';
import { entityKey as fieldsOfActionSliceKey } from '../../redux/entities/fieldsOfActionKeys';
import { default as fieldsOfActionOperations } from '../../redux/entities/fieldsOfActionOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { isAdmin } = state.authentication;
  const { errors, byId } = state.entities[fieldsOfActionSliceKey];

  return {
    isAdmin,
    errors,
    byId
  };
};

const mapDispatchToProps = dispatch => {
  const fieldOfActionGetRequest = id => dispatch(fieldsOfActionOperations.fieldOfActionGetRequest(id));
  const fieldOfActionPutRequest = (id, data, formikBag) => dispatch(fieldsOfActionOperations.fieldOfActionPutRequest(id, data, formikBag));

  const fieldsOfActionCancelRequest = (cancelType, cancelMessage) => dispatch(fieldsOfActionOperations.fieldsOfActionCancelRequest(cancelType, cancelMessage));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    fieldOfActionGetRequest,
    fieldOfActionPutRequest,
    fieldsOfActionCancelRequest,
    notificationEnqueue
  };
};

const FieldOfActionEditContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FieldOfActionEditComponent));

export default FieldOfActionEditContainer;