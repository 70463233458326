import {
  Build as BuildIcon,
  SquareFoot as SquareFootIcon,
  RecordVoiceOver as RecordVoiceOverIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon
} from "@material-ui/icons";

const taskPhases = {
  1: {
    label: 'Annahme',
    icon: RecordVoiceOverIcon
  },
  2: {
    label: 'Planung',
    icon: SquareFootIcon
  },
  3: {
    label: 'Durchführung',
    icon: BuildIcon
  },
  4: {
    label: 'Abschluss',
    icon: AssignmentTurnedInIcon
  }
};

export default taskPhases;