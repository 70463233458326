import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { duckTypes } from "../../redux/entities/linksKeys";
import { default as LinkForm } from "./LinkFormComponent";
import routes from "../../utils/routes";

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: 640,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
});

class LinkNewComponent extends React.Component {
    handleSubmit = (values, formikBag) => {
        const data = {
            user: this.props.myId,
            url: values.url || '',
            title: values.title || '',
            description: values.description || '',
            hiddenFromApprentice: values.hidden === '1'
        };
        this.props.linkPostRequest(data, formikBag).then(() => {
            this.props.history.push(routes.LINKS.path);
        });
    };

    handleReset = () => {
        this.props.linksCancelRequest(duckTypes.LINK_POST.group);
        this.props.history.goBack();
    };

    render() {
        const { classes, isAdmin, isInstructor } = this.props;

        const isApprentice = !isAdmin && !isInstructor;

        return (
            <Paper elevation={0} className={classes.paper}>
                <LinkForm submitHandler={ this.handleSubmit } resetHandler={ this.handleReset } isApprentice={isApprentice} />
            </Paper>
        );
    }
}

export default withStyles(styles)(LinkNewComponent);
