import React from 'react';
import {
  Badge,
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  withStyles
} from "@material-ui/core";
import {
  FileCopy as CopyIcon,
  Send as SendIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
} from "@material-ui/icons";
import SwipeableViews from 'react-swipeable-views';
import { duckTypes } from "../../redux/entities/competenceChecksKeys";
import flavors from "../../redux/notifications/flavors";
import routes from "../../utils/routes";
import roles from "../../utils/roles";
import taskPhases from "../../utils/taskPhases";
import { compareByKey2Sort } from "../../utils/helper";
import ConfirmDialog from '../common/ConfirmDialog';
import RemovalDialog from '../common/RemovalDialog';
import SelectionDialog from '../common/SelectionDialog';
import PortalToolbarMenu from '../common/navigation/PortalToolbarMenu';
import AdhocInput from "../common/AdhocInput";
import AdhocRichText from "../common/AdhocRichText";
import { default as CompetenceSpecification } from "./CompetenceSpecificationContainer";

const styles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
        width: 640,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
  },
  box: {
    padding: theme.spacing(2),
  },
  adhocTitle: theme.typography.h5,
  tabsHeader: {
    padding: theme.spacing(2, 2, 0),
  },
  tabsBox: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 2, 2),
    },
  },
  tabs: {
    backgroundColor: theme.palette.grey[50],
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    borderWidth: '0 0 1px',
    justifyContent: "center"
  },
  tabsScroller: {
    flexGrow: "0"
  },
  tabBadge: {
    margin: theme.spacing(1),
  },
  tabPanel: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(2),
  },
});

class CompetenceCheckEditComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copyDialogOpen: false,
      removalDialogOpen: false,
      assignDialogOpen: false,
      archiveDialogOpen: false,
      competenceCheckId: null,
      tabIndex: 0
    };
  }

  componentDidMount() {
    this.props.usersGetRequest();
    this.initData();
  }

  componentWillUnmount() {
    this.props.titleChange(null);
    this.props.parentRouteChange(null);
  }

  initData = () => {
    const { match } = this.props; // https://reacttraining.com/react-router/web/api/match
    if (match.params.id) {
      this.setState({
        competenceCheckId: match.params.id
      }, () => {
        this.props.competenceCheckGetRequest(this.state.competenceCheckId).then(() => {
          this.updateMeta();
        });
      });
    }
  };

  updateMeta = () => {
    const { byId } = this.props;
    const { competenceCheckId } = this.state;

    this.props.titleChange(null);
    this.props.parentRouteChange(null);

    if (!competenceCheckId) return;

    const item = byId[competenceCheckId];

    this.props.titleChange(item && item.title ? `Kompetenzcheck "${item.title}" bearbeiten` : null);
    this.props.parentRouteChange(this.getParentRoute());
  };

  getParentRoute = () => {
    const { byId } = this.props;
    const { competenceCheckId } = this.state;

    if (!competenceCheckId) {
      return routes.COMPETENCECHECKS;
    }

    const item = byId[competenceCheckId];
    const isArchived = item && item.isArchived;
    const isMaster = item && item.apprentice === null;
    return isArchived ? routes.COMPETENCECHECKS_ARCHIVE : isMaster ? routes.COMPETENCECHECKS_TEMPLATES : routes.COMPETENCECHECKS;
  };

  getRedirectPath = () => {
    const parentRoute = this.getParentRoute();

    return parentRoute && parentRoute.path ? parentRoute.path : null;
  };

  handleAdhocSubmit = (key, value, formikBag) => {
    const { competenceCheckId } = this.state;

    if (!competenceCheckId || !key) {
      this.props.notificationEnqueue('Es ist ein Fehler aufgetreten', flavors.ERROR);
      return;
    }
    
    const data = {
      [key]: value || '',
    };
    this.props.competenceCheckPutRequest(competenceCheckId, data, formikBag).then(() => {
      if (key === 'title') {
        this.updateMeta();
      }
      this.props.notificationEnqueue('Der Kompetenzcheck wurde aktualisiert.', flavors.SUCCESS);
    });
  };

  handleAdhocReset = () => {
    this.props.competenceChecksCancelRequest(duckTypes.COMPETENCECHECK_PUT.group);
  };

  handleRemove = () => {
    const { competenceCheckId } = this.state;

    if (!competenceCheckId) return;

    this.props.competenceCheckDeleteRequest(competenceCheckId).then(() => {
      this.props.notificationEnqueue('Der Kompetenzcheck wurde gelöscht.', flavors.SUCCESS);
      this.props.history.push(this.getRedirectPath());
    });
  };

  handleAssign = selectedItems => {
    const { competenceCheckId } = this.state;

    if (!competenceCheckId || !selectedItems || selectedItems.length === 0) return;

    selectedItems.forEach(assigneeId => {
      this.props.competenceCheckAssignRequest(competenceCheckId, assigneeId).then(() => {
        this.props.history.push(routes.COMPETENCECHECKS.path);
      });
    });
  };

  handleCopy = () => {
    const { competenceCheckId } = this.state;

    if (!competenceCheckId) return;

    this.props.competenceCheckCopyRequest(competenceCheckId);
    // NOTE: Operations redirects to detail view of copied check
  };

  handleArchive = () => {
    const { competenceCheckId } = this.state;

    if (!competenceCheckId) return;

    this.props.competenceCheckArchiveRequest(competenceCheckId).then(() => {
      this.initData();
    });
  };

  handleTabChange = (evt, newValue) => {
    this.setState({
      tabIndex: newValue
    });
  };

  handleTabChangeIndex = index => {
    this.setState({
      tabIndex: index
    });
  };

  render() {
    const { classes, isInstructor, byId, usersById, competenceSpecificationsById } = this.props;
    const { competenceCheckId, copyDialogOpen, assignDialogOpen, removalDialogOpen, archiveDialogOpen, tabIndex } = this.state;

    const item = byId[competenceCheckId];

    if (!item) {
      return null;
    }

    const { id, title, description, permissions, apprentice, isArchived, competenceSpecifications } = item;

    const canEdit = permissions.canEdit && !apprentice;
    const canDelete = permissions.canDelete && !apprentice;

    const apprenticesOptions = Object.keys(usersById)
      .filter(key => usersById[key].roles.includes(roles.APPRENTICE))
      .sort((a, b) => compareByKey2Sort(usersById[a], usersById[b], 'firstName'))
      .map(key => {
        const user = usersById[key];
        return {
          value: user.id,
          label: `${user.firstName} ${user.lastName}`
        };
      }, []);

    const competenceSpecificationsAllowed = (competenceSpecifications && competenceSpecifications.length > 0) ? competenceSpecifications : [];
    const competenceSpecificationsArray = Object.keys(competenceSpecificationsById)
      .filter(key => competenceSpecificationsAllowed.includes(key))
      .map(key => competenceSpecificationsById[key]);

    const portalToolbarMenuData = [
      {
        "title": "Zuordnen",
        "action": () => this.setState({ assignDialogOpen: true }),
        "icon": SendIcon,
        "visible": canEdit && competenceSpecificationsArray.length > 0,
        "disabled": false
      }, {
        "title": "Kopieren",
        "action": () => this.setState({ copyDialogOpen: true }),
        "icon": CopyIcon,
        "visible": canEdit,
        "disabled": false
      }, {
        "title": "Archivieren",
        "action": () => this.setState({ archiveDialogOpen: true }),
        "icon": ArchiveIcon,
        "visible": !isArchived,
        "disabled": false
      },{
        "title": "Löschen",
        "action": () => this.setState({ removalDialogOpen: true }),
        "icon": DeleteIcon,
        "visible": canDelete,
        "disabled": false
      }
    ];

    const renderToolbarMenu = () => {
      return (
        <React.Fragment>
          <PortalToolbarMenu menuData={portalToolbarMenuData}/>
          <ConfirmDialog
            open={archiveDialogOpen}
            title={`Kompetenzcheck archivieren`}
            content={`Möchten Sie den Kompetenzcheck "${title}" archivieren?`}
            onClose={confirm => {
              this.setState({ archiveDialogOpen: false });

              if (confirm === true) {
                this.handleArchive();
              }
            }}
          />
          <ConfirmDialog
            open={copyDialogOpen}
            title={`Kompetenzcheck kopieren`}
            content={`Möchten Sie den Kompetenzcheck "${title}" kopieren?`}
            onClose={confirm => {
              this.setState({ copyDialogOpen: false });

              if (confirm === true) {
                this.handleCopy(id);
              }
            }}
          />
          <SelectionDialog
            open={assignDialogOpen}
            onClose={(success, selectedItems) => {
              this.setState({ assignDialogOpen: false });

              if (success && selectedItems) {
                this.handleAssign(selectedItems);
              }
            }}
            title="Kompetenzcheck zuordnen"
            description="Kompetenzcheck folgenden Auszubildenden zuordnen:"
            placeholder="Auszubildende auswählen..."
            label="Auszubildende"
            submitLabel="Zuordnen"
            isMulti={true}
            items={apprenticesOptions}
          />
          <RemovalDialog
            open={removalDialogOpen}
            subject={title}
            onClose={(remove) => {
              this.setState({ removalDialogOpen: false });

              if (remove === true) {
                this.handleRemove(id);
              }
            }}
          />
        </React.Fragment>   
      );
    };

    return (
      <React.Fragment>
        {renderToolbarMenu()}
        <Grid container>
          <Grid item xs={12} sm={12} md={8}>
            <Box className={classes.box}>
              <Typography component="h2" variant="overline" gutterBottom>Titel</Typography>
              <AdhocInput propKey="title" propValue={title} required={true} fieldClasses={{input: classes.adhocTitle}} gutterBottom={true} submitHandler={ this.handleAdhocSubmit } resetHandler={ this.handleAdhocReset } />
              <Divider/>
              <Typography component="h2" variant="overline" gutterBottom>Beschreibung</Typography>
              <AdhocRichText propKey="description" propValue={description} required={false} fieldClasses={{input: classes.adhocTitle}} gutterBottom={true} submitHandler={ this.handleAdhocSubmit } resetHandler={ this.handleAdhocReset } />
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.tabsHeader}>
          <Divider/>
          <Typography component="h2" variant="overline" gutterBottom>Kompetenzbeschreibungen</Typography>
        </Box>
        <Box className={classes.tabsBox}>
          <Tabs value={tabIndex}
            onChange={this.handleTabChange}
            aria-label="Kompetenzbeschreibungen"
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            classes={{
              root: classes.tabs,
              scroller: classes.tabsScroller // https://github.com/mui-org/material-ui/issues/10235#issuecomment-531505399
            }}
          >
            {[...Array(4)].map((e, i) => {
              const phaseId = i+1;
              return (
                <Tab key={`competenceSpecificationTab-${i}`} 
                id={`competenceSpecification-tab-${i}`} 
                label={taskPhases[phaseId].label} 
                icon={<Badge color="secondary" badgeContent={competenceSpecificationsArray.filter(obj => obj.phase === phaseId).length} className={classes.tabBadge}>
                {React.createElement(taskPhases[phaseId].icon)}
                </Badge>}
                aria-controls={`competenceSpecification-tabpanel-${i}`} />
              );
            })}
          </Tabs>
          <SwipeableViews
            index={tabIndex}
            onChangeIndex={this.handleTabChangeIndex}
          >
            {[...Array(4)].map((e, i) => {
              const phaseId = i+1;
              return (
                <Box key={`competenceSpecificationTabPanel-${i}`} id={`competenceSpecification-tabpanel-${i}`} role="tabpanel" aria-labelledby={`competenceSpecification-tab-${i}`} value={tabIndex} hidden={tabIndex !== i} className={classes.tabPanel}>
                  {competenceSpecificationsArray.filter(obj => obj.phase === phaseId).map(obj => {
                    return (
                      <CompetenceSpecification key={obj.id} isInstructor={isInstructor} item={obj} canEdit={true} phaseId={phaseId} />
                    )
                  })}
                  {permissions.canCreateCompetenceSpecification && <CompetenceSpecification isInstructor={isInstructor} canEdit={true} competenceCheckId={competenceCheckId} phaseId={phaseId} />}
                </Box>
              );
            })}
          </SwipeableViews>
        </Box>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CompetenceCheckEditComponent);
