import React from 'react';
import { Field, Form, Formik } from "formik";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField } from 'formik-material-ui';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    wrapper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

class BusinessFormComponent extends React.Component {
    render() {
        const { classes, item, submitHandler, resetHandler } = this.props;

        let name = '';
        let street = '';
        let postcode = '';
        let city = '';
        if (item) {
            name = item.name || '';
            street = item.street || '';
            postcode = item.postcode || '';
            city = item.city || '';
        }

        return (
            <Formik
                initialValues = {{
                    name: name,
                    street: street,
                    postcode: postcode,
                    city: city
                }}
                enableReinitialize={true}
                validate = {values => {
                    let errors = {};
                    if (values.name === '') {
                        errors.name = 'Dies ist ein Pflichtfeld';
                    }
                    // TODO: Validate postcode???
                    return errors;
                }}
                onSubmit = {(values, formikBag) => {
                    submitHandler(values, formikBag);
                }}>
                {({ status, touched, errors, isSubmitting, handleSubmit, handleReset }) => (
                    <Form
                        autoComplete="off"
                        onSubmit={evt => {
                            evt.stopPropagation();
                            handleSubmit(evt);
                        }}
                        onReset={evt => {
                            evt.stopPropagation();
                            handleReset(evt);
                            resetHandler();
                        }}
                        className={classes.form}
                    >
                        {status &&
                            <FormHelperText error>{status}</FormHelperText>
                        }
                        <Field component={TextField}
                            type="text"
                            id="name"
                            name="name"
                            label="Name"
                            required
                            fullWidth
                            margin="normal"/>
                        <Field component={TextField}
                            type="text"
                            id="street"
                            name="street"
                            label="Straße"
                            fullWidth
                            margin="normal"/>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Field component={TextField}
                                    type="text"
                                    id="postcode"
                                    name="postcode"
                                    label="PLZ"
                                    fullWidth
                                    margin="normal"/>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Field component={TextField}
                                    type="text"
                                    id="city"
                                    name="city"
                                    label="Ort"
                                    fullWidth
                                    margin="normal"/>
                            </Grid>
                        </Grid>
                        <div className={classes.wrapper}>
                            <Button type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}>
                                Speichern
                            </Button>
                            {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress} />}
                        </div>
                        <div className={classes.wrapper}>
                            <Button type="reset" color="primary">Abbrechen</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withStyles(styles)(BusinessFormComponent);
