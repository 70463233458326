// https://stackoverflow.com/a/48369370
import React from 'react';
import { withRouter } from 'react-router';

let globalHistory = null;
let globalLocation = null;

class Spy extends React.Component {
  constructor(props) {
    super(props);
    globalHistory = props.history;
    globalLocation = props.location;
  }

  componentDidUpdate() {
    const { history, location } = this.props;
    globalHistory = history;
    globalLocation = location;
  }

  render() {
    return null;
  }
}

export const GlobalHistory = withRouter(Spy);

export default function getHistory() {
  return globalHistory;
}

export function getLocation() {
  return globalLocation;
}
