import { normalize, schema } from 'normalizr';
import { requestsOperations } from "./requests";
import { entityKey as usersSliceKey } from './usersKeys';
import { default as usersOperations } from './usersOperations';
import { nameKeys, entityKey, duckTypes } from "./filesKeys";
import { keys as genericKeys, type as genericType, action as genericAction } from '../generics';
import getHistory from "../../utils/history";
import routes from "../../utils/routes";

const fileSchema = new schema.Entity(entityKey);
const fileListSchema = new schema.Array(fileSchema);

const fetchRelations = [{
  relationResponseKey: 'user',
  relationSliceKey: usersSliceKey,
  operation: usersOperations.usersByFileGetRequest,
  noRefetch: true,
  isSingle: true,
}];

const listGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.FILES_GET,
    requestPath: url,
    clearEntityData: true,
    normalizeSchema: fileListSchema,
    fetchRelations: [],
  };
  return requestsOperations.requestsOperationGeneric(null, settings);
};

const filesGetRequest = () => listGetRequest("/media");

const associationGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.FILES_GET,
    requestPath: url,
    clearEntityData: false,
    normalizeSchema: fileListSchema,
    fetchRelations: fetchRelations,
    requestCancelling: false,
  };
  return requestsOperations.requestsOperationGeneric(null, settings);
};

const filesByTaskGetRequest = (taskId) => associationGetRequest(`/tasks/${taskId}/media`);

const filesBySubtaskGetRequest = (subTaskId) => associationGetRequest(`/subtasks/${subTaskId}/media`);

const filesByResultGetRequest = (resultId) => associationGetRequest(`/results/${resultId}/media`);

const filesByReportGetRequest = (reportId) => associationGetRequest(`/reports/${reportId}/media`);

const filesByFieldOfActionGetRequest = (fieldOfActionId) => associationGetRequest(`/field-of-actions/${fieldOfActionId}/media`);

const filesByAvatarUsersGetRequest = (userId) => associationGetRequest(`/users/${userId}/avatars`);

const fileGetRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.FILE_GET,
    requestPath: `/media/${id}`,
    idParamRequired: true,
    normalizeSchema: fileSchema,
    fetchRelations: fetchRelations
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const filePutRequest = (id, data, formikBag) => {
  const params = {
    id: id,
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.FILE_PUT,
    requestType: 'put',
    requestPath: `/media/${id}`,
    idParamRequired: true,
    normalizeSchema: fileSchema,
    appendOnSuccess: gotoList
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const filePostRequest = (data, formikBag) => {
  const params = {
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.FILE_POST,
    requestType: 'post',
    requestPath: '/media',
    normalizeSchema: fileSchema
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const filePostSuccess = data => {
  if (data.result) {
    return dispatch => {
      dispatch(genericAction(genericType(entityKey, nameKeys.FILE, genericKeys.intents.POST, genericKeys.status.SUCCESS), normalize(data.result, fileSchema)));
    };
  }
};

const filePostError = (dispatch, handledError) => {
  return dispatch => {
    dispatch(genericAction(genericType(entityKey, nameKeys.FILE, genericKeys.intents.POST, genericKeys.status.ERROR), handledError));
  };
};

const fileDeleteRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.FILE_DELETE,
    requestType: 'delete',
    requestPath: `/media/${id}`,
    idParamRequired: true
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const filesCancelRequest = (cancelType, cancelMessage) => {
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsCancellation(entityKey, cancelType, cancelMessage));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const gotoList = () => {
  getHistory().push(routes.FILES.path);
};

export default {
  filesGetRequest,
  fileGetRequest,
  filePutRequest,
  filePostRequest,
  filePostSuccess,
  filePostError,
  fileDeleteRequest,
  filesCancelRequest,
  filesByTaskGetRequest,
  filesBySubtaskGetRequest,
  filesByResultGetRequest,
  filesByReportGetRequest,
  filesByFieldOfActionGetRequest,
  filesByAvatarUsersGetRequest
};
