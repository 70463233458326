import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  CircularProgress,
  Slide,
  withStyles
} from "@material-ui/core";
import { 
  Field, 
  Form, 
  Formik 
} from "formik";
import { otherTypes } from "../../redux/other";
import { viewKeys } from "../../redux/ui";
import { TextField } from 'formik-material-ui';
import RichTextField from './RichTextField';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class FeedbackDialogComponent extends Component {
  constructor(){
    super();
    this.state = {
      url: window.location.href
    }
  }

  componentDidMount() {
    this.resetView();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open === false && this.props.open === true) {
      this.resetView();
    }
  }

  resetView = () => {
    this.props.viewChange(viewKeys.FEEDBACK.values.DEFAULT);
  };

  handleSubmit = (values, formikBag) => {
    const data = {
      url: values.url,
      text: values.text
    };
    this.props.feedbackPostRequest(data, formikBag);
  };

  handleReset = () => {
    this.props.otherCancelRequest(otherTypes.FEEDBACK_POST.group);
    this.props.onClose();
  };

  render() {
    const { classes, view, open, onClose } = this.props;
    const { url } = this.state;

    return (
      <Dialog
        open={open}
        fullWidth={true}
        TransitionComponent={Transition}
        onClose={onClose}
        aria-labelledby="feedback-dialog-title"
      >
          {view === viewKeys.FEEDBACK.values.SUCCESS ? (
            <React.Fragment>
              <DialogTitle id="feedback-dialog-title">Vielen Dank</DialogTitle>
              <DialogContent>
                <DialogContentText>Ihr Feedback wurde erfolgreich abgeschickt!</DialogContentText>
              </DialogContent>
            </React.Fragment>
          ) : (
            <Formik
              initialValues = {{
                url: url,
                text: ''
              }}
              enableReinitialize={true}
              validate = {values => {
                let errors = {};
                if ('' === values.text) {
                  errors.text = 'Dies ist ein Pflichtfeld';
                }
                return errors;
              }}
              onSubmit = {(values, formikBag) => {
                this.handleSubmit(values, formikBag);
              }}
              onReset={() => {
                this.handleReset();
              }}>
              {({ status, isSubmitting, handleSubmit, handleReset }) => (
              <Form
                autoComplete="off"
                onSubmit={evt => {
                  evt.stopPropagation();
                  handleSubmit(evt);
                }}
                onReset={evt => {
                  evt.stopPropagation();
                  handleReset(evt);
                }}
                className={classes.form}
              >
                <DialogTitle id="feedback-dialog-title">Ihr Feedback</DialogTitle>
                <DialogContent>
                  {status &&
                    <DialogContentText><FormHelperText error>{status}</FormHelperText></DialogContentText>
                  }
                  <Field component={TextField}
                    type="text"
                    id="url"
                    name="url"
                    label="Betrifft Ansicht unter"
                    disabled
                    fullWidth
                    margin="none"/>
                  <Field component={RichTextField}
                    type="text"
                    id="text"
                    name="text"
                    label="Beschreibung"

                    // maxLength={250}
                    required
                    fullWidth
                    margin="normal"/>
                </DialogContent>
                <DialogActions>
                  <div className={classes.wrapper}>
                    <Button type="reset" color="secondary">
                      Abbrechen
                    </Button>
                  </div>
                  <div className={classes.wrapper}>
                    <Button type="submit" color="primary">
                      Abschicken
                    </Button>
                    {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress} />}
                  </div>
                </DialogActions>
              </Form>
            )}
            </Formik>
        )}
      </Dialog> 
    );
  }
}

export default withStyles(styles)(FeedbackDialogComponent);
