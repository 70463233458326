import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import NotificationsIcon from '@material-ui/icons/Notifications';

import { default as notificationsOperations } from '../../redux/entities/notificationsOperations';
import NotificationItem from './NotificationItem';
import NotificationList from './NotificationList';
import Title from '../common/Title';
import routes from "../../utils/routes";
import { DateTime } from "luxon";

class NotificationContainer extends Component {
  render() {
    const unread = this.props.notifications.filter(notification => false === notification.readFlag).length;
    return (
      <Fragment>
        <Title icon={<NotificationsIcon/>}>Benachrichtigungen {0 < unread ? ` (${unread})` : ''}</Title>
        <NotificationList>
          {this.props.notifications.map((notification, i) => {
            // const user = this.props.users[notification.user];

            const showNotificationDetails = (url) => {
              this.props.putReadFlag(notification.id);
              this.props.history.push(url);
            };

            let linkUrl = null;

            let title = '';
            let notificationTitle = '';
            let notificationDescription = DateTime.fromISO(notification.createdAt)
              .setLocale('de')
              .toLocaleString(DateTime.DATETIME_SHORT);

            if ('taskKey' in notification.context && notification.typeId !== 25) {
              title = ('subTaskNumber' in notification.context) ? `${notification.context.taskKey}` : `${notification.context.taskKey}: `;
              notificationDescription += ` — ${notification.context.taskKey}`;
              linkUrl = routes.TASK_DETAILS.path.replace(':id', notification.context.taskKey);
            }

            if ('subTaskNumber' in notification.context && notification.typeId !== 25) {
              title += ` / ${notification.context.subTaskNumber}: `;
              notificationDescription += ` / ${notification.context.subTaskNumber}`;
            }

            if ('competenceCheckTitle' in notification.context) {
              title += `"${notification.context.competenceCheckTitle}"`;
              notificationDescription += ` — "${notification.context.competenceCheckTitle}"`;
            }
            if ('competenceCheckId' in notification.context) {
              linkUrl = routes.COMPETENCECHECK_DETAILS.path.replace(':id', notification.context.competenceCheckId);
            }

            if ('taskTitle' in notification.context) {
              title += `"${notification.context.taskTitle}"`;
              notificationDescription += ` — "${notification.context.taskTitle}"`;
            }

            if ('assigner' in notification.context) {
              title += ` von ${notification.context.assigner}`;
              notificationDescription += ` von ${notification.context.assigner}`;
            }

            if (notification.context) {
              switch (notification.typeId) {
              case 10:
                notificationTitle = `Aufgabe ${title} zugewiesen`;
                break;
              case 15:
                notificationTitle = `Aufgabe ${title} zugewiesen`;
                break;
              case 20:
                notificationTitle = `Aufgabe ${title} wurde eingereicht`;
                break;
              case 25:
                notificationTitle = `Teilaufgabe ${title} wurde eingereicht`;
                linkUrl = routes.SUBTASK_DETAILS.path
                  .replace(':id', notification.context.taskKey)
                  .replace(':number', notification.context.subTaskNumber);
                break;
              case 30:
                notificationTitle = `Kopierte Aufgabe ${title} zugewiesen`;
                break;
              case 35:
                notificationTitle = `Kompetenzcheck ${title} zugewiesen`;
                break;
              case 40:
                notificationTitle = `Kompetenzcheck ${title} zur Prüfung zugewiesen`;
                break;
              case 45:
                notificationTitle = `Kompetenzcheck ${title} fertiggestellt`;
                break;
              default:
                notificationTitle = `${title}`;
              }
            }

            return <NotificationItem
              title={notificationTitle}
              message={notificationDescription}
              onClick={() => showNotificationDetails(linkUrl)}
              read={notification.readFlag}
              key={i}
            />;
          })}
          {0 === this.props.notifications.length &&
            <NotificationItem
              title="Keine Benachrichtigungen"
              message="Derzeit gibt es keine Benachrichtigungen"
              read={true}/>
          }
        </NotificationList>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetching: false,
    users: state.entities.users.byId,
    notifications: state.entities.notifications.allIds
      .map(id => state.entities.notifications.byId[id])
      .sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return 1;
        } else if (a.createdAt > b.createdAt) {
          return -1;
        } else {
          return 0;
        }
      })
  };
};

export default connect(mapStateToProps, {
  putReadFlag: notificationsOperations.notificationPutReadFlagRequest
})(NotificationContainer);
