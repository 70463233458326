import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { duckTypes } from "../../redux/entities/competenceChecksKeys";
import routes from "../../utils/routes";
import { default as CompetenceCheckForm } from "./CompetenceCheckFormComponent";

const styles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
        width: 640,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
  },
});

class CompetenceCheckNewComponent extends React.Component {

  handleSubmit = (values, formikBag) => {
    const data = {
      title: values.title || '',
      description: values.description || '',
    };
    this.props.competenceCheckPostRequest(data, formikBag).then(axiosResponse => {
      if (axiosResponse.result) {
        this.props.history.push(routes.COMPETENCECHECK_EDIT.path.replace(':id', axiosResponse.result.id));
      }
    });
  };

  handleReset = () => {
    this.props.competenceChecksCancelRequest(duckTypes.COMPETENCECHECK_POST.group);
    this.props.history.goBack();
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Paper elevation={0} className={classes.paper}>
          <CompetenceCheckForm submitHandler={ this.handleSubmit } resetHandler={ this.handleReset } />
        </Paper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CompetenceCheckNewComponent);
