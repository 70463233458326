import { createSelector } from 'reselect';
import * as R from "ramda";

const checkIfFetching = (entities, sliceKey, type) => {
  if (!sliceKey) {
    return null;
  }
  const cancelTokens = entities[sliceKey].cancelTokens;
  if (!cancelTokens || R.isEmpty(cancelTokens)) {
    return null;
  }

  return Object.keys(cancelTokens).reduce((hasTokens, key) => {
    if (type && key !== type) {
      return hasTokens;
    }
    return hasTokens || 0 < cancelTokens[key].length;
  }, false);
};

const checkIfFetchingAll = createSelector([
  state => state.entities
], (entities) => {
  return Object.keys(entities).reduce((hasTokens, sliceKey) => {
    return hasTokens || checkIfFetching(entities, sliceKey);
  }, false);
}
);

export default {
  checkIfFetching,
  checkIfFetchingAll
};
