import { withRouter } from "react-router";
import { connect } from 'react-redux';
import CompetenceCheckNewComponent from './CompetenceCheckNewComponent';
import { entityKey as competenceChecksSliceKey } from '../../redux/entities/competenceChecksKeys';
import { default as competenceChecksOperations } from '../../redux/entities/competenceChecksOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { isAdmin } = state.authentication;
  const { errors, byId } = state.entities[competenceChecksSliceKey];

  return {
    isAdmin,
    errors,
    byId
  };
};

const mapDispatchToProps = dispatch => {
  const competenceCheckPostRequest = (data, formikBag) => dispatch(competenceChecksOperations.competenceCheckPostRequest(data, formikBag));

  const competenceChecksCancelRequest = (cancelType, cancelMessage) => dispatch(competenceChecksOperations.competenceChecksCancelRequest(cancelType, cancelMessage));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    competenceCheckPostRequest,
    competenceChecksCancelRequest,
    notificationEnqueue
  };
};

const CompetenceCheckNewContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(CompetenceCheckNewComponent));

export default CompetenceCheckNewContainer;