import {
  Build as BuildIcon,
  SupervisorAccount as SupervisorAccountIcon,
  DeviceHub as DeviceHubIcon,
} from "@material-ui/icons";

const competenceSpecificationTypes = {
  1: {
    label: 'Sozialkompetenz',
    icon: SupervisorAccountIcon
  },
  2: {
    label: 'Methodenkompetenz',
    icon: DeviceHubIcon
  },
  3: {
    label: 'Fachkompetenz',
    icon: BuildIcon
  }
};

export default competenceSpecificationTypes;