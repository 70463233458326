import { withRouter } from "react-router";
import { connect } from 'react-redux';
import CompetenceCheckComponent from './CompetenceCheckComponent';
import { entityKey as competenceChecksSliceKey } from '../../redux/entities/competenceChecksKeys';
import { default as competenceChecksOperations } from '../../redux/entities/competenceChecksOperations';
import { entityKey as usersSliceKey } from '../../redux/entities/usersKeys';
import { default as usersOperations } from '../../redux/entities/usersOperations';
import { entityKey as filesSliceKey } from '../../redux/entities/filesKeys';
import { entityKey as competenceSpecificationsSliceKey } from '../../redux/entities/competenceSpecificationsKeys';
import { entityKey as competenceEvaluationsSliceKey } from '../../redux/entities/competenceEvaluationsKeys';
import { entityKey as competenceRatingsSliceKey } from '../../redux/entities/competenceRatingsKeys';
import { notificationsOperations } from "../../redux/notifications";
import { metaKeys, uiOperations } from "../../redux/ui";

const mapStateToProps = state => {
  const { myId, isInstructor } = state.authentication;
  const { errors, byId } = state.entities[competenceChecksSliceKey];
  const usersById = state.entities[usersSliceKey].byId;
  const filesById = state.entities[filesSliceKey].byId;
  const competenceSpecificationsById = state.entities[competenceSpecificationsSliceKey].byId;
  const competenceEvaluationsById = state.entities[competenceEvaluationsSliceKey].byId;
  const competenceRatingsById = state.entities[competenceRatingsSliceKey].byId;

  return {
    myId,
    isInstructor,
    errors,
    byId,
    usersById,
    filesById,
    competenceSpecificationsById,
    competenceEvaluationsById,
    competenceRatingsById
  };
};

const mapDispatchToProps = dispatch => {
  const competenceCheckGetRequest = id => dispatch(competenceChecksOperations.competenceCheckGetRequest(id));
  const competenceCheckPutRequest = (id, data, formikBag) => dispatch(competenceChecksOperations.competenceCheckPutRequest(id, data, formikBag));
  const competenceCheckCopyRequest = id => dispatch(competenceChecksOperations.competenceCheckCopyRequest(id));
  const competenceCheckAssignRequest = (id, userId) => dispatch(competenceChecksOperations.competenceCheckAssignRequest(id, userId));
  const competenceCheckDeleteRequest = id => dispatch(competenceChecksOperations.competenceCheckDeleteRequest(id));
  const competenceCheckArchiveRequest = id => dispatch(competenceChecksOperations.competenceCheckArchiveRequest(id));
  const competenceCheckUnarchiveRequest = id => dispatch(competenceChecksOperations.competenceCheckUnarchiveRequest(id));
  const competenceCheckSubmitRequest = id => dispatch(competenceChecksOperations.competenceCheckSubmitRequest(id));
  const competenceCheckReopenRequest = id => dispatch(competenceChecksOperations.competenceCheckReopenRequest(id));

  const competenceChecksCancelRequest = (cancelType, cancelMessage) => dispatch(competenceChecksOperations.competenceChecksCancelRequest(cancelType, cancelMessage));

  const usersGetRequest = () => dispatch(usersOperations.usersGetRequest());

  const titleChange = value => dispatch(uiOperations.metaChangeOperationGeneric(metaKeys.TITLE, value));
  const parentRouteChange = value => dispatch(uiOperations.metaChangeOperationGeneric(metaKeys.PARENTROUTE, value));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    competenceCheckGetRequest,
    competenceCheckPutRequest,
    competenceCheckCopyRequest,
    competenceCheckAssignRequest,
    competenceCheckDeleteRequest,
    competenceCheckArchiveRequest,
    competenceCheckUnarchiveRequest,
    competenceCheckSubmitRequest,
    competenceCheckReopenRequest,
    competenceChecksCancelRequest,
    usersGetRequest,
    titleChange,
    parentRouteChange,
    notificationEnqueue
  };
};

const CompetenceCheckContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(CompetenceCheckComponent));

export default CompetenceCheckContainer;