import {Field} from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";

const SingleCheckbox = props => {
    return (
        <Field name={props.name}>
            {({ field, form }) => (
                <FormControlLabel
                    control={
                        <Checkbox
                            name={field.name}
                            id={field.id}
                            value={props.value}
                            checked={field.value === props.value}
                            onChange={() => {
                                if (field.value !== props.value) {
                                    form.setFieldValue(field.name, props.value);
                                } else {
                                    form.setFieldValue(field.name, '');
                                }
                            }}/>
                    }
                    label={props.label}
                />
            )}
        </Field>
    );
};

export default SingleCheckbox;
