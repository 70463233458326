import config from '../../utils/config';

const namespace = config.APP_NAME + '/other/';

const FEEDBACK_POST = namespace + 'FEEDBACK_POST';
const FEEDBACK_POST_STARTED = FEEDBACK_POST + '_STARTED';
const FEEDBACK_POST_SUCCESS = FEEDBACK_POST + '_SUCCESS';
const FEEDBACK_POST_FAILURE = FEEDBACK_POST + '_FAILURE';

export default {
  FEEDBACK_POST,
  FEEDBACK_POST_STARTED,
  FEEDBACK_POST_SUCCESS,
  FEEDBACK_POST_FAILURE,
};
