import { withRouter } from "react-router";
import { connect } from "react-redux";
import { entityKey as reportsSliceKey } from "../../redux/entities/reportsKeys";
import { default as reportsOperations } from "../../redux/entities/reportsOperations";
import { default as fieldsOfActionOperations } from "../../redux/entities/fieldsOfActionOperations";
import { entityKey as fieldsOfActionSliceKey } from "../../redux/entities/fieldsOfActionKeys";
import { entityKey as facilitiesSliceKey } from "../../redux/entities/facilitiesKeys";
import { default as facilitiesOperations } from "../../redux/entities/facilitiesOperations";
import { metaKeys, uiOperations } from "../../redux/ui";
import ReportEditComponent from "./ReportEditComponent";

const mapStateToProps = state => {
  const { myId, isAdmin } = state.authentication;
  const { errors, byId } = state.entities[reportsSliceKey];
  const fieldsOfActionAllIds = state.entities[fieldsOfActionSliceKey].allIds;
  const fieldsOfActionById = state.entities[fieldsOfActionSliceKey].byId;
  const facilitiesAllIds = state.entities[facilitiesSliceKey].allIds;
  const facilityById = state.entities[facilitiesSliceKey].byId;

  return {
    myId,
    isAdmin,
    errors,
    byId,
    fieldsOfActionAllIds,
    fieldsOfActionById,
    facilitiesAllIds,
    facilityById
  };
};

const mapDispatchToProps = dispatch => {
  const reportPutRequest = (id, data, formikBag) => dispatch(reportsOperations.reportPutRequest(id, data, formikBag));
  const reportCancelRequest = (cancelType, cancelMessage) => dispatch(reportsOperations.reportCancelRequest(cancelType, cancelMessage));
  const fieldsOfActionGetRequest = () => dispatch(fieldsOfActionOperations.fieldsOfActionGetRequest());
  const reportGetRequest = (id) => dispatch(reportsOperations.reportGetRequest(id));
  const reportDeleteRequest = (id) => dispatch(reportsOperations.reportDeleteRequest(id));
  const facilitiesGetRequest = () => dispatch(facilitiesOperations.facilitiesGetRequest());

  const titleChange = value => dispatch(uiOperations.metaChangeOperationGeneric(metaKeys.TITLE, value));
  const parentRouteChange = value => dispatch(uiOperations.metaChangeOperationGeneric(metaKeys.PARENTROUTE, value));

  return {
    reportPutRequest,
    reportCancelRequest,
    reportDeleteRequest,
    fieldsOfActionGetRequest,
    reportGetRequest,
    facilitiesGetRequest,
    titleChange,
    parentRouteChange
  };
};

const ReportEditContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportEditComponent));

export default ReportEditContainer;
