import React from 'react';
import { Field, Form, Formik } from "formik";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField } from 'formik-material-ui';
import Typography from "@material-ui/core/Typography/Typography";

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    wrapper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

class LoginFormComponent extends React.Component {
    render() {
        const { classes, submitHandler, recoveryHandler } = this.props;

        return (
            <Formik
                initialValues = {{
                    email: '',
                    password: ''
                }}
                validate = {values => {
                    let errors = {};
                    if (!values.email) {
                        errors.email = 'Dies ist ein Pflichtfeld';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Keine gültige E-Mail Adresse';
                    }
                    if (!values.password) {
                        errors.password = 'Dies ist ein Pflichtfeld';
                    }
                    return errors;
                }}
                onSubmit = {(values, formikBag) => {
                    submitHandler(values, formikBag);
                }}>
                {({ status, isSubmitting, handleSubmit }) => (
                    <Form
                      autoComplete="off"
                      onSubmit={evt => {
                            evt.stopPropagation();
                            handleSubmit(evt);
                        }}
                        className={classes.form}
                    >
                        {status &&
                        <Typography variant="subtitle2" color="error" paragraph>{status}</Typography>
                        }
                        <Field component={TextField}
                            type="email"
                            id="email"
                            name="email"
                            label="E-Mail Adresse"
                            autoComplete="email"
                            required
                            fullWidth
                            margin="normal"/>
                        <Field component={TextField}
                            type="password"
                            id="password"
                            name="password"
                            label="Passwort"
                            autoComplete="current-password"
                            required
                            fullWidth
                            margin="normal"/>
                        <div className={classes.wrapper}>
                            <Button type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}>
                                Anmelden
                            </Button>
                            {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress} />}
                        </div>
                        <div className={classes.wrapper}>
                            <Button color="primary" onClick={ recoveryHandler }>
                                Passwort vergessen?
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withStyles(styles)(LoginFormComponent);
