import { schema } from 'normalizr';
import routes from "../../utils/routes";
import getHistory from '../../utils/history';
import { requestsOperations } from "./requests";
import { notificationsOperations } from "../notifications";
import { entityKey as professionsSliceKey } from './professionsKeys';
import { default as professionsOperations } from './professionsOperations';
import { entityKey as fieldsOfActionSliceKey } from "./fieldsOfActionKeys";
import { default as fieldsOfActionOperations } from "./fieldsOfActionOperations";
import { entityKey as subTasksSliceKey } from './subTasksKeys';
import { default as subTasksOperations } from './subTasksOperations';
import { entityKey as usersSliceKey } from './usersKeys';
import { default as usersOperations } from './usersOperations';
import { entityKey as filesSliceKey } from './filesKeys';
import { default as filesOperations } from './filesOperations';
import { entityKey as linksSliceKey } from './linksKeys';
import { default as linksOperations } from './linksOperations';
import { entityKey, duckTypes } from "./tasksKeys";
import flavors from "../notifications/flavors";

const taskSchema = new schema.Entity(entityKey);
const taskListSchema = new schema.Array(taskSchema);

let fetchRelations = [{
  relationResponseKey: 'job',
  relationSliceKey: professionsSliceKey,
  operation: professionsOperations.professionsByTaskGetRequest,
  noRefetch: false,
  isSingle: true,
}, {
  relationResponseKey: 'fieldOfAction',
  relationSliceKey: fieldsOfActionSliceKey,
  operation: fieldsOfActionOperations.fieldOfActionsByTaskGetRequest,
  noRefetch: false,
  isSingle: true,
}, {
  relationResponseKey: 'subTasks',
  relationSliceKey: subTasksSliceKey,
  operation: subTasksOperations.subTasksByTaskGetRequest,
}, {
  relationResponseKey: 'apprentices',
  relationSliceKey: usersSliceKey,
  operation: usersOperations.apprenticesByTaskGetRequest,
  noRefetch: false
}, {
  relationResponseKey: 'cooperators',
  relationSliceKey: usersSliceKey,
  operation: usersOperations.cooperatorsByTaskGetRequest,
  noRefetch: false
}, {
  relationResponseKey: 'media',
  relationSliceKey: filesSliceKey,
  operation: filesOperations.filesByTaskGetRequest,
  noRefetch: false
}, {
  relationResponseKey: 'links',
  relationSliceKey: linksSliceKey,
  operation: linksOperations.linksByTaskGetRequest,
  noRefetch: false
}];

const tasksGetRequest = (urlParameters = {}) => {
  const settings = {
    duckType: duckTypes.TASKS_GET,
    requestPath: '/tasks',
    clearEntityData: true,
    normalizeSchema: taskListSchema,
    fetchRelations: [],
  };

  return requestsOperations.requestsOperationGeneric(null, settings, urlParameters);
};

const tasksFilterOptionsRequest = (urlParameters = {}) => {
  const settings = {
    duckType: duckTypes.TASK_FILTER_OPTIONS,
    requestPath: '/filter_options/tasks',
  };

  return requestsOperations.requestsFilterOptionsGeneric(null, settings, urlParameters);
};

const getOneRequest = (id, additionalSettings = {}) => {
  const params = {
    id: id
  };
  let settings = {
    duckType: duckTypes.TASK_GET,
    requestPath: `/tasks/${id}`,
    idParamRequired: true,
    normalizeSchema: taskSchema,
    fetchRelations: fetchRelations,
    appendOnError: taskGetError
  };
  settings = {
    ...settings,
    ...additionalSettings
  };

  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const taskGetRequest = (id) => getOneRequest(id);

const taskWithoutRelationsGetRequest = (id) => getOneRequest(id, {
  fetchRelations: [],
});

const taskGetError = (dispatch, handledError) => {
  dispatch(notificationsOperations.notificationEnqueue(handledError.message, flavors.ERROR));
  gotoList();
};

const taskPutRequest = (id, data, formikBag, partialUpdate = false) => {
  const params = {
    id: id,
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.TASK_PUT,
    requestType: 'put',
    requestPath: `/tasks/${id}`,
    idParamRequired: true,
    normalizeSchema: taskSchema,
    appendOnSuccess: taskPutSuccess(partialUpdate)
  };

  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const taskPutSuccess = partialUpdate => (dispatch, data) => {
  dispatch(notificationsOperations.notificationEnqueue("Der Datensatz wurde aktualisiert", flavors.SUCCESS));
  if (data.result && data.result.taskKey && !partialUpdate) {
    getHistory().push(routes.TASK_DETAILS.path.replace(":id", data.result.taskKey));
  }
};

const taskPostRequest = (data, formikBag) => {
  const params = {
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.TASK_POST,
    requestType: 'post',
    requestPath: '/tasks',
    normalizeSchema: taskSchema,
    appendOnSuccess: () => gotoList(!!data.master)
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const taskDeleteRequest = (id, returnPath) => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.TASK_DELETE,
    requestType: 'delete',
    requestPath: `/tasks/${id}`,
    idParamRequired: true,
    normalizeSchema: taskSchema,
    appendOnSuccess: () => getHistory().push(returnPath)
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const taskRestoreRequest = (id, returnPath) => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.TASK_RESTORE,
    requestType: 'delete',
    requestPath: `/tasks/${id}/undo`,
    idParamRequired: true,
    normalizeSchema: taskSchema,
    appendOnSuccess: () => getHistory().push(returnPath)
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const taskSendRequest = (id, recipientId) => {
  const params = {
    data: {
      recipient: recipientId,
    },
  };
  const settings = {
    duckType: duckTypes.TASK_SEND,
    requestType: 'post',
    requestPath: '/tasks/:id/send'.replace(':id', id),
    normalizeSchema: taskSchema,
    appendOnSuccess: gotoList(false),
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
      promise.then(() =>
        dispatch(notificationsOperations.notificationEnqueue("Die Aufgabe wurde erfolgreich verschickt", flavors.SUCCESS))
      );
    }
    return promise;
  };
};

const taskCopyRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.TASK_COPY,
    requestType: 'post',
    requestPath: '/tasks/:id/copy'.replace(':id', id),
    normalizeSchema: taskSchema,
    appendOnSuccess: taskCopySuccess,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const taskCopySuccess = (dispatch, data) => {
  dispatch(notificationsOperations.notificationEnqueue("Der Aufgabe wurde erfolgreich kopiert", flavors.SUCCESS));
  if (data.result && data.result.taskKey) {
    getHistory().push(routes.TASK_DETAILS.path.replace(":id", data.result.taskKey));
  }
};

const taskChangeArchiveRequest = (id, isCreate) => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.TASK_PUT,
    requestType: isCreate ? 'post' : 'delete',
    requestPath: '/tasks/:id/archivings'.replace(':id', id),
    normalizeSchema: taskSchema,
    appendOnSuccess: taskChangeSuccess(
      isCreate ? 'Die Aufgabe wurde erfolgreich archiviert' : 'Die Archivierung der Aufgabe wurde erfolgreich aufgehoben'
    ),
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const taskChangeSuccess = message => (dispatch) => {
  dispatch(notificationsOperations.notificationEnqueue(message, flavors.SUCCESS));
};

const taskArchiveRequest = (id) => taskChangeArchiveRequest(id, true);
const taskUnarchiveRequest = (id) => taskChangeArchiveRequest(id, false);

const taskSubmitRequest = (id) => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.TASK_POST,
    requestType: 'post',
    requestPath: '/tasks/:id/submit'.replace(':id', id),
    normalizeSchema: taskSchema,
    appendOnSuccess: taskChangeSuccess(
      'Die Aufgabe wurde erfolgreich eingereicht'
    ),
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const taskReopenRequest = (id) => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.TASK_POST,
    requestType: 'post',
    requestPath: '/tasks/:id/reopen'.replace(':id', id),
    normalizeSchema: taskSchema,
    appendOnSuccess: taskChangeSuccess(
      'Die Aufgabe wurde erfolgreich erneut geöffnet'
    ),
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const tasksCancelRequest = (cancelType, cancelMessage) => {
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsCancellation(entityKey, cancelType, cancelMessage));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const gotoList = (master = false) => {
  if (master) {
    getHistory().push(routes.MASTER_TASKS.path);
  } else {
    getHistory().push(routes.TASKS.path);
  }
};

export default {
  tasksGetRequest,
  taskGetRequest,
  taskPutRequest,
  taskPostRequest,
  taskDeleteRequest,
  taskSendRequest,
  tasksCancelRequest,
  taskWithoutRelationsGetRequest,
  taskCopyRequest,
  taskArchiveRequest,
  taskUnarchiveRequest,
  taskSubmitRequest,
  taskReopenRequest,
  tasksFilterOptionsRequest,
  taskRestoreRequest
};
