import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper/Paper";
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    paper: {
        padding: theme.spacing(3),
    }
});

const HomeComponent = (props) => {

    const { classes } = props;

    return (
        <Paper elevation={0} className={classes.paper}>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>LIKA-Manager</Typography>
            <Typography variant="h6" align="center" color="textSecondary" component="p">Startseiteninhalte...</Typography>
        </Paper>
    );
};

export default withStyles(styles)(HomeComponent);