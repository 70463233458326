import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import routes from "../../utils/routes";
import DoneIcon from '@material-ui/icons/Done';
import green from '@material-ui/core/colors/green';
import Link from "@material-ui/core/Link";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  cardContent: {
    padding: theme.spacing(1.5, 1.5, 0),
  },
  cardActions: {
    justifyContent: "space-between",
    padding: theme.spacing(1.5),
  },
  cardMeta: {
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  cardTitle: {
    fontWeight: 500,
    lineHeight: 1.375,
  },
  doneChip: {
    borderColor: green[500],
    color: green[500],
  },
  doneChipIcon: {
    fill: green[500],
  },
});

class SubTaskCardComponent extends React.Component {

  handleSortClick = (evt, delta) => {
    evt.preventDefault();

    this.props.sortHandler(delta);
  };

  handleDetailClick(evt) {
    evt.preventDefault();
    const {taskKey, item} = this.props;
    const route = routes.SUBTASK_DETAILS.path.replace(':id', taskKey).replace(':number', item.subTaskNumber);
    this.props.history.push(route);
  }

  render() {
    const {classes, width, item, usersById, canEdit, isDragging, isFirst, isLast} = this.props;

    const owner = (item && item.owner) ? usersById[item.owner] : null;

    const ownerFullName = (owner) ? `${owner.firstName} ${owner.lastName}` : '';

    return (
      <Card elevation={isDragging ? 10 : 1} className={classes.card}>
        <CardContent className={classes.cardContent}>
          {/*<div className={classes.cardMeta}>
                        <Typography variant="body2" display="block" color="textSecondary">
                            {taskKey} / {item.subTaskNumber}
                        </Typography>
                        {item.status === 20 &&
                            <Chip
                              variant="outlined"
                              size="small"
                              icon={<DoneIcon classes={{ root: classes.doneChipIcon }} />}
                              label="Erledigt"
                              classes={{ outlined: classes.doneChip }}
                            />
                        }
                    </div>*/}
          {item.status === 20 &&
          <div className={classes.cardMeta}>
            <Chip
              variant="outlined"
              size="small"
              icon={<DoneIcon classes={{root: classes.doneChipIcon}}/>}
              label="Erledigt"
              classes={{outlined: classes.doneChip}}
            />
          </div>
          }
          <Typography variant="body1" display="block" component="h3" className={classes.cardTitle}>
            <Link color="textPrimary" underline="none" onClick={evt => this.handleDetailClick(evt)}>{item.title}</Link>
          </Typography>
          <Typography variant="body2" display="block" color="textSecondary">
            {ownerFullName}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button variant="outlined" size="small" color="primary" onClick={evt => this.handleDetailClick(evt)}>Details</Button>
          {canEdit && isWidthDown('sm', width) && !(isFirst && isLast) &&
          <ButtonGroup size="small" aria-label="Sortierung">
              {!isFirst && <Button onClick={evt => this.handleSortClick(evt, -1)} aria-label="Nach oben"><ArrowDropUpIcon/></Button>}
              {!isLast && <Button onClick={evt => this.handleSortClick(evt, 1)} aria-label="Nach unten"><ArrowDropDownIcon/></Button>}
          </ButtonGroup>
          }
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(withWidth()(SubTaskCardComponent));
