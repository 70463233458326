import React from "react";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import withStyles from "@material-ui/core/styles/withStyles";
import routes from "../../utils/routes";
import { withRouter } from "react-router-dom";
import CardActions from "@material-ui/core/CardActions";
import config from "../../utils/config";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import InfoIcon from '@material-ui/icons/InfoOutlined';

const styles = theme => ({
  card: {
    maxWidth: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    height: 0,
    paddingTop: "56.25%",
    cursor: "pointer",
  },
  cardContent: {
    padding: theme.spacing(1.5, 1.5, 0),
  },
  cardActions: {
    justifyContent: "space-between",
    marginTop: "auto",
    padding: theme.spacing(1.5),
  },
  cardMeta: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  cardTitle: {
    cursor: 'pointer',
    fontWeight: 500,
    lineHeight: 1.375,
  },
  dialogTitle: {
    margin: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  dialogActions: {
    justifyContent: 'center',
    margin: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

class PortfolioFieldOfActionComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
    };
  }

  handleDialogOpen = () => {
    this.setState({
      dialogOpen: true
    });
  };

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    });
  };

  handleInfoClick(evt) {
    evt.preventDefault();
    this.setState(prevState => ({
      open: !prevState.open
    }));
  }

  handleDetailClick(evt, id) {
    evt.preventDefault();
    this.handleRoute(evt, routes.REPORTS.path.replace(":fieldOfActionId", id));
  }

  handleRoute(evt, route) {
    evt.preventDefault();
    if (route) {
      this.props.history.push(route);
    }
  }

  render() {
    const { classes, item } = this.props;
    const { dialogOpen } = this.state;

    const hasDescription = item && item.description && 0 < item.description.trim().length;

    const image = item.imageFilename ? config.DOWNLOAD_URL + "/uploads/" + item.imageFilename : false;

    return (
      <>
        <Card className={classes.card}>
          {image &&
          <CardMedia
            className={classes.cardMedia}
            image={image}
            title={item.name}
            onClick={evt => this.handleDetailClick(evt, item.id)}
          />}
          <CardContent className={classes.cardContent}>
            <div className={classes.cardMeta}>
              {item.numberOfReports ? (
                <Typography variant="body2" display="block" color="textSecondary">
                  {1 === item.numberOfReports ? "1 Eintrag" : `${item.numberOfReports} Einträge`}
                </Typography>
              ) : (
                <Typography variant="body2" display="block" color="textSecondary">Noch keine Einträge</Typography>
              )}
              {hasDescription &&
                <IconButton size="small" aria-label="Details" onClick={() => this.handleDialogOpen()}>
                  <InfoIcon color="primary" fontSize="small"/>
                </IconButton>
              }
            </div>
            <Typography variant="body1" display="block" component="h3" className={classes.cardTitle}>
              <Link color="textPrimary" underline="none" onClick={evt => this.handleDetailClick(evt, item.id)}>{item.name}</Link>
            </Typography>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button variant="outlined" color="primary" size="small" onClick={evt => this.handleDetailClick(evt, item.id)}>Details/Bearbeiten</Button>
          </CardActions>
        </Card>
        {hasDescription &&
          <Dialog
            open={dialogOpen}
            maxWidth='md'
            onClose={() => this.handleDialogClose()}
          >
            <DialogTitle disableTypography classes={{
              root: classes.dialogTitle
            }}>
              <Typography variant="h6">{item.name}</Typography>
              <IconButton aria-label="Close" className={classes.closeButton} onClick={() => this.handleDialogClose()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent classes={{
              root: classes.dialogContent
            }}>
              {item.description.split('\n').map((line) => {
                  return (
                    <>
                      {line}
                      <br/>
                    </>
                  )
              })}
            </DialogContent>
            <DialogActions classes={{
              root: classes.dialogActions
            }}>
              <Button onClick={() => this.handleDialogClose()} variant="outlined" color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        }
      </>
    );
  }
}

export default withRouter(withStyles(styles)(PortfolioFieldOfActionComponent));
