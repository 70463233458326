import { schema } from 'normalizr';
import routes from "../../utils/routes";
import getHistory from '../../utils/history';
import { requestsOperations } from "./requests";
import { entityKey, duckTypes } from "./fieldsOfActionKeys";
import { entityKey as filesSliceKey } from './filesKeys';
import { default as filesOperations } from './filesOperations';

const fieldOfActionSchema = new schema.Entity(entityKey);
const fieldOfActionListSchema = new schema.Array(fieldOfActionSchema);

const fetchRelations = [{
  relationResponseKey: 'image',
  relationSliceKey: filesSliceKey,
  operation: filesOperations.filesByFieldOfActionGetRequest,
  noRefetch: true,
  isSingle: true,
}];

const gotoList = () => {
  getHistory().push(routes.FIELDSOFACTION.path);
};

const listGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.FIELDSOFACTION_GET,
    requestPath: url,
    clearEntityData: true,
    normalizeSchema: fieldOfActionListSchema
  };

  return requestsOperations.requestsOperationGeneric(null, settings);
};

const fieldsOfActionGetRequest = () => listGetRequest('/field-of-actions');

const associationGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.FIELDSOFACTION_GET,
    requestPath: url,
    clearEntityData: false,
    normalizeSchema: fieldOfActionListSchema,
    requestCancelling: false,
  };
  return requestsOperations.requestsOperationGeneric(null, settings);
};

const fieldOfActionsByTaskGetRequest = (taskId) => associationGetRequest(`tasks/${taskId}/field-of-actions`);

const fieldOfActionsByProfessionGetRequest = (professionId) => associationGetRequest(`jobs/${professionId}/field-of-actions`);

const fieldOfActionsByReportGetRequest = (reportId) => associationGetRequest(`reports/${reportId}/field-of-actions`);

const fieldOfActionGetRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.FIELDOFACTION_GET,
    requestPath: `/field-of-actions/${id}`,
    idParamRequired: true,
    normalizeSchema: fieldOfActionSchema,
    fetchRelations: fetchRelations,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const fieldOfActionPutRequest = (id, data, formikBag) => {
  const params = {
    id: id,
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.FIELDOFACTION_PUT,
    requestType: 'put',
    requestPath: `/field-of-actions/${id}`,
    idParamRequired: true,
    normalizeSchema: fieldOfActionSchema,
    appendOnSuccess: gotoList
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const fieldOfActionPostRequest = (data, formikBag) => {
  const params = {
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.FIELDOFACTION_POST,
    requestType: 'post',
    requestPath: '/field-of-actions',
    normalizeSchema: fieldOfActionSchema,
    appendOnSuccess: gotoList
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const fieldOfActionDeleteRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.FIELDOFACTION_DELETE,
    requestType: 'delete',
    requestPath: `/field-of-actions/${id}`,
    idParamRequired: true,
    normalizeSchema: fieldOfActionSchema,
    appendOnSuccess: gotoList
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const fieldsOfActionCancelRequest = (cancelType, cancelMessage) => {
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsCancellation(entityKey, cancelType, cancelMessage));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

export default {
  fieldsOfActionGetRequest,
  fieldOfActionGetRequest,
  fieldOfActionPutRequest,
  fieldOfActionPostRequest,
  fieldOfActionDeleteRequest,
  fieldsOfActionCancelRequest,
  fieldOfActionsByTaskGetRequest,
  fieldOfActionsByProfessionGetRequest,
  fieldOfActionsByReportGetRequest,
};
