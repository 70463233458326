import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import FileIcon from '@material-ui/icons/Description';
import ImageIcon from '@material-ui/icons/Image';
import VideoIcon from '@material-ui/icons/OndemandVideo';
import AudioIcon from '@material-ui/icons/RecordVoiceOver';
import FileManagerIcon from "@material-ui/icons/AttachFile";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Tooltip from "@material-ui/core/Tooltip";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import { Dashboard } from '@uppy/react';
import createUppy from '../../utils/uppy';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';

import MUIDataTable from "mui-datatables";

import CustomToolbarSelect from "./CustomToolbarSelect";
import flavors from "../../redux/notifications/flavors";
import { labelsMuiDatatables } from "../../utils/labels";
import ShowMoreText from "./ShowMoreText";
import { formatDate } from "../../utils/helper";

const styles = theme => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  list: {
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(1),
  },
  dates: {
    whiteSpace: "nowrap"
  },
  dialogTitle: {
    margin: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 !important',
    '& .uppy-Root': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.primary
    },
    '& .uppy-Dashboard--modal': {
      zIndex: theme.zIndex.modal + 1,
    },
    '& .uppy-Dashboard-inner': {
      borderRadius: 0,
      borderWidth: 0,
    },
    '& .uppy-Dashboard-innerWrap': {
      borderRadius: 0,
    },
    '& .uppy-Dashboard-AddFiles': {
      borderWith: 0,
    },
    '& .uppy-Dashboard-dropFilesTitle': {
      fontSize: theme.typography.h6.fontSize,
    },
    '& .uppy-Dashboard-browse': {
      color: theme.palette.primary.main
    },
    '& .uppy-DashboardTab-btn': {
      color: theme.palette.primary.main
    },
    '& .uppy-DashboardTab-btn > svg': {
      fill: theme.palette.primary.main
    },
    '& .uppy-DashboardTab-name': {
      fontSize: theme.typography.button.fontSize,
    },
  },
  dialogActions: {
    justifyContent: 'center',
    margin: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

class FilesSelectionComponent extends Component {
  constructor(props) {
    super(props);

    this.uppy = createUppy(this.handleUploadSuccess.bind(this), this.handleUploadError.bind(this));

    this.state = {
      dialogOpen: false,
      tabNav: 0
    };
  }

  componentDidMount() {
    const { allowedFileTypes, maxNumberOfFiles } = this.props;

    this.uppy.setOptions({
      restrictions: {
        maxNumberOfFiles: maxNumberOfFiles,
        allowedFileTypes: allowedFileTypes
      }
    });

    this.uppy.on('complete', () => {
      this.handleDialogClose();
    });

    this.initData();
  }

  componentWillUnmount() {
    this.uppy.close();
    this.uppy = null;
  }

  initData = () => {
    this.props.filesGetRequest();
  };

  handleDialogOpen = () => {
    this.setState({
      dialogOpen: true
    });
  };

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    });
  };

  handleTabChange = (evt, value) => {
    this.setState({
      tabNav: value
    });
  };

  handleSelected = ids => {
    if (!ids || ids.length === 0) {
      return;
    }

    const { field, maxNumberOfFiles } = this.props;
    const { name, value } = field;

    const newIds = ids.filter(id => {
      if (value.indexOf(id) === -1) {
        return true;
      } else {
        this.handleWarning(`'${this.props.byId[id].title}' wurde bereits hinzugefügt`);
        return false;
      }
    });

    if (maxNumberOfFiles && maxNumberOfFiles > 0 && maxNumberOfFiles < newIds.length) {
      this.handleWarning(`Maximal ${maxNumberOfFiles} erlaubt`);
      newIds.splice(maxNumberOfFiles);
    }

    const newValue = value.length > 0 ? [...value, ...newIds] : [...newIds];

    this.props.onChange(name, newValue);
    this.handleDialogClose();
  };

  handleDelete = id => {
    const { name, value } = this.props.field;
    value.splice(value.indexOf(id), 1);
    const newValue = [...value];
    this.props.onChange(name, newValue);
  };

  handleUploadSuccess = data => {
    this.props.filePostSuccess(data);

    const newId = data.result ? data.result.id : null;

    if (!newId) {
      return;
    }

    const { name, value } = this.props.field;

    const newValue = value.length > 0 ? [...value, newId] : [newId];
    this.props.onChange(name, newValue);
  };

  handleUploadError = handledError => {
    this.props.filePostError(handledError);
  };

  handleWarning = message => {
    if (!message) {
      return;
    }
    this.props.notificationEnqueue(message, flavors.WARNING);
  }

  render() {
    const { classes, isAdmin, isInstructor, fullScreen, byId, allIds, value, allowedFileTypes, maxNumberOfFiles, labelAddFiles, labelNoFiles, labelAllFiles } = this.props;
    const { tabNav } = this.state;

    const isApprentice = !isAdmin && !isInstructor;

    const tableOptions = {
      selectableRows: maxNumberOfFiles === 1 ? 'single' : 'multiple',
      elevation: 0,
      responsive: "scrollMaxHeight",
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 20, 50],
      textLabels: labelsMuiDatatables,
      customSort: (data, colIndex, order) => {
        return data.sort((a, b) => {
          let valA = a.data[colIndex];
          let valB = b.data[colIndex];
          // NOTE: timestamps on colIndex 1
          if (colIndex === 1) {
            valA = new Date(valA[1]); // NOTE: updatedAt on array-value index 1
            valB = new Date(valB[1]); // NOTE: updatedAt on array-value index 1
          }
          return (valA < valB ? -1 : 1) * (order === 'desc' ? -1 : 1);
        });
      },
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col !== null) {
            let cellContent = '';
            if (Array.isArray(col)) {
              col.forEach(index => {
                const value = col[index] || '';
                cellContent += `${value.toString().toLowerCase()} `;
              });
            } else {
              cellContent = col.toString();
            }
            if (cellContent.indexOf(searchQuery.toLowerCase()) >= 0) {
              isFound = true;
            }
          }
        });
        return isFound;
      },
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData}
                             setSelectedHandler={this.handleSelected} maxNumberOfItems={maxNumberOfFiles} warningHandler={this.handleWarning}/>
      ),
    };

    const tableColumns = [{
      name: "id",
      label: " ",
      options: {
        display: "excluded"
      }
    }, {
      name: "timestamps",
      label: "Erstellt, geändert",
      options: {
        sortDirection: 'desc',
        customBodyRender: timestamps => {
          return (
            <div className={classes.dates}>
              {formatDate(timestamps[0])}<br/>
              {formatDate(timestamps[1])}
            </div>
          )
        }
      }
    }, {
      name: "meta",
      label: "Titel, Beschreibung",
      options: {
        sort: false,
        customBodyRender: meta => {
          return (
            <React.Fragment>
              {meta[0]}
              {meta[1] &&
              <React.Fragment>
                <br/>
                <ShowMoreText maxLength={64}>{meta[1]}</ShowMoreText>
              </React.Fragment>
              }
            </React.Fragment>
          )
        }
      }
    }, {
      name: "hidden",
      label: " ",
      options: {
        display: !isApprentice,
        sort: false,
        customBodyRender: hiddenFromApprentice => hiddenFromApprentice ? (
          <Tooltip title="Ohne Beschränkung" aria-label="Ohne Beschränkung">
            <LockOpenIcon color="disabled"/>
          </Tooltip>
        ) : (
          <Tooltip title="Kein Zugriff für Auszubildende" aria-label="Kein Zugriff für Auszubildende">
            <LockIcon color="action"/>
          </Tooltip>
        )
      }
    }, {
      name: "filename",
      label: "Orig. Dateiname",
      options: {
          customBodyRender: id => {
              const item = byId[id];
              return (
                  <React.Fragment>
                      {item.originalFilename}
                      {!item.fileExists && <Typography variant="caption" display="block" color="error">Achtung: Datei fehlt</Typography>}
                  </React.Fragment>
              );
          }
      }
    }];

    const tableData = allIds.map(itemId => {
      const item = byId[itemId];
      if (!item) {
        return null;
      }

      if (allowedFileTypes) {
        //check if is allowwd file type
        const isAllowed = allowedFileTypes
          .map(fileType => new RegExp(fileType.replace("/", "\\/"), "ig").exec(item.mimeType) !== null)
          .reduce((accumulator, currentValue) => accumulator || currentValue);

        if (!isAllowed) {
          return null;
        }
      }

      const timestamps = [
        item.createdAt,
        item.updatedAt
      ];
      const meta = [
        item.title,
        item.description
      ];
      return [
        item.id,
        timestamps,
        meta,
        item.hiddenFromApprentice,
        item.id
      ];
    }).filter(v => v);

    const addButtonDisabled = maxNumberOfFiles && maxNumberOfFiles <= value.length;

    return (
      <Box className={classes.container}>
        <List
          dense
          classes={{
            root: classes.list,
          }}
        >
          {value.length > 0 ? value.map((id, key) => {
            const file = byId[id];
            if (!file) {
              return null;
            }
            let avatarIcon = (
              <FileIcon/>
            );
            if (file.mimeType.indexOf('image') >= 0) {
              avatarIcon = (
                <ImageIcon/>
              );
            } else if (file.mimeType.indexOf('video') >= 0) {
              avatarIcon = (
                <VideoIcon/>
              );
            } else if (file.mimeType.indexOf('audio') >= 0) {
              avatarIcon = (
                <AudioIcon/>
              );
            }
            return (
              <ListItem key={key}>
                <ListItemAvatar>
                  <Avatar>{avatarIcon}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={file.title}/>
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={evt => this.handleDelete(id)} aria-label="Entfernen">
                    <DeleteIcon/>
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          }) : (
            <ListItem>
              <ListItemText secondary={labelNoFiles || 'Keine Dateien zugeordnet'}/>
            </ListItem>
          )}
        </List>
        <Button size="small" variant="outlined" onClick={this.handleDialogOpen} disabled={addButtonDisabled}>
          {labelAddFiles || 'Datei(en) hinzufügen'}
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={this.state.dialogOpen}
          onClose={this.handleDialogClose}
        >
          <DialogTitle disableTypography classes={{
            root: classes.dialogTitle
          }}>
            <Typography variant="h6">{labelAddFiles || 'Datei(en) hinzufügen'}</Typography>
            <IconButton aria-label="Close" className={classes.closeButton} onClick={evt => this.handleDialogClose()}>
              <CloseIcon/>
            </IconButton>
          </DialogTitle>
          <DialogContent classes={{
            root: classes.dialogContent
          }}>
            {tabNav === 0 &&
            <MUIDataTable
              title={labelAllFiles || "Alle Dateien"}
              options={tableOptions}
              columns={tableColumns}
              data={tableData}
            />
            }
            {tabNav === 1 &&
            <Dashboard
              uppy={this.uppy}
              height={360}
              plugins={['Webcam']}
              proudlyDisplayPoweredByUppy={false}
            />
            }
          </DialogContent>
          <DialogActions classes={{
            root: classes.dialogActions
          }}>
            <BottomNavigation
              value={tabNav}
              onChange={this.handleTabChange}
              showLabels
            >
              <BottomNavigationAction label="Dateimanager" icon={<FileManagerIcon/>}/>
              <BottomNavigationAction label="Direktupload" icon={<CloudUploadIcon/>}/>
            </BottomNavigation>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(FilesSelectionComponent));
