import { schema } from 'normalizr';
import routes from "../../utils/routes";
import getHistory from '../../utils/history';
import { requestsOperations } from "./requests";

// import { entityKey as usersSliceKey } from "./usersKeys";
import { userListSchema } from './usersOperations';
import { entityKey, duckTypes } from "./businessesKeys";

const companySchema = new schema.Entity(entityKey, {
  users: userListSchema
});
const companyListSchema = new schema.Array(companySchema);

/* NOTE: Currently not in use b/c company endpoint send full user data object, which are normalized accordingly
const fetchRelations = [{
    relationResponseKey: 'users',
    relationSliceKey: usersSliceKey,
    operation: usersOperations.userGetRequest,
    noRefetch: true
}];
*/

const businessesGetRequest = (urlParameters) => {
  const settings = {
    duckType: duckTypes.BUSINESSES_GET,
    requestPath: '/companies',
    clearEntityData: true,
    normalizeSchema: companyListSchema
  };
  return requestsOperations.requestsOperationGeneric(null, settings, urlParameters);
};

const businessesFilterOptionsRequest = (urlParameters = {}) => {
  const settings = {
    duckType: duckTypes.BUSINESSES_FILTER_OPTIONS,
    requestPath: '/filter_options/companies',
  };

  return requestsOperations.requestsFilterOptionsGeneric(null, settings, urlParameters);
};


const businessGetRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.BUSINESS_GET,
    requestPath: `/companies/${id}`,
    idParamRequired: true,
    normalizeSchema: companySchema,

    // fetchRelations: fetchRelations
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const businessPutRequest = (id, data, formikBag) => {
  const params = {
    id: id,
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.BUSINESS_PUT,
    requestType: 'put',
    requestPath: `/companies/${id}`,
    idParamRequired: true,
    normalizeSchema: companySchema,
    appendOnSuccess: gotoList
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const businessPostRequest = (data, formikBag) => {
  const params = {
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.BUSINESS_POST,
    requestType: 'post',
    requestPath: '/companies',
    normalizeSchema: companySchema,
    appendOnSuccess: gotoList
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const businessDeleteRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.BUSINESS_DELETE,
    requestType: 'delete',
    requestPath: `/companies/${id}`,
    idParamRequired: true,
    normalizeSchema: companySchema,
    appendOnSuccess: gotoList
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const businessesCancelRequest = (cancelType, cancelMessage) => {
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsCancellation(entityKey, cancelType, cancelMessage));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const gotoList = () => {
  getHistory().push(routes.BUSINESSES.path);
};

export default {
  businessesGetRequest,
  businessGetRequest,
  businessPutRequest,
  businessPostRequest,
  businessDeleteRequest,
  businessesCancelRequest,
  businessesFilterOptionsRequest
};
