import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

class ShowMoreText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      triggered: false,
      collapsed: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate() {
    this.init();
  }

  init() {
    const { children, maxLength } = this.props;
    const { triggered, collapsed } = this.state;
    
    if (triggered || !children) {
      return;
    }

    if (children.length > maxLength && !collapsed) {
      this.setState({
        collapsed: true,
      });
    }
  }

  handleExpandClick(evt) {
    evt.preventDefault();
    this.setState({
      triggered: true,
      collapsed: false,
    });
  }

  render() {
    const { children, maxLength } = this.props;
    const { collapsed } = this.state;

    if (!children) {
      return <></>;
    }

    const text = children.replace(/(<([^>]+)>)/ig,"").replace(/&nbsp;/g, " ");

    if (!collapsed) {
      return <>
        {text}
      </>;
    }

    const textShorted = text.substr(0, maxLength);
    return <>
      {textShorted}
      <IconButton
        size="small"
        onClick={(evt) => this.handleExpandClick(evt)}
        aria-label="Mehr...">
        <MoreHorizIcon/>
      </IconButton>
    </>;
  }
}

export default ShowMoreText;
