import { withRouter } from "react-router";
import { connect } from 'react-redux';
import LoginComponent from './LoginComponent';
import { authenticationOperations } from '../../redux/authentication';

const mapStateToProps = state => {
  const { errors } = state.authentication;

  return {
    errors
  };
};

const mapDispatchToProps = dispatch => {
  const authPostRequest = (username, password, formikBag) => dispatch(authenticationOperations.authPostRequest(username, password, formikBag));

  const cancelAuthRequest = (cancelType, cancelMessage) => dispatch(authenticationOperations.cancelAuthRequest(cancelType, cancelMessage));

  return {
    authPostRequest,
    cancelAuthRequest
  };
};

const LoginContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginComponent));

export default LoginContainer;