import React from 'react';
import { Field, Form, Formik } from "formik";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField } from 'formik-material-ui';
import FormHelperText from '@material-ui/core/FormHelperText';
import SingleCheckbox from "../common/SingleCheckbox";
import FormControl from "@material-ui/core/FormControl";
import RichTextField from '../common/RichTextField';

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    wrapper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

class LinkFormComponent extends React.Component {

    render() {
        const { classes, item, submitHandler, resetHandler, isApprentice } = this.props;

        let setUrl = '';
        let setTitle = '';
        let setDescription = '';
        let setHidden = '';
        if (item) {
            setUrl = item.url || '';
            setTitle = item.title || '';
            setDescription = item.description || '';
            setHidden = (item.hiddenFromApprentice) ? '1' : '';
        }

        const checkboxValues = {
            hidden: '1'
        };

        return (
            <Formik
                initialValues = {{
                    url: setUrl,
                    title: setTitle,
                    description: setDescription,
                    hidden: setHidden
                }}
                enableReinitialize={true}
                validate = {values => {
                    let errors = {};
                    if (values.url === '') {
                        errors.url = 'Dies ist ein Pflichtfeld';
                    }
                    if (values.title === '') {
                        errors.title = 'Dies ist ein Pflichtfeld';
                    }
                    return errors;
                }}
                onSubmit = {(values, formikBag) => {
                    submitHandler(values, formikBag);
                }}>
                {({ status, touched, errors, isSubmitting, handleSubmit, handleReset }) => (
                    <Form
                        autoComplete="off"
                        onSubmit={evt => {
                            evt.stopPropagation();
                            handleSubmit(evt);
                        }}
                        onReset={evt => {
                            evt.stopPropagation();
                            handleReset(evt);
                            resetHandler();
                        }}
                        className={classes.form}
                    >
                        {status &&
                            <FormHelperText error>{status}</FormHelperText>
                        }
                        <Field component={TextField}
                            type="text"
                            id="url"
                            name="url"
                            label="URL"
                            required
                            fullWidth
                            margin="normal"/>
                        <Field component={TextField}
                            type="text"
                            id="title"
                            name="title"
                            label="Titel"
                            required
                            fullWidth
                            margin="normal"/>
                        <Field component={RichTextField}
                            type="text"
                            id="description"
                            name="description"
                            label="Beschreibung"
                            // maxLength={250}
                            fullWidth
                            margin="normal"/>
                        {!isApprentice &&
                            <FormControl fullWidth margin="normal">
                                <SingleCheckbox name="hidden" value={checkboxValues.hidden} label="Kein Zugriff für Auszubildende" />
                            </FormControl>
                        }
                        <div className={classes.wrapper}>
                            <Button type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}>
                                Speichern
                            </Button>
                            {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress} />}
                        </div>
                        <div className={classes.wrapper}>
                            <Button type="reset" color="primary">Abbrechen</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withStyles(styles)(LinkFormComponent);
