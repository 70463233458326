import config from '../../utils/config';

const namespace = config.APP_NAME + '/notifications/';

const NOTIFICATION = namespace + 'NOTIFICATION';
const NOTIFICATION_ENQUEUE = NOTIFICATION + '_ENQUEUE';
const NOTIFICATION_REMOVE = NOTIFICATION + '_REMOVE';

export default {
  NOTIFICATION_ENQUEUE,
  NOTIFICATION_REMOVE
};
