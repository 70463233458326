import React from 'react';
import { Field, Form, Formik } from "formik";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ReactSelect from "../common/ReactSelect";
import taskPhases from "../../utils/taskPhases";
import taskStatus from "../../utils/taskStatus";
import FilesSelection from "../common/FilesSelectionContainer";
import LinksSelection from "../common/LinksSelectionContainer";
import RichTextField from '../common/RichTextField';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  wrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class SubTaskEditFormComponent extends React.Component {
  render() {
    const { classes, isAdmin, isInstructor, item, submitHandler, resetHandler } = this.props;

    let setTitle = '';
    let setDescription = '';
    let setPhase = 1;
    let setStatus = null;
    let setMedia = [];
    let setLinks = [];
    if (item) {
      setTitle = item.title || '';
      setDescription = item.description || '';
      setPhase = item.phase || 1;
      setStatus = item.status || null;
      setMedia = item.media || [];
      setLinks = item.links || [];
    }

    return (
      <Formik
        initialValues = {{
          title: setTitle,
          description: setDescription,
          phase: setPhase,
          status: setStatus,
          media: setMedia,
          links: setLinks
        }}
        enableReinitialize={true}
        validate = {values => {
          let errors = {};
          if ('' === values.title) {
            errors.title = 'Dies ist ein Pflichtfeld';
          }
          return errors;
        }}
        onSubmit = {(values, formikBag) => {
          submitHandler(values, formikBag);
        }}>
        {({ status, values, touched, errors, setFieldValue, setFieldTouched, isSubmitting, handleSubmit, handleReset }) => (
          <Form
            autoComplete="off"
            onSubmit={evt => {
              evt.stopPropagation();
              handleSubmit(evt);
            }}
            onReset={evt => {
              evt.stopPropagation();
              handleReset(evt);
              resetHandler();
            }}
            className={classes.form}
          >
            {status &&
                            <FormHelperText error>{status}</FormHelperText>
            }
            <Field component={TextField}
              type="text"
              id="title"
              name="title"
              label="Titel"
              required
              fullWidth
              margin="normal"/>
            <Field component={RichTextField}
              type="text"
              id="description"
              name="description"
              label="Beschreibung"

              // maxLength={250}
              fullWidth
              margin="normal"/>
            <Field component={FilesSelection}
              id="media"
              name="media"
              label="Dateien"
              value={values.media}
              onChange={setFieldValue}
            />
            <Field component={LinksSelection}
              id="links"
              name="links"
              label="Links"
              value={values.links}
              onChange={setFieldValue}
            />
            <FormControl fullWidth margin="normal" error={!!errors.phase && touched.phase}>
              <ReactSelect name="phase"
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                value={values.phase}
                error={errors.phase}
                touched={touched.phase}
                options={[{
                  value: 1,
                  label: taskPhases[1].label,
                  isDisabled: !isAdmin && !isInstructor
                }, {
                  value: 2,
                  label: taskPhases[2].label,
                  isDisabled: !isAdmin && !isInstructor
                }, {
                  value: 3,
                  label: taskPhases[3].label,
                  isDisabled: !isAdmin && !isInstructor
                }, {
                  value: 4,
                  label: taskPhases[4].label,
                  isDisabled: !isAdmin && !isInstructor
                }]}
                placeholder="Phase auswählen..."
                label="Phase"
                isMulti={false}
                isClearable={false}/>
              {(!!errors.phase && touched.phase) &&
                            <FormHelperText>{errors.phase}</FormHelperText>
              }
            </FormControl>
            <FormControl fullWidth margin="normal" error={!!errors.status && touched.status}>
              <ReactSelect name="status"
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                value={values.status}
                error={errors.status}
                touched={touched.status}
                options={[{
                  value: 10,
                  label: taskStatus[10],
                  isDisabled: !isAdmin && !isInstructor
                }, {
                  value: 20,
                  label: taskStatus[20],
                  isDisabled: !isAdmin && !isInstructor
                }]}
                placeholder="Status auswählen..."
                label="Status"
                isMulti={false}
                isClearable={false}/>
              {(!!errors.status && touched.status) &&
                            <FormHelperText>{errors.status}</FormHelperText>
              }
            </FormControl>
            <div className={classes.wrapper}>
              <Button type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}>
                                Speichern
              </Button>
              {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress} />}
            </div>
            <div className={classes.wrapper}>
              <Button type="reset" color="primary">Abbrechen</Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(SubTaskEditFormComponent);
