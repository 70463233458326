import { keys as genericKeys, type as genericType } from "../generics";

const { intents } = genericKeys;

const nameKeys = {
  TASKS: 'TASKS',
  TASK: 'TASK',
};
export const entityKey = nameKeys.TASKS.toLowerCase();
export const duckTypes = {
  TASKS_GET: genericType(entityKey, nameKeys.TASKS, intents.GET),
  TASK_GET: genericType(entityKey, nameKeys.TASK, intents.GET),
  TASK_PUT: genericType(entityKey, nameKeys.TASK, intents.PUT),
  TASK_POST: genericType(entityKey, nameKeys.TASK, intents.POST),
  TASK_SEND: genericType(entityKey, nameKeys.TASK, intents.SEND),
  TASK_COPY: genericType(entityKey, nameKeys.TASK, intents.COPY),
  TASK_DELETE: genericType(entityKey, nameKeys.TASK, intents.DELETE),
  TASK_RESTORE: genericType(entityKey, nameKeys.TASK, intents.RESTORE),
  TASK_FILTER_OPTIONS: genericType(entityKey, nameKeys.TASK, intents.FETCH_FILTER_OPTIONS)
};
