import { withRouter } from "react-router";
import { connect } from 'react-redux';
import FilesSelectionComponent from './FilesSelectionComponent';
import { entityKey as filesSliceKey } from '../../redux/entities/filesKeys';
import { default as filesOperations } from '../../redux/entities/filesOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { isAdmin, isInstructor } = state.authentication;
  const { errors, byId, allIds } = state.entities[filesSliceKey];

  return {
    isAdmin,
    isInstructor,
    errors,
    byId,
    allIds
  };
};

const mapDispatchToProps = dispatch => {
  const filesGetRequest = () => dispatch(filesOperations.filesGetRequest());

  const filePostSuccess = data => dispatch(filesOperations.filePostSuccess(data));
  const filePostError = handledError => dispatch(filesOperations.filePostError(dispatch, handledError));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    filesGetRequest,
    filePostSuccess,
    filePostError,
    notificationEnqueue
  };
};

const FilesSelectionContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FilesSelectionComponent));

export default FilesSelectionContainer;
