import { keys as genericKeys, type as genericType } from "../generics";

const { intents } = genericKeys;

const nameKeys = {
  COMPETENCEEVALUATIONS: 'COMPETENCEEVALUATIONS',
  COMPETENCEEVALUATION: 'COMPETENCEEVALUATION',
};
export const entityKey = nameKeys.COMPETENCEEVALUATIONS.toLowerCase();
export const duckTypes = {
  COMPETENCEEVALUATIONS_GET: genericType(entityKey, nameKeys.COMPETENCEEVALUATIONS, intents.GET),
  COMPETENCEEVALUATION_GET: genericType(entityKey, nameKeys.COMPETENCEEVALUATION, intents.GET),
  COMPETENCEEVALUATION_PUT: genericType(entityKey, nameKeys.COMPETENCEEVALUATION, intents.PUT),
  COMPETENCEEVALUATION_POST: genericType(entityKey, nameKeys.COMPETENCEEVALUATION, intents.POST),
  COMPETENCEEVALUATION_COPY: genericType(entityKey, nameKeys.COMPETENCEEVALUATION, intents.COPY),
  COMPETENCEEVALUATION_DELETE: genericType(entityKey, nameKeys.COMPETENCEEVALUATION, intents.DELETE)
};
