import { withRouter } from "react-router";
import { connect } from 'react-redux';
import LinksComponent from './LinksComponent';
import { entityKey as linksSliceKey } from '../../redux/entities/linksKeys';
import { default as linksOperations } from '../../redux/entities/linksOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { isAdmin, isInstructor } = state.authentication;
  const { errors, byId, allIds } = state.entities[linksSliceKey];

  return {
    isAdmin,
    isInstructor,
    errors,
    byId,
    allIds
  };
};

const mapDispatchToProps = dispatch => {
  const linksGetRequest = () => dispatch(linksOperations.linksGetRequest());
  const linkDeleteRequest = id => dispatch(linksOperations.linkDeleteRequest(id));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    linksGetRequest,
    linkDeleteRequest,
    notificationEnqueue
  };
};

const LinksContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(LinksComponent));

export default LinksContainer;
