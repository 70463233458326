import { combineReducers } from "redux";
import { updateCancelTokens } from "../../../utils/helper";
import { keys as genericKeys } from '../../generics';
import { entityKey as businessesSliceKey } from "../businessesKeys";
import { entityKey as notificationsSliceKey } from '../notificationsKeys';
import { entityKey as usersSliceKey } from "../usersKeys";
import { entityKey as professionsSliceKey } from "../professionsKeys";
import { entityKey as fieldsOfActionSliceKey } from "../fieldsOfActionKeys";
import { entityKey as tasksSliceKey } from "../tasksKeys";
import { entityKey as subTasksSliceKey } from "../subTasksKeys";
import { entityKey as filesSliceKey } from "../filesKeys";
import { entityKey as linksSliceKey } from "../linksKeys";
import { entityKey as facilitiesSliceKey } from "../facilitiesKeys";
import { entityKey as resultsSliceKey } from "../resultsKeys";
import { entityKey as reportsSliceKey } from "../reportsKeys";
import { entityKey as competenceChecksSliceKey } from "../competenceChecksKeys";
import { entityKey as competenceSpecificationSliceKey } from "../competenceSpecificationsKeys";
import { entityKey as competenceRatingsSliceKey } from "../competenceRatingsKeys";
import { entityKey as competenceEvaluationsSliceKey } from "../competenceEvaluationsKeys";
import * as R from "ramda";

const { status } = genericKeys;

const INITIAL_STATE = {
  cancelTokens: {},
  errors: {},
  byId: {},
  allIds: [],
  filterOptions: []
};

const updateEntities = (state, type, action, isCurrentSlice, sliceKey) => {
  const updatedCancelTokens = (isCurrentSlice) ?
    updateCancelTokens(state.cancelTokens, { type: type.group }) :
    { ...state.cancelTokens };
  const updatedErrors = (isCurrentSlice) ? {} : { ...state.errors };
  const { entities, result, clearEntityData, deleteId } = action.payload;
  const totaCountInState = "totalCount" in state ? state.totalCount : null;
  const totalCount = "totalCount" in action.payload ? action.payload.totalCount : totaCountInState;

  if (entities && result) {
    const normalizrEntities = entities[sliceKey];

    let updatedAllIds = [];
    if (isCurrentSlice) {
      // Use normalizr "result" only for the original entityKey (b/c they are from original entity request)
      updatedAllIds = (Array.isArray(result)) ? result : [result];
    } else if (normalizrEntities) {
      // Assuming all entities other than the entityKey are related "normalized" entities and thus they need to be added to their respective slices "allIds" as well.
      updatedAllIds = Object.keys(normalizrEntities);
    }
    let byId = {};
    let allIds = [];
    if (isCurrentSlice && clearEntityData) {
      byId = {
        ...normalizrEntities
      };
      allIds = [
        ...updatedAllIds
      ];
    } else {
      byId = {
        ...state.byId,
        ...normalizrEntities
      };
      allIds = R.uniq([
        ...state.allIds,
        ...updatedAllIds
      ]);
    }

    return {
      ...state,
      cancelTokens: updatedCancelTokens,
      errors: updatedErrors,
      byId: {
        ...byId
      },
      allIds: [
        ...allIds
      ],
      totalCount: totalCount
    };
  } else if (deleteId) {
    const byId = {
      ...state.byId
    };
    delete byId[deleteId];
    const allIds = [
      ...state.allIds
    ];
    allIds.splice(allIds.indexOf(deleteId), 1);
    return {
      ...state,
      cancelTokens: updatedCancelTokens,
      errors: updatedErrors,
      byId: {
        ...byId
      },
      allIds: [
        ...allIds
      ],
      totalCount: totalCount
    };
  }
};

const requestsReducerGeneric = sliceKey => (state = INITIAL_STATE, action) => {
  const type = action.type;
  const entityKey = type.entity;
  const isCurrentSlice = (sliceKey === entityKey);

  if (type.intent === genericKeys.intents.FETCH_FILTER_OPTIONS) {
    return {
      ...state,
      filterOptions: action.payload,
    };
  }

  if (type.status === status.STARTED && isCurrentSlice) {
    const cancelProps = {
      type: type.group,
      token: action.payload.token,
      id: action.payload.id
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens
    };
  }

  if (type.status === status.SUCCESS) {
    return updateEntities(state, type, action, isCurrentSlice, sliceKey);
  }

  if (type.status === status.FAILURE && isCurrentSlice) {
    const cancelProps = {
      type: type.group
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens,
      errors: {
        ...action.payload
      }
    };
  }

  return state;
};

const requestsReducerBusinesses = requestsReducerGeneric(businessesSliceKey);
const requestsReducerNotifications = requestsReducerGeneric(notificationsSliceKey);
const requestsReducerUsers = requestsReducerGeneric(usersSliceKey);
const requestsReducerProfessions = requestsReducerGeneric(professionsSliceKey);
const requestsReducerFieldsOfAction = requestsReducerGeneric(fieldsOfActionSliceKey);
const requestsReducerTasks = requestsReducerGeneric(tasksSliceKey);
const requestsReducerSubTasks = requestsReducerGeneric(subTasksSliceKey);
const requestsReducerFiles = requestsReducerGeneric(filesSliceKey);
const requestsReducerLinks = requestsReducerGeneric(linksSliceKey);
const requestsReducerFacilities = requestsReducerGeneric(facilitiesSliceKey);
const requestsReducerResults = requestsReducerGeneric(resultsSliceKey);
const requestsReducerReports = requestsReducerGeneric(reportsSliceKey);
const requestsReducerCompetenceChecks = requestsReducerGeneric(competenceChecksSliceKey);
const requestsReducerCompetenceSpecifications = requestsReducerGeneric(competenceSpecificationSliceKey);
const requestsReducerCompetenceRatings = requestsReducerGeneric(competenceRatingsSliceKey);
const requestsReducerCompetenceEvaluations = requestsReducerGeneric(competenceEvaluationsSliceKey);

const requestsReducer = combineReducers({
  [businessesSliceKey]: requestsReducerBusinesses,
  [notificationsSliceKey]: requestsReducerNotifications,
  [usersSliceKey]: requestsReducerUsers,
  [professionsSliceKey]: requestsReducerProfessions,
  [fieldsOfActionSliceKey]: requestsReducerFieldsOfAction,
  [tasksSliceKey]: requestsReducerTasks,
  [subTasksSliceKey]: requestsReducerSubTasks,
  [filesSliceKey]: requestsReducerFiles,
  [linksSliceKey]: requestsReducerLinks,
  [facilitiesSliceKey]: requestsReducerFacilities,
  [resultsSliceKey]: requestsReducerResults,
  [reportsSliceKey]: requestsReducerReports,
  [competenceChecksSliceKey]: requestsReducerCompetenceChecks,
  [competenceSpecificationSliceKey]: requestsReducerCompetenceSpecifications,
  [competenceRatingsSliceKey]: requestsReducerCompetenceRatings,
  [competenceEvaluationsSliceKey]: requestsReducerCompetenceEvaluations,
});

export default requestsReducer;
