import { keys as genericKeys, type as genericType } from "../generics";

const { intents } = genericKeys;

const nameKeys = {
  BUSINESSES: 'BUSINESSES',
  BUSINESS: 'BUSINESS',
};
export const entityKey = nameKeys.BUSINESSES.toLowerCase();
export const duckTypes = {
  BUSINESSES_GET: genericType(entityKey, nameKeys.BUSINESSES, intents.GET),
  BUSINESS_GET: genericType(entityKey, nameKeys.BUSINESS, intents.GET),
  BUSINESS_PUT: genericType(entityKey, nameKeys.BUSINESS, intents.PUT),
  BUSINESS_POST: genericType(entityKey, nameKeys.BUSINESS, intents.POST),
  BUSINESS_DELETE: genericType(entityKey, nameKeys.BUSINESS, intents.DELETE),
  BUSINESSES_FILTER_OPTIONS: genericType(entityKey, nameKeys.BUSINESS, intents.FETCH_FILTER_OPTIONS)
};
