import React from "react";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import { duckTypes } from "../../redux/entities/subTasksKeys";
import { default as SubTaskNewForm } from "./SubTaskNewFormComponent";
import routes from "../../utils/routes";
import * as R from "ramda";

const styles = theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      width: 640,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
});

class SubTaskNewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      taskId: null,
      taskKey: null,
      taskFetched: false,
      subTaskPhaseId: null,
    };
  }

  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      if (!this.state.taskId) {
        this.getTask();
      }
    }
  }

  initData = () => {
    const { match, location } = this.props; // https://reacttraining.com/react-router/web/api/match
    if (match.params.id) {
      this.setState({
        taskKey: match.params.id
      }, () => {
        this.getTask();
      });
    }
    // NOTE: Using routing state param to pass optional preset info (i.e. phaseId)
    // The task specific info needs to be determined from redux or api requests (b/c url could have been entered from scratch)
    if (location.state) {
      this.setState({
        subTaskPhaseId: location.state.subTaskPhaseId
      });
    }
  };

  getTask = () => {
    let taskId = null;

    Object.keys(this.props.tasksById).some(id => {
      if (this.props.tasksById[id].taskKey === this.state.taskKey) {
        taskId = id;
        return true;
      }
      return false;
    });

    if (!taskId) {
      if (!this.state.taskFetched) {
        if (this.state.taskKey) {
          // NOTE: Prevent fetch loop, if something goes wrong
          this.setState({
            taskFetched: true
          }, () => {
            this.props.taskGetRequest(this.state.taskKey);
          });
        }
      }
    } else {
      this.setState({
        taskId: taskId
      });
    }
  };

  handleSubmit = (values, formikBag) => {
    const data = {
      owner: this.props.myId,
      phase: values.phase,
      title: values.title,
      description: values.description,
      media: values.media,
      links: values.links,
      task: this.state.taskId,
    };
    const task = this.props.tasksById[this.state.taskId];
    if (task) {
      const { id, taskKey, subTasks } = task;
      const appendType = "updateTask";
      const appendData = { appendType, id, taskKey, subTasks };
      this.props.subTaskPostRequest(data, formikBag, appendData);
    }
  };

  handleReset = () => {
    this.props.subTasksCancelRequest(duckTypes.SUBTASK_POST.group);
    this.props.history.push(routes.TASK_DETAILS.path.replace(':id', this.state.taskKey));
  };

  render() {
    const { classes, isAdmin, isInstructor, tasksById } = this.props;

    const taskReady = !R.isEmpty(tasksById[this.state.taskId]);

    return (
      <Paper elevation={0} className={classes.paper}>
        <SubTaskNewForm isAdmin={isAdmin} isInstructor={isInstructor} taskReady={taskReady}
                        phaseId={this.state.subTaskPhaseId} submitHandler={this.handleSubmit}
                        resetHandler={this.handleReset}/>
      </Paper>
    );
  }
}

export default withStyles(styles)(SubTaskNewComponent);