import React from 'react';
import AddIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import DownloadIcon from '@material-ui/icons/GetApp';
import Button from "@material-ui/core/Button/Button";
import Typography from '@material-ui/core/Typography';
import config from '../../utils/config';
import routes from "../../utils/routes";
import {DateTime} from "luxon";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {labelsMuiDatatables} from "../../utils/labels";
import RemovalDialog from "../common/RemovalDialog";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ShowMoreText from "../common/ShowMoreText";
import MUIDataTable from "mui-datatables";
import PortalToolbarMenu from '../common/navigation/PortalToolbarMenu';

class FilesComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      removalIds: [],
      removalSubject: '',
      removalDialogOpen: false
    };
  }

  componentDidMount() {
    this.initData();
  }

  initData = () => {
    this.props.filesGetRequest();
  };

  handleRoute = (evt, route) => {
    evt.preventDefault();
    if (route) {
      this.props.history.push(route);
    }
  };

  handleNew = () => {
    this.props.history.push(routes.FILE_NEW.path);
  };

  handleDownload = (evt, id) => {
    evt.preventDefault();
    if (id) {
      const filename = this.props.byId[id].filename;
      window.open(config.DOWNLOAD_URL + `/uploads/${filename}`);
    }
  };

  render() {
    const {isAdmin, isInstructor, byId, allIds} = this.props;

    const isApprentice = !isAdmin && !isInstructor;

    const portalToolbarMenuData = [
      {
        "title": "Neue Datei hinzufügen",
        "action": () => this.handleNew(),
        "icon": AddIcon,
        "visible": true,
        "disabled": false
      }
    ];

    const tableOptions = {
      selectableRows: 'multiple',
      elevation: 0,
      responsive: "stacked",
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 20, 50],
      textLabels: labelsMuiDatatables,
      customSort: (data, colIndex, order) => {
        return data.sort((a, b) => {
          let valA = a.data[colIndex];
          let valB = b.data[colIndex];
          // NOTE: timestamps on colIndex 0
          if (colIndex === 0) {
            valA = new Date(valA[1]); // NOTE: updatedAt on array-value index 1
            valB = new Date(valB[1]); // NOTE: updatedAt on array-value index 1
          }
          return (valA < valB ? -1 : 1) * (order === 'desc' ? -1 : 1);
        });
      },
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col !== null) {
            let cellContent = '';
            if (Array.isArray(col)) {
              col.forEach(index => {
                const value = col[index] || '';
                cellContent += `${value.toString().toLowerCase()} `;
              });
            } else {
              cellContent = col.toString();
            }
            if (cellContent.indexOf(searchQuery.toLowerCase()) >= 0) {
              isFound = true;
            }
          }
        });
        return isFound;
      },
      onRowsDelete: rowsDeleted => {
        const deleteIds = rowsDeleted.data.map(item => tableData[item.dataIndex][4]);
        if (deleteIds.length > 0) {
          this.setState({
            removalIds: deleteIds,
            removalSubject: deleteIds.length + ' Datei(en)',
            removalDialogOpen: true
          });
        }
        return false; // NOTE: No row delete here b/c deletion could be blocked for some files... rows will be deleted when file deletion was successful and thus file data is removed from redux
      },
    };

    const tableColumns = [{
      name: "timestamps",
      label: "Erstellt, geändert",
      options: {
        sortDirection: 'desc',
        customBodyRender: timestamps => {
          return (
            <React.Fragment>
              {DateTime.fromISO(timestamps[0]).setLocale('de').toLocaleString(DateTime.DATETIME_SHORT)}<br/>
              {DateTime.fromISO(timestamps[1]).setLocale('de').toLocaleString(DateTime.DATETIME_SHORT)}
            </React.Fragment>
          )
        }
      }
    }, {
      name: "meta",
      label: "Titel, Beschreibung",
      options: {
        sort: false,
        customBodyRender: meta => {
          return (
            <React.Fragment>
              {meta[0]}
              {meta[1] &&
              <React.Fragment>
                <br/>
                <ShowMoreText maxLength={64}>{meta[1]}</ShowMoreText>
              </React.Fragment>
              }
            </React.Fragment>
          )
        }
      }
    }, {
      name: "hidden",
      label: " ",
      options: {
        display: !isApprentice,
        sort: false,
        customBodyRender: hiddenFromApprentice => hiddenFromApprentice ? (
          <Tooltip title="Kein Zugriff für Auszubildende"
                   aria-label="Kein Zugriff für Auszubildende">
            <LockIcon color="action"/>
          </Tooltip>
        ) : (
          <Tooltip title="Ohne Beschränkung" aria-label="Ohne Beschränkung">
            <LockOpenIcon color="disabled"/>
          </Tooltip>
        )
      }
    }, {
      name: "filename",
      label: "Orig. Dateiname",
      options: {
        customBodyRender: id => {
          const item = byId[id];
          return (
            <React.Fragment>
              {item.originalFilename}
              {!item.fileExists &&
              <Typography variant="caption" display="block" color="error">Achtung: Datei
                fehlt</Typography>}
            </React.Fragment>
          );
        }
      }
    }, {
      name: "actions",
      label: " ",
      options: {
        sort: false,
        setCellProps: value => ({align: "right"}),
        customBodyRender: id => {
          const item = byId[id];
          return (
            <React.Fragment>
              <ButtonGroup color="primary">
                <Tooltip title="Bearbeiten" aria-label="Bearbeiten">
                  <Button
                    onClick={evt => this.handleRoute(evt, routes.FILE_EDIT.path.replace(':id', item.id))}>
                    <EditIcon/>
                  </Button>
                </Tooltip>
                {item.fileExists ? (
                  <Tooltip title="Download" aria-label="Download">
                    <Button onClick={evt => this.handleDownload(evt, item.id)}>
                      <DownloadIcon/>
                    </Button>
                  </Tooltip>
                ) : (
                  <Button disabled>
                    <DownloadIcon/>
                  </Button>
                )}
              </ButtonGroup>
            </React.Fragment>
          );
        }
      }
    }];

    const tableData = allIds.map(itemId => {
      const item = byId[itemId];
      if (!item) {
        return [];
      }
      const timestamps = [
        item.createdAt,
        item.updatedAt
      ];
      const meta = [
        item.title,
        item.description
      ];

      return [
        timestamps,
        meta,
        item.hiddenFromApprentice,
        item.id,
        item.id
      ];
    });

    return (
      <React.Fragment>
        <PortalToolbarMenu menuData={portalToolbarMenuData}/>
        <MUIDataTable title={"Alle Dateien"} options={tableOptions} columns={tableColumns}
                      data={tableData}/>
        <RemovalDialog
          open={this.state.removalDialogOpen}
          subject={this.state.removalSubject}
          onClose={remove => {
            if (remove === true) {
              this.state.removalIds.forEach(id => {
                this.props.fileDeleteRequest(id);
              });
            }
            this.setState({
              removalIds: [],
              removalSubject: '',
              removalDialogOpen: false
            });
          }}
        />
      </React.Fragment>
    );
  }
}

export default FilesComponent;
