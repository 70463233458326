import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper/Paper";
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    paper: {
        padding: theme.spacing(3),
    }
});

const HelpComponent = (props) => {

    const { classes } = props;

    return (
        <Paper elevation={0} className={classes.paper}>
            <Typography component="h2" variant="h4" gutterBottom>Hilfe</Typography>
            <Typography variant="body1" paragraph>...</Typography>
        </Paper>
    );
};

export default withStyles(styles)(HelpComponent);