import { connect } from "react-redux";
import { default as resultsOperations } from "../../redux/entities/resultsOperations";
import { default as subTasksOperations } from "../../redux/entities/subTasksOperations";
import { requestsSelectors } from "../../redux/entities/requests";
import { withRouter } from "react-router-dom";
import { entityKey as resultsSliceKey } from "../../redux/entities/resultsKeys";
import { entityKey as subTasksKey } from "../../redux/entities/subTasksKeys";
import { entityKey as usersSliceKey } from "../../redux/entities/usersKeys";

const mapStateToProps = (state) => {
  const isFetching = requestsSelectors.checkIfFetching(state.entities, resultsSliceKey) ||
    requestsSelectors.checkIfFetching(state.entities, subTasksKey) ||
    requestsSelectors.checkIfFetching(state.entities, usersSliceKey);

  const { errors, byId, allIds } = state.entities[resultsSliceKey];
  const resultsById = state.entities[resultsSliceKey].byId;
  const subTasksById = state.entities[subTasksKey].byId;
  const usersById = state.entities[usersSliceKey].byId;

  return {
    isFetching,
    errors,
    byId,
    allIds,
    resultsById,
    subTasksById,
    usersById
  };
};

export default (component) => withRouter(connect(mapStateToProps, {
  subTaskGetRequest: subTasksOperations.subTaskGetRequest,
  resultPutRequest: resultsOperations.resultPutRequest,
  resultGetRequest: (id) => resultsOperations.resultGetRequest(id),
  resultPostRequest: resultsOperations.resultPostRequest,
})(component));
