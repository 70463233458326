import { withRouter } from "react-router";
import { connect } from 'react-redux';
import FeedbackDialogComponent from './FeedbackDialogComponent';
import { otherOperations } from "../../redux/other";
import { uiEntityKeys, viewKeys, uiOperations } from '../../redux/ui';

const uiEntityKey = uiEntityKeys.views;
const viewKey = viewKeys.FEEDBACK.key;

const mapStateToProps = state => {
  const view = state.ui[uiEntityKey][viewKey];
  const { errors } = state.other;

  return {
    view,
    errors,
  };
};

const mapDispatchToProps = dispatch => {
  const feedbackPostRequest = (data, formikBag) => dispatch(otherOperations.feedbackPostRequest(data, formikBag));

  const otherCancelRequest = (cancelType, cancelMessage) => dispatch(otherOperations.otherCancelRequest(cancelType, cancelMessage));

  const viewChange = value => dispatch(uiOperations.viewChangeOperationGeneric(viewKey, value));

  return {
    feedbackPostRequest,
    otherCancelRequest,
    viewChange
  };
};

const FeedbackDialogContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FeedbackDialogComponent));

export default FeedbackDialogContainer;