import { withRouter } from "react-router";
import { connect } from 'react-redux';
import FieldsOfActionComponent from './FieldsOfActionComponent';
import { entityKey as fieldsOfActionSliceKey } from '../../redux/entities/fieldsOfActionKeys';
import { default as fieldsOfActionOperations } from '../../redux/entities/fieldsOfActionOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { isAdmin } = state.authentication;
  const { errors, byId, allIds } = state.entities[fieldsOfActionSliceKey];

  return {
    isAdmin,
    errors,
    byId,
    allIds
  };
};

const mapDispatchToProps = dispatch => {
  const fieldsOfActionGetRequest = () => dispatch(fieldsOfActionOperations.fieldsOfActionGetRequest());

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    fieldsOfActionGetRequest,
    notificationEnqueue
  };
};

const FieldsOfActionContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FieldsOfActionComponent));

export default FieldsOfActionContainer;