import React from 'react';
import { connect } from 'react-redux';
import { withSnackbar} from 'notistack';
import { notificationsOperations } from "../../redux/notifications";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

class Notifier extends React.Component {
    constructor(props) {
        super(props);

        this.displayed = [];
    }

    componentDidUpdate(prevProps) {
        if (this.props.notifications.length > prevProps.notifications.length) {
            this.updateNotifications();
        }
    }

    updateNotifications = () => {
        const { notifications, enqueueSnackbar, closeSnackbar } = this.props;

        notifications.forEach(notification => {
            if (this.displayed.indexOf(notification.key) === -1) {
                const { key, message, options } = notification;
                this.displayed.push(key);
                enqueueSnackbar(message, {
                    ...options,
                    key: key,
                    preventDuplicate: true,
                    action: key => (
                        <IconButton
                            onClick={() => closeSnackbar(key)}
                            color="inherit"
                            aria-label="Schließen"
                        >
                            <CloseIcon/>
                        </IconButton>
                    )
                });
                this.cleanupNotifications(key);
            }
        });
    };

    cleanupNotifications = key => {
        this.props.notificationRemove(key);
        const displayedCleaned = this.displayed.filter(k => k !== key);
        this.displayed = displayedCleaned;
    };

    render() {
        return null; // Renderless Component pattern
    }
}

const mapStateToProps = state => {
    return {
        notifications: state.notifications
    };
};

const mapDispatchToProps = dispatch => {
    const notificationRemove = key => dispatch(notificationsOperations.notificationRemove(key));

    return {
        notificationRemove
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Notifier));
