import React from 'react';

import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    width: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.paper,
  },
});

const NotificationList = ({classes, children}) => {
  return (
    <List className={classes.root}>
      {children}
    </List>
  );
};

export default withStyles(styles)(NotificationList);
