import React from "react";
import TextField from "@material-ui/core/TextField";

/**
 * loading data with pagination, sorting and search for mui data table
 */
class TableServerDataProvider {
  constructor() {
    this.initTableParameters();
    this.tableFilters = {};
  }

  initTableParameters() {
    this.tableParameters = {
      page: 0,
      pageSize: 10,
      sort: null,
      sortDirection: null,
      searchText: null,
    };
  }

  /**
   * should be called in componentDidMount
   * @param {function} dataGetFunction
   */
  init(dataGetFunction) {
    this.tableFilters = {};
    this.initTableParameters();
    this.dataGetFunction = dataGetFunction;
    this.loadData();
  }

  /**
   * should be called in render with tableOptions for mui data table
   * @param {object} tableOptions
   */
  bindToTableOptions(tableOptions) {
    tableOptions.onChangePage = (currentPage) => this.changeTablePage(currentPage);
    tableOptions.onColumnSortChange = (changedColumn, direction) => this.changeTableSort(changedColumn, direction);
    tableOptions.onSearchChange = (searchText) => this.changeTableSearchText(searchText);
    tableOptions.onChangeRowsPerPage = (numberOfRows) => this.changeTablePageSize(numberOfRows);
    tableOptions.onSearchClose = () => this.changeTableSearchText(null);
    if (!tableOptions.onFilterChange) {
      tableOptions.onFilterChange = (columnChanged, filterList) => this.changeTableFilter(columnChanged, filterList);
    }

    tableOptions.searchText = this.tableParameters.searchText;
    tableOptions.rowsPerPage = this.tableParameters.pageSize;
  }

  bindTableFilters(filterOptions, tableColumns) {
    tableColumns.forEach((column, index) => {
      if (!('options' in column)) {
        column.options = {};
      }

      if (column.name in filterOptions) {
        column.options.filter = false !== column.options.filter;

        if (!(column.name in this.tableFilters)) {
          this.tableFilters[column.name] = {
            index: index,
            values: [],
            options: filterOptions[column.name]
          };
        }

        if ('###date_from###' === this.tableFilters[column.name].options ||
          '###date_to###' === this.tableFilters[column.name].options) {
          this.creatDateFilterForColumn(column, this.tableFilters[column.name].options);
        } else {
          this.createStandardFilterListForColumn(column, filterOptions);
        }
      } else {
        column.options.filter = false;
      }
    });
  }

  createStandardFilterListForColumn(column, filterOptions) {
    // set previous selected values
    column.options.filterList = this.tableFilters[column.name].values.map(v => this.tableFilters[column.name].options[v]);
    if (!('filterOptions' in column.options)) {
      column.options.filterOptions = {};
    }
    column.options.filterOptions.names = Object.values(filterOptions[column.name]);
  }

  creatDateFilterForColumn(column, dateFilterKey) {
    const customDateFilter = (label, name, defaultValue) => React.createElement(TextField, {
      name: 'dateFilter' + name,
      label: label,
      InputLabelProps: {
        shrink: true,
        required: false
      },
      type: 'date',
      defaultValue: defaultValue,
      onChange: (evt) => {
        this.tableFilters[column.name].values = '' !== evt.target.value ? [evt.target.value] : [];
        this.loadData();
      }
    });

    column.options.filterType = 'custom';

    const dateFormatted = dateString => {
      return dateString ? new Date(dateString).toLocaleDateString("de-DE", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric" }) : '';
    };

    let dateSelectionLabel = 'Datum ' + ('###date_from###' === dateFilterKey ? 'von' : 'bis');
    if ('label' in column && column.label) {
      dateSelectionLabel = column.label;
    }
    column.options.filterOptions = {
      display: () => customDateFilter(dateSelectionLabel, column.name, null),
      logic: (date, filterValue) => {
        if (0 === filterValue.length) {
          return false;
        }

        const filterDateValue = new Date(filterValue);
        const dateValue = new Date(date);
        return dateValue < filterDateValue;
      }
    };

    column.options.customFilterListRender = v => 0 < v.length ? dateSelectionLabel + dateFormatted(v) : null;
    column.options.customBodyRender = (dateString) => <>{dateFormatted(dateString)}</>;
  }

  loadData() {
    const currentParameters = {
      page: this.tableParameters.page + 1,
      pageSize: this.tableParameters.pageSize,
    };

    if (null !== this.tableParameters.sort && null !== this.tableParameters.sortDirection) {
      currentParameters.sort = ("descending" === this.tableParameters.sortDirection ? "-" : "") + this.tableParameters.sort;
    }

    if (null !== this.tableParameters.searchText) {
      currentParameters.search = encodeURIComponent(this.tableParameters.searchText);
    }

    if (this.tableFilters) {
      for (const filterColumn in this.tableFilters) {
        if (this.tableFilters[filterColumn].values.length) {
          currentParameters[filterColumn] = this.tableFilters[filterColumn].values.join(',');
        }
      }
    }

    this.dataGetFunction(currentParameters);
  }

  /**
   * @param {number} currentPage
   */
  changeTablePage(currentPage) {
    if (currentPage !== this.tableParameters.page) {
      this.tableParameters.page = currentPage;
      this.loadData();
    }
  }

  /**
   * @param {number} currentPageSize
   */
  changeTablePageSize(currentPageSize) {
    if (currentPageSize !== this.tableParameters.pageSize) {
      this.tableParameters.pageSize = currentPageSize;
      this.tableParameters.page = 0;
      this.loadData();
    }
  }

  /**
   * @param {string} changedColumn
   * @param {string} direction
   */
  changeTableSort(changedColumn, direction) {
    if (direction !== this.tableParameters.sortDirection || changedColumn !== this.tableParameters.sort) {
      this.tableParameters.sort = changedColumn;
      this.tableParameters.sortDirection = direction;
      this.loadData();
    }
  }

  /**
   * @param {string|null} searchText
   */
  changeTableSearchText(searchText) {
    if (this.tableParameters.searchText !== searchText) {
      this.tableParameters.page = 0;
      this.tableParameters.searchText = searchText;
      setTimeout(() => {
        if (searchText === this.tableParameters.searchText) {
          this.loadData();
        }
      }, 1000);
    }
  }

  changeTableFilter(columnChanged, filterList) {
    if (null === columnChanged) {
      this.tableFilters = {};
      this.loadData();

      return;
    }
    if (null !== this.tableFilters) {
      const filterIndex = this.tableFilters[columnChanged].index;
      const currentTableFilters = this.tableFilters[columnChanged];

      this.tableFilters[columnChanged].values = filterList[filterIndex]
        .map(
          selectedFilterName => Object.entries(currentTableFilters.options).find(v => v[1] === selectedFilterName)[0]
        );

      this.loadData();
    }
  }
}

const withTableServerDataProvider = wrappedComponent => {
  const tableServerDataProvider = new TableServerDataProvider();

  return class extends React.Component {
    render() {
      const newProps = {
        ...this.props,
        tableServerDataProvider: tableServerDataProvider
      };

      return React.createElement(
        wrappedComponent,
        newProps
      );
    }
  };
};

export { withTableServerDataProvider, TableServerDataProvider };
