import types from './types';

const INITIAL_STATE = [];

const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case types.NOTIFICATION_ENQUEUE: {
    const hasDuplicate = state.some(notification => notification.message === action.payload.message);
    if (!hasDuplicate) {
      return [
        ...state,
        {
          ...action.payload
        }
      ];
    }
    return state;
  }
  case types.NOTIFICATION_REMOVE:
    return [].concat(state.filter(notification => notification.key !== action.payload));

  default:
    return state;
  }
};

export default notificationReducer;
