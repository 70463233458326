import React from "react";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import config from '../../utils/config';

const ListUsers = props => {
  const { ids, byId, filesById } = props;

  return (
    <List dense>
      {ids.map((id, index) => {
        const item = byId[id];

        if (!item) {
          return null;
        }

        const avatarUrl = item.avatar && filesById && filesById[item.avatar] ? config.DOWNLOAD_URL + `/uploads/${filesById[item.avatar].filename}` : null;

        return (
          <React.Fragment key={id}>
            {0 < index && <Divider variant="inset" component="li" />}
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                {avatarUrl ? (
                  <Avatar src={avatarUrl} alt={`${item.firstName} ${item.lastName}`} />
                ) : (
                  <Avatar>{item.firstName.charAt(0).toUpperCase()}{item.lastName.charAt(0).toUpperCase()}</Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={item.firstName + ' ' + item.lastName}
                secondary={item.username}
              />
            </ListItem>
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default ListUsers;
