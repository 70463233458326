import React from 'react';
import { Field, Form, Formik } from "formik";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ReactSelect from "../common/ReactSelect";
import taskStatus from "../../utils/taskStatus";
import FilesSelection from "../common/FilesSelectionContainer";
import LinksSelection from "../common/LinksSelectionContainer";
import RichTextField from '../common/RichTextField';
import config from '../../utils/config';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  wrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class TaskEditFormComponent extends React.Component {
    
    buildUsersSelectOptions = type => {
        const { apprentices, cooperators, filesById } = this.props;
        const users = type === 'cooperators' ? cooperators : apprentices;
        
        return Object.keys(users).map(itemId => {
            const item = users[itemId];
            const avatarUrl = item.avatar && filesById && filesById[item.avatar] ? config.DOWNLOAD_URL + `/uploads/${filesById[item.avatar].filename}` : null;

            return {
                value: item.id,
                label: `${item.firstName} ${item.lastName}`,
                avatarUrl: avatarUrl
            };
        }, []);
    }

  render() {
    const { classes, isAdmin, isInstructor, item, fieldsOfAction, submitHandler, resetHandler } = this.props;

    const fieldsOfActionOptions = Object.keys(fieldsOfAction).map(objId => {
      const obj = fieldsOfAction[objId];
      return {
        value: obj.id,
        label: obj.name
      };
    }, []);

    const apprenticesOptions = this.buildUsersSelectOptions('apprentices');

    const cooperatorsOptions = this.buildUsersSelectOptions('cooperators'); 

    let setId = '';
    let setTitle = '';
    let setFieldOfAction = '';
    let setDescription = '';
    let setApprentices = [];
    let setCooperators = [];
    let setStatus = null;
    let setMedia = [];
    let setLinks = [];
    if (item) {
      setId = item.id || '';
      setTitle = item.title || '';
      setFieldOfAction = item.fieldOfAction || '';
      setDescription = item.description || '';
      setApprentices = item.apprentices || [];
      setCooperators = item.cooperators || [];
      setStatus = item.status || null;
      setMedia = item.media || [];
      setLinks = item.links || [];
    }

    return (
      <Formik
        initialValues = {{
          id: setId,
          title: setTitle,
          fieldOfAction: setFieldOfAction,
          description: setDescription,
          apprentices: setApprentices,
          cooperators: setCooperators,
          status: setStatus,
          media: setMedia,
          links: setLinks
        }}
        enableReinitialize={true}
        validate = {values => {
          let errors = {};
          if ('' === values.title) {
            errors.title = 'Dies ist ein Pflichtfeld';
          }
          if ('' === values.fieldOfAction) {
            errors.fieldOfAction = 'Bitte Auswahl treffen';
          }
          return errors;
        }}
        onSubmit = {(values, formikBag) => {
          submitHandler(values, formikBag);
        }}>
        {({ status, values, touched, errors, setFieldValue, setFieldTouched, isSubmitting, handleSubmit, handleReset }) => (
          <Form
            autoComplete="off"
            onSubmit={evt => {
              evt.stopPropagation();
              handleSubmit(evt);
            }}
            onReset={evt => {
              evt.stopPropagation();
              handleReset(evt);
              resetHandler();
            }}
            className={classes.form}
          >
            {status &&
                            <FormHelperText error>{status}</FormHelperText>
            }
            <FormControl fullWidth margin="normal" error={!!errors.fieldOfAction && touched.fieldOfAction}>
              <ReactSelect name="fieldOfAction"
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                value={values.fieldOfAction}
                error={errors.fieldOfAction}
                touched={touched.fieldOfAction}
                options={fieldsOfActionOptions}
                placeholder="Aufgabenbereich auswählen..."
                label="Aufgabenbereich"
                isMulti={false}
                isClearable={true}/>
              {(!!errors.fieldOfAction && touched.fieldOfAction) &&
                                <FormHelperText>{errors.fieldOfAction}</FormHelperText>
              }
            </FormControl>
            <Field component={TextField}
              type="text"
              id="title"
              name="title"
              label="Titel"
              required
              fullWidth
              margin="normal"/>
            <Field component={RichTextField}
              type="text"
              id="description"
              name="description"
              label="Beschreibung"

              // maxLength={250}
              fullWidth
              margin="normal"/>
            {!item.master && <FormControl fullWidth margin="normal" error={!!errors.apprentices && touched.apprentices}>
              <ReactSelect name="apprentices"
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                value={values.apprentices}
                error={errors.apprentices}
                touched={touched.apprentices}
                options={apprenticesOptions}
                placeholder="Auszubildende auswählen..."
                label="Auszubildende"
                isMulti={true}
                isClearable={false}
                useAvatar={true}/>
              {(!!errors.apprentices && touched.apprentices) &&
                                <FormHelperText>{errors.apprentices}</FormHelperText>
              }
            </FormControl>}
            {!item.master && <FormControl fullWidth margin="normal" error={!!errors.cooperators && touched.cooperators}>
              <ReactSelect name="cooperators"
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                value={values.cooperators}
                error={errors.cooperators}
                touched={touched.cooperators}
                options={cooperatorsOptions}
                placeholder="Mitwirkende Ausbilder*innen auswählen..."
                label="Mitwirkende Ausbilder*innen"
                isMulti={true}
                isClearable={false}/>
              {(!!errors.cooperators && touched.cooperators) &&
                                <FormHelperText>{errors.cooperators}</FormHelperText>
              }
            </FormControl>}
            <Field component={FilesSelection}
              id="media"
              name="media"
              label="Dateien"
              value={values.media}
              onChange={setFieldValue}
            />
            <Field component={LinksSelection}
              id="links"
              name="links"
              label="Links"
              value={values.links}
              onChange={setFieldValue}
            />
            <FormControl fullWidth margin="normal" error={!!errors.status && touched.status}>
              <ReactSelect name="status"
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                value={values.status}
                error={errors.status}
                touched={touched.status}
                options={[{
                  value: 10,
                  label: taskStatus[10],
                  isDisabled: !isAdmin && !isInstructor
                }, {
                  value: 20,
                  label: taskStatus[20],
                  isDisabled: !isAdmin && !isInstructor
                }]}
                placeholder="Status auswählen..."
                label="Status"
                isMulti={false}
                isClearable={false}/>
              {(!!errors.status && touched.status) &&
                            <FormHelperText>{errors.status}</FormHelperText>
              }
            </FormControl>
            <div className={classes.wrapper}>
              <Button type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}>
                                Speichern
              </Button>
              {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress} />}
            </div>
            <div className={classes.wrapper}>
              <Button type="reset" color="primary">Abbrechen</Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(TaskEditFormComponent);
