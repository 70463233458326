import React from "react";
import AddIcon from "@material-ui/icons/PlaylistAdd";
import Button from "@material-ui/core/Button/Button";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  buttonContainer: {
    marginRight: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

class CustomToolbarSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedRows.data.length !== this.props.selectedRows.data.length) {
        this.checkMaxLength();
    }
  }

  checkMaxLength = () => {
    const { selectedRows, maxNumberOfItems, warningHandler } = this.props;
    const disabled = maxNumberOfItems && selectedRows.data.length > maxNumberOfItems;

    this.setState({
      disabled: disabled
    });

    if (disabled && warningHandler) {
      warningHandler(`Maximal ${maxNumberOfItems} erlaubt`);
    }
  }

  handleSelected = () => {
    const { selectedRows, displayData } = this.props;
    const ids = selectedRows.data.map(item => displayData[item.index].data[0]);
    this.props.setSelectedHandler(ids);
  };

  render() {
    const { classes } = this.props;
    const { disabled } = this.state;

    return (
      <div className={classes.buttonContainer}>
        <Button onClick={this.handleSelected} size="small" variant="contained" color="primary" className={classes.button} disabled={disabled}>
          <AddIcon className={classes.leftIcon} />
          Verwenden
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(CustomToolbarSelect);
