import React from 'react';
import {
  Avatar,
  Badge,
  Box,
  Grid,
  Typography,
  Divider,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tabs,
  Tab,
  withStyles
} from "@material-ui/core";
import {
  Edit as EditIcon,
  FileCopy as CopyIcon,
  Send as SendIcon,
  Delete as DeleteIcon,
  AssignmentTurnedIn as SubmitIcon,
  Archive as ArchiveIcon,
  Unarchive as UnArchiveIcon,
  StarHalf as RateIcon,
  Assessment as AssessmentIcon
} from "@material-ui/icons";
import SwipeableViews from 'react-swipeable-views';
import ReactHtmlParser from "react-html-parser";
import flavors from "../../redux/notifications/flavors";
import taskPhases from "../../utils/taskPhases";
import competenceCheckStatus from "../../utils/competenceCheckStatus";
import routes from "../../utils/routes";
import config from "../../utils/config";
import roles from "../../utils/roles";
import { compareByKey2Sort } from "../../utils/helper";
import ConfirmDialog from '../common/ConfirmDialog';
import RemovalDialog from '../common/RemovalDialog';
import SelectionDialog from '../common/SelectionDialog';
import ShowMoreHtml from "../common/ShowMoreHtml";
import PortalToolbarMenu from '../common/navigation/PortalToolbarMenu';
import { default as CompetenceSpecification } from "./CompetenceSpecificationContainer";
import { default as CompetenceRating } from "./CompetenceRatingContainer";
import axios from "axios";
import { getAuthHeader } from "../../utils/auth";
import { notificationsFlavors } from "../../redux/notifications";
import PdfDialog from "../common/PdfDialog";

const styles = theme => ({
  box: {
    padding: theme.spacing(2),
  },
  evalCounter: {
    marginTop: theme.spacing(1),
    padding: 0,

    '& li': {
      marginLeft: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  tabsHeader: {
    padding: theme.spacing(2, 2, 0),
  },
  tabsBox: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 2, 2),
    },
  },
  tabs: {
    backgroundColor: theme.palette.grey[50],
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    borderWidth: '0 0 1px',
    justifyContent: "center"
  },
  tabsScroller: {
    flexGrow: "0"
  },
  tabBadge: {
    margin: theme.spacing(1),
  },
  tabPanel: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(2),
  },
});

class CompetenceCheckComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copyDialogOpen: false,
      removalDialogOpen: false,
      assignDialogOpen: false,
      submitDialogOpen: false,
      reevalDialogOpen: false,
      archiveDialogOpen: false,
      unarchiveDialogOpen: false,
      competenceCheckId: null,
      tabIndex: 0,
      isCreatingPdf: false,
      showDownloadPdf: false,
      pdfDialogOpen: false,
    };
  }

  componentDidMount() {
    this.props.usersGetRequest();
    this.initData();
  }

  componentWillUnmount() {
    this.props.titleChange(null);
    this.props.parentRouteChange(null);
  }

  initData = () => {
    const { match } = this.props; // https://reacttraining.com/react-router/web/api/match
    if (match.params.id) {
      this.setState({
        competenceCheckId: match.params.id
      }, () => {
        this.props.competenceCheckGetRequest(this.state.competenceCheckId).then(() => {
          this.updateMeta();
        });
      });
    }
  };

  updateMeta = () => {
    const { byId } = this.props;
    const { competenceCheckId } = this.state;

    this.props.titleChange(null);
    this.props.parentRouteChange(null);

    if (!competenceCheckId) return;

    const item = byId[competenceCheckId];
    const isArchived = item && item.isArchived;
    const isMaster = item && item.apprentice === null;
    const parentRoute = isArchived ? routes.COMPETENCECHECKS_ARCHIVE : isMaster ? routes.COMPETENCECHECKS_TEMPLATES : routes.COMPETENCECHECKS;

    this.props.titleChange(item && item.title ? 'Kompetenzcheck: ' + item.title : null);
    this.props.parentRouteChange(parentRoute);
  };

  handleEdit = () => {
    const { competenceCheckId } = this.state;

    if (!competenceCheckId) return;

    this.props.history.push(routes.COMPETENCECHECK_EDIT.path.replace(':id', competenceCheckId));
  };

  handleRemove = () => {
    const { byId } = this.props;
    const { competenceCheckId } = this.state;

    if (!competenceCheckId) return;

    const item = byId[competenceCheckId];
    const isArchived = item && item.isArchived;
    const isMaster = item && item.apprentice === null;
    const redirectPath = isArchived ? routes.COMPETENCECHECKS_ARCHIVE.path : isMaster ? routes.COMPETENCECHECKS_TEMPLATES.path : routes.COMPETENCECHECKS.path;

    this.props.competenceCheckDeleteRequest(competenceCheckId).then(() => {
      this.props.notificationEnqueue('Der Kompetenzcheck wurde gelöscht.', flavors.SUCCESS);
      this.props.history.push(redirectPath);
    });
  };

  handleAssign = selectedItems => {
    const { competenceCheckId } = this.state;

    if (!competenceCheckId || !selectedItems || selectedItems.length === 0) return;

    selectedItems.forEach(assigneeId => {
      this.props.competenceCheckAssignRequest(competenceCheckId, assigneeId).then(() => {
        this.props.history.push(routes.COMPETENCECHECKS.path);
      });
    });
  };

  handleCopy = () => {
    const { competenceCheckId } = this.state;

    if (!competenceCheckId) return;

    this.props.competenceCheckCopyRequest(competenceCheckId);
    // NOTE: Operations redirects to detail view of copied check
  };

  handleSubmit = () => {
    const { competenceCheckId } = this.state;

    if (!competenceCheckId) return;

    this.props.competenceCheckSubmitRequest(competenceCheckId).then(() => {
      this.initData();
    });
  };

  handleReeval = () => {
    const { competenceCheckId } = this.state;

    if (!competenceCheckId) return;

    this.props.competenceCheckReopenRequest(competenceCheckId).then(() => {
      this.initData();
    });
  };

  handleArchive = () => {
    const { competenceCheckId } = this.state;

    if (!competenceCheckId) return;

    this.props.competenceCheckArchiveRequest(competenceCheckId).then(() => {
      this.initData();
    });
  };

  handleUnarchive = () => {
    const { competenceCheckId } = this.state;

    if (!competenceCheckId) return;

    this.props.competenceCheckUnarchiveRequest(competenceCheckId).then(() => {
      this.initData();
    });
  };

  handleTabChange = (evt, newValue) => {
    this.setState({
      tabIndex: newValue
    });
  };

  handleTabChangeIndex = index => {
    this.setState({
      tabIndex: index
    });
  };

  handleCreatePdf = async() => {
    const { competenceCheckId } = this.state;

    this.setState({
      isCreatingPdf: true
    });

    try {
      const response = await this.createPdfRequest(competenceCheckId);

      this.pdfDownloadUrl = response.data.result;

      this.setState({
        isCreatingPdf: false,
        showDownloadPdf: true,
      });
    } catch (e) {
      console.error(e);
      this.props.notificationEnqueue('PDF konnte nicht erstellt werden', notificationsFlavors.ERROR);
    }
  };

  createPdfRequest(competenceCheckId) {
    return axios
      .post(config.API_URL + `/competence-checks/${competenceCheckId}/pdf`, {
      }, {
        headers: getAuthHeader()
      });
  }

  downloadPdf() {
    this.setState({
      showDownloadPdf: false,
      pdfDialogOpen: false,
    });

    window.open(this.pdfDownloadUrl, '_blank');
  }

  render() {
    const { classes, myId, isInstructor, byId, usersById, filesById, competenceSpecificationsById, competenceEvaluationsById, competenceRatingsById } = this.props;
    const { competenceCheckId, copyDialogOpen, assignDialogOpen, removalDialogOpen, submitDialogOpen, reevalDialogOpen, archiveDialogOpen, unarchiveDialogOpen, tabIndex, pdfDialogOpen, isCreatingPdf, showDownloadPdf } = this.state;

    const item = byId[competenceCheckId];

    if (!item) {
      return null;
    }

    const { id, title, description, permissions, owner, apprentice, isArchived, otherEvaluators, activeApprenticeEvaluations,  activeInstructorEvaluations, submittedApprenticeEvaluations, submittedInstructorEvaluations, competenceSpecifications, competenceEvaluations } = item;

    const hasDescription = description && description.trim().length > 0;
    const canEdit = permissions.canEdit && !apprentice;
    const canDelete = permissions.canDelete && !apprentice;
    const canCreatePdf = permissions.canCreatePdf;
    const isCheckOwner = myId === owner;
    const isCheckApprentice = myId === apprentice;
    const canRate = apprentice && (isCheckOwner || isCheckApprentice || otherEvaluators.includes(myId));

    const apprenticesOptions = Object.keys(usersById)
      .filter(key => usersById[key].roles.includes(roles.APPRENTICE))
      .sort((a, b) => compareByKey2Sort(usersById[a], usersById[b], 'firstName'))
      .map(key => {
        const user = usersById[key];
        return {
          value: user.id,
          label: `${user.firstName} ${user.lastName}`
        };
      }, []);

    const competenceSpecificationsAllowed = (competenceSpecifications && competenceSpecifications.length > 0) ? competenceSpecifications : [];
    const competenceSpecificationsArray = Object.keys(competenceSpecificationsById)
      .filter(key => competenceSpecificationsAllowed.includes(key))
      .map(key => competenceSpecificationsById[key]);

    const competenceEvaluationsAllowed = (competenceEvaluations && competenceEvaluations.length > 0) ? competenceEvaluations : [];
    const competenceEvaluationsArray = Object.keys(competenceEvaluationsById)
      .filter(key => competenceEvaluationsAllowed.includes(key))
      .map(key => competenceEvaluationsById[key]);

    const activeEvaluations = competenceEvaluationsArray.filter(evaluation => !evaluation.finished);
    const myActiveEvaluations = activeEvaluations.filter(evaluation => evaluation.owner === myId);
    const myActiveEvaluationId = myActiveEvaluations[0] ? myActiveEvaluations[0].id : null;
    const allMyActiveEvalSpecsRated = Object.keys(competenceRatingsById).filter(id => competenceRatingsById[id].competenceEvaluation === myActiveEvaluationId).length === competenceSpecificationsArray.length;
    const submitCondition = myActiveEvaluationId !== null; 
    const reevalCondition = activeApprenticeEvaluations === 0 && activeInstructorEvaluations === 0 && submittedApprenticeEvaluations > 0 && submittedApprenticeEvaluations === submittedInstructorEvaluations; 

    let statusCode = 10;
    if (isArchived) {
      statusCode = 40;
    } else if (activeApprenticeEvaluations || activeInstructorEvaluations) {
      statusCode = 20;
    } else if (submittedApprenticeEvaluations > 0 && submittedApprenticeEvaluations === submittedInstructorEvaluations) {
      statusCode = 30;
    }

    const apprenticeUser = apprentice ? usersById[apprentice] : null;
    const apprenticeAvatarUrl = apprenticeUser && apprenticeUser.avatar && filesById && filesById[apprenticeUser.avatar] ? config.DOWNLOAD_URL + `/uploads/${filesById[apprenticeUser.avatar].filename}` : null;

    const ownerUser = owner ? usersById[owner] : null;
    const ownerAvatarUrl = ownerUser && ownerUser.avatar && filesById && filesById[ownerUser.avatar] ? config.DOWNLOAD_URL + `/uploads/${filesById[ownerUser.avatar].filename}` : null;
    // NOTE: Not dealing with otherEvaluators yet, b/c apparently not needed (for now?)

    const portalToolbarMenuData = [
      {
        "title": "Einreichen",
        "action": () => this.setState({ submitDialogOpen: true }),
        "icon": SubmitIcon,
        "visible": submitCondition,
        "disabled": !allMyActiveEvalSpecsRated
      }, {
        "title": "Neue Einschätzung",
        "action": () => this.setState({ reevalDialogOpen: true }),
        "icon": RateIcon,
        "visible": canRate && reevalCondition,
        "disabled": false
      }, {
        "title": "Bearbeiten",
        "action": () => this.handleEdit(),
        "icon": EditIcon,
        "visible": canEdit,
        "disabled": false
      }, {
        "title": "Zuordnen",
        "action": () => this.setState({ assignDialogOpen: true }),
        "icon": SendIcon,
        "visible": canEdit && competenceSpecificationsArray.length > 0,
        "disabled": false
      }, {
        "title": "Kopieren",
        "action": () => this.setState({ copyDialogOpen: true }),
        "icon": CopyIcon,
        "visible": canEdit,
        "disabled": false
      }, {
        "title": "Auswertung (PDF) erstellen",
        "action": () => this.setState({ pdfDialogOpen: true }),
        "icon": AssessmentIcon,
        "visible": canCreatePdf,
        "disabled": false
      }, {
        "title": "Archivieren",
        "action": () => this.setState({ archiveDialogOpen: true }),
        "icon": ArchiveIcon,
        "visible": !isArchived,
        "disabled": false
      }, {
        "title": "Archivierung aufheben",
        "action": () => this.setState({ unarchiveDialogOpen: true }),
        "icon": UnArchiveIcon,
        "visible": isArchived,
        "disabled": false
      }, {
        "title": "Löschen",
        "action": () => this.setState({ removalDialogOpen: true }),
        "icon": DeleteIcon,
        "visible": canDelete,
        "disabled": false
      }
    ];

    const renderToolbarMenu = () => {
      return (
        <React.Fragment>
          <PortalToolbarMenu menuData={portalToolbarMenuData}/>
          <ConfirmDialog
            open={submitDialogOpen}
            title={`Einschätzung einreichen`}
            content={`Möchten Sie die aktuelle Einschätzung einreichen?`}
            onClose={confirm => {
              this.setState({ submitDialogOpen: false });

              if (confirm === true) {
                this.handleSubmit();
              }
            }}
          />
          <ConfirmDialog
            open={reevalDialogOpen}
            title={`Neue Einschätzung starten`}
            content={`Möchten Sie eine neue Einschätzung starten?`}
            onClose={confirm => {
              this.setState({ reevalDialogOpen: false });

              if (confirm === true) {
                this.handleReeval();
              }
            }}
          />
          <ConfirmDialog
            open={archiveDialogOpen}
            title={`Kompetenzcheck archivieren`}
            content={`Möchten Sie den Kompetenzcheck "${title}" archivieren?`}
            onClose={confirm => {
              this.setState({ archiveDialogOpen: false });

              if (confirm === true) {
                this.handleArchive();
              }
            }}
          />
          <ConfirmDialog
            open={unarchiveDialogOpen}
            title={`Archivierung rückgängig machen`}
            content={`Möchten Sie die Archivierung des Kompetenzchecks "${title}" rückgängig machen?`}
            onClose={confirm => {
              this.setState({ unarchiveDialogOpen: false });

              if (confirm === true) {
                this.handleUnarchive();
              }
            }}
          />
          <ConfirmDialog
            open={copyDialogOpen}
            title={`Kompetenzcheck kopieren`}
            content={`Möchten Sie den Kompetenzcheck "${title}" kopieren?`}
            onClose={confirm => {
              this.setState({ copyDialogOpen: false });

              if (confirm === true) {
                this.handleCopy(id);
              }
            }}
          />
          <SelectionDialog
            open={assignDialogOpen}
            onClose={(success, selectedItems) => {
              this.setState({ assignDialogOpen: false });

              if (success && selectedItems) {
                this.handleAssign(selectedItems);
              }
            }}
            title="Kompetenzcheck zuordnen"
            description="Kompetenzcheck folgenden Auszubildenden zuordnen:"
            placeholder="Auszubildende auswählen..."
            label="Auszubildende"
            submitLabel="Zuordnen"
            isMulti={true}
            items={apprenticesOptions}
          />
          <RemovalDialog
            open={removalDialogOpen}
            subject={title}
            onClose={(remove) => {
              this.setState({ removalDialogOpen: false });

              if (remove === true) {
                this.handleRemove(id);
              }
            }}
          />
          <PdfDialog
            open={pdfDialogOpen}
            title={`Auswertung (PDF) erstellen`}
            content={`Möchten Sie eine Auswertung für den Kompetenzcheck "${title}" erstellen?`}
            onClose={() => {
              this.setState({
                pdfDialogOpen: false,
                showDownloadPdf: false,
                isCreatingPdf: false,
              });
            }}
            onCreate={() => {
              this.handleCreatePdf();
            }}
            onDownload={() => {
              this.downloadPdf();
            }}
            showDownload={showDownloadPdf}
            isCreatingPdf={isCreatingPdf}
          />
        </React.Fragment>   
      );
    };

    return (
      <React.Fragment>
        {renderToolbarMenu()}
        <Grid container>
          <Grid item xs={12} sm={12} md={8}>
            <Box className={classes.box}>
              <Typography component="h2" variant="overline" gutterBottom>Titel</Typography>
              <Typography component="h1" variant="h5" gutterBottom>{title ? title : '-'}</Typography>
              <Divider/>
              <Typography component="h2" variant="overline" gutterBottom>Bemerkung</Typography>
              {hasDescription ? (
                <ShowMoreHtml maxHeight={96}>
                  {ReactHtmlParser(description)}
                </ShowMoreHtml> 
              ) : (
                <Typography component="p" variant="body1" gutterBottom>-</Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box elevation={0} className={classes.box}>
              <Typography component="h2" variant="overline" gutterBottom>Status</Typography>
              <Typography component="p" variant="body1" gutterBottom>{competenceCheckStatus[statusCode] ? competenceCheckStatus[statusCode] : '-'}</Typography>
              <Divider/>
              <Typography component="h2" variant="overline" gutterBottom>Einschätzungen</Typography>  
              {apprenticeUser ? (
                <List disablePadding>
                  <ListItem alignItems="flex-start" disableGutters>
                    <ListItemAvatar>
                      {apprenticeAvatarUrl ? ( 
                        <Avatar src={apprenticeAvatarUrl} alt={`${apprenticeUser.firstName} ${apprenticeUser.lastName}`} />
                      ) : (
                        <Avatar>{apprenticeUser.firstName.charAt(0).toUpperCase()}{apprenticeUser.lastName.charAt(0).toUpperCase()}</Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={apprenticeUser.firstName + ' ' + apprenticeUser.lastName}
                      secondary={
                        <React.Fragment>
                          {apprenticeUser.username}
                          <ul className={classes.evalCounter}>
                            <Chip component="li" size="small" variant={activeApprenticeEvaluations > 0 ? 'default' : 'outlined'} avatar={<Avatar>{activeApprenticeEvaluations}</Avatar>} label="offen" />
                            <Chip component="li" size="small" variant={submittedApprenticeEvaluations > 0 ? 'default' : 'outlined'} avatar={<Avatar>{submittedApprenticeEvaluations}</Avatar>} label="eingereicht" />
                          </ul>
                        </React.Fragment>
                      }
                      secondaryTypographyProps={{component: 'div'}}
                    />
                  </ListItem>
                  {ownerUser &&
                    <ListItem alignItems="flex-start" disableGutters>
                      <ListItemAvatar>
                        {ownerAvatarUrl ? (
                          <Avatar src={ownerAvatarUrl} alt={`${ownerUser.firstName} ${ownerUser.lastName}`} />
                        ) : (
                          <Avatar>{ownerUser.firstName.charAt(0).toUpperCase()}{ownerUser.lastName.charAt(0).toUpperCase()}</Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={ownerUser.firstName + ' ' + ownerUser.lastName}
                        secondary={
                          <React.Fragment>
                            {ownerUser.username}
                            <ul className={classes.evalCounter}>
                              <Chip component="li" size="small" variant={activeInstructorEvaluations > 0 ? 'default' : 'outlined'} avatar={<Avatar>{activeInstructorEvaluations}</Avatar>} label="offen" />
                              <Chip component="li" size="small" variant={submittedInstructorEvaluations > 0 ? 'default' : 'outlined'} avatar={<Avatar>{submittedInstructorEvaluations}</Avatar>} label="eingereicht" />
                            </ul>
                          </React.Fragment>
                        }
                        secondaryTypographyProps={{component: 'div'}}
                      />
                    </ListItem>
                  }
                </List>
              ) : (
                <Typography component="p" variant="body1" gutterBottom>-</Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.tabsHeader}>
          <Divider/>
          <Typography component="h2" variant="overline" gutterBottom>{myActiveEvaluationId ? 'Kompetenzeinschätzung' : 'Kompetenzbeschreibungen'}</Typography>
        </Box>
        <Box className={classes.tabsBox}>
          <Tabs value={tabIndex}
            onChange={this.handleTabChange}
            aria-label="Kompetenzbeschreibungen"
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            classes={{
              root: classes.tabs,
              scroller: classes.tabsScroller // https://github.com/mui-org/material-ui/issues/10235#issuecomment-531505399
            }}
          >
            {[...Array(4)].map((e, i) => {
              const phaseId = i+1;
              return (
                <Tab key={`competenceSpecificationTab-${i}`} 
                id={`competenceSpecification-tab-${i}`} 
                label={taskPhases[phaseId].label} 
                icon={<Badge color="secondary" badgeContent={competenceSpecificationsArray.filter(obj => obj.phase === phaseId).length} className={classes.tabBadge}>
                {React.createElement(taskPhases[phaseId].icon)}
                </Badge>}
                aria-controls={`competenceSpecification-tabpanel-${i}`} />
              );
            })}
          </Tabs>
          <SwipeableViews
            index={tabIndex}
            onChangeIndex={this.handleTabChangeIndex}
          >
            {[...Array(4)].map((e, i) => {
              const phaseId = i+1;
              return (
                <Box key={`competenceSpecificationTabPanel-${i}`} id={`competenceSpecification-tabpanel-${i}`} role="tabpanel" aria-labelledby={`competenceSpecification-tab-${i}`} value={tabIndex} hidden={tabIndex !== i} className={classes.tabPanel}>
                  {competenceSpecificationsArray.filter(obj => obj.phase === phaseId).map(obj => {
                    return myActiveEvaluationId ? (
                      <CompetenceRating key={obj.id} isInstructor={isInstructor} item={obj} evaluationId={myActiveEvaluationId} />
                    ) : (
                      <CompetenceSpecification key={obj.id} isInstructor={isInstructor} item={obj} />
                    );
                  })}
                </Box>
              );
            })}
          </SwipeableViews>
        </Box>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CompetenceCheckComponent);
