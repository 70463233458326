import React from 'react';
import AddIcon from '@material-ui/icons/AddBox';
import Button from "@material-ui/core/Button/Button";
import routes from "../../utils/routes";
import * as R from "ramda";
import {labelsMuiDatatables} from "../../utils/labels";
import MUIDataTable from "mui-datatables";
import PortalToolbarMenu from '../common/navigation/PortalToolbarMenu';

class ProfessionsComponent extends React.Component {
    componentDidMount() {
        this.initData();
    }

    initData = () => {
        // TODO: isFetching needs to be fixed for requestsSelectors
        this.props.professionsGetRequest();
    };

    handleClick = (evt, route) => {
        evt.preventDefault();
        if (route) {
            this.props.history.push(route);
        }
    };

    handleNew = () => {
      this.props.history.push(routes.PROFESSION_NEW.path);
    };

    render() {
        const { byId, allIds, fieldsOfActionById } = this.props;

        const portalToolbarMenuData = [
          {
            "title": "Neuen Beruf anlegen",
            "action": () => this.handleNew(),
            "icon": AddIcon,
            "visible": true,
            "disabled": false
          }
        ];

        const tableOptions = {
            selectableRows: 'none',
            elevation: 0,
            responsive: "stacked",
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 20, 50],
            textLabels: labelsMuiDatatables,
        };
        const tableColumns = ["Schlüssel", "Berufsbezeichung", "Aufgabenbereiche", {
            options: {
                customBodyRender: (value) => {
                    return (
                        <Button href="#" onClick={evt => this.handleClick(evt, routes.PROFESSION_EDIT.path.replace(':id', value))} size="small" variant="outlined" color="primary">Bearbeiten</Button>
                    );
                }
            }
        }];
        const tableData = allIds.map(itemId => {
            const item = byId[itemId];
            let fieldsOfActions = '';
            if (item && item.fieldsOfAction.length > 0 && !R.isEmpty(fieldsOfActionById)) {
                let arr = [];
                item.fieldsOfAction.forEach(fieldOfActionId => {
                    const foa = fieldsOfActionById[fieldOfActionId];
                    if (foa && foa.name) {
                        arr.push(foa.name);
                    }
                });
                fieldsOfActions = arr.join(', ');
            }
            return (item) ? [item.taskKey, item.name, fieldsOfActions, item.id] : [];
        });

        return (
            <React.Fragment>
              <PortalToolbarMenu menuData={portalToolbarMenuData}/>
              <MUIDataTable title={"Alle Berufe"} options={tableOptions} columns={tableColumns} data={tableData}/>
            </React.Fragment>
        );
    }
}

export default ProfessionsComponent;
