import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { duckTypes } from "../../redux/entities/facilitiesKeys";
import { default as FacilityForm } from "./FacilityFormComponent";
import { responseFormikValidator } from "../../utils/validations";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: 640,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});

class FacilityNewComponent extends React.Component {
  handleSubmit = (values, formikBag) => {
    const data = {
      contactPerson: values.contactPerson || '',
      name: values.name || '',
      description: values.description || '',
      email: values.email || '',
      phone: values.phone || '',
      website: values.website || '',
      area: values.area || '',
      street: values.street || '',
      zip: values.zip || '',
      city: values.city || '',
      apprentice: this.props.myId,
      instructorName: values.instructorName
    };

    this.props.facilityPostRequest(data, formikBag).then((axiosResponse) => {
      responseFormikValidator(axiosResponse, formikBag);
    });
  };

  handleReset = () => {
    this.props.facilitiesCancelRequest(duckTypes.FACILITY_POST.group);
    this.props.history.goBack();
  };

  render() {
    const { classes, isInstructor, usersGetRequest, usersById } = this.props;

    return (
      <>
        <Paper elevation={0} className={classes.paper}>
          <FacilityForm
            submitHandler={this.handleSubmit}
            resetHandler={this.handleReset}
            isInstructor={isInstructor}
            usersGetRequest={usersGetRequest}
            usersById={usersById}
          />
        </Paper>
      </>
    );
  }
}

export default withStyles(styles)(FacilityNewComponent);