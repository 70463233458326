import { keys as genericKeys, type as genericType, action as genericAction } from '../generics';
import { uiEntityKeys } from './keys';

const { intents } = genericKeys;

const viewChangeOperationGeneric = (nameKey, value) => {
  return dispatch => {
    dispatch(genericAction(genericType(uiEntityKeys.views, nameKey, intents.CHANGE), value));
  };
};

const metaChangeOperationGeneric = (nameKey, value) => {
  return dispatch => {
    dispatch(genericAction(genericType(uiEntityKeys.meta, nameKey, intents.CHANGE), value));
  };
};

export default {
  viewChangeOperationGeneric,
  metaChangeOperationGeneric
};


