import React from 'react';
import { Field, Form, Formik } from "formik";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField } from 'formik-material-ui';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from "@material-ui/core/Grid";
import RichTextField from '../common/RichTextField';
import FormControl from "@material-ui/core/FormControl";
import ReactSelect from "../common/ReactSelect";
import { compareByKey2Sort } from "../../utils/helper";

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  wrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class FacilityFormComponent extends React.Component {
  componentDidMount() {
    this.initData();
  }

  initData() {
    this.props.usersGetRequest();
  }

  render() {
    const { classes, item, submitHandler, resetHandler, isInstructor, usersById } = this.props;

    let apprenticesOptions = Object.keys(usersById)
      .sort((a, b) => compareByKey2Sort(usersById[a], usersById[b], 'firstName'))
      .map(objId => {
        const obj = usersById[objId];
        return {
          value: obj.id,
          label: `${obj.firstName} ${obj.lastName}`
        };
      }, []);

    const setApprentices = item && item.apprentices && item.apprentices.length ? item.apprentices : [];

    return (
      <Formik
        initialValues={{
          contactPerson: (item && item.contactPerson) ? item.contactPerson : '',
          name: (item && item.name) ? item.name : '',
          description: (item && item.description) ? item.description : '',
          email: (item && item.email) ? item.email : '',
          phone: (item && item.phone) ? item.phone : '',
          website: (item && item.website) ? item.website : '',
          area: (item && item.area) ? item.area : '',
          street: (item && item.street) ? item.street : '',
          zip: (item && item.zip) ? item.zip : '',
          city: (item && item.city) ? item.city : '',
          instructorName: (item && item.instructorName) ? item.instructorName : '',
          apprentices: setApprentices,
        }}
        enableReinitialize={true}
        validate={values => {
          let errors = {};
          if (values.name === '') {
            errors.name = 'Dies ist ein Pflichtfeld';
          }
          if (values.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Keine gültige E-Mail Adresse';
          }
          if (values.website && !/^.*?\.[A-Z]{2,}$/i.test(values.website)) {
            errors.website = 'Keine gültige Website';
          }
          if (values.zip && !/^[0-9]{5}$/i.test(values.zip)) {
            errors.zip = 'Keine gültige Postleitzahl';
          }
          return errors;
        }}
        onSubmit={(values, formikBag) => {
          submitHandler(values, formikBag);
        }}>
        {({ status, values, touched, errors, isSubmitting, handleSubmit, handleReset, setFieldValue, setFieldTouched }) => (
          <Form
            autoComplete="off"
            onSubmit={evt => {
              evt.stopPropagation();
              handleSubmit(evt);
            }}
            onReset={evt => {
              evt.stopPropagation();
              handleReset(evt);
              resetHandler();
            }}
            className={classes.form}
          >
            {status &&
            <FormHelperText error>{status}</FormHelperText>
            }
            <Field component={TextField}
                   type="text"
                   id="name"
                   name="name"
                   label="Name"
                   required
                   fullWidth
                   margin="normal"/>
            <Field component={RichTextField}
                   type="text"
                   id="description"
                   name="description"
                   label="Beschreibung"
              // maxLength={250}
                   fullWidth
                   margin="normal"/>
            <Field component={TextField}
                   type="text"
                   id="contactPerson"
                   name="contactPerson"
                   label="Ansprechpartner"
                   margin="normal"/>
            <Field component={TextField}
                   type="text"
                   id="instructorName"
                   name="instructorName"
                   label="Ausbilder*in"
                   fullWidth
                   margin="normal"/>
            <Field component={TextField}
                   type="email"
                   id="email"
                   name="email"
                   label="E-Mail Adresse"
                   autoComplete="email"
                   fullWidth
                   margin="normal"/>
            <Field component={TextField}
                   type="tel"
                   id="phone"
                   name="phone"
                   label="Telefon"
                   fullWidth
                   margin="normal"/>
            <Field component={TextField}
                   type="text"
                   id="website"
                   name="website"
                   label="Website"
                   fullWidth
                   margin="normal"/>
            <Field component={TextField}
                   type="text"
                   id="area"
                   name="area"
                   label="Gebietsbezeichnung"
                   fullWidth
                   margin="normal"/>
            <Field component={TextField}
                   type="text"
                   id="street"
                   name="street"
                   label="Straße"
                   fullWidth
                   margin="normal"/>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Field component={TextField}
                       inputProps={{ maxLength: 5 }}
                       type="text"
                       id="zip"
                       name="zip"
                       label="PLZ"
                       fullWidth
                       margin="normal"/>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field component={TextField}
                       type="text"
                       id="city"
                       name="city"
                       label="Ort"
                       fullWidth
                       margin="normal"/>
              </Grid>
              <Grid item xs={12} sm={8}>
                {isInstructor &&
                <FormControl fullWidth margin="normal" error={!!errors.apprentices && touched.apprentices}>
                  <ReactSelect name="apprentices"
                               onChange={setFieldValue}
                               onBlur={setFieldTouched}
                               error={errors.apprentices}
                               touched={touched.apprentices}
                               value={values.apprentices}
                               options={apprenticesOptions}
                               placeholder="Auszubildende auswählen..."
                               label="Auszubildende"
                               isMulti={true}
                               isClearable={false}/>
                  {(!!errors.apprentices && touched.apprentices) &&
                  <FormHelperText>{errors.apprentices}</FormHelperText>
                  }
                </FormControl>
                }
              </Grid>
            </Grid>
            <div className={classes.wrapper}>
              <Button type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}>
                Speichern
              </Button>
              {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress}/>}
            </div>
            <div className={classes.wrapper}>
              <Button type="reset" color="primary">Abbrechen</Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(FacilityFormComponent);
