import { schema } from "normalizr";
import { requestsOperations } from "./requests";
import { entityKey as filesSliceKey } from "./filesKeys";
import { default as filesOperations } from "./filesOperations";
import { entityKey as linksSliceKey } from "./linksKeys";
import { default as linksOperations } from "./linksOperations";
import { entityKey as usersSliceKey } from "./usersKeys";
import { default as usersOperations } from "./usersOperations";
import { entityKey, duckTypes } from "./resultsKeys";

const resultSchema = new schema.Entity(entityKey);
const resultListSchema = new schema.Array(resultSchema);

const fetchRelations = [
  {
    relationResponseKey: "media",
    relationSliceKey: filesSliceKey,
    operation: filesOperations.filesByResultGetRequest,
    noRefetch: false,
  }, {
    relationResponseKey: "links",
    relationSliceKey: linksSliceKey,
    operation: linksOperations.linksByResultGetRequest,
    noRefetch: false
  }, {
    relationResponseKey: "owner",
    relationSliceKey: usersSliceKey,
    operation: usersOperations.usersByResultGetRequest,
    noRefetch: false,
    isSingle: true,
  }
];

const listGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.RESULTS_GET,
    requestPath: url,
    clearEntityData: true,
    normalizeSchema: resultListSchema,
    fetchRelations: fetchRelations
  };
  return requestsOperations.requestsOperationGeneric(null, settings);
};

const resultsGetRequest = () => listGetRequest("/results");

const associationsGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.RESULTS_GET,
    requestPath: url,
    clearEntityData: false,
    normalizeSchema: resultListSchema,
    fetchRelations: [],
    requestCancelling: false,
  };
  return requestsOperations.requestsOperationGeneric(null, settings);
};

const resultsBySubTaskGetRequest = (subTaskId) => associationsGetRequest(`/subtasks/${subTaskId}/results`);

const resultGetRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.RESULT_GET,
    requestPath: `/results/${id}`,
    idParamRequired: true,
    normalizeSchema: resultSchema,
    fetchRelations: fetchRelations,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const resultPutRequest = (id, data, formikBag) => {
  const params = {
    id: id,
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.RESULT_PUT,
    requestType: "put",
    requestPath: `/results/${id}`,
    idParamRequired: true,
    normalizeSchema: resultSchema
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const resultPostRequest = (data, formikBag) => {
  const params = {
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.RESULT_POST,
    requestType: "post",
    requestPath: "/results",
    normalizeSchema: resultSchema
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const resultDeleteRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.RESULT_DELETE,
    requestType: "delete",
    requestPath: `/results/${id}`,
    idParamRequired: true,
    normalizeSchema: resultSchema
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const resultsCancelRequest = (cancelType, cancelMessage) => {
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsCancellation(entityKey, cancelType, cancelMessage));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

export default {
  resultsGetRequest,
  resultGetRequest,
  resultPutRequest,
  resultPostRequest,
  resultDeleteRequest,
  resultsCancelRequest,
  resultsBySubTaskGetRequest
};
