import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper/Paper";
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    paper: {
        padding: theme.spacing(3),
    }
});

const TermsComponent = (props) => {

    const { classes } = props;

    return (
        <Paper elevation={0} className={classes.paper}>
            <Typography component="h2" variant="h4" gutterBottom>
                Einwilligungserklärung in die Verarbeitung personenbezogener Daten
            </Typography>
            <Typography variant="body1" paragraph>Fassung vom 20. Dezember 2014</Typography>
            <Typography variant="body1" paragraph>Wir danken Ihnen für Ihr Interesse an einer Unterstützung des Forschungs- und Entwicklungsprojekts „Kompetenzwerkstatt – Mein Beruf“.</Typography>
            <Typography variant="body1" paragraph>Für die Nutzung der unter der Domain</Typography>
            <Typography variant="subtitle1" align="center" paragraph>
                <strong>www.aufgaben-manager.de</strong>
            </Typography>
            <Typography variant="body1" paragraph>angebotenen Dienste ist es erforderlich, personenbezogene Daten zu verarbeiten. Eine Verarbeitung solcher Daten ist dann zulässig, wenn der Betroffene schriftlich eingewilligt hat. Wir bitten daher um die Unterzeichnung dieser Einwilligungserklärung.</Typography>
            <Typography variant="body1" paragraph>Die wichtigsten Eckpunkte der Einwilligungserklärung sind wie folgt:</Typography>
            <Typography component="ul" variant="body1" paragraph>
                <li>Die Daten der Nutzer werden ausschließlich für die Umsetzung des Dienstes selber, sowie für Forschungszwecke verwendet. Eine weitere kommerzielle Verwendung der Daten und eine Weitergabe an Dritte findet nicht statt.</li>
                <li>Es können neben dem Namen, der Anschrift und der E-Mailadresse weitere Angaben in einem Profil abgelegt werden.</li>
                <li>Die Daten können zur weiteren Verwendung vom Nutzer in einem offenen Datenformat exportiert werden.</li>
                <li>Zugriff auf die Informationen haben neben dem Nutzer auch die beteiligten Wissenschaftler.</li>
                <li>Der Nutzer kann jederzeit die Löschung seiner Daten beantragen. Dies kann formlos per Mail oder Kontaktformular erfolgen.</li>
                <li>Im („beta“-)Testbetrieb sind die Daten einem erhöhten Risiko durch IT-Sicherheitsvorfälle ausgesetzt, hierzu zählen insbesondere auch das Risiko des Datenverlustes. Es existieren aber bereits zu diesem Zeitpunkt zu weiten Teilen dem Stand der Technik entsprechende Schutzmechanismen, dennoch wird dem Unterzeichner eine Sicherung der Daten ausdrücklich empfohlen.</li>
                <li>Die Datenbestände werden auch für wissenschaftliche Zwecke ausgewertet und stehen hierfür auch nach Ende der Projektlaufzeit weiter zur Verfügung.</li>
            </Typography>
            <Typography variant="body1" paragraph>Die Nutzung der Dienste und die Unterstützung des Forschungsprojektes erfolgt freiwillig. Diese Einwilligungserklärung kann jederzeit und ohne Angabe von Gründen widerrufen werden.</Typography>
            <Typography variant="body1" paragraph>Hiermit willige ich in die Verarbeitung meiner personenbezogenen Daten durch das</Typography>
            <Typography variant="body1" paragraph>Institut Technik und Bildung, Abt. Arbeitsorientierte Bildungsprozesse, Universität Bremen, Am Fallturm 1, 28359 Bremen</Typography>
            <Typography variant="body1" align="center" paragraph>nachfolgend: “datenverarbeitende Stelle” -</Typography>
            <Typography variant="body1" paragraph>im Rahmen des Projektes „Kompetenzwerkst@tt – Mein Beruf“ unter der Domain</Typography>
            <Typography variant="body1" align="center" paragraph><strong>www.aufgaben-manager.de</strong></Typography>
            <Typography variant="body1" paragraph>unter den nachstehenden Bedingungen ein:</Typography>
            <Typography component="h3" variant="h5" gutterBottom>1. Aufgaben-Manager: Gegenstand, Inhalt, Umfang und Zwecke der erlaubten Verarbeitung</Typography>
            <Typography variant="body1" paragraph>Der/die Unterzeichnende willigt in die Verarbeitung seiner/ihrer personenbezogener Daten im Rahmen des folgenden Dienstes ein:</Typography>
            <Typography component="ul" variant="body1" paragraph>
                <li>Der Aufgaben-Manager unterstützt Ausbilder/innen und Lehrkräften bei der Erstellung von Lern- und Arbeitsaufgaben. Schritt für Schritt entwickeln diese aus einer Idee eine arbeitsprozessorientierte Aufgabe; digital vorliegende Unterrichts- und Arbeitsmaterialien lassen sich dabei problemlos einbinden. Mit dem Aufgaben-Manager können Ausbilder/innen und Lehrkräften auch im Team arbeiten und Aufgaben untereinander austauschen. Die Lern- und Arbeitsaufgaben verteilen Ausbilder/innen und Lehrkräften online an Auszubildende. Der Aufgaben-Manager bietet somit eine ideale Umgebung für die Entwicklung, Nutzung und Verwaltung von Lern- und Arbeitsaufgaben.</li>
                <li>Der Aufgaben-Manager befördert damit den seit langem bewährten didaktischen Ansatz zur Unterstützung arbeitsprozessorientierten Lernens mit Hilfe von Lern- und Arbeitsaufgaben. Diese werden aus betrieblichen Arbeitsaufgaben gewonnen; die Bezeichnung signalisiert, dass Lernen und Arbeiten verknüpft und systematisch aufeinander bezogen sind. Mit der damit verbundenen Rückverlagerung des Lernens in betriebliche Arbeitsprozesse betonen Lern- und Arbeitsaufgaben zugleich den Zusammenhang zwischen Berufsbildung und Arbeitswelt. Das Bildungs- und Qualifizierungspotenzial der Arbeitswirklichkeit wird für berufliches Lernen genutzt, Ausbildungsinhalte aus Betrieb und Berufsschule lassen sich unmittelbar aufeinander beziehen.</li>
            </Typography>
            <Typography variant="body1" paragraph>Begleitend zur Bereitstellung der vorgenannten Dienste erfolgt eine Evaluation und Weiterentwicklung im Rahmen wissenschaftlicher Forschungstätigkeit, in deren Rahmen auch die anfallenden Daten der/des Unterzeichnenden ausgewertet werden können. Im Einzelnen ist dabei etwa an folgende Forschungsaspekte zu denken:</Typography>
            <Typography component="ul" variant="body1" paragraph>
                <li>Weiterentwicklung der Software und des wissenschaftlichen Konzeptes anhand folgender Aspekte: Infrastruktur, Usability, Motivation, Ausbildungskonformität, Reflexionsqualität, Ausbildungsqualität</li>
                <li>Wie lässt sich das didaktisch-methodische Konzept der Lern- und Arbeitsaufgaben zur Unterstützung einer arbeitsprozessorientierten Ausbildung weiterentwickeln?</li>
                <li>Wo liegen weitere Potentiale und wo zeigen sich Grenzen softwaregestützter Lehr-Lernkonzepte in der beruflichen Bildung?</li>
                <li>Wie lässt sich der Einsatz digitaler Tools in der beruflichen Bildung fördern und welche Einsatzmöglichkeiten ergeben sich an den unterschiedlichen Lernorten?</li>
                <li>Welche Möglichkeiten ergeben sich zur Weiterentwicklung mediengestützter Lernsysteme in der beruflichen Bildung zu „Persönlichen Lernumgebungen“?</li>
                <li>Für die Evaluation und Weiterentwicklung werden die Zugriffe auf die Nutzeraccounts des Aufgaben-Managers auf der Grundlage anonymisierter Protokolldaten (s.u. 2.) herangezogen. Hierbei geht es darum, die Wege in der Applikation nachzuvollziehen und Schlüsse zu ziehen, die auf häufige und wenig genutzte Funktionen in der Applikation hindeuten.</li>
            </Typography>
            <Typography component="h3" variant="h5" gutterBottom>2. Art der Daten</Typography>
            <Typography variant="body1" paragraph>Die/der Unterzeichnende kann folgende Daten im System hinterlegen:</Typography>
            <Typography component="ul" variant="body1" paragraph>
                <li>Basisdaten („Ihr Profil“):</li>
                <Typography component="ul" variant="body1" paragraph>
                    <li>Vorname, Nachname, E-Mail-Adresse, Institution, Anschrift</li>
                    <li>Angaben zum Team</li>
                    <li>Angaben zu Räumen</li>
                    <li>Angaben zu Klassen</li>
                    <li>Angaben zu Berufen, die ausgebildet werden</li>
                    <li>Selbstgewählte Passwörter werden nicht dauerhaft gespeichert, sondern nur als Prüfsumme im System hinterlegt.</li>
                </Typography>
                <li>Eigene Medien („Mediathek“):</li>
                <Typography component="ul" variant="body1" paragraph>
                    <li>beliebige eigene Dateien</li>
                </Typography>
                <li>Daten zur Gestaltung von Lern- und Arbeitsaufgaben einschl. eigener Medien (siehe Eigene Medien (Mediathek)</li>
            </Typography>
            <Typography variant="body1" paragraph>Darüber hinaus werden vom System folgende Daten automatisch generiert bzw. protokolliert:</Typography>
            <Typography component="ul" variant="body1" paragraph>
                <li>Für die Applikation kommt ein so genannter Session-Cookie zum Einsatz. Dies ist eine kleine Datei, die unter anderem eine eindeutige Kennzahl enthält und die auf dem Computer der/des Unterzeichnenden gespeichert wird. Sie wird bei jedem Zugriff auf den Dienst übermittelt, um die/den Unterzeichnenden durch den Dienst wieder zu erkennen (zu authentifizieren).</li>
                <li>Auf der Profilseite wird ein weiterer Cookie eingesetzt, der gespeichert wird und dem Dienst mitteilt, welchen Inhalt der/die Unterzeichnende ausgewählt hatte, wenn er/sie zwischen Ansichts- und Bearbeitungsmodus hin- und herschaltet. Diese Cookies können durch den Unterzeichnenden direkt über seinen Browser gelöscht werden.</li>
                <li>Der datenverarbeitenden Stelle werden im Rahmen der Nutzung durch den/die Unterzeichnenden neben seiner oder ihrer IP-Adresse auch Angaben zum eingesetzten Browser und dessen Version, sowie der aufgerufenen Seite übermittelt. Diese Daten werden mit Ausnahme der IP-Adresse in einer Protokolldatei hinterlegt. Die Speicherung der IP-Adresse wird durch Einsatz eines entsprechenden Moduls («mod_removeip») unterbunden, so dass die Protokolldateien im Regelfall nicht mehr auf die/den Unterzeichnende/n zurückgeführt werden können.</li>
            </Typography>
            <Typography component="h3" variant="h5" gutterBottom>3. Empfänger der Daten</Typography>
            <Typography variant="body1" paragraph>Der/die Unterzeichnende wird darauf hingewiesen und willigt ein, dass die personenbezogenen Daten jeweils gemäß den jeweiligen Berechtigungen von folgenden Personengruppen eingesehen werden können:</Typography>
            <Typography component="ul" variant="body1" paragraph>
                <li>Administratoren und am Projekt beteiligte Wissenschaftler</li>
            </Typography>
            <Typography variant="body1" paragraph>Darüber hinaus kann die datenverarbeitende Stelle einen Dienstleister für die Bereitstellung der Dienste im Internet beauftragen (Hosting).</Typography>
            <Typography component="ul" variant="body1" paragraph>
                <li>Es ist derzeit technisch nicht auszuschließen, dass Mitarbeiter dieses Dienstleisters auch auf die Datenbestände der Kompetenzwerkstatt zugreifen können. Es werden allerdings entsprechende rechtliche, technische und organisatorische Maßnahmen ergriffen, um das Risiko unbefugter Zugriffe auf ein Minimum zu reduzieren.</li>
                <li>Der jeweils beauftragte Hosting-Dienstleister wird der/dem Unterzeichnenden auf Verlangen mitgeteilt.</li>
                <li>Zum Zeitpunkt des Erstellung dieser Einwilligungserklärung erfolgt das Hosting durch die Firma domainfactory GmbH, Oskar-Messter-Str. 33, 85737 Ismaning, Deutschland.</li>
            </Typography>
            <Typography component="h3" variant="h5" gutterBottom>4. Testbetrieb</Typography>
            <Typography component="ul" variant="body1" paragraph>
                <li>Während der ersten Projektphase ist ein Testbetrieb vorgesehen, in dessen Rahmen das IT-Sicherheitskonzept noch nicht vollständig entwickelt ist. In dieser Phase besteht ein erhöhtes Risiko für Störungen und IT-Sicherheitsvorfälle im Betrieb. Insbesondere kann ein Datenverlust trotz regelmäßiger Sicherungskopien nicht völlig ausgeschlossen werden. Der Unterzeichner sollte die eingestellten Daten daher zur Sicherheit auch bei sich speichern, etwa über den den Abruf des Ausbildungsnachweises.</li>
                <li>Der Unterzeichner willigt ausdrücklich auch in die Verarbeitung im Rahmen dieser Projektphase ein.</li>
                <li>Der Testbetrieb ist mit der Markierung „beta“ auf den Webseiten des Projektes unter www.auufgaben-manager.de deutlich kenntlich gemacht.</li>
                <li>Während der Testphase kann es zu Ausfällen des Systems aufgrund von Wartungen kommen, die aber spätestens innerhalb von 48 Stunden behoben sein sollten.</li>
            </Typography>
            <Typography component="h3" variant="h5" gutterBottom>5. Dauer der Aufbewahrung</Typography>
            <Typography component="ul" variant="body1" paragraph>
                <li>Sämtliche Daten werden – sofern kein vorheriger Widerruf erfolgt (s.u.) - für die Projektlaufzeit bis Ende Dezember 2014 gespeichert.</li>
                <li>Nach dem Ende der Projektlaufzeit ist eine Fortsetzung der Dienste sichergestellt.</li>
                <li>Der Nutzer kann die Löschung jederzeit formlos per Mail oder Kontaktformular beantragen. Die Löschung wird nach Beantragung binnen 14 Tagen vollständig durchgeführt sein.</li>
                <li>Für wissenschaftliche Zwecke kann eine längere Speicherung erfolgen, hierzu s.u.</li>
            </Typography>
            <Typography component="h3" variant="h5" gutterBottom>6. Verwendung für Wissenschaftliche Zwecke</Typography>
            <Typography component="ul" variant="body1" paragraph>
                <li>Den Regeln des Bremischen Datenschutzgesetzes (BremDSG) folgend, können die Daten nach Projektende oder auch nach Widerruf für wissenschaftliche Zwecke durch die datenverarbeitende Stelle weiterverwendet werden, soweit die schutzwürdigen Interessen des Unterzeichners dadurch nicht verletzt werden.</li>
                <li>Soweit es der Forschungszweck gestattet, werden die Daten anonymisiert.</li>
                <li>Das Forschungskonzept kann auf Anfrage zur Verfügung gestellt werden.</li>
            </Typography>
            <Typography component="h3" variant="h5" gutterBottom>7. Widerruf</Typography>
            <Typography component="ul" variant="body1" paragraph>
                <li>Ein Widerruf dieser Einwilligungserklärung kann jederzeit erfolgen.</li>
                <li>
                    Er sollte schriftlich gerichtet sein an das:
                    Institut Technik und Bildung, Abt. Arbeitsorientierte Bildungsprozesse, Universität Bremen<br/>
                    - Prof. Dr. Falk Howe -<br/>
                    Am Fallturm 1<br/>
                    28359 Bremen <br/>
                    Telefax: 0421/218 98 66250 <br/>
                    Mail: howe@uni-bremen.de<br/>
                </li>
            </Typography>
            <Typography component="h3" variant="h5" gutterBottom>8. Berichtigung</Typography>
            <Typography variant="body1" paragraph>Der/die Unterzeichnende kann jederzeit die Berichtigung unrichtiger Daten verlangen.</Typography>
            <Typography component="h3" variant="h5" gutterBottom>9. Datenschutzbeauftragte</Typography>
            <Typography variant="body1" paragraph>Die/der Unterzeichnende hat selbstverständlich jederzeit das Recht, sich an die/den zuständigen behördliche/n Datenschutzbeauftragte/n zu wenden.</Typography>
        </Paper>
    );
};

export default withStyles(styles)(TermsComponent);