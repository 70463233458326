import React from 'react';
import { Field, Form, Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import {TextField} from "formik-material-ui";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    wrapper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

class PasswordRecoveryFormComponent extends React.Component {
    render() {

        const { classes, submitHandler, resetHandler } = this.props;

        return (
            <Formik
                initialValues = {{
                    email: ''
                }}
                validate = {values => {
                    let errors = {};
                    if (!values.email) {
                        errors.email = 'Dies ist ein Pflichtfeld';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Keine gültige E-Mail Adresse';
                    }
                    return errors;
                }}
                validateOnChange = {false}
                onSubmit = {(values, formikBag) => {
                    submitHandler(values, formikBag);
                }}>
                {({ status, isSubmitting, handleSubmit, handleReset }) => (
                    <Form
                        autoComplete="off"
                        onSubmit={evt => {
                            evt.stopPropagation();
                            handleSubmit(evt);
                        }}
                        onReset={evt => {
                            evt.stopPropagation();
                            handleReset(evt);
                            resetHandler();
                        }}
                        className={classes.form}
                    >
                        {status &&
                            <FormHelperText error>{status}</FormHelperText>
                        }
                        <Field component={TextField}
                            type="email"
                            id="email"
                            name="email"
                            label="E-Mail Adresse"
                            autoComplete="email"
                            required
                            fullWidth
                            margin="normal"/>
                        <div className={classes.wrapper}>
                            <Button type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}>
                                Neues Passwort anfordern
                            </Button>
                            {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress} />}
                        </div>
                        <div className={classes.wrapper}>
                            <Button type="reset" color="primary">Abbrechen</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withStyles(styles)(PasswordRecoveryFormComponent);
