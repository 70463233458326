import React from 'react';
import { Field, Form, Formik } from "formik";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField } from 'formik-material-ui';
import FormHelperText from '@material-ui/core/FormHelperText';
import RichTextField from '../common/RichTextField';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  wrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class CompetenceCheckFormComponent extends React.Component {
  render() {
    const { classes, item, submitHandler, resetHandler } = this.props;

    let title = '';
    let description = '';

    if (item) {
      title = item.title || '';
      description = item.description || '';
    }

    return (
      <Formik
        initialValues = {{
          title: title,
          description: description,
        }}
        enableReinitialize={true}
        validate = {values => {
          let errors = {};
          if ('' === values.title) {
            errors.title = 'Dies ist ein Pflichtfeld';
          }
          return errors;
        }}
        onSubmit = {(values, formikBag) => {
          submitHandler(values, formikBag);
        }}>
        {({ status, isSubmitting, handleSubmit, handleReset }) => (
          <Form
            autoComplete="off"
            onSubmit={evt => {
              evt.stopPropagation();
              handleSubmit(evt);
            }}
            onReset={evt => {
              evt.stopPropagation();
              handleReset(evt);
              resetHandler();
            }}
            className={classes.form}
          >
            {status && <FormHelperText error>{status}</FormHelperText>}
            <Field component={TextField}
              type="text"
              id="title"
              name="title"
              label="Titel"
              required
              fullWidth
              margin="normal"/>
            <Field component={RichTextField}
              type="text"
              id="description"
              name="description"
              label="Bemerkung"

              // maxLength={250}
              fullWidth
              margin="normal"/>

            <div className={classes.wrapper}>
              <Button type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}>
                Speichern
              </Button>
              {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress} />}
            </div>
            <div className={classes.wrapper}>
              <Button type="reset" color="primary">Abbrechen</Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(CompetenceCheckFormComponent);
