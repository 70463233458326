import { keys as genericKeys, type as genericType } from "../generics";

const { intents } = genericKeys;

const nameKeys = {
  RESULTS: 'RESULTS',
  RESULT: 'RESULT',
};
export const entityKey = nameKeys.RESULTS.toLowerCase();
export const duckTypes = {
  RESULTS_GET: genericType(entityKey, nameKeys.RESULTS, intents.GET),
  RESULT_GET: genericType(entityKey, nameKeys.RESULT, intents.GET),
  RESULT_PUT: genericType(entityKey, nameKeys.RESULT, intents.PUT),
  RESULT_POST: genericType(entityKey, nameKeys.RESULT, intents.POST),
  RESULT_DELETE: genericType(entityKey, nameKeys.RESULT, intents.DELETE)
};