import { schema } from "normalizr";
import routes from "../../utils/routes";
import getHistory from "../../utils/history";
import { requestsOperations } from "./requests";
import { entityKey as filesSliceKey } from "./filesKeys";
import { default as filesOperations } from "./filesOperations";
import { entityKey as linksSliceKey } from "./linksKeys";
import { default as linksOperations } from "./linksOperations";
import { entityKey as resultsSliceKey } from "./resultsKeys";
import { default as resultsOperations } from "./resultsOperations";
import { entityKey, duckTypes } from "./subTasksKeys";
import { notificationsFlavors, notificationsOperations } from "../notifications";
import flavors from "../notifications/flavors";

const subTaskSchema = new schema.Entity(entityKey);
const subTaskListSchema = new schema.Array(subTaskSchema);

const fetchRelations = [{
  relationResponseKey: "media",
  relationSliceKey: filesSliceKey,
  operation: filesOperations.filesBySubtaskGetRequest,
  noRefetch: false
}, {
  relationResponseKey: "links",
  relationSliceKey: linksSliceKey,
  operation: linksOperations.linksBySubtaskGetRequest,
  noRefetch: false
}, {
  relationResponseKey: "results",
  relationSliceKey: resultsSliceKey,
  operation: resultsOperations.resultsBySubTaskGetRequest,
  noRefetch: false
}];

const listGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.SUBTASKS_GET,
    requestPath: url,
    clearEntityData: true,
    normalizeSchema: subTaskListSchema,
    fetchRelations: []
  };
  return requestsOperations.requestsOperationGeneric(null, settings);
};

const subTasksGetRequest = () => listGetRequest("/subtasks");

const associationGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.SUBTASKS_GET,
    requestPath: url,
    clearEntityData: false,
    normalizeSchema: subTaskListSchema,
    fetchRelations: [],
    requestCancelling: false,
  };
  return requestsOperations.requestsOperationGeneric(null, settings);
};

const subTasksByTaskGetRequest = (taskId) => associationGetRequest(`/tasks/${taskId}/subtasks`);

const subTaskGetRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.SUBTASK_GET,
    requestPath: `/subtasks/${id}`,
    idParamRequired: true,
    normalizeSchema: subTaskSchema,
    fetchRelations: fetchRelations
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const subTasksGetByTaskAndNumberRequest = (taskId, subTaskNumber) => {
  const settings = {
    duckType: duckTypes.SUBTASKS_GET,
    requestPath: `/tasks/${taskId}/subtasks`,
    idParamRequired: false,
    normalizeSchema: subTaskListSchema,
    fetchRelations: fetchRelations,
    clearEntityData: true,
  };

  return requestsOperations.requestsOperationGeneric({}, settings, { subTaskNumber: subTaskNumber });
};

const subTaskPutRequest = (id, data, formikBag, appendData) => {
  const isSubmitRequest = "status" in data && 20 === data.status;

  const params = {
    id: id,
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.SUBTASK_PUT,
    requestType: "put",
    requestPath: `/subtasks/${id}`,
    idParamRequired: true,
    normalizeSchema: subTaskSchema
  };
  if (appendData) {
    settings.appendOnSuccess = subTaskPutAppend(appendData);
  }
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
      promise.then(() => {
        if (isSubmitRequest) {
          dispatch(notificationsOperations.notificationEnqueue("Die Teilaufgabe wurde erfolgreich eingereicht.", notificationsFlavors.SUCCESS));
        }
      });
    }
    return promise;
  };
};

const subTaskPutAppend = appendData => () => {
  const { appendType } = appendData;
  if ("redirectToTask" === appendType) {
    const { taskKey, subTaskNumber } = appendData;
    getHistory().push(routes.SUBTASK_DETAILS.path.replace(":id", taskKey).replace(":number", subTaskNumber));
  }
};

const subTaskPostRequest = (data, formikBag, appendData) => {
  const params = {
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.SUBTASK_POST,
    requestType: "post",
    requestPath: "/subtasks",
    normalizeSchema: subTaskSchema
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise
        .catch(error => console.error(error))
        .then(() => {
          const { taskKey } = appendData; // task id and current subtasks
          getHistory().push(routes.TASK_DETAILS.path.replace(":id", taskKey)); // jump back to task view
        });
    }
    return promise;
  };
};

const subTaskDeleteRequest = (id, returnPath) => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.SUBTASK_DELETE,
    requestType: "delete",
    requestPath: `/subtasks/${id}`,
    idParamRequired: true,
    normalizeSchema: subTaskSchema,
    appendOnSuccess: () => getHistory().push(returnPath)
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const subTaskSubmitRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.SUBTASK_POST,
    requestType: 'post',
    requestPath: '/subtasks/:id/submit'.replace(':id', id),
    normalizeSchema: subTaskSchema,
    appendOnSuccess: subtaskChangeSuccess(
      'Die Teilaufgabe wurde erfolgreich eingereicht'
    ),
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const subtaskChangeSuccess = message => (dispatch) => {
  dispatch(notificationsOperations.notificationEnqueue(message, flavors.SUCCESS));
};

const subTasksCancelRequest = (cancelType, cancelMessage) => {
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsCancellation(entityKey, cancelType, cancelMessage));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

export default {
  subTasksGetRequest,
  subTasksByTaskGetRequest,
  subTaskGetRequest,
  subTaskPutRequest,
  subTaskPostRequest,
  subTaskDeleteRequest,
  subTasksCancelRequest,
  subTasksGetByTaskAndNumberRequest,
  subTaskSubmitRequest,
};
