import { schema } from 'normalizr';
import routes from "../../utils/routes";
import getHistory from '../../utils/history';
import { requestsOperations } from "./requests";
import { entityKey as fieldsOfActionSliceKey } from "./fieldsOfActionKeys";
import { default as fieldsOfActionOperations } from "./fieldsOfActionOperations";
import { entityKey, duckTypes } from "./professionsKeys";

const professionSchema = new schema.Entity(entityKey);
const professionListSchema = new schema.Array(professionSchema);

const fetchRelations = [{
  relationResponseKey: 'fieldsOfAction',
  relationSliceKey: fieldsOfActionSliceKey,
  operation: fieldsOfActionOperations.fieldOfActionsByProfessionGetRequest,
  noRefetch: false
}];

const gotoList = () => {
  getHistory().push(routes.PROFESSIONS.path);
};

const listGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.PROFESSIONS_GET,
    requestPath: url,
    clearEntityData: true,
    normalizeSchema: professionListSchema,
    fetchRelations: fetchRelations
  };
  return requestsOperations.requestsOperationGeneric(null, settings);
};

const professionsGetRequest = () => listGetRequest('/jobs');

const associationsGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.PROFESSIONS_GET,
    requestPath: url,
    clearEntityData: false,
    normalizeSchema: professionListSchema,
    fetchRelations: fetchRelations,
    requestCancelling: false,
  };
  return requestsOperations.requestsOperationGeneric(null, settings);
};

const professionsByTaskGetRequest = (taskId) => associationsGetRequest(`/tasks/${taskId}/jobs`);

const professionGetRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.PROFESSION_GET,
    requestPath: `/jobs/${id}`,
    idParamRequired: true,
    normalizeSchema: professionSchema,
    fetchRelations: fetchRelations
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const professionPutRequest = (id, data, formikBag) => {
  const params = {
    id: id,
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.PROFESSION_PUT,
    requestType: 'put',
    requestPath: `/jobs/${id}`,
    idParamRequired: true,
    normalizeSchema: professionSchema,
    appendOnSuccess: gotoList
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const professionPostRequest = (data, formikBag) => {
  const params = {
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.PROFESSION_POST,
    requestType: 'post',
    requestPath: '/jobs',
    normalizeSchema: professionSchema,
    appendOnSuccess: gotoList
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const professionDeleteRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.PROFESSION_DELETE,
    requestType: 'delete',
    requestPath: `/jobs/${id}`,
    idParamRequired: true,
    normalizeSchema: professionSchema,
    appendOnSuccess: gotoList
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const professionsCancelRequest = (cancelType, cancelMessage) => {
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsCancellation(entityKey, cancelType, cancelMessage));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

export default {
  professionsGetRequest,
  professionGetRequest,
  professionPutRequest,
  professionPostRequest,
  professionDeleteRequest,
  professionsCancelRequest,
  professionsByTaskGetRequest
};
