import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from "@material-ui/core";
import ReactSelect from '../common/ReactSelect';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SelectionDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItems: []
    };
  }

  render() {
    const { open, onClose, title, description, placeholder, label, submitLabel, isMulti, items } = this.props;
    const { selectedItems } = this.state;

    return items ? (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="selection-dialog-title"
        aria-describedby="selection-dialog-description"
      >
        <DialogTitle id="selection-dialog-title">{title ? title : "Auswahl"}</DialogTitle>
        <DialogContent>
          {description &&
            <DialogContentText id="selection-dialog-description">
              {description ? description : "Bitte wählen Sie aus:"}
            </DialogContentText>
          }
          <ReactSelect name="items"
            onChange={(fieldName, value) => {
              this.setState({ selectedItems: value });
            }}
            onBlur={() => {}}
            value={selectedItems}
            options={items}
            placeholder={placeholder ? placeholder : "Bitte auswählen..."}
            label={label ? label : "Auswahl"}
            isMulti={isMulti}
            isClearable={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => onClose(true, selectedItems)} color="secondary">
            {submitLabel ? submitLabel : "Speichern"}
          </Button>
        </DialogActions>
      </Dialog>
    ) : null;
  }
}

export default SelectionDialog;
