import React from 'react';
import { Field, Form, Formik } from "formik";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import roles from "../../utils/roles";
import ReactSelect from "../common/ReactSelect";
import { compareByKey2Sort } from "../../utils/helper";

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  wrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class UserRegistrationFormComponent extends React.Component {
  render() {
    const { classes, isAdmin, isInstructor, profile, businesses, professionsById, submitHandler, resetHandler, facilityById } = this.props;

    const setCompany = (profile && profile.company) ? profile.company : '';

    const { byId } = businesses;

    const optionSorter = (byId) => {
      return Object.keys(byId)
        .sort((a, b) => compareByKey2Sort(byId[a], byId[b], 'name'))
        .map(objId => {
          const obj = byId[objId];
          return {
            value: obj.id,
            label: obj.name
          };
        }, []);
    };

    const companiesOptions = optionSorter(byId);
    const professionsOptions = optionSorter(professionsById);
    const facilitiesOptions = optionSorter(facilityById);

    return (
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          company: !isAdmin ? setCompany : "",
          profession: "",
          role: isAdmin ? "instructor" : "",
          facilities: []
        }}
        validate={values => {
          let errors = {};
          if (values.firstName === "") {
            errors.firstName = "Dies ist ein Pflichtfeld";
          }
          if (values.lastName === '') {
            errors.lastName = "Dies ist ein Pflichtfeld";
          }
          if (!values.username) {
            errors.username = "Dies ist ein Pflichtfeld";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)
          ) {
            errors.username = "Keine gültige E-Mail Adresse";
          }
          if (values.company === "") {
            errors.company = 'Bitte auswählen';
          }
          if (values.profession === "") {
            errors.profession = "Bitte auswählen";
          }
          if (values.role === "") {
            errors.role = "Bitte auswählen";
          }

          return errors;
        }}
        onSubmit={(values, formikBag) => {
          submitHandler(values, formikBag);
        }}>
        {({ status, values, touched, errors, setFieldValue, setFieldTouched, isSubmitting, handleSubmit, handleReset }) => (
          <Form
            autoComplete="off"
            onSubmit={evt => {
              evt.stopPropagation();
              handleSubmit(evt);
            }}
            onReset={evt => {
              evt.stopPropagation();
              handleReset(evt);
              resetHandler();
            }}
            className={classes.form}
          >
            {status &&
            <FormHelperText error>{status}</FormHelperText>
            }
            <Field component={TextField}
                   type="text"
                   id="firstName"
                   name="firstName"
                   label="Vorname"
                   error={errors.firstName}
                   required
                   fullWidth
                   margin="normal"/>
            <Field component={TextField}
                   type="text"
                   id="lastName"
                   name="lastName"
                   label="Nachname"
                   error={errors.lastName}
                   required
                   fullWidth
                   margin="normal"/>
            <Field component={TextField}
                   type="email"
                   id="username"
                   name="username"
                   label="E-Mail Adresse"
                   error={errors.username}
                   required
                   fullWidth
                   margin="normal"/>
            {isInstructor &&
            <FormControl fullWidth margin="normal" error={!!errors.facilities && touched.facilities}>
              <ReactSelect name="facilities"
                           onChange={setFieldValue}
                           onBlur={setFieldTouched}
                           value={values.facilities}
                           error={errors.facilities}
                           touched={touched.facilities}
                           options={facilitiesOptions}
                           placeholder="Lernorte auswählen..."
                           label="Lernorte"
                           isMulti={true}
                           isClearable={false}/>
              {(!!errors.facilities && touched.facilities) &&
              <FormHelperText>{errors.facilities}</FormHelperText>
              }
            </FormControl>
            }

            {isAdmin &&
            <FormControl fullWidth margin="normal" error={!!errors.company && touched.company}>
              <ReactSelect name="company"
                           onChange={setFieldValue}
                           onBlur={setFieldTouched}
                           value={values.company}
                           error={errors.company}
                           touched={touched.company}
                           options={companiesOptions}
                           placeholder="Unternehmen auswählen..."
                           label="Unternehmen"
                           isMulti={false}
                           isClearable={false}/>
              {(!!errors.company && touched.company) &&
              <FormHelperText>{errors.company}</FormHelperText>
              }
            </FormControl>
            }
            <FormControl fullWidth margin="normal" error={!!errors.profession && touched.profession}>
              <ReactSelect name="profession"
                           onChange={setFieldValue}
                           onBlur={setFieldTouched}
                           value={values.profession}
                           error={errors.profession}
                           touched={touched.profession}
                           options={professionsOptions}
                           placeholder="Ausbildungsberuf auswählen..."
                           label="Ausbildungsberuf"
                           isMulti={false}
                           isClearable={false}/>
              {(!!errors.profession && touched.profession) &&
              <FormHelperText>{errors.profession}</FormHelperText>
              }
            </FormControl>
            <FormControl fullWidth margin="normal" error={!!errors.role && touched.role}>
              <ReactSelect name="role"
                           onChange={setFieldValue}
                           onBlur={setFieldTouched}
                           value={values.role}
                           error={errors.role}
                           touched={touched.role}
                           options={[{
                             value: roles.ADMIN,
                             label: "Administrator*in",
                             isDisabled: !isAdmin
                           }, {
                             value: roles.INSTRUCTOR,
                             label: "Ausbilder*in",
                             isDisabled: !isAdmin
                           }, {
                             value: roles.APPRENTICE,
                             label: "Auszubildende*r"
                           }]}
                           placeholder="Benutzerrolle auswählen..."
                           label="Aufgaben-Manager benutzen als"
                           isMulti={false}
                           isClearable={false}/>
              {(!!errors.role && touched.role) &&
              <FormHelperText>{errors.role}</FormHelperText>
              }
            </FormControl>
            <div className={classes.wrapper}>
              <Button type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}>
                Registrierung abschicken
              </Button>
              {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress}/>}
            </div>
            <div className={classes.wrapper}>
              <Button type="reset" color="primary">Abbrechen</Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(UserRegistrationFormComponent);
