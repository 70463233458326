import React from 'react';

import Avatar from '@material-ui/core/Avatar/index';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import Paper from '@material-ui/core/Paper/index';
import Typography from '@material-ui/core/Typography/index';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
});

const Title = ({ classes, children, icon = <ChatBubbleIcon /> }) => {
    return (
        <Paper elevation={0} className={classes.paper}>
            <Avatar className={classes.avatar}>
                {icon}
            </Avatar>
            <Typography component="h1" variant="h5" gutterBottom>{children}</Typography>
        </Paper>
    )
};

export default withStyles(styles)(Title);
