import React from 'react';
import AddIcon from '@material-ui/icons/AddBox';
import Button from "@material-ui/core/Button/Button";
import routes from "../../utils/routes";
import { labelsMuiDatatables } from "../../utils/labels";
import ShowMoreText from "../common/ShowMoreText";
import MUIDataTable from "mui-datatables";
import PortalToolbarMenu from '../common/navigation/PortalToolbarMenu';

class FieldsOfActionComponent extends React.Component {
  componentDidMount() {
    this.initData();
  }

  initData = () => {
    this.props.fieldsOfActionGetRequest();
  };

  handleClick = (evt, route) => {
    evt.preventDefault();
    if (route) {
      this.props.history.push(route);
    }
  };

  handleNew = () => {
    this.props.history.push(routes.FIELDOFACTION_NEW.path);
  };

  render() {
    const { byId, allIds } = this.props;

    const portalToolbarMenuData = [
      {
        "title": "Neuen Aufgabenbereich anlegen",
        "action": () => this.handleNew(),
        "icon": AddIcon,
        "visible": true,
        "disabled": false
      }
    ];
    
    const tableOptions = {
      selectableRows: 'none',
      elevation: 0,
      responsive: "stacked",
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 20, 50],
      textLabels: labelsMuiDatatables,
    };

    const tableColumns = ["Titel",
      {
        name: "beschreibung",
        label: "Beschreibung",
        options: {
          filter: false,
          customBodyRender: (value) => {
            return <ShowMoreText maxLength={64}>{value}</ShowMoreText>;
          }
        }
      },
      {
        options: {
          customBodyRender: (value) => {
            return (
              <Button href="#" onClick={evt => this.handleClick(evt, routes.FIELDOFACTION_EDIT.path.replace(':id', value))}
                      size="small" variant="outlined" color="primary">Bearbeiten</Button>
            );
          }
        }
      }];
    const tableData = allIds.map(itemId => {
      const item = byId[itemId];

      return (item) ? [item.name, item.description, item.id] : [];
    });

    return (
      <React.Fragment>
        <PortalToolbarMenu menuData={portalToolbarMenuData}/>
        <MUIDataTable title={"Alle Aufgabenbereiche"} options={tableOptions} columns={tableColumns} data={tableData}/>
      </React.Fragment>
    );
  }
}

export default FieldsOfActionComponent;
