import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { duckTypes } from "../../redux/entities/subTasksKeys";
import { default as SubTaskEditForm } from "./SubTaskEditFormComponent";
import routes from "../../utils/routes";

const styles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: 640,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});

class SubTaskEditComponent extends React.Component {
  //static contextType = LayoutContext;

  constructor(props) {
    super(props);

    this.isDataLoaded = false;
  }

  componentDidMount() {
    if (!this.isDataLoaded) {
      this.isDataLoaded = true;
      this.initData();
    }
  }

  componentWillUnmount() {
    this.props.titleChange(null);
  }

  initData = () => {
    const { match } = this.props;

    this.props.taskWithoutRelationsGetRequest(match.params.id).then(() => {
      const task = this.getTask();
      this.props.subTasksGetByTaskAndNumberRequest(task.id, match.params.number).then(() => {
        this.updateTitle();
      });
    });
  };

  updateTitle = () => {
    const subTask = this.getSubTask();
    const task = this.getTask();

    if (null !== subTask && null !== task) {
      this.props.titleChange(`Teilaufgabe ${task.taskKey} / ${subTask.subTaskNumber} bearbeiten`);
    } else {
      this.props.titleChange(null);
    }
  };

  getTask = () => {
    const { match } = this.props;
    let task = Object.values(this.props.tasksById).filter(t => t.taskKey === match.params.id);
    task = task.length ? task[0] : null;

    return task;
  };

  getSubTask = () => {
    const { match } = this.props;
    let subTask = Object.values(this.props.byId).filter(subTask => subTask.subTaskNumber === parseInt(match.params.number));
    subTask = subTask.length ? subTask[0] : null;

    return subTask;
  };

  handleSubmit = (values, formikBag) => {
    const subTask = this.getSubTask();
    const task = this.getTask();

    const id = subTask.id;
    const data = {
      title: values.title,
      description: values.description,
      phase: values.phase,
      status: values.status,
      media: values.media,
      links: values.links
    };
    const appendType = 'redirectToTask';
    const taskKey = task.taskKey;
    const subTaskNumber = subTask.subTaskNumber;
    const appendData = { appendType, taskKey, subTaskNumber };
    this.props.subTaskPutRequest(id, data, formikBag, appendData);
  };

  handleReset = () => {
    const subTask = this.getSubTask();
    const task = this.getTask();

    this.props.subTasksCancelRequest(duckTypes.SUBTASK_PUT.group);
    this.props.history.push(routes.SUBTASK_DETAILS.path.replace(':id', task.taskKey).replace(':number', subTask.subTaskNumber));
  };

  render() {
    const { classes, isAdmin, isInstructor } = this.props;
    const task = this.getTask();
    const subTask = this.getSubTask();

    if (!subTask || !task) {
      return <></>;
    }

    return (
      <Paper elevation={0} className={classes.paper}>
        <SubTaskEditForm isAdmin={isAdmin} isInstructor={isInstructor} item={subTask} submitHandler={this.handleSubmit}
                         resetHandler={this.handleReset}/>
      </Paper>
    );
  }
}

export default withStyles(styles)(SubTaskEditComponent);