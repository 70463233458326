import { keys as genericKeys, type as genericType } from "../generics";

const { intents } = genericKeys;

export const nameKeys = {
  FILES: 'FILES',
  FILE: 'FILE',
};
export const entityKey = nameKeys.FILES.toLowerCase();
export const duckTypes = {
  FILES_GET: genericType(entityKey, nameKeys.FILES, intents.GET),
  FILE_GET: genericType(entityKey, nameKeys.FILE, intents.GET),
  FILE_PUT: genericType(entityKey, nameKeys.FILE, intents.PUT),
  FILE_POST: genericType(entityKey, nameKeys.FILE, intents.POST),
  FILE_DELETE: genericType(entityKey, nameKeys.FILE, intents.DELETE)
};