import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { duckTypes } from "../../redux/entities/filesKeys";
import { default as FileForm } from "./FileFormComponent";
import flavors from "../../redux/notifications/flavors";

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: 640,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
});

class FileNewComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fileId: null
        };
    }

    handleUploadSuccess = data => {
        const fileId = (data.result && data.result.id) ? data.result.id : null;
        this.setState({
            fileId: fileId,
        }, () => {
            this.props.filePostSuccess(data);
        });
    };

    handleUploadError = handledError => {
        this.props.filePostError(handledError);
    };

    handleSubmit = (values, formikBag) => {
        const { fileId } = this.state;
        const data = {
            title: values.title || '',
            description: values.description || '',
            hiddenFromApprentice: values.hidden === '1'
        };
        if (fileId) {
            this.props.filePutRequest(fileId, data, formikBag);
        } else {
            this.props.notificationEnqueue('Es ist ein Fehler aufgetreten', flavors.ERROR);
        }
    };

    handleReset = () => {
        this.props.filesCancelRequest(duckTypes.FILE_PUT.group);
        this.props.history.goBack();
    };

    render() {
        const { classes, isAdmin, isInstructor, byId } = this.props;

        const isApprentice = !isAdmin && !isInstructor;

        const item = byId[this.state.fileId];

        return (
            <Paper elevation={0} className={classes.paper}>
                <FileForm item={item} uploadSuccessHandler={this.handleUploadSuccess} uploadErrorHandler={this.handleUploadError} submitHandler={ this.handleSubmit } resetHandler={ this.handleReset } isApprentice={isApprentice} />
            </Paper>
        );
    }
}

export default withStyles(styles)(FileNewComponent);
