import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button/Button";
import DetailsIcon from "@material-ui/icons/ExitToApp";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/AddBox";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import routes from "../../utils/routes";
import { labelsMuiDatatables } from "../../utils/labels";
import taskStatus from "../../utils/taskStatus";
import Tooltip from "@material-ui/core/Tooltip";
import PortalToolbarMenu from '../common/navigation/PortalToolbarMenu';
import MUIDataTable from "mui-datatables";

const styles = theme => ({
  titleLink: {
    cursor: "pointer",
  },
  title: {
    marginRight: theme.spacing(1),
  },
  titleTaskKey: {
    alignItems: "center",
    color: theme.palette.text.hint,
    display: "inline-flex",
    fontSize: theme.typography.caption.fontSize,
    verticalAlign: "middle",
    whiteSpace: "nowrap",
  },
  titleIcon: {
    fontSize: theme.typography.body1.fontSize,
    marginLeft: theme.spacing(0.5),
  },
});

class TasksComponent extends React.Component {
  
  componentDidMount() {
    const parametersByProps = {
      master: this.props.master ? 1 : 0,
      archived: this.props.archived ? 1 : 0,
    };

    const adjustedTasksGetRequest = (urlParameters) => this.props.tasksGetRequest({
        ...urlParameters,
        ...parametersByProps,
        sort: '-updatedAt'
      });
    this.props.tableServerDataProvider.init(adjustedTasksGetRequest);

    this.props.tasksFilterOptionsRequest(parametersByProps);
  }

  handleClick = (evt, route) => {
    evt.preventDefault();
    if (route) {
      this.props.history.push(route);
    }
  };

  handleTaskCopy = id => {
    this.props.taskCopyRequest(id);
  };

  handleTaskNew = () => {
    const createNewPath = routes.TASK_NEW.path.replace(':master', this.props.master ? '1' : '0');
    this.props.history.push(createNewPath);
  };

  render() {
    const { isInstructor, byId, allIds, fieldsOfActionById, usersById, subTasksById, totalCount, archived, master, filterOptions } = this.props;

    const portalToolbarMenuData = [
      {
        "title": this.props.master ? "Neue Aufgabenvorlage anlegen" : "Neue Aufgabe anlegen",
        "action": () => this.handleTaskNew(),
        "icon": AddIcon,
        "visible": isInstructor && !archived,
        "disabled": false
      }
    ];

    const tableOptions = {
      selectableRows: "none",
      elevation: 0,
      filter: true,
      print: false,
      download: false,
      viewColumns: false,
      rowsPerPageOptions: [10, 20, 50],
      textLabels: labelsMuiDatatables,
      serverSide: true,
      serverSideFilterList: [],
      count: totalCount,
    };

    this.props.tableServerDataProvider.bindToTableOptions(tableOptions);

    const tableColumns = this.createTableColumns();

    this.props.tableServerDataProvider.bindTableFilters(filterOptions, tableColumns);

    const tableData = allIds
      .map(itemId => {
        const item = byId[itemId];

        if (!item) return [];

        const { taskKey, title, fieldOfAction, ownerName, apprentices, cooperators, apprenticeNames, cooperatorNames, status, subTasks, subTaskCount, subTaskOpenCount } = item;

        const fieldOfActionObj = fieldsOfActionById[fieldOfAction];
        let fieldOfActionName = item.fieldOfActionName;
        if (undefined === fieldOfActionName) {
          fieldOfActionName = fieldOfActionObj ? fieldOfActionObj.name : '';
        }

        const nameListConvert = (names) => {
          if (!names || (names.length === 1 && undefined === names[0])) {
            return "-";
          }
          const namesArray = [];
          if (names.length > 0) {
            names
              .sort((a, b) => a.firstName.localeCompare(b.firstName))
              .forEach(name => {
                namesArray.push(`${name.firstName} ${name.lastName}`);
              });
          }

          return (namesArray.length > 0) ? namesArray.join(", ") : "-";
        };

        const apprenticesList = nameListConvert(apprenticeNames !== undefined ? apprenticeNames : apprentices && apprentices.map(a => usersById[a]));
        const cooperatorsList = nameListConvert(cooperatorNames !== undefined ? cooperatorNames : cooperators && cooperators.map(c => usersById[c]));

        let currentSubTaskCount = subTaskCount;
        let currentSubTaskOpenCount = subTaskOpenCount;

        if (currentSubTaskCount === undefined) {
          currentSubTaskOpenCount = 0;
          currentSubTaskCount = (subTasks) ? subTasks.length : 0;
          if (subTasks) {
            subTasks.forEach(key => {
              if (subTasksById[key] && subTasksById[key].status === 10) {
                currentSubTaskOpenCount++;
              }
            });
          }
        }

        const subTasksData = `${currentSubTaskCount} (${currentSubTaskOpenCount})`;

        return ['', taskKey, title + ":::" + taskKey, fieldOfActionName, ownerName, apprenticesList, cooperatorsList, taskStatus[status], subTasksData, item.id];
      });

    return (
      <React.Fragment>
        <PortalToolbarMenu menuData={portalToolbarMenuData}/>
        <MUIDataTable title={master ? "Alle Aufgabenvorlagen" : (archived ? "Archivierte Aufgaben" : "Alle Aufgaben")} options={tableOptions} columns={tableColumns} data={tableData}/>
      </React.Fragment>
    );
  }

  createTableColumns() {
    const { classes, width, byId, isInstructor, isAdmin } = this.props;

    const tableColumns = [
      {
        name: "selection",
        label: "Auswahl",
        options: {
          filter: isInstructor || isAdmin,
          display: false,
        }
      },
      {
        name: "taskKey",
        label: "Schlüssel",
        options: {
          filter: false,
          display: false,
        }
      },
      {
        name: "title",
        label: "Titel",
        options: {
          customBodyRender: (value) => {
            const splitValues = value.split(":::");

            return (
              <div className={classes.titleLink}
                    onClick={evt => this.handleClick(evt, routes.TASK_DETAILS.path.replace(":id", splitValues[1]))}>
                <span className={classes.title}>{splitValues[0]}</span>
                {!isWidthUp('md', width) &&
                  <span className={classes.titleTaskKey}>
                    {splitValues[1]}
                    <DetailsIcon className={classes.titleIcon}/>
                  </span>
                }
              </div>
            );
          }
        }
      },
      {
        name: "fieldOfAction",
        label: "Aufgabenbereich",
        options: {
          display: isWidthUp('md', width),
        }
      },
      {
        name: "owner",
        label: "Ersteller",
        options: {
          display: isWidthUp('md', width),
        }
      },
      {
        name: "apprentices",
        label: "Auszubildende",
        options: {
          display: !this.props.master && isWidthUp('md', width),
        }
      },
      {
        name: "cooperators",
        label: "Mitwirkende Ausbilder*innen",
        options: {
          filter: false,
          display: !this.props.master && isWidthUp('lg', width),
        }
      },
      {
        name: "status",
        label: "Status",
        options: {
          display: !this.props.master,
        }
      },
      {
        name: "subTaskCounter",
        label: "TA (offen)",
        options: {
          filter: false,
          display: isWidthUp('md', width),
        }
      },
      {
        name: "actions",
        label: " ",
        options: {
          setCellProps: value => ({ align: "right" }),
          customBodyRender: id => {
            const item = byId[id];
            return (
              <ButtonGroup color="primary">
                <Tooltip title="Details" aria-label="Details">
                  <Button onClick={evt => this.handleClick(evt, routes.TASK_DETAILS.path.replace(":id", item.taskKey))}>
                    <DetailsIcon/>
                  </Button>
                </Tooltip>
                {item.permissions.canEdit && <Tooltip title="Bearbeiten" aria-label="Bearbeiten">
                  <Button onClick={evt => this.handleClick(evt, routes.TASK_EDIT.path.replace(":id", item.taskKey))}>
                    <EditIcon/>
                  </Button>
                </Tooltip>}
                {item.permissions.canCopy && this.props.master && <Tooltip title="Kopieren" aria-label="Kopieren">
                  <Button onClick={() => this.handleTaskCopy(item.id)}>
                    <FileCopyIcon/>
                  </Button>
                </Tooltip>}
              </ButtonGroup>
            );
          },
          filter: false,
          display: isWidthUp('md', width),
        }
      },
      /* virtual column for date filter not mapped to real data */
      {
        name: "createdAtFrom",
        options: {
          filter: true,
          display: false,
        }
      },
      /* virtual column for date filter not mapped to real data */
      {
        name: "createdAtTo",
        options: {
          filter: true,
          display: false,
        }
      },
    ];

    return tableColumns;
  }
}

export default withStyles(styles)(withWidth()(TasksComponent));
