import config from '../../utils/config';

const namespace = config.APP_NAME + '/authentication/';

const REGISTRATION_POST = namespace + 'REGISTRATION_POST';
const REGISTRATION_POST_STARTED = REGISTRATION_POST + '_STARTED';
const REGISTRATION_POST_SUCCESS = REGISTRATION_POST + '_SUCCESS';
const REGISTRATION_POST_FAILURE = REGISTRATION_POST + '_FAILURE';

const ACTIVATION_POST = namespace + 'ACTIVATION_POST';
const ACTIVATION_POST_STARTED = ACTIVATION_POST + '_STARTED';
const ACTIVATION_POST_SUCCESS = ACTIVATION_POST + '_SUCCESS';
const ACTIVATION_POST_FAILURE = ACTIVATION_POST + '_FAILURE';

const AUTH_POST = namespace + 'AUTH_POST';
const AUTH_POST_STARTED = AUTH_POST + '_STARTED';
const AUTH_POST_SUCCESS = AUTH_POST + '_SUCCESS';
const AUTH_POST_FAILURE = AUTH_POST + '_FAILURE';

const AUTH_REMOVE_SUCCESS = 'AUTH_REMOVE_SUCCESS';

const RECOVERY_POST = namespace + 'RECOVERY_POST';
const RECOVERY_POST_STARTED = RECOVERY_POST + '_STARTED';
const RECOVERY_POST_SUCCESS = RECOVERY_POST + '_SUCCESS';
const RECOVERY_POST_FAILURE = RECOVERY_POST + '_FAILURE';

const RESET_POST = namespace + 'RESET_POST';
const RESET_POST_STARTED = RESET_POST + '_STARTED';
const RESET_POST_SUCCESS = RESET_POST + '_SUCCESS';
const RESET_POST_FAILURE = RESET_POST + '_FAILURE';

const ME_GET = namespace + 'ME_GET';
const ME_GET_STARTED = ME_GET + '_STARTED';
const ME_GET_SUCCESS = ME_GET + '_SUCCESS';
const ME_GET_FAILURE = ME_GET + '_FAILURE';

export default {
  REGISTRATION_POST,
  REGISTRATION_POST_STARTED,
  REGISTRATION_POST_SUCCESS,
  REGISTRATION_POST_FAILURE,
  ACTIVATION_POST,
  ACTIVATION_POST_STARTED,
  ACTIVATION_POST_SUCCESS,
  ACTIVATION_POST_FAILURE,
  AUTH_POST,
  AUTH_POST_STARTED,
  AUTH_POST_SUCCESS,
  AUTH_POST_FAILURE,
  AUTH_REMOVE_SUCCESS,
  RECOVERY_POST,
  RECOVERY_POST_STARTED,
  RECOVERY_POST_SUCCESS,
  RECOVERY_POST_FAILURE,
  RESET_POST,
  RESET_POST_STARTED,
  RESET_POST_SUCCESS,
  RESET_POST_FAILURE,
  ME_GET,
  ME_GET_STARTED,
  ME_GET_SUCCESS,
  ME_GET_FAILURE
};
