import { keys as genericKeys, type as genericType } from "../generics";

const { intents } = genericKeys;

const nameKeys = {
  COMPETENCESPECIFICATIONS: 'COMPETENCESPECIFICATIONS',
  COMPETENCESPECIFICATION: 'COMPETENCESPECIFICATION',
};
export const entityKey = nameKeys.COMPETENCESPECIFICATIONS.toLowerCase();
export const duckTypes = {
  COMPETENCESPECIFICATIONS_GET: genericType(entityKey, nameKeys.COMPETENCESPECIFICATIONS, intents.GET),
  COMPETENCESPECIFICATION_GET: genericType(entityKey, nameKeys.COMPETENCESPECIFICATION, intents.GET),
  COMPETENCESPECIFICATION_PUT: genericType(entityKey, nameKeys.COMPETENCESPECIFICATION, intents.PUT),
  COMPETENCESPECIFICATION_POST: genericType(entityKey, nameKeys.COMPETENCESPECIFICATION, intents.POST),
  COMPETENCESPECIFICATION_COPY: genericType(entityKey, nameKeys.COMPETENCESPECIFICATION, intents.COPY),
  COMPETENCESPECIFICATION_DELETE: genericType(entityKey, nameKeys.COMPETENCESPECIFICATION, intents.DELETE)
};
