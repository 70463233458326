import React from "react";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import { duckTypes } from "../../redux/entities/reportsKeys";
import { default as ReportForm } from "./ReportFormComponent";
import { withRouter } from "react-router-dom";
import { responseFormikValidator } from "../../utils/validations";
import DeleteIcon from "@material-ui/icons/Delete";
import PortalToolbarMenu from "../common/navigation/PortalToolbarMenu";
import RemovalDialog from "../common/RemovalDialog";
import routes from "../../utils/routes";

const styles = theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      width: 640,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  }
});

class ReportEditComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportId: null,
      removalDialogOpen: false,
    };
  }

  componentDidMount() {
    this.initData();
  }

  componentWillUnmount() {
    this.props.titleChange(null);
    this.props.parentRouteChange(null);
  }

  initData = () => {
    const { match, fieldsOfActionGetRequest, reportGetRequest, facilitiesGetRequest } = this.props;

    if (match.params.id) {
      this.setState({
        reportId: match.params.id
      }, () => {
        fieldsOfActionGetRequest();
        reportGetRequest(this.state.reportId).then(() => {
          this.updateMeta();
        });
        facilitiesGetRequest();
      });
    }
  };

  updateMeta() {
    const { byId, titleChange, parentRouteChange } = this.props;
    const { reportId } = this.state;

    titleChange(null);
    parentRouteChange(null);

    if (!reportId) {
      return;
    }

    const item = byId[reportId];
    let parentRoute = {
      ...routes.PORTFOLIO
    };
    const fieldOfActionId = item && item.fieldOfActions && 0 < item.fieldOfActions.length ?
      item.fieldOfActions[0] : null;


    if (fieldOfActionId) {
      parentRoute = {
        ...routes.REPORTS
      };
      const parentPath = parentRoute.path;
      parentRoute.path = parentPath.replace(':fieldOfActionId', fieldOfActionId);
    }

    titleChange(item && item.title ? 'Eintrag "' + item.title + '" bearbeiten': null);
    parentRouteChange(parentRoute);
  }

  handleSubmit = (values, formikBag) => {
    const { reportId } = this.state;

    const data = {
      dateFrom: values.dateFrom,
      dateTo: values.dateTo,
      duration: values.duration,
      title: values.title || "",
      text: values.text || "",
      fieldOfActions: [ values.fieldOfActionId ] || [],
      learningPlaces: [ values.learningPlaceId ] || [],
      description: values.description || "",
      media: values.media || [],
    };

    this.props.reportPutRequest(reportId, data, formikBag).then((axiosResponse) => {
      responseFormikValidator(axiosResponse, formikBag);
    });
  };

  handleReset = () => {
    this.props.reportCancelRequest(duckTypes.REPORT_PUT.group);
    this.props.history.goBack();
  };

  handleReportRemove(id) {
    this.props.reportDeleteRequest(id).then(() => this.props.history.goBack());
  };

  render() {
    const { classes, byId, fieldsOfActionAllIds, fieldsOfActionById, facilitiesAllIds, facilityById } = this.props;
    const { reportId, removalDialogOpen } = this.state;

    const item = byId[reportId];

    const fieldsOfAction = fieldsOfActionAllIds.map((id) => fieldsOfActionById[id]);
    const facilities = facilitiesAllIds.map((id) => facilityById[id]);

    if (undefined === item) {
      return <></>;
    }

    const portalToolbarMenuData = [{
      "title": "Löschen",
      "action": () => this.setState({ removalDialogOpen: true }),
      "icon": DeleteIcon,
      "disabled": false,
      "visible": true,
    }];

    return <>
      <PortalToolbarMenu menuData={portalToolbarMenuData}/>
      <RemovalDialog
        open={removalDialogOpen}
        subject={item.title}
        onClose={(remove) => {
          this.setState({ removalDialogOpen: false });

          if (remove === true) {
            this.handleReportRemove(item.id);
          }
        }}
      />
      <Paper elevation={0} className={classes.paper}>
        <ReportForm
          submitHandler={this.handleSubmit}
          resetHandler={this.handleReset}
          item={item}
          fieldsOfAction={fieldsOfAction}
          fieldsOfActionLocked={false}
          facilities={facilities}
        />
      </Paper>
    </>;
  }
}

export default withRouter(withStyles(styles)(ReportEditComponent));
