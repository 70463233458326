import React from "react";
import Grid from "@material-ui/core/Grid";
import PortfolioFieldOfAction from "./PortfolioFieldOfActionComponent";
import withStyles from "@material-ui/core/styles/withStyles";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import routes from "../../utils/routes";
import PortalToolbarMenu from "../common/navigation/PortalToolbarMenu";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  gridContainer: {
    margin: 0,
    width: "100%",
    padding: theme.spacing(1),
  },
});

class PortfolioComponent extends React.Component {
  componentDidMount() {
    this.initData();
  }

  initData() {
    this.props.fieldOfActionsByProfessionGetRequest(this.props.myJob);
  }

  handleCreatePdf(evt) {
    evt.preventDefault();
    this.props.history.push(routes.PORTFOLIO_PDF.path);
  }

  render() {
    const { classes, byId, allIds } = this.props;

    const renderGridItem = (itemId) => {
      const fieldsOfAction = byId[itemId];

      return <Grid key={itemId} item xs={12} sm={6} md={4} lg={3}>
        <PortfolioFieldOfAction item={fieldsOfAction}/>
      </Grid>;
    };

    const portalToolbarMenuData = [
      {
        "title": "Bericht erstellen",
        "action": evt => this.handleCreatePdf(evt),
        "icon": ImportContactsIcon,
        "visible": true,
        "disabled": false
      }
    ];

    const filteredIds = allIds.filter(id => byId[id] && byId[id].jobs.includes(this.props.myJob));

    return (
      <>
        <PortalToolbarMenu menuData={portalToolbarMenuData}/>
        <Grid container spacing={3} className={classes.gridContainer}>
          {filteredIds.map(itemId => renderGridItem(itemId))}
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(PortfolioComponent);
