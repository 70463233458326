import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { duckTypes } from "../../redux/entities/professionsKeys";
import { default as ProfessionForm } from "./ProfessionFormComponent";
import { responseFormikValidator } from "../../utils/validations";

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: 640,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
});

class ProfessionNewComponent extends React.Component {
    componentDidMount() {
        this.initData();
    }

    initData = () => {
        this.props.fieldsOfActionGetRequest(); // NOTE: Currently fetching all items to get new items (may be overkill)
    };

    handleSubmit = (values, formikBag) => {
        const data = {
            name: values.name || '',
            fieldsOfAction: values.fieldsOfAction || [],
            taskKey: values.taskKey || ''
        };
        this.props.professionPostRequest(data, formikBag).then((axiosResponse) => {
            responseFormikValidator(axiosResponse, formikBag);
        });
    };

    handleReset = () => {
        this.props.professionsCancelRequest(duckTypes.PROFESSION_POST.group);
        this.props.history.goBack();
    };

    render() {
        const { classes, fieldsOfActionById } = this.props;

        return (
            <Paper elevation={0} className={classes.paper}>
                <ProfessionForm fieldsOfAction={fieldsOfActionById} submitHandler={ this.handleSubmit } resetHandler={ this.handleReset } />
            </Paper>
        );
    }
}

export default withStyles(styles)(ProfessionNewComponent);