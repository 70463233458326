
export const keys = {
  entities: {
    VIEWS: 'VIEWS',
    META: 'META'
  },
  intents: {
    INIT: 'INIT',
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE',
    CHANGE: 'CHANGE',
    SEND: 'SEND',
    COPY: 'COPY',
    FETCH_FILTER_OPTIONS: 'FETCH_FILTER_OPTIONS',
    RESTORE: 'RESTORE',
  },
  status: {
    STARTED: 'STARTED',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
  }
};

export const type = (entity, name, intent, status) => {
  const ENTITY = entity.toString();
  const NAME = name.toString();
  const INTENT = intent.toString();
  let STATUS = '';
  if (status) {
    STATUS = status.toString();
  }
  const type = {};
  type.entity = `${ENTITY}`;
  type.name = `${NAME}`;
  type.intent = `${INTENT}`;
  type.group = `${NAME}_${INTENT}`;
  type.status = `${STATUS}`;
  if ('' !== STATUS) {
    type.set = `${NAME}_${INTENT}_${STATUS}`;
  }
  return type;
};

export const action = (type, payload) => {
  return {
    type: type,
    payload: payload
  };
};
