const responseFormikValidator = (axiosResponse, formikBag) => {
  if (axiosResponse.response &&
    axiosResponse.response.status &&
    422 === axiosResponse.response.status &&
    axiosResponse.response.data &&
    axiosResponse.response.data.errors) {
    formikBag.setSubmitting(false);
    formikBag.setErrors(axiosResponse.response.data.errors);

    return true;
  }

  return false;
};

export { responseFormikValidator };