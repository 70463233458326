import React from "react";
import { withStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import { fieldToTextField } from 'formik-material-ui';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
// import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount';

const styles = theme => ({
    '@global': {
        ':root': {
            '--ck-z-modal': 1301,
        },
    },
    root: {
        '& .ck': {
            '&.ck-editor__editable_inline': {
                minHeight: '6em',
                padding: 0,
                width: '100%',
                '&.ck-focused': {
                    border: '0',
                    boxShadow: 'none',
                },
                '& > :first-child': {
                    marginTop: '0.375em',
                },
                '& > :last-child': {
                    marginBottom: '0.375em',
                },
            },
        },
    },
});

function Editor(props) {
    const { value, onChange, focusHandler, blurHandler } = props;

    return (
        <CKEditor
            editor={InlineEditor}
            data={value}
            onInit={editor => {
                // Note: This seems redundant (in conjunction with the data attribute above), but apparently it is needed to robustly fill the editor with data in different scenarios.
                editor.setData(value); 
            }}
            onChange={(event, editor) => {
                onChange(editor.getData());
            }}
            onFocus={editor => {
                focusHandler();
            }}
            onBlur={editor => {
                blurHandler();
            }}
            config={{
                toolbar: [
                    'heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo'
                ],
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Überschrift 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Überschrift 2', class: 'ck-heading_heading2' }
                    ]
                }
            }}
        />
    );
}

class RichTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: false
        };
    }

    handleChange = value => {
        this.props.form.setFieldValue(this.props.field.name, value);
    };

    handleFocus = () => {
        this.setState({ focused: true });
    };

    handleBlur = () => {
        this.props.form.setFieldTouched(this.props.field.name, true);
        this.setState({ focused: false });
    };
                                
    render() {
        const { classes, form, field, maxLength, richTextInputProps } = this.props;
        const { focused } = this.state

        const TextFieldProps = {
            ...this.props
        };
        delete TextFieldProps.richTextInputProps;

        const InputProps = {
            ...richTextInputProps,
            inputComponent: Editor,
            inputProps: {
                focusHandler: this.handleFocus,
                blurHandler: this.handleBlur,
            },
        };

        const InputLabelProps = {
            focused: focused,
            shrink: focused || !!field.value.length,
        };

        const fieldError = form.errors[field.name] || null;
        let maxCharsError = false;
        let charsCountText = null;

        if (maxLength > 0) {
            const charsLeft = maxLength - field.value.length;
            maxCharsError = charsLeft < 0;
            charsCountText = maxCharsError ? `Text zu lang!` : `Noch ${charsLeft} Zeichen`;
        }

        const helperText = fieldError ? fieldError : charsCountText
        const helperError = fieldError ? true : maxCharsError
        
        const FormHelperTextProps = {
            error: helperError
        };

        return (         
            <MuiTextField 
                {...fieldToTextField(TextFieldProps)}
                onChange={this.handleChange}
                InputProps={InputProps}
                InputLabelProps={InputLabelProps}
                helperText={helperText}
                FormHelperTextProps={FormHelperTextProps}
                classes={classes}
            />     
        );
    }
}

export default withStyles(styles)(RichTextField);
