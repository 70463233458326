import { withRouter } from "react-router";
import { connect } from 'react-redux';
import UsersComponent from './UsersComponent';
import { entityKey as usersSliceKey } from '../../redux/entities/usersKeys';
import { default as usersOperations } from '../../redux/entities/usersOperations';
import { entityKey as businessesSliceKey } from '../../redux/entities/businessesKeys';
import { default as businessesOperations } from "../../redux/entities/businessesOperations";
import { entityKey as professionsSliceKey } from '../../redux/entities/professionsKeys';
import { default as professionsOperations } from '../../redux/entities/professionsOperations';
import { notificationsOperations } from "../../redux/notifications";
import { withTableServerDataProvider } from "../../utils/tableServerDataProvider";
import { entityKey as filesSliceKey } from "../../redux/entities/filesKeys";

const mapStateToProps = state => {
  const { isAdmin, isInstructor } = state.authentication;
  const { errors, byId, allIds, totalCount, filterOptions } = state.entities[usersSliceKey];
  const businessesById = state.entities[businessesSliceKey].byId;
  const professionsById = state.entities[professionsSliceKey].byId;
  const filesById = state.entities[filesSliceKey].byId;

  return {
    isAdmin,
    isInstructor,
    errors,
    byId,
    allIds,
    businessesById,
    professionsById,
    filesById,
    totalCount,
    filterOptions
  };
};

const mapDispatchToProps = dispatch => {
  const usersGetRequest = (urlParameters) => dispatch(usersOperations.usersGetRequest(urlParameters));
  const usersFilterOptionsRequest = (urlParameters) => dispatch(usersOperations.usersFilterOptionsRequest(urlParameters));

  const businessesGetRequest = () => dispatch(businessesOperations.businessesGetRequest());
  const businessGetRequest = id => dispatch(businessesOperations.businessGetRequest(id));

  const professionsGetRequest = () => dispatch(professionsOperations.professionsGetRequest());

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    usersGetRequest,
    businessesGetRequest,
    businessGetRequest,
    professionsGetRequest,
    notificationEnqueue,
    usersFilterOptionsRequest
  };
};

const UsersContainer = withTableServerDataProvider(withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersComponent)));

export default UsersContainer;
