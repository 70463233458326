import { withRouter } from "react-router";
import { connect } from "react-redux";
import { entityKey as fieldsOfActionSliceKey } from "../../redux/entities/fieldsOfActionKeys";
import { default as fieldsOfActionOperations } from "../../redux/entities/fieldsOfActionOperations";
import { entityKey as reportsSliceKey } from "../../redux/entities/reportsKeys";
import { default as reportsOperations } from "../../redux/entities/reportsOperations";
import PortfolioPdfFilterComponent from "./PortfolioPdfFilterComponent";
import { entityKey as facilitiesSliceKey } from "../../redux/entities/facilitiesKeys";
import { default as facilitiesOperations } from "../../redux/entities/facilitiesOperations";
import { entityKey as filesSliceKey } from "../../redux/entities/filesKeys";
import { default as filesOperations } from "../../redux/entities/filesOperations";
import { withTableServerDataProvider } from "../../utils/tableServerDataProvider";
import { requestsSelectors } from "../../redux/entities/requests";

const mapStateToProps = state => {
  const { isAdmin } = state.authentication;
  const fieldsOfActionErrors = state.entities[fieldsOfActionSliceKey].errors;
  const fieldsOfActionById = state.entities[fieldsOfActionSliceKey].byId;
  const facilitiesAllIds = state.entities[facilitiesSliceKey].allIds;
  const facilityById = state.entities[facilitiesSliceKey].byId;
  const fileById = state.entities[filesSliceKey].byId;
  const { errors, byId, allIds, totalCount, filterOptions } = state.entities[reportsSliceKey];
  const isFetching = requestsSelectors.checkIfFetching(state.entities, reportsSliceKey);

  return {
    isAdmin,
    fieldsOfActionErrors,
    fieldsOfActionById,
    errors,
    byId,
    allIds,
    facilitiesAllIds,
    facilityById,
    fileById,
    totalCount,
    isFetching,
    filterOptions
  };
};

const mapDispatchToProps = dispatch => {
  const fieldsOfActionGetRequest = () => dispatch(fieldsOfActionOperations.fieldsOfActionGetRequest());
  const reportsGetRequest = (urlParameters) => dispatch(reportsOperations.reportsGetRequest(urlParameters));
  const reportGetRequest = (id) => dispatch(reportsOperations.reportGetRequest(id));
  const facilitiesGetRequest = () => dispatch(facilitiesOperations.facilitiesGetRequest());
  const filesByReportGetRequest = (reportId) => dispatch(filesOperations.filesByReportGetRequest(reportId));
  const reportsFilterOptionsRequest = (urlParameters) => dispatch(reportsOperations.reportsFilterOptionsRequest(urlParameters));

  return {
    fieldsOfActionGetRequest,
    reportsGetRequest,
    facilitiesGetRequest,
    reportGetRequest,
    filesByReportGetRequest,
    reportsFilterOptionsRequest,
  };
};

const PortfolioPdfFilterContainer = withTableServerDataProvider(withRouter(connect(mapStateToProps, mapDispatchToProps)(PortfolioPdfFilterComponent)));

export default PortfolioPdfFilterContainer;
