import * as R from 'ramda';
import { DateTime } from "luxon";

export const convertFieldViolations = data => {
  let fieldErrors = {};
  if (data && 0 < data.length) {
    fieldErrors = data.reduce((errors, violation) => {
      errors[violation.propertyPath] = violation.message;
      return errors;
    }, {});
  }
  return fieldErrors;
};

export const updateCancelTokens = (stateTokens, cancelProps) => {
  const { type, token, id } = cancelProps;
  let cancelTokens = R.clone(stateTokens[type] || []);
  if (token) {
    cancelTokens.push({
      token: token,
      id: id
    });
  } else {
    cancelTokens.shift();
  }
  return {
    ...stateTokens,
    [type]: cancelTokens
  };
};

export const getUrlParamFromString = (key, url) => {
  if (!key || !url) {
    return false;
  }
  const newKey = key.replace(/[[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + newKey + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const sortByKey = (arr, key, desc) => {
  if (!arr) {
    return [];
  }
  if (!key) {
    return arr;
  }

  const byKey = (desc) ? R.descend(R.prop(key)) : R.ascend(R.prop(key));

  return R.sort(byKey, arr);
};

export const compareByKey2Sort = (objA, objB, key, desc) => {
  if (!objA || !objB || !key) {
    return 0;
  }

  const valueA = ('string' === typeof objA[key]) ? objA[key].toUpperCase() : objA[key];
  const valueB = ('string' === typeof objB[key]) ? objB[key].toUpperCase() : objB[key];
  if (valueA < valueB) {
    return (desc) ? 1 : -1;
  }
  if (valueA >= valueB) {
    return (desc) ? -1 : 1;
  }
  return 0;
};

export const formatDate = (timestamp, format) => {
  const toFormat = format || "EEE dd.MM.yy, HH:mm";
  if (!timestamp || "" === timestamp) {
    return "-";
  } else if ("object" === typeof timestamp) {
    // TODO: Fix this depending on API changes
    return DateTime.fromISO(new Date(timestamp.date).toISOString())
      .setLocale('de')
      .toFormat(toFormat);
  } else {
    return DateTime.fromISO(timestamp)
      .setLocale('de')
      .toFormat(toFormat);
  }
};
