import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Field, Form, Formik } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import { TextField } from "formik-material-ui";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import config from './../../utils/config';
import { getAuthHeader } from "./../../utils/auth";
import { PortfolioPdfFilter } from "./../portfolio";
import { notificationsFlavors } from './../../redux/notifications';
import FormControl from "@material-ui/core/FormControl";
import ReactSelect from "../common/ReactSelect";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      width: 640,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  reports: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    textAlign: "center"
  },
  wrapper: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  dialogTitle: {
    margin: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 !important',
  },
  dialogActions: {
    justifyContent: 'center',
    margin: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

class PortfolioPdfComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      downloadUrl: null,
      isGenerating: false,
      filterDialogOpen: false,
      filterExpandedFirstTime: false,
      filteredReports: {}
    };
  }

  handleFilterDialogOpen = () => {
    this.setState({
      filterDialogOpen: true
    });
    if (!this.state.filterExpandedFirstTime) {
      this.setState({
        filterExpandedFirstTime: true,
      });
    }
  };

  handleFilterDialogClose = () => {
    this.setState({
      filterDialogOpen: false
    });
  };

  handleSubmit = async (values, formikBag) => {
    this.setState({
      downloadUrl: null,
      isGenerating: true,
    });

    try {
      const response = await this.createPdfRequest(values);

      this.setState({
        downloadUrl: response.data.result,
        isGenerating: false,
      });
    } catch (e) {
      console.error(e);
      this.props.notificationEnqueue("PDF konnte nicht erstellt werden", notificationsFlavors.ERROR);
      this.setState({
        isGenerating: false,
      });
    }

    formikBag.setSubmitting(false);
  };

  handleReset = () => {
    this.props.history.goBack();
  };

  createPdfRequest(values) {
    const data = Object.values(this.state.filteredReports)
      .filter(report => report.selected)
      .map(report => {
        return {
          id: report.id,
          media: Object.values(report.media),
        }
      });

    return axios
      .post(config.API_URL + "/pdf", {
        title: values.title,
        subTitle: values.subtitle,
        type: values.type,
        sort: values.sort,
        data: data,
      }, {
        headers: getAuthHeader()
      });
  }

  downloadPdf(downloadUrl) {
    window.open(downloadUrl, "_blank");
  }

  onFilterChanged(reportIds) {
    this.setState({
      filteredReports: reportIds
    });
  }

  getDefaultTitle() {
    // TODO: use luxon to find week
    const d = new Date();
    d.setHours(0,0,0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const weekNumber = Math.ceil((((d - new Date(d.getFullYear(),0,1)) / 8.64e7) + 1) / 7);

    return `Ausbildungsnachweis für KW ${weekNumber}`;
  }

  render() {
    const { classes, profile } = this.props;
    const { filterDialogOpen, filterExpandedFirstTime, downloadUrl, isGenerating, filteredReports } = this.state;

    const initialValues = {
      type: "explicit",
      sort: "dateAscend",
      title: this.getDefaultTitle(),
      subtitle: profile.companyName ? profile.companyName : ''
    };

    const filteredReportsCount = Object.keys(filteredReports)
      .filter(key => true === filteredReports[key].selected).length;
    let filteredReportsLabel = "Es werden alle Einträge verwendet";
    if (filteredReportsCount > 0) {
      filteredReportsLabel = filteredReportsCount === 1 ?
        "Es wird 1 Eintrag verwendet" :
        `Es werden ${filteredReportsCount} Einträge verwendet`;
    }

    const renderFilterDialog = () => {
      return (
        <Dialog
          open={filterDialogOpen}
          fullScreen
          keepMounted
          onClose={() => this.handleFilterDialogClose()}
        >
          <DialogTitle disableTypography classes={{
            root: classes.dialogTitle
          }}>
            <Typography variant="h6">Einträge auswählen</Typography>
            <IconButton aria-label="Close" className={classes.closeButton} onClick={() => this.handleFilterDialogClose()}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent classes={{
            root: classes.dialogContent
          }}>
            {filterExpandedFirstTime && <PortfolioPdfFilter onChangeHandler={reportIds => this.onFilterChanged(reportIds)}/>}
          </DialogContent>
          <DialogActions classes={{
            root: classes.dialogActions
          }}>
            <Button onClick={() => this.handleFilterDialogClose()} color="primary" variant="contained">
              Auswahl übernehmen
            </Button>
          </DialogActions>
        </Dialog>
      );
    };

    return (
      <>
        <Paper elevation={0} className={classes.paper}>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validate={values => {
              let errors = {};
              if (values.title === "") {
                errors.title = "Dies ist ein Pflichtfeld";
              }
              if (values.subtitle === "") {
                errors.subtitle = "Dies ist ein Pflichtfeld";
              }
              return errors;
            }}
            onSubmit={(values, formikBag) => {
              this.handleSubmit(values, formikBag);
            }}
            onReset={() => {
              this.handleReset();
            }}>
            {({ status, handleSubmit, handleReset, setFieldValue, setFieldTouched, values }) => (
              <Form
                autoComplete="off"
                onSubmit={evt => {
                  evt.stopPropagation();
                  handleSubmit(evt);
                }}
                onReset={evt => {
                  evt.stopPropagation();
                  handleReset(evt);
                }}
                className={classes.form}
              >
                {status &&
                <FormHelperText error>{status}</FormHelperText>
                }
                <Field component={TextField}
                       type="text"
                       id="title"
                       name="title"
                       label="Titel"
                       required={true}
                       fullWidth
                       margin="normal"/>
                <Field component={TextField}
                       type="text"
                       id="subtitle"
                       name="subtitle"
                       label="Untertitel"
                       required={true}
                       fullWidth
                       margin="normal"/>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth margin="normal">
                      <ReactSelect name="type"
                                   onChange={setFieldValue}
                                   onBlur={setFieldTouched}
                                   value={values.type}
                                   options={[{ value: 'explicit', label: 'Ausbildungsnachweis' }, {
                                     value: 'individual',
                                     label: 'Individuell'
                                   }]}
                                   label="Berichtstyp"
                                   isMulti={false}
                                   isClearable={false}/>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth margin="normal">
                      <ReactSelect name="sort"
                                   onChange={setFieldValue}
                                   onBlur={setFieldTouched}
                                   value={values.sort}
                                   options={[{ value: 'dateAscend', label: 'Datum aufsteigend' }, {
                                     value: 'dateDescend',
                                     label: 'Datum absteigend'
                                   }]}
                                   label="Sortierung"
                                   isMulti={false}
                                   isClearable={false}/>
                    </FormControl>
                  </Grid>
                </Grid>
                <div className={classes.reports}>
                  <Typography variant="body1" display="block" color="textPrimary" gutterBottom>{filteredReportsLabel}</Typography>
                  <Button variant="outlined" size="small" onClick={() => this.handleFilterDialogOpen()}>
                    Einträge auswählen
                  </Button>
                </div>
                {downloadUrl &&
                  <div className={classes.wrapper}>
                    <Button onClick={() => this.downloadPdf(downloadUrl)} color="secondary" variant="contained" fullWidth>
                      Bericht herunterladen
                    </Button>
                  </div>
                }
                <div className={classes.wrapper}>
                  <Button type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={isGenerating}>
                    {downloadUrl ? "Bericht neu erstellen" : "Bericht erstellen"}
                  </Button>
                  {isGenerating && <CircularProgress size={24} color="primary" className={classes.progress}/>}
                </div>
                <div className={classes.wrapper}>
                  <Button type="reset" color="primary">Abbrechen</Button>
                </div>
              </Form>
            )}
          </Formik>
        </Paper>
        {renderFilterDialog()}
      </>
    );
  }
}

export default withStyles(styles)(PortfolioPdfComponent);
