import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";

const styles = theme => ({
  contextWrapper: {
    position: "relative",
  },
  container: {
    position: "relative",
    "&:after": {
      transition: "opacity 200ms",
      position: "absolute",
      bottom: 0,
      display: "block",
      pointerEvents: "none",
      opacity: 1,
      background: "linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))",
      width: "100%",
      height: "75%",
      content: '""',
    },
  },
  entered: {
    "&:after": {
      opacity: 0,
    },
  },
  wrapperInner: {
    "& > :first-child": {
      marginTop: 0,
    },
  },
  expand: {
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    transform: "rotate(0deg)",
    position: "absolute",
    right: 0,
    bottom: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
  },
  expandOpen: {
    transform: "rotate(180deg)",
    backgroundColor: "transparent",
  },
});

class ShowMoreHtml extends React.Component {
  constructor(props) {
    super(props);

    this.containerElement = React.createRef();
    this.oldContainerHeight = null;

    this.state = {
      initialized: false,
      collapsible: false,
      collapsed: false,
    };
  }

  componentDidMount() {
    this.adjustToHeight();
  }

  componentDidUpdate() {
    let recheck = false;

    if (this.containerElement && this.containerElement.current) {
      const newContainerHeight = this.containerElement.current.offsetHeight;
      recheck = this.oldContainerHeight !== null && this.oldContainerHeight !== newContainerHeight;
      this.oldContainerHeight = newContainerHeight;
    }

    this.adjustToHeight(recheck);
  }

  /**
   * @param {boolean} recheck
   */
  adjustToHeight(recheck = false) {
    if (!recheck && (this.state.initialized || 0 === this.containerElement.current.offsetHeight)) {
      return;
    }

    const shouldCollapse = this.containerElement.current.offsetHeight > this.props.maxHeight;

    this.setState({
      initialized: true,
      collapsible: shouldCollapse,
      collapsed: shouldCollapse,
    });
  }

  handleExpandClick(evt) {
    evt.preventDefault();
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  }

  render() {
    const { classes, children, maxHeight } = this.props;
    const { collapsed } = this.state;

    if (!this.state.initialized || !this.state.collapsible) {
      return <div ref={this.containerElement}>{children}</div>;
    }

    return (
      <div className={classes.contextWrapper}>
        <Collapse in={!collapsed} collapsedHeight={maxHeight + "px"} classes={{
          container: classes.container,
          entered: classes.entered,
          wrapperInner: classes.wrapperInner
        }}>
          {children}
        </Collapse>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: !collapsed,
          })}
          onClick={(evt) => this.handleExpandClick(evt)}
          aria-expanded={!collapsed}
          aria-label="Mehr...">
          <ExpandMoreIcon/>
        </IconButton>
      </div>
    );
  }
}

export default withStyles(styles)(ShowMoreHtml);
