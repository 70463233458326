import { keys as genericKeys, type as genericType } from "../generics";

const { intents } = genericKeys;

const nameKeys = {
  REPORTS: "REPORTS",
  REPORT: "REPORT",
};
export const entityKey = nameKeys.REPORTS.toLowerCase();
export const duckTypes = {
  REPORTS_GET: genericType(entityKey, nameKeys.REPORTS, intents.GET),
  REPORT_GET: genericType(entityKey, nameKeys.REPORT, intents.GET),
  REPORT_PUT: genericType(entityKey, nameKeys.REPORT, intents.PUT),
  REPORT_POST: genericType(entityKey, nameKeys.REPORT, intents.POST),
  REPORT_DELETE: genericType(entityKey, nameKeys.REPORT, intents.DELETE),
  REPORT_FILTER_OPTIONS: genericType(entityKey, nameKeys.REPORT, intents.FETCH_FILTER_OPTIONS)
};
