import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './root';

const initialState = {};
const middlewares = [];
const enhancers = [];

middlewares.push(thunk);

if ('development' === process.env.NODE_ENV) {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if ('function' === typeof devToolsExtension) {
    enhancers.push(devToolsExtension());
  }

  const titleFormatter = (action, time, took) => {
    let type = '';
    if (null !== action.type && 'object' === typeof action.type) {
      type = (action.type.set) ? action.type.set : action.type.group;
    } else {
      type = action.type;
    }
    return `${type} @ ${time} (in ${took.toFixed(2)} ms)`;
  };

  const logger = createLogger({
    titleFormatter: titleFormatter,
  });

  middlewares.push(logger);
}

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(
      ...middlewares
    ),
    ...enhancers
  )
);

export default store;
