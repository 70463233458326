import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

const NotificationItem = ({
  title, message, read, icon = <ChatBubbleIcon/>, onClick = () => {}
}) => {
  return (
    <ListItem button onClick={onClick}>
      <ListItemAvatar>
        <Avatar>
          {icon}
        </Avatar>
      </ListItemAvatar>
      {read &&
      <ListItemText primary={title} secondary={message}/>
      }
      {!read &&
      <ListItemText primary={<strong>{title}</strong>} secondary={message}/>
      }
    </ListItem>
  );
};

export default NotificationItem;
