import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { duckTypes } from "../../redux/entities/fieldsOfActionKeys";
import { default as FieldOfActionForm } from "./FieldOfActionFormComponent";
import flavors from "../../redux/notifications/flavors";
import { responseFormikValidator } from "../../utils/validations";

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: 640,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
});

class FieldOfActionEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldOfActionId: null
        };
    }

    componentDidMount() {
        this.initData();
    }

    initData = () => {
        const { match } = this.props; // https://reacttraining.com/react-router/web/api/match
        if (match.params.id) {
            this.setState({
                fieldOfActionId: match.params.id
            }, () => {
                this.props.fieldOfActionGetRequest(this.state.fieldOfActionId);
            });
        }
    };

    handleSubmit = (values, formikBag) => {
        const id = this.state.fieldOfActionId;
        const data = {
            name: values.name || '',
            description: values.description || '',
            image: values.media.length ? values.media[0] : null
        };
        if (id) {
            this.props.fieldOfActionPutRequest(id, data, formikBag).then((axiosResponse) => {
                responseFormikValidator(axiosResponse, formikBag);
            });
        } else {
            this.props.notificationEnqueue('Es ist ein Fehler aufgetreten', flavors.ERROR);
        }
    };

    handleReset = () => {
        this.props.fieldsOfActionCancelRequest(duckTypes.FIELDOFACTION_PUT.group);
        this.props.history.goBack();
    };

    render() {
        const { classes, byId } = this.props;

        const item = byId[this.state.fieldOfActionId];

        return (
            <React.Fragment>
                <Paper elevation={0} className={classes.paper}>
                    <FieldOfActionForm item={item} submitHandler={ this.handleSubmit } resetHandler={ this.handleReset } />
                </Paper>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(FieldOfActionEditComponent);