import React from 'react';
import {
  Avatar,
  Chip,
  Button,
  ButtonGroup,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  FileCopy as FileCopyIcon,
  ExitToApp as DetailsIcon,
  AddBox as AddIcon
} from "@material-ui/icons";
import routes from "../../utils/routes";
import competenceCheckStatus from "../../utils/competenceCheckStatus";
import MUIDataTable from "mui-datatables";
import { labelsMuiDatatables } from "../../utils/labels";
import ShowMoreText from "../common/ShowMoreText";
import PortalToolbarMenu from '../common/navigation/PortalToolbarMenu';
import Alert from "@material-ui/lab/Alert";

const styles = theme => ({
  evalCounter: {
    margin: 0,
    padding: 0,

    '& li': {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      '&:first-child': {
        marginLeft: 0,
      },
    },

    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',

      '& li': {
        marginLeft: 0,
        '&:first-child': {
          marginTop: 0,
        },
      },
    },
  },
});

class CompetenceChecksComponent extends React.Component {
  componentDidMount() {
    this.initData();
  }

  initData() {
    const parametersByProps = {
      assigned: this.props.master ? 0 : 1,
      archived: this.props.archived ? 1 : 0
    };

    const adjustedCompetenceChecksGetRequest = (urlParameters) => this.props.competenceChecksGetRequest({
      ...urlParameters,
      ...parametersByProps
    });

    this.props.tableServerDataProvider.init(adjustedCompetenceChecksGetRequest);

    this.props.competenceChecksFilterOptionsRequest(parametersByProps);
  }

  handleRoute(evt, route) {
    evt.preventDefault();
    if (route) {
      this.props.history.push(route);
    }
  }

  handleCopy(id) {
    this.props.competenceCheckCopyRequest(id);
  }

  handleNew() {
    const createNewPath = routes.COMPETENCECHECK_NEW.path.replace(':master', this.props.master ? '1' : '0');
    this.props.history.push(createNewPath);
  }

  render() {
    const { classes, isAdmin, isInstructor, allIds, byId, archived, master, totalCount, filterOptions } = this.props;

    const isApprentice = !isAdmin && !isInstructor;

    const portalToolbarMenuData = [
      {
        "title": this.props.master ? "Neue Kompetenzcheckvorlage anlegen" : "Neuen Kompetenzcheck anlegen",
        "action": () => this.handleNew(),
        "icon": AddIcon,
        "visible": isInstructor && !archived,
        "disabled": false
      }
    ];

    const tableOptions = {
      selectableRows: 'none',
      elevation: 0,
      responsive: "stacked",
      filter: true,
      print: false,
      download: false,
      viewColumns: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 20, 50],
      textLabels: labelsMuiDatatables,
      serverSide: true,
      serverSideFilterList: [],
      count: totalCount
    };

    this.props.tableServerDataProvider.bindToTableOptions(tableOptions);

    const tableColumns = [{
        name: "title",
        label: "Titel",
      },
      {
        name: "description",
        label: "Bemerkungen",
        options: {
          filter: false,
          customBodyRender: (value) => {
            return <ShowMoreText maxLength={64}>{value}</ShowMoreText>;
          }
        }
      },
      {
        name: "specifications",
        label: "Beschreibungen",
        options: {
          display: master || archived,
        }
      },
      {
        name: isApprentice ? 'owner' : 'apprentice',
        label: isApprentice ? 'Ersteller' : 'Auszubildende*r',
        options: {
          display: !master,
          customBodyRender: id => {
            const userName = isApprentice ? byId[id].ownerName : byId[id].apprenticeName;
            return userName || '&nbsp;-';
          }
        }
      },
      {
        name: "evalutationsApprentice",
        label: "Einschätzungen Auszubildende*r",
        options: {
          display: !master,
          customBodyRender: values => {
            const evals = values.split('|');
            return (
              <ul className={classes.evalCounter}>
                <Chip
                  component="li"
                  size="small"
                  variant={0 < evals[0] ? 'default' : 'outlined'}
                  avatar={<Avatar>{evals[0] || '-'}</Avatar>}
                  label="offen"
                />
                <Chip
                  component="li"
                  size="small"
                  variant={0 < evals[1] ? 'default' : 'outlined'}
                  avatar={<Avatar>{evals[1] || '-'}</Avatar>}
                  label="eingereicht"
                />
              </ul>
            );
          }
        }
      },
      {
        name: "evalutationsInstructor",
        label: "Einschätzungen Ausbilder*in",
        options: {
          display: !master,
          customBodyRender: values => {
            const evals = values.split('|');
            return (
              <ul className={classes.evalCounter}>
                <Chip
                  component="li"
                  size="small"
                  variant={0 < evals[0] ? 'default' : 'outlined'}
                  avatar={<Avatar>{evals[0] || '-'}</Avatar>}
                  label="offen"
                />
                <Chip
                  component="li"
                  size="small"
                  variant={0 < evals[1] ? 'default' : 'outlined'}
                  avatar={<Avatar>{evals[1] || '-'}</Avatar>}
                  label="eingereicht"
                />
              </ul>
            );
          }
        }
      },
      {
        name: "status",
        label: "Status",
        options: {
          display: !master && !archived,
          customBodyRender: (value) => {
            return (
              <Alert
                severity={'Einschätzungen eingereicht' === value ? 'success' : 'warning'}
                color={'Einschätzungen eingereicht' === value ? 'success' : 'warning'}
              >{value}</Alert>
            );
          },
        },
      },
      {
        options: {
          customBodyRender: id => {
            const item = byId[id];
            return (
              <ButtonGroup color="primary">
                <Tooltip title="Details" aria-label="Details">
                  <Button onClick={evt => this.handleRoute(evt, routes.COMPETENCECHECK_DETAILS.path.replace(":id", item.id))}>
                    <DetailsIcon/>
                  </Button>
                </Tooltip>
                {item.permissions.canEdit && !item.apprentice && !archived && <Tooltip title="Bearbeiten" aria-label="Bearbeiten">
                  <Button onClick={evt => this.handleRoute(evt, routes.COMPETENCECHECK_EDIT.path.replace(":id", item.id))}>
                    <EditIcon/>
                  </Button>
                </Tooltip>}
                {item.permissions.canEdit && !item.apprentice && master && <Tooltip title="Kopieren" aria-label="Kopieren">
                  <Button onClick={() => this.handleCopy(item.id)}>
                    <FileCopyIcon/>
                  </Button>
                </Tooltip>}
              </ButtonGroup>
            );
          }
        }
      },
      /* virtual column for date filter not mapped to real data */
      {
        name: "createdAtFrom",
        options: {
          filter: true,
          display: false,
        }
      },
      /* virtual column for date filter not mapped to real data */
      {
        name: "createdAtTo",
        options: {
          filter: true,
          display: false,
        }
      },
    ];

    this.props.tableServerDataProvider.bindTableFilters(filterOptions, tableColumns);

    const tableData = allIds
      .map(itemId => {
        const item = byId[itemId];

        if (!item) return [];

        const { id, title, description, isArchived, competenceSpecifications, activeApprenticeEvaluations,  activeInstructorEvaluations, submittedApprenticeEvaluations, submittedInstructorEvaluations } = item;

        let statusCode = 10;
        if (isArchived) {
          statusCode = 40;
        } else if (activeApprenticeEvaluations || activeInstructorEvaluations) {
          statusCode = 20;
        } else if (0 < submittedApprenticeEvaluations && submittedApprenticeEvaluations === submittedInstructorEvaluations) {
          statusCode = 30;
        }

        return [title, description, competenceSpecifications.length, id, `${activeApprenticeEvaluations}|${submittedApprenticeEvaluations}`, `${activeInstructorEvaluations}|${submittedInstructorEvaluations}`, competenceCheckStatus[statusCode], id];
      });

    return (
      <React.Fragment>
        <PortalToolbarMenu menuData={portalToolbarMenuData}/>
        <MUIDataTable title={master ? "Alle Kompetenzcheckvorlagen" : (archived ? "Archivierte Kompetenzchecks" : "Alle Kompetenzchecks")} options={tableOptions} columns={tableColumns} data={tableData}/>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CompetenceChecksComponent);
