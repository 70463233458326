import React, { Component } from 'react';
import { Field, Form, Formik } from 'formik';
import Button from '@material-ui/core/Button/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import FilesSelection from '../common/FilesSelectionContainer';
import LinksSelection from '../common/LinksSelectionContainer';
import RichTextField from "../common/RichTextField";

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  wrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class ResultFormComponent extends Component {
  render() {
    const { classes, item, submitHandler, resetHandler } = this.props;

    let initialValues = {
      text: item.text || '',
      media: item.media || [],
      links: item.links || []
    };

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={values => {
          let errors = {};
          if (values.text === '') {
            errors.text = 'Dies ist ein Pflichtfeld';
          }
          return errors;
        }}
        onSubmit={(values, formikBag) => {
          submitHandler(values, formikBag);
        }}>
        {({ status, values, touched, errors, setFieldValue, setFieldTouched, isSubmitting, handleSubmit, handleReset }) => (
          <Form
            autoComplete="off"
            onSubmit={evt => {
              evt.stopPropagation();
              handleSubmit(evt);
            }}
            onReset={evt => {
              evt.stopPropagation();
              handleReset(evt);
              resetHandler();
            }}
            className={classes.form}
          >
            {status &&
            <FormHelperText error>{status}</FormHelperText>
            }
            <Field component={RichTextField}
                   type="text"
                   id="text"
                   name="text"
                   label="Inhalt"
                   required
                   fullWidth
                   margin="normal"/>
            <Field component={FilesSelection}
                   id="media"
                   name="media"
                   label="Dateien"
                   value={values.media}
                   onChange={setFieldValue}
            />
            <Field component={LinksSelection}
                   id="links"
                   name="links"
                   label="Links"
                   value={values.links}
                   onChange={setFieldValue}
            />
            <div className={classes.wrapper}>
              <Button type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}>
                Speichern
              </Button>
              {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress}/>}
            </div>
            <div className={classes.wrapper}>
              <Button type="reset" color="primary">Abbrechen</Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(ResultFormComponent);
