import { keys as genericKeys, type as genericType } from "../generics";

const { intents } = genericKeys;

const nameKeys = {
  LINKS: 'LINKS',
  LINK: 'LINK',
};
export const entityKey = nameKeys.LINKS.toLowerCase();
export const duckTypes = {
  LINKS_GET: genericType(entityKey, nameKeys.LINKS, intents.GET),
  LINK_GET: genericType(entityKey, nameKeys.LINK, intents.GET),
  LINK_PUT: genericType(entityKey, nameKeys.LINK, intents.PUT),
  LINK_POST: genericType(entityKey, nameKeys.LINK, intents.POST),
  LINK_DELETE: genericType(entityKey, nameKeys.LINK, intents.DELETE)
};