import { withRouter } from "react-router";
import { connect } from 'react-redux';
import TaskEditComponent from './TaskEditComponent';
import { entityKey as tasksSliceKey } from '../../redux/entities/tasksKeys';
import { default as tasksOperations } from '../../redux/entities/tasksOperations';
import { entityKey as professionsSliceKey } from '../../redux/entities/professionsKeys';
import { default as professionsOperations } from '../../redux/entities/professionsOperations';
import { entityKey as usersSliceKey } from '../../redux/entities/usersKeys';
import { default as usersOperations } from '../../redux/entities/usersOperations';
import { entityKey as fieldsOfActionSliceKey } from '../../redux/entities/fieldsOfActionKeys';
import { notificationsOperations } from "../../redux/notifications";
import { metaKeys, uiOperations } from "../../redux/ui";
import { entityKey as filesSliceKey } from '../../redux/entities/filesKeys';
import { requestsSelectors } from "../../redux/entities/requests";
import fieldsOfActionOperations from "../../redux/entities/fieldsOfActionOperations";


const mapStateToProps = state => {
  const isFetching = requestsSelectors.checkIfFetching(state.entities, usersSliceKey) || requestsSelectors.checkIfFetching(state.entities, filesSliceKey);
  const { myId, isAdmin, isInstructor } = state.authentication;
  const { errors, byId } = state.entities[tasksSliceKey];
  const professionsById = state.entities[professionsSliceKey].byId;
  const usersById = state.entities[usersSliceKey].byId;
  const fieldsOfActionById = state.entities[fieldsOfActionSliceKey].byId;
  const filesById = state.entities[filesSliceKey].byId;

  return {
    isFetching,
    myId,
    isAdmin,
    isInstructor,
    errors,
    byId,
    professionsById,
    usersById,
    fieldsOfActionById,
    filesById
  };
};

const mapDispatchToProps = dispatch => {
  const taskGetRequest = id => dispatch(tasksOperations.taskGetRequest(id));
  const taskPutRequest = (id, data, formikBag) => dispatch(tasksOperations.taskPutRequest(id, data, formikBag));

  const tasksCancelRequest = (cancelType, cancelMessage) => dispatch(tasksOperations.tasksCancelRequest(cancelType, cancelMessage));

  const professionsGetRequest = () => dispatch(professionsOperations.professionsGetRequest());

  const usersGetRequest = () => dispatch(usersOperations.usersGetRequest());

  const titleChange = value => dispatch(uiOperations.metaChangeOperationGeneric(metaKeys.TITLE, value));
  const parentRouteChange = value => dispatch(uiOperations.metaChangeOperationGeneric(metaKeys.PARENTROUTE, value));

  const fieldOfActionsByProfessionGetRequest = professionId => dispatch(fieldsOfActionOperations.fieldOfActionsByProfessionGetRequest(professionId));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    taskGetRequest,
    taskPutRequest,
    tasksCancelRequest,
    professionsGetRequest,
    usersGetRequest,
    titleChange,
    parentRouteChange,
    notificationEnqueue,
    fieldOfActionsByProfessionGetRequest
  };
};

const TaskEditContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskEditComponent));

export default TaskEditContainer;