import { withRouter } from "react-router";
import { connect } from 'react-redux';
import FacilityNewComponent from './FacilityNewComponent';
import { entityKey as facilitiesSliceKey } from '../../redux/entities/facilitiesKeys';
import { default as facilitiesOperations } from '../../redux/entities/facilitiesOperations';
import { notificationsOperations } from "../../redux/notifications";
import { default as usersOperations } from "../../redux/entities/usersOperations";
import { entityKey as usersSliceKey } from '../../redux/entities/usersKeys';

const mapStateToProps = state => {
  const { myId, isAdmin, isInstructor } = state.authentication;
  const { errors, byId } = state.entities[facilitiesSliceKey];
  const usersById = state.entities[usersSliceKey].byId;

  return {
    myId,
    isAdmin,
    errors,
    byId,
    isInstructor,
    usersById
  };
};

const mapDispatchToProps = dispatch => {
  const facilityPostRequest = (data, formikBag) => dispatch(facilitiesOperations.facilityPostRequest(data, formikBag));
  const facilitiesCancelRequest = (cancelType, cancelMessage) => dispatch(facilitiesOperations.facilitiesCancelRequest(cancelType, cancelMessage));

  const usersGetRequest = () => dispatch(usersOperations.usersGetRequest());

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    facilityPostRequest,
    facilitiesCancelRequest,
    usersGetRequest,
    notificationEnqueue
  };
};

const FacilityNewContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FacilityNewComponent));

export default FacilityNewContainer;