import { withRouter } from "react-router";
import { connect } from 'react-redux';
import UserEditComponent from './UserEditComponent';
import { authenticationSelectors } from "../../redux/authentication";
import { requestsSelectors } from "../../redux/entities/requests";
import { entityKey as usersSliceKey } from '../../redux/entities/usersKeys';
import { default as usersOperations } from '../../redux/entities/usersOperations';
import { entityKey as filesSliceKey } from '../../redux/entities/filesKeys';
import { notificationsOperations } from "../../redux/notifications";
import { metaKeys, uiOperations } from "../../redux/ui";
import { default as professionsOperations } from "../../redux/entities/professionsOperations";
import { entityKey as professionsSliceKey } from "../../redux/entities/professionsKeys";

const mapStateToProps = state => {
  const isFetching = authenticationSelectors.checkIfFetching(state) || requestsSelectors.checkIfFetching(state.entities, usersSliceKey);
  const { myId, isAdmin, isInstructor } = state.authentication;
  const profile = authenticationSelectors.profile(state);
  const { errors, byId } = state.entities[usersSliceKey];
  const filesById = state.entities[filesSliceKey].byId;
  const professionsById = state.entities[professionsSliceKey].byId;

  return {
    isFetching,
    myId,
    isAdmin,
    isInstructor,
    profile,
    errors,
    byId,
    filesById,
    professionsById
  };
};

const mapDispatchToProps = dispatch => {
  const userGetRequest = id => dispatch(usersOperations.userGetRequest(id));
  const userPutRequest = (id, data, formikBag, noRedirect) => dispatch(usersOperations.userPutRequest(id, data, formikBag, noRedirect));
  const userDeleteRequest = id => dispatch(usersOperations.userDeleteRequest(id));
  const userRestoreRequest = id => dispatch(usersOperations.userRestoreRequest(id));

  const usersCancelRequest = (cancelType, cancelMessage) => dispatch(usersOperations.usersCancelRequest(cancelType, cancelMessage));

  const titleChange = value => dispatch(uiOperations.metaChangeOperationGeneric(metaKeys.TITLE, value));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  const professionsGetRequest = () => dispatch(professionsOperations.professionsGetRequest());

  return {
    userGetRequest,
    userPutRequest,
    usersCancelRequest,
    titleChange,
    notificationEnqueue,
    professionsGetRequest,
    userDeleteRequest,
    userRestoreRequest
  };
};

const UserEditContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(UserEditComponent));

export default UserEditContainer;