import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper/Paper";
import withStyles from '@material-ui/core/styles/withStyles';
import TaskIcon from "@material-ui/icons/Assignment";
import CompetenceCheckIcon from "@material-ui/icons/Star";
import FileIcon from "@material-ui/icons/AttachFile";
import PortalToolbarMenu from "../common/navigation/PortalToolbarMenu";
import routes from "../../utils/routes";
import roles from "../../utils/roles";
import { labelsMuiDatatables } from "../../utils/labels";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import DetailsIcon from "@material-ui/icons/ExitToApp";
import MUIDataTable from "mui-datatables";
import { Avatar, Chip } from "@material-ui/core";
import competenceCheckStatus from "../../utils/competenceCheckStatus";
import { compareByKey2Sort, formatDate } from "../../utils/helper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { default as DashboardApprenticeDetails } from "./DashboardApprenticeDetailsContainer";
import config from "../../utils/config";
import Alert from '@material-ui/lab/Alert';

const styles = theme => ({
  paper: {
    margin: theme.spacing(3),
  },
  paperTitle: {
    alignItems: "center",
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.disabled,
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
  },
  dates: {
    whiteSpace: "nowrap"
  },
  evalCounter: {
    margin: 0,
    padding: 0,

    '& li': {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      '&:first-child': {
        marginLeft: 0,
      },
    },

    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',

      '& li': {
        marginLeft: 0,
        '&:first-child': {
          marginTop: 0,
        },
      },
    },
  },
  dialogTitle: {
    margin: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 !important',
  },
  dialogActions: {
    justifyContent: 'center',
    margin: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

class DashboardInstructorComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      apprenticeDialogOpen: false,
      apprenticeDialogUserId: null
    };
  }

  componentDidMount() {
    this.initData();
  }

  initData = () => {
    this.props.usersGetRequest({
      role: "ROLE_APPRENTICE"
    });

    this.props.tasksGetRequest({
      sort: "-updatedAt",
      archived: 0,
      master: 0,
    });

    this.props.competenceChecksGetRequest({
      sort: "-updatedAt",
      archived: 0,
      master: 0,
    });
  };

  handleApprenticeDialogOpen = id => {
    if (!id) {
      return;
    }
    this.setState({
      apprenticeDialogOpen: true,
      apprenticeDialogUserId: id
    });
  };

  handleApprenticeDialogClose = () => {
    this.setState({
      apprenticeDialogOpen: false,
      apprenticeDialogUserId: null
    });
  };

  handleRoute(evt, route) {
    if (evt) {
      evt.preventDefault();
    }
    if (route) {
      this.props.history.push(route);
    }
  }

  render() {
    const { classes, usersById, tasksById, competenceChecksById, filesById } = this.props;
    const { apprenticeDialogOpen, apprenticeDialogUserId } = this.state;

    const portalToolbarMenuData = [
      {
        "title": this.props.master ? "Neue Aufgabenvorlage anlegen" : "Neue Aufgabe anlegen",
        "action": () => this.handleRoute(null, routes.TASK_NEW.path.replace(':master', this.props.master ? '1' : '0')),
        "icon": TaskIcon,
        "visible": true,
        "disabled": false
      },
      {
        "title": this.props.master ? "Neue Kompetenzcheckvorlage anlegen" : "Neuen Kompetenzcheck anlegen",
        "action": () => this.handleRoute(null, routes.COMPETENCECHECK_NEW.path.replace(':master', this.props.master ? '1' : '0')),
        "icon": CompetenceCheckIcon,
        "visible": true,
        "disabled": false
      },
      {
        "title": "Neue Datei hinzufügen",
        "action": () => this.handleRoute(null, routes.FILE_NEW.path),
        "icon": FileIcon,
        "visible": true,
        "disabled": false
      }
    ];

    const apprenticesTableOptions = {
      selectableRows: "none",
      elevation: 0,
      responsive: "stacked",
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      rowsPerPage: 5,
      rowsPerPageOptions: [5, 10, 20, 50],
      textLabels: labelsMuiDatatables
    };

    const apprenticesTableColumns = [
      {
        name: "avatar",
        label: "Avatar",
        options: {
          sort: false,
          customBodyRender: id => {
            const item = usersById[id];

            const avatarUrl = item && item.avatar && filesById && filesById[item.avatar] ? config.DOWNLOAD_URL + `/uploads/${filesById[item.avatar].filename}` : null;

            return avatarUrl ?
              <Avatar src={avatarUrl} alt={`${item.firstName} ${item.lastName}`} /> :
              <Avatar>{item.firstName.charAt(0).toUpperCase()}{item.lastName.charAt(0).toUpperCase()}</Avatar>;
          }
        }
      },
      {
        name: "firstName",
        label: "Vorname",
      },
      {
        name: "lastName",
        label: "Nachname",
      },
      {
        name: "username",
        label: "E-Mail",
      },
      {
        name: "actions",
        label: " ",
        options: {
          sort: false,
          setCellProps: value => ({ align: "right" }),
          customBodyRender: id => {
            return (
              <ButtonGroup color="primary">
                <Tooltip title="Details" aria-label="Details">
                  <Button onClick={() => this.handleApprenticeDialogOpen(id)}>
                    <DetailsIcon/>
                  </Button>
                </Tooltip>
              </ButtonGroup>
            );
          }
        }
      }
    ];

    const apprenticesTableData = !usersById ? [] : Object.keys(usersById)
      .filter(itemId =>
        usersById[itemId] &&
        usersById[itemId].roles.indexOf(roles.APPRENTICE) > -1)
      .map(itemId => {
        const item = usersById[itemId];

        if (!item) {
          return [];
        }

        const { id, firstName, lastName, username } = item;

        return [id, firstName, lastName, username, id, id, id];
      });

    const renderApprenticeDialog = () => {
      const apprentice = usersById[apprenticeDialogUserId] || null;

      return (
        <Dialog
          open={apprenticeDialogOpen}
          fullScreen
          keepMounted
          onClose={() => this.handleApprenticeDialogClose()}
        >
          <DialogTitle disableTypography classes={{
            root: classes.dialogTitle
          }}>
            <Typography variant="h6">
              Details
              {apprentice && ` ${apprentice.firstName} ${apprentice.lastName}`}
            </Typography>
            <IconButton aria-label="Close" className={classes.closeButton} onClick={() => this.handleApprenticeDialogClose()}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent classes={{
            root: classes.dialogContent
          }}>
            {apprenticeDialogUserId && <DashboardApprenticeDetails userId={apprenticeDialogUserId}/>}
          </DialogContent>
          <DialogActions classes={{
            root: classes.dialogActions
          }}>
            <Button onClick={() => this.handleApprenticeDialogClose()} color="primary" variant="contained">
              Fertig
            </Button>
          </DialogActions>
        </Dialog>
      );
    };

    const tableOptions = {
      selectableRows: "none",
      elevation: 0,
      responsive: "stacked",
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      search: false,
      pagination: false,
      sort: false,
      textLabels: labelsMuiDatatables
    };

    const tasksTableColumns = [
      {
        name: "title",
        label: "Titel"
      },
      {
        name: "fieldOfAction",
        label: "Aufgabenbereich"
      },
      {
        name: "apprentices",
        label: "Auszubildende"
      },
      {
        name: "subTaskCounter",
        label: "TA (offen)"
      },
      {
        name: "timestamps",
        label: "Erstellt, geändert",
        options: {
          customBodyRender: timestamps => {
            return (
              <div className={classes.dates}>
                {formatDate(timestamps[0])}<br/>
                {formatDate(timestamps[1])}
              </div>
            )
          }
        }
      },
      {
        name: "actions",
        label: " ",
        options: {
          setCellProps: value => ({ align: "right" }),
          customBodyRender: taskKey => {
            return (
              <ButtonGroup color="primary">
                <Tooltip title="Details" aria-label="Details">
                  <Button onClick={evt => this.handleRoute(evt, routes.TASK_DETAILS.path.replace(":id", taskKey))}>
                    <DetailsIcon/>
                  </Button>
                </Tooltip>
              </ButtonGroup>
            );
          }
        }
      }
    ];

    const tasksTableData = !tasksById ? [] : Object.keys(tasksById)
      .filter(itemId =>
        tasksById[itemId] &&
        !tasksById[itemId].master &&
        !tasksById[itemId].isArchived &&
        !tasksById[itemId].deleted &&
        tasksById[itemId].status === 10 &&
        tasksById[itemId].apprentices.length > 0)
      .sort((a, b) => compareByKey2Sort(tasksById[a], tasksById[b], "updatedAt", true))
      .slice(0, 3)
      .map(itemId => {
        const item = tasksById[itemId];

        if (!item) {
          return [];
        }

        const { title, createdAt, updatedAt, fieldOfActionName, apprenticeNames, subTaskCount, subTaskOpenCount, taskKey } = item;

        const timestamps = [
          createdAt,
          updatedAt
        ];

        const nameListConvert = (names) => {
          if (!names || (names.length === 1 && undefined === names[0])) {
            return "-";
          }
          const namesArray = [];
          if (names.length > 0) {
            names
              .sort((a, b) => a.firstName.localeCompare(b.firstName))
              .forEach(name => {
                namesArray.push(`${name.firstName} ${name.lastName}`);
              });
          }

          return (namesArray.length > 0) ? namesArray.join(", ") : "-";
        };

        const apprenticesList = nameListConvert(apprenticeNames);

        const subTasksData = `${subTaskCount} (${subTaskOpenCount})`;

        return [title, fieldOfActionName, apprenticesList, subTasksData, timestamps, taskKey];
      });

    const competenceChecksTableColumns = [
      {
        name: "title",
        label: "Titel",
      },
      {
        name: 'apprentice',
        label: 'Auszubildende*r'
      },
      {
        name: "evalutationsApprentice",
        label: "Einschätzungen Auszubildende*r",
        options: {
          customBodyRender: values => {
            const evals = values.split('|');
            return (
              <ul className={classes.evalCounter}>
                <Chip component="li" size="small" variant={evals[0] > 0 ? 'default' : 'outlined'} avatar={<Avatar>{evals[0] || '-'}</Avatar>} label="offen" />
                <Chip component="li" size="small" variant={evals[1] > 0 ? 'default' : 'outlined'} avatar={<Avatar>{evals[1] || '-'}</Avatar>} label="eingereicht" />
              </ul>
            );
          }
        }
      },
      {
        name: "evalutationsInstructor",
        label: "Einschätzungen Ausbilder*in",
        options: {
          customBodyRender: values => {
            const evals = values.split('|');
            return (
              <ul className={classes.evalCounter}>
                <Chip component="li" size="small" variant={evals[0] > 0 ? 'default' : 'outlined'} avatar={<Avatar>{evals[0] || '-'}</Avatar>} label="offen" />
                <Chip component="li" size="small" variant={evals[1] > 0 ? 'default' : 'outlined'} avatar={<Avatar>{evals[1] || '-'}</Avatar>} label="eingereicht" />
              </ul>
            );
          }
        }
      },
      {
        name: "status",
        label: "Status",
        options: {
          customBodyRender: (value) => {
            return (
              <Alert
                severity={'Einschätzungen eingereicht' === value ? 'success' : 'warning'}
                color={'Einschätzungen eingereicht' === value ? 'success' : 'warning'}
              >{value}</Alert>
            );
          },
        },
      },
      {
        name: "timestamps",
        label: "Erstellt, geändert",
        options: {
          customBodyRender: timestamps => {
            return (
              <div className={classes.dates}>
                {formatDate(timestamps[0])}<br/>
                {formatDate(timestamps[1])}
              </div>
            )
          }
        }
      },
      {
        options: {
          setCellProps: value => ({ align: "right" }),
          customBodyRender: id => {
            const item = competenceChecksById[id];
            return (
              <ButtonGroup color="primary">
                <Tooltip title="Details" aria-label="Details">
                  <Button onClick={evt => this.handleRoute(evt, routes.COMPETENCECHECK_DETAILS.path.replace(":id", item.id))}>
                    <DetailsIcon/>
                  </Button>
                </Tooltip>
              </ButtonGroup>
            );
          }
        }
      }
    ];

    const competenceChecksTableData = !competenceChecksById ? [] : Object.keys(competenceChecksById)
      .filter(itemId =>
        competenceChecksById[itemId] &&
        !competenceChecksById[itemId].isArchived &&
        competenceChecksById[itemId].status < 40 &&
        competenceChecksById[itemId].apprentice)
      .sort((a, b) => compareByKey2Sort(competenceChecksById[a], competenceChecksById[b], "updatedAt", true))
      .slice(0, 3)
      .map(itemId => {
        const item = competenceChecksById[itemId];

        if (!item) {
          return [];
        }

        const { id, title, createdAt, updatedAt, apprenticeName, activeApprenticeEvaluations, activeInstructorEvaluations, submittedApprenticeEvaluations, submittedInstructorEvaluations } = item;

        const timestamps = [
          createdAt,
          updatedAt
        ];

        let statusCode = 10;
        if (activeApprenticeEvaluations || activeInstructorEvaluations) {
          statusCode = 20;
        } else if (submittedApprenticeEvaluations > 0 && submittedApprenticeEvaluations === submittedInstructorEvaluations) {
          statusCode = 30;
        }

        return [title, apprenticeName, `${activeApprenticeEvaluations}|${submittedApprenticeEvaluations}`, `${activeInstructorEvaluations}|${submittedInstructorEvaluations}`, competenceCheckStatus[statusCode], timestamps, id];
      });

    return (
      <React.Fragment>
        <PortalToolbarMenu menuData={portalToolbarMenuData}/>
        <Paper elevation={2} className={classes.paper}>
          <MUIDataTable title={"Meine Auszubildenden"} options={apprenticesTableOptions} columns={apprenticesTableColumns} data={apprenticesTableData}/>
        </Paper>
        <Paper elevation={2} className={classes.paper}>
          <div className={classes.paperTitle}>
            <Typography component="h2" variant="h6" color="primary">Meine letzten drei aktiven, zugewiesenen Aufgaben</Typography>
            <Button variant="outlined" size="small" color="primary" onClick={evt => this.handleRoute(evt, routes.TASKS.path)}>Alle Aufgaben</Button>
          </div>
          <MUIDataTable options={tableOptions} columns={tasksTableColumns} data={tasksTableData}/>
        </Paper>
        <Paper elevation={2} className={classes.paper}>
          <div className={classes.paperTitle}>
            <Typography component="h2" variant="h6" color="primary">Meine letzten drei aktiven, zugewiesenen Kompetenzchecks</Typography>
            <Button variant="outlined" size="small" color="primary" onClick={evt => this.handleRoute(evt, routes.COMPETENCECHECKS.path)}>Alle Kompetenz&shy;checks</Button>
          </div>
          <MUIDataTable options={tableOptions} columns={competenceChecksTableColumns} data={competenceChecksTableData}/>
        </Paper>
        {renderApprenticeDialog()}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DashboardInstructorComponent);
