import React from 'react';
import { Field, Form, Formik } from "formik";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from '@material-ui/core/CircularProgress/index';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ReactSelect from "../common/ReactSelect";
import taskPhases from "../../utils/taskPhases";
import FilesSelection from "../common/FilesSelectionContainer";
import LinksSelection from "../common/LinksSelectionContainer";
import RichTextField from '../common/RichTextField';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  wrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
});

class SubTaskNewFormComponent extends React.Component {
  render() {
    const { classes, isAdmin, isInstructor, taskReady, phaseId, submitHandler, resetHandler } = this.props;

    return (
      <Formik
        initialValues = {{
          title: '',
          phase: parseInt(phaseId) || 1,
          description: '',
          media: [],
          links: []
        }}
        enableReinitialize={true}
        validate = {values => {
          let errors = {};
          if ('' === values.title) {
            errors.title = 'Dies ist ein Pflichtfeld';
          }
          if ('' === values.fieldOfAction) {
            errors.fieldOfAction = 'Bitte Auswahl treffen';
          }
          return errors;
        }}
        onSubmit = {(values, formikBag) => {
          submitHandler(values, formikBag);
        }}>
        {({ status, values, touched, errors, setFieldValue, setFieldTouched, isSubmitting, handleSubmit, handleReset }) => (
          <Form
            autoComplete="off"
            onSubmit={evt => {
              evt.stopPropagation();
              handleSubmit(evt);
            }}
            onReset={evt => {
              evt.stopPropagation();
              handleReset(evt);
              resetHandler();
            }}
            className={classes.form}
          >
            {status &&
                            <FormHelperText error>{status}</FormHelperText>
            }
            <FormControl fullWidth margin="normal" error={!!errors.phase && touched.phase}>
              <ReactSelect name="phase"
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                value={values.phase}
                error={errors.phase}
                touched={touched.phase}
                options={[{
                  value: 1,
                  label: taskPhases[1].label,
                  isDisabled: !isAdmin && !isInstructor
                }, {
                  value: 2,
                  label: taskPhases[2].label,
                  isDisabled: !isAdmin && !isInstructor
                }, {
                  value: 3,
                  label: taskPhases[3].label,
                  isDisabled: !isAdmin && !isInstructor
                }, {
                  value: 4,
                  label: taskPhases[4].label,
                  isDisabled: !isAdmin && !isInstructor
                }]}
                placeholder="Phase auswählen..."
                label="Phase"
                isMulti={false}
                isClearable={false}/>
              {(!!errors.phase && touched.phase) &&
                            <FormHelperText>{errors.phase}</FormHelperText>
              }
            </FormControl>
            <Field component={TextField}
              type="text"
              id="title"
              name="title"
              label="Titel"
              required
              fullWidth
              margin="normal"/>
            <Field component={RichTextField}
              type="text"
              id="description"
              name="description"
              label="Beschreibung"

              // maxLength={250}
              fullWidth
              margin="normal"/>
            <Field component={FilesSelection}
              id="media"
              name="media"
              label="Dateien"
              value={values.media}
              onChange={setFieldValue}
            />
            <Field component={LinksSelection}
              id="links"
              name="links"
              label="Links"
              value={values.links}
              onChange={setFieldValue}
            />
            <div className={classes.wrapper}>
              <Button type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting || !taskReady}>
                                Speichern
              </Button>
              {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress} />}
            </div>
            <div className={classes.wrapper}>
              <Button type="reset" color="primary">Abbrechen</Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(SubTaskNewFormComponent);
