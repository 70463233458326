import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListFiles from "../common/ListFiles";
import config from "../../utils/config";
import ReactHtmlParser from "react-html-parser";
import ShowMoreHtml from "../common/ShowMoreHtml";
import EditIcon from "@material-ui/icons/Edit";
import PortalToolbarMenu from "../common/navigation/PortalToolbarMenu";
import routes from "../../utils/routes";
import { formatDate } from "../../utils/helper";

const styles = theme => ({
  box: {
    padding: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.grey[50],
  },
  phaseGrid: {
    borderStyle: "solid",
    borderColor: theme.palette.grey[300],
    borderWidth: "1px 1px 0 0",
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    "&:last-child": {
      borderRightWidth: 0,
    },
  },
  phaseHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  phaseHeaderTitle: {
    marginRight: theme.spacing(1),
  },
  inline: {
    display: "inline",
  },
  uncollapseButton: {
    float: "right",
  },
});

class ReportComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportId: null
    };
  }

  componentDidMount() {
    this.initData();
  }

  componentWillUnmount() {
    this.props.titleChange(null);
    this.props.parentRouteChange(null);
  }

  initData() {
    const { match, reportGetRequest } = this.props;

    if (match.params.id) {
      this.setState({
        reportId: match.params.id
      }, () => {
        reportGetRequest(this.state.reportId).then(() => {
          this.updateMeta();
        });
      });
    }
  }

  updateMeta() {
    const { byId, titleChange, parentRouteChange } = this.props;
    const { reportId } = this.state;

    titleChange(null);
    parentRouteChange(null);

    if (!reportId) {
      return;
    }

    const item = byId[reportId];
    let parentRoute = {
      ...routes.PORTFOLIO
    };
    const fieldOfActionId = item && item.fieldOfActions && 0 < item.fieldOfActions.length ?
      item.fieldOfActions[0] : null;


    if (fieldOfActionId) {
      parentRoute = {
        ...routes.REPORTS
      };
      const parentPath = parentRoute.path;
      parentRoute.path = parentPath.replace(':fieldOfActionId', fieldOfActionId);
    }

    titleChange(item && item.title ? 'Eintrag: ' + item.title : null);
    parentRouteChange(parentRoute);
  }

  handleEdit() {
    const { reportId } = this.state;

    if (!reportId) {
      return;
    }

    this.props.history.push(routes.REPORT_EDIT.path.replace(':id', reportId));
  }

  handleDownload(evt, id) {
    evt.preventDefault();
    if (id) {
      const filename = this.props.filesById[id].filename;
      window.open(config.DOWNLOAD_URL + `/uploads/${filename}`);
    }
  }

  render() {
    const { byId, filesById, classes } = this.props;
    const { reportId } = this.state;

    const item = byId[reportId];

    if (!item) {
      return null;
    }

    const hasDescription = item.text && item.text.trim().length > 0;
    const hasMediaItems = item.media && item.media.length > 0;
    const canEdit = item.permissions && item.permissions.canEdit;

    const portalToolbarMenuData = [
      {
        "title": "Bearbeiten",
        "action": () => this.handleEdit(),
        "icon": EditIcon,
        "visible": canEdit,
        "disabled": false
      }
    ];

    const renderToolbarMenu = () => {
      return (
        <React.Fragment>
          <PortalToolbarMenu menuData={portalToolbarMenuData}/>
        </React.Fragment>
      );
    };

    return <>
      {renderToolbarMenu()}
      <Grid container>
        <Grid item xs={12} sm={12} md={8}>
          <Box className={classes.box}>
            <Typography component="h2" variant="overline" gutterBottom>Titel</Typography>
            <Typography component="h1" variant="h5" gutterBottom>{item ? item.title : "-"}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Divider/>
                <Typography component="h2" variant="overline" gutterBottom>Von - bis</Typography>
                <Typography component="p" variant="body1" gutterBottom>
                  {formatDate(item.dateFrom, "EEE dd.MM.yyyy")} - {formatDate(item.dateTo, "EEE dd.MM.yyyy")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Divider/>
                <Typography component="h2" variant="overline" gutterBottom>Dauer</Typography>
                <Typography component="p" variant="body1" gutterBottom>
                  {item.duration} {item.duration === 1 ? "Stunde" : "Stunden"}
                </Typography>
              </Grid>
            </Grid>
            <Divider/>
            <Typography component="h2" variant="overline" gutterBottom>Beschreibung</Typography>
            {hasDescription ? (
              <ShowMoreHtml maxHeight={96}>
                {ReactHtmlParser(item.text)}
              </ShowMoreHtml>
            ) : (
              <Typography component="p" variant="body1" gutterBottom>-</Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box elevation={0} className={classes.box}>
            <Typography component="h2" variant="overline" gutterBottom>Aufgabenbereich</Typography>
            <Typography component="p" variant="body1" gutterBottom>
              {item.fieldOfActionName || "-"}
            </Typography>
            <Divider/>
            <Typography component="h2" variant="overline" gutterBottom>Lernort</Typography>
            <Typography component="p" variant="body1" gutterBottom>
              {item.learningPlaceName || "-"}
            </Typography>
            <Divider/>
            <Typography component="h2" variant="overline" gutterBottom>
              Dateien
            </Typography>
            {hasMediaItems ? (
              <ListFiles ids={item.media} byId={filesById} actionHandler={() => this.handleDownload}/>
            ) : (
              <Typography component="p" variant="body1" gutterBottom>-</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </>;
  }
}

export default withStyles(styles)(ReportComponent);
