import React from 'react';
import AddIcon from '@material-ui/icons/AddBox';
import Button from "@material-ui/core/Button/Button";
import routes from "../../utils/routes";
import {labelsMuiDatatables} from "../../utils/labels";
import MUIDataTable from "mui-datatables";
import PortalToolbarMenu from '../common/navigation/PortalToolbarMenu';

class BusinessesComponent extends React.Component {
    componentDidMount() {
        this.initData();
    }

    initData = () => {
        this.props.tableServerDataProvider.init(urlParameters => this.props.businessesGetRequest(urlParameters));

        this.props.businessesFilterOptionsRequest();
    };

    handleClick = (evt, route) => {
        evt.preventDefault();
        if (route) {
            this.props.history.push(route);
        }
    };

    handleNew = () => {
      this.props.history.push(routes.BUSINESS_NEW.path);
    };

    render() {
        const { byId, allIds, filterOptions, totalCount } = this.props;

        const portalToolbarMenuData = [
          {
            "title": "Neuen Betrieb anlegen",
            "action": () => this.handleNew(),
            "icon": AddIcon,
            "visible": true,
            "disabled": false
          }
        ];

        const tableOptions = {
            selectableRows: 'none',
            elevation: 0,
            responsive: "stacked",
            filter: true,
            print: false,
            download: false,
            viewColumns: false,
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 20, 50],
            textLabels: labelsMuiDatatables,
            serverSide: true,
            serverSideFilterList: [],
            count: totalCount
        };

        this.props.tableServerDataProvider.bindToTableOptions(tableOptions);

        const tableColumns = [
            {
                name: "name",
                label: "Betrieb",
            },
            {
                name: "street",
                label: "Straße",
            },
            {
                name: "zip",
                label: "PLZ",
            },
            {
                name: "city",
                label: "Ort",
            },
            {
                options: {
                customBodyRender: (value) => {
                    return (
                        <Button href="#" onClick={evt => this.handleClick(evt, routes.BUSINESS_EDIT.path.replace(":id", value))} size="small" variant="outlined" color="primary">Bearbeiten</Button>
                    );
                }
            }
        }];

        this.props.tableServerDataProvider.bindTableFilters(filterOptions, tableColumns);

        const tableData = allIds.map(itemId => {
            const item = byId[itemId];
            return (item) ? [item.name, item.street, item.postcode, item.city, item.id] : [];
        });

        return (
            <React.Fragment>
              <PortalToolbarMenu menuData={portalToolbarMenuData}/>
              <MUIDataTable title={"Alle Betriebe"} options={tableOptions} columns={tableColumns} data={tableData}/>
            </React.Fragment>
        );
    }
}

export default BusinessesComponent;