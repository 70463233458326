import { withRouter } from "react-router";
import { connect } from "react-redux";
import { entityKey as reportsSliceKey } from "../../redux/entities/reportsKeys";
import { default as reportsOperations } from "../../redux/entities/reportsOperations";
import ReportComponent from "./ReportComponent";
import { entityKey as filesSliceKey } from "../../redux/entities/filesKeys";
import { metaKeys, uiOperations } from "../../redux/ui";

const mapStateToProps = state => {
  const { isAdmin } = state.authentication;
  const { errors, byId, allIds } = state.entities[reportsSliceKey];
  const filesById = state.entities[filesSliceKey].byId;

  return {
    isAdmin,
    errors,
    byId,
    allIds,
    filesById
  };
};

const mapDispatchToProps = dispatch => {
  const reportGetRequest = (id) => dispatch(reportsOperations.reportGetRequest(id));

  const titleChange = value => dispatch(uiOperations.metaChangeOperationGeneric(metaKeys.TITLE, value));
  const parentRouteChange = value => dispatch(uiOperations.metaChangeOperationGeneric(metaKeys.PARENTROUTE, value));

  return {
    reportGetRequest,
    titleChange,
    parentRouteChange
  };
};

const ReportContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportComponent));

export default ReportContainer;
