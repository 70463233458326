import { withRouter } from "react-router";
import { connect } from 'react-redux';
import SubTaskNewComponent from './SubTaskNewComponent';
import { entityKey as subTasksSliceKey } from '../../redux/entities/subTasksKeys';
import { default as subTasksOperations } from '../../redux/entities/subTasksOperations';
import { entityKey as tasksSliceKey } from '../../redux/entities/tasksKeys';
import { default as tasksOperations } from '../../redux/entities/tasksOperations';
import { notificationsOperations } from "../../redux/notifications";
import { requestsSelectors } from "../../redux/entities/requests";

const mapStateToProps = state => {
  const isFetching = requestsSelectors.checkIfFetching(state.entities, tasksSliceKey);
  const { isAdmin, isInstructor } = state.authentication;
  const { errors, byId } = state.entities[subTasksSliceKey];
  const tasksById = state.entities[tasksSliceKey].byId;

  return {
    isFetching,
    isAdmin,
    isInstructor,
    errors,
    byId,
    tasksById
  };
};

const mapDispatchToProps = dispatch => {
  const subTaskPostRequest = (data, formikBag, appendData) => dispatch(subTasksOperations.subTaskPostRequest(data, formikBag, appendData));

  const subTasksCancelRequest = (cancelType, cancelMessage) => dispatch(subTasksOperations.subTasksCancelRequest(cancelType, cancelMessage));

  const taskGetRequest = id => dispatch(tasksOperations.taskGetRequest(id));

  const tasksCancelRequest = (cancelType, cancelMessage) => dispatch(tasksOperations.tasksCancelRequest(cancelType, cancelMessage));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    subTaskPostRequest,
    subTasksCancelRequest,
    taskGetRequest,
    tasksCancelRequest,
    notificationEnqueue
  };
};

const SubTaskContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SubTaskNewComponent));

export default SubTaskContainer;