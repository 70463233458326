import { withRouter } from "react-router";
import { connect } from 'react-redux';
import UserActivationComponent from './UserActivationComponent';
import { authenticationOperations } from '../../redux/authentication';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { errors, isAuthenticated } = state.authentication;

  return {
    errors,
    isAuthenticated
  };
};

const mapDispatchToProps = dispatch => {
  const activationPostRequest = (data, formikBag) => dispatch(authenticationOperations.activationPostRequest(data, formikBag));

  const cancelAuthRequest = (cancelType, cancelMessage) => dispatch(authenticationOperations.cancelAuthRequest(cancelType, cancelMessage));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    activationPostRequest,
    cancelAuthRequest,
    notificationEnqueue
  };
};

const UserActivationContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(UserActivationComponent));

export default UserActivationContainer;