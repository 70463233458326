import { schema } from 'normalizr';
import { requestsOperations } from "./requests";
import { entityKey, duckTypes } from "./competenceRatingsKeys";

const competenceRatingSchema = new schema.Entity(entityKey);
const competenceRatingListSchema = new schema.Array(competenceRatingSchema);

const fetchRelations = [];

const listGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.COMPETENCERATINGS_GET,
    requestPath: url,
    clearEntityData: true,
    normalizeSchema: competenceRatingListSchema
  };

  return requestsOperations.requestsOperationGeneric(null, settings);
};

const competenceRatingsGetRequest = () => listGetRequest('/competence-ratings');

const associationGetRequest = (url) => {
  const settings = {
    duckType: duckTypes.COMPETENCERATINGS_GET,
    requestPath: url,
    clearEntityData: false,
    normalizeSchema: competenceRatingListSchema,
    requestCancelling: false,
  };
  return requestsOperations.requestsOperationGeneric(null, settings);
};

const competenceRatingsByCompetenceSpecificationGetRequest = (competenceSpecificationId) => associationGetRequest(`/competence-specifications/${competenceSpecificationId}/competence-ratings`);

const competenceRatingGetRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.COMPETENCERATING_GET,
    requestPath: `/competence-ratings/${id}`,
    idParamRequired: true,
    normalizeSchema: competenceRatingSchema,
    fetchRelations: fetchRelations,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceRatingPutRequest = (id, data, formikBag) => {
  const params = {
    id: id,
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.COMPETENCERATING_PUT,
    requestType: 'put',
    requestPath: `/competence-ratings/${id}`,
    idParamRequired: true,
    normalizeSchema: competenceRatingSchema,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceRatingPostRequest = (competenceSpecificationId, data, formikBag) => {
  const params = {
    data: data,
    formikBag: formikBag,
  };
  const settings = {
    duckType: duckTypes.COMPETENCERATING_POST,
    requestType: 'post',
    requestPath: `/competence-specifications/${competenceSpecificationId}/competence-ratings`,
    normalizeSchema: competenceRatingSchema,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceRatingDeleteRequest = id => {
  const params = {
    id: id
  };
  const settings = {
    duckType: duckTypes.COMPETENCERATING_DELETE,
    requestType: 'delete',
    requestPath: `/competence-ratings/${id}`,
    idParamRequired: true,
    normalizeSchema: competenceRatingSchema,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const competenceRatingsCancelRequest = (cancelType, cancelMessage) => {
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsCancellation(entityKey, cancelType, cancelMessage));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

export default {
  competenceRatingsGetRequest,
  competenceRatingGetRequest,
  competenceRatingPutRequest,
  competenceRatingPostRequest,
  competenceRatingDeleteRequest,
  competenceRatingsCancelRequest,
  competenceRatingsByCompetenceSpecificationGetRequest,
};
