import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import ResultFormComponent from "./ResultFormComponent";
import resultContainerWrapper from "./ResultContainerWrapper";
import { responseFormikValidator } from "../../utils/validations";

const styles = theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      width: 640,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
});

class ResultEditComponent extends Component {
  componentDidMount() {
    this.initData();
  }

  initData = () => {
    const { match, resultGetRequest, subTaskGetRequest } = this.props;
    const { id, subTaskId } = match.params;
    resultGetRequest(id);
    subTaskGetRequest(subTaskId);
  };

  handleSubmit(values, formikBag) {
    const { resultPutRequest, history } = this.props;
    resultPutRequest(values.id, values, formikBag).then((axiosResponse) => {
      responseFormikValidator(axiosResponse, formikBag);
    });

    setTimeout(() => history.goBack(), 800);
  }

  handleReset() {
    this.props.history.goBack();
  }

  render() {
    const { classes, resultsById, subTasksById, match } = this.props;
    const { id, subTaskId } = match.params;
    const result = resultsById[id];
    const subTask = subTasksById[subTaskId];

    if (!result || !subTask) {
      return <></>;
    }

    const item = { ...result };

    return (
      <Paper elevation={0} className={classes.paper}>
        {subTask &&
        <ResultFormComponent
          item={item}
          submitHandler={this.handleSubmit.bind(this)}
          resetHandler={this.handleReset.bind(this)}
        />
        }
      </Paper>
    );
  }
}

export default resultContainerWrapper(withStyles(styles)(ResultEditComponent));
