import { withRouter } from "react-router";
import { connect } from 'react-redux';
import LinkNewComponent from './LinkNewComponent';
import { entityKey as linksSliceKey } from '../../redux/entities/linksKeys';
import { default as linksOperations } from '../../redux/entities/linksOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { myId, isAdmin, isInstructor } = state.authentication;
  const { errors } = state.entities[linksSliceKey];

  return {
    myId,
    isAdmin,
    isInstructor,
    errors
  };
};

const mapDispatchToProps = dispatch => {
  const linkPostRequest = (data, formikBag) => dispatch(linksOperations.linkPostRequest(data, formikBag));

  const linksCancelRequest = (cancelType, cancelMessage) => dispatch(linksOperations.linksCancelRequest(cancelType, cancelMessage));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    linkPostRequest,
    linksCancelRequest,
    notificationEnqueue
  };
};

const LinkNewContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(LinkNewComponent));

export default LinkNewContainer;
