import React from 'react';
import RegistrationIcon from '@material-ui/icons/PersonAdd';
import Button from "@material-ui/core/Button/Button";
import routes from "../../utils/routes";
import { labelsMuiDatatables } from "../../utils/labels";
import MUIDataTable from "mui-datatables";
import PortalToolbarMenu from '../common/navigation/PortalToolbarMenu';
import roles from "../../utils/roles";
import uuidv4 from 'uuid/v4';
import config from "../../utils/config";
import { Avatar } from "@material-ui/core";

class UsersComponent extends React.Component {
  componentDidMount() {
    this.initBusinessesData();
    this.initProfessionsData();

    const userGetFunc = urlParameters =>  {
      urlParameters.requestUuid = uuidv4();
      this.requestUuid = urlParameters.requestUuid;
      this.props.usersGetRequest(urlParameters);
    };


    this.props.tableServerDataProvider.init(userGetFunc);
    this.props.usersFilterOptionsRequest();
  }

  initUserData = () => {
    this.props.usersGetRequest();
  };

  initBusinessesData = () => {
    const { isAdmin, isInstructor, profile } = this.props;
    if (isAdmin) {
        this.props.businessesGetRequest();
    } else if (isInstructor && profile) {
        this.props.businessGetRequest(profile.company);
    }
  };

  initProfessionsData = () => {
    this.props.professionsGetRequest();
  };

  handleClick = (evt, route) => {
    evt.preventDefault();
    if (route) {
      this.props.history.push(route);
    }
  };

  handleUserRegistration = () => {
    this.props.history.push(routes.USER_REGISTRATION.path);
  };

  render() {
    const { isAdmin, isInstructor, byId, allIds, businessesById, professionsById, filesById, totalCount, filterOptions } = this.props;

    const portalToolbarMenuData = [
        {
          "title": "Neuen Benutzer anlegen",
          "action": () => this.handleUserRegistration(),
          "icon": RegistrationIcon,
          "visible": isInstructor || isAdmin,
          "disabled": false
        }
    ];

    const tableOptions = {
      selectableRows: 'none',
      elevation: 0,
      responsive: "stacked",
      filter: true,
      print: false,
      download: false,
      viewColumns: false,
      rowsPerPage: 1,
      rowsPerPageOptions: [10, 20, 50],
      serverSide: true,
      serverSideFilterList: [],
      textLabels: labelsMuiDatatables,
      count: totalCount,
    };

    this.props.tableServerDataProvider.bindToTableOptions(tableOptions);

    const tableColumns = [
      {
        name: "selection",
        label: "Auswahl",
        options: {
          display: false,
        }
      },
      {
        name: "avatar",
        label: "Avatar",
        options: {
          sort: false,
          customBodyRender: id => {
            const item = byId[id];

            const avatarUrl = item && item.avatar && filesById && filesById[item.avatar] ? config.DOWNLOAD_URL + `/uploads/${filesById[item.avatar].filename}` : null;

            return avatarUrl ?
              <Avatar src={avatarUrl} alt={`${item.firstName} ${item.lastName}`} /> :
              <Avatar>{item.firstName.charAt(0).toUpperCase()}{item.lastName.charAt(0).toUpperCase()}</Avatar>;
          }
        }
      },
      {
        name: "firstName",
        label: "Vorname",
        options: {
          filter: false
        }
      },
      {
        name: "lastName",
        label: "Nachname",
        options: {
          filter: false
        }
      },
      {
        name: "username",
        label: "E-Mail",
        options: {
          filter: false
        }
      },
      {
        name: "company",
        label: "Betrieb",
        options: {
          display: isAdmin,
          customBodyRender: id => {
            const item = byId[id];
            const business = item && item.company ? businessesById[item.company] : null;
            return business ? business.name : '-';
          }
        }
      },
      {
        name: "roles",
        label: "Rolle",
      },
      {
        name: "job",
        label: "Ausbildungsberuf",
        options: {
          customBodyRender: id => {
            const item = byId[id];
            const profession = item && item.job ? professionsById[item.job] : null;
            return profession ? profession.name : '-';
          }
        }
      },
      {
        options: {
          display: isAdmin || isInstructor,
          filter: false,
          setCellProps: value => ({ align: "right" }),
          customBodyRender: (value) => {
            return (
              <Button href="#" onClick={evt => this.handleClick(evt, routes.USER_EDIT.path.replace(':id', value))}
                      size="small" variant="outlined" color="primary">Bearbeiten</Button>
            );
          }
        }
      }
    ];

    this.props.tableServerDataProvider.bindTableFilters(filterOptions, tableColumns);

    const tableData = allIds
      .filter(
        itemId => itemId in byId && byId[itemId].fetchInfos && this.requestUuid &&
          byId[itemId].fetchInfos.url.indexOf(`requestUuid=${this.requestUuid}`) >= 0
      )
      .map(itemId => {

      const item = byId[itemId];

      const itemRoles = item.roles.map(role => {
          switch (role) {
            case roles.ADMIN:
              return 'Admin';
            case roles.INSTRUCTOR:
              return 'Ausbilder*in';
            case roles.APPRENTICE:
              return 'Auszubildende*r';
            default:
              return null;
          }
      });

      return (item) ? ['', item.id, item.firstName, item.lastName, item.username, item.id, itemRoles.join(', '), item.id, item.id] : [];
    });

    return (
      <React.Fragment>
        <PortalToolbarMenu menuData={portalToolbarMenuData}/>
        <MUIDataTable title={"Alle Benutzer"} options={tableOptions} columns={tableColumns} data={tableData}/>
      </React.Fragment>
    );
  }
}

export default UsersComponent;
