import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import ReactSelect from '../common/ReactSelect';
import roles from '../../utils/roles';
import { compareByKey2Sort } from '../../utils/helper';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SendTaskDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apprentice: null
    };
  }

  render() {
    const { open, onClose, usersById, myId, task } = this.props;

    const apprentices = Object.keys(usersById)
      .filter(key => myId !== key && usersById[key] !== undefined && usersById[key].roles.includes(roles.APPRENTICE) && !task.apprentices.includes(key))
      .sort((a, b) => compareByKey2Sort(usersById[a], usersById[b], 'firstName'))
      .map(key => usersById[key], {});

    let apprenticesOptions = apprentices.map(apprentice => {
      return {
        value: apprentice.id,
        label: `${apprentice.firstName} ${apprentice.lastName}`
      };
    }, []);

    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="assignTaskDialogTitle"
        aria-describedby="assignTaskDialogDescription"
      >
        <DialogTitle id="assignTaskDialogTitle">Aufgabe zuweisen</DialogTitle>
        <DialogContent>
          <DialogContentText id="assignTaskDialogDescription">
            Weisen Sie die Aufgabe dem*r folgenden Auszubildenden zwecks Bearbeitung zu:
          </DialogContentText>
          <ReactSelect name="recipient"
            onChange={(fieldName, value) => {
              this.setState({ apprentice: value });
            }}
            onBlur={() => {}}
            value={this.state.apprentice}
            options={apprenticesOptions}
            placeholder="Bitte auswählen..."
            label="Auszubildende*r"
            isMulti={false}
            isClearable={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => onClose(true, this.state.apprentice)} color="secondary">
            Zuweisen
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  const { myId } = state.authentication;
  const usersById = state.entities.users.byId;

  return {
    myId,
    usersById
  };
};

export default connect(mapStateToProps)(SendTaskDialog);
