import config from './config';

export const setAuth = token => {
  if (token) {
    sessionStorage.setItem(`${config.APP_NAME}-token`, JSON.stringify(token));

    // console.log('setAuth Token');
  } else {
    sessionStorage.removeItem(`${config.APP_NAME}-token`);

    // console.log('setAuth Remove');
  }
};

export const getAuthHeader = () => {
  let token = JSON.parse(sessionStorage.getItem(`${config.APP_NAME}-token`));
  if (token) {
    return { 'Authorization': 'Bearer ' + token };
  } else {
    return {};
  }
};
