
export const uiEntityKeys = {
  views: 'views',
  meta: 'meta'
};

export const viewKeys = {
  REGISTRATION: {
    key: 'REGISTRATION',
    values: {
      DEFAULT: 'DEFAULT',
      SUCCESS: 'SUCCESS'
    }
  },
  PASSWORD_RECOVERY: {
    key: 'PASSWORD_RECOVERY',
    values: {
      DEFAULT: 'DEFAULT',
      SUCCESS: 'SUCCESS'
    }
  },
  TASK: {
    key: 'TASK',
    values: {
      DEFAULT: 'DEFAULT',
      SUBTASK_NEW: 'SUBTASK_NEW'
    }
  },
  FEEDBACK: {
    key: 'FEEDBACK',
    values: {
      DEFAULT: 'DEFAULT',
      SUCCESS: 'SUCCESS'
    }
  }
};

export const metaKeys = {
  TITLE: 'TITLE',
  PARENTROUTE: 'PARENTROUTE'
};
