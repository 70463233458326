import { connect } from 'react-redux';
import CompetenceRatingComponent from './CompetenceRatingComponent';
import { entityKey as competenceRatingsSliceKey } from '../../redux/entities/competenceRatingsKeys';
import { default as competenceRatingsOperations } from '../../redux/entities/competenceRatingsOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { myId, isAdmin, isInstructor } = state.authentication;
  const { errors, byId } = state.entities[competenceRatingsSliceKey];

  return {
    myId,
    isAdmin,
    isInstructor,
    errors,
    byId
  };
};

const mapDispatchToProps = dispatch => {
  const competenceRatingsByCompetenceSpecificationGetRequest = competenceSpecificationId => dispatch(competenceRatingsOperations.competenceRatingsByCompetenceSpecificationGetRequest(competenceSpecificationId));
  const competenceRatingGetRequest = id => dispatch(competenceRatingsOperations.competenceRatingGetRequest(id));
  const competenceRatingPutRequest = (id, data, formikBag) => dispatch(competenceRatingsOperations.competenceRatingPutRequest(id, data, formikBag));
  const competenceRatingPostRequest = (competenceCheckId, data, formikBag) => dispatch(competenceRatingsOperations.competenceRatingPostRequest(competenceCheckId, data, formikBag));
  const competenceRatingDeleteRequest = id => dispatch(competenceRatingsOperations.competenceRatingDeleteRequest(id));

  const competenceRatingsCancelRequest = (cancelType, cancelMessage) => dispatch(competenceRatingsOperations.competenceRatingsCancelRequest(cancelType, cancelMessage));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    competenceRatingsByCompetenceSpecificationGetRequest,
    competenceRatingGetRequest,
    competenceRatingPutRequest,
    competenceRatingPostRequest,
    competenceRatingDeleteRequest,
    competenceRatingsCancelRequest,
    notificationEnqueue
  };
};

const CompetenceRatingContainer = connect(mapStateToProps, mapDispatchToProps)(CompetenceRatingComponent);

export default CompetenceRatingContainer;