import { withRouter } from "react-router";
import { connect } from 'react-redux';
import LinkEditComponent from './LinkEditComponent';
import { entityKey as linksSliceKey } from '../../redux/entities/linksKeys';
import { default as linksOperations } from '../../redux/entities/linksOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { isAdmin, isInstructor } = state.authentication;
  const { errors, byId } = state.entities[linksSliceKey];

  return {
    isAdmin,
    isInstructor,
    errors,
    byId
  };
};

const mapDispatchToProps = dispatch => {
  const linkGetRequest = id => dispatch(linksOperations.linkGetRequest(id));
  const linkPutRequest = (id, data, formikBag) => dispatch(linksOperations.linkPutRequest(id, data, formikBag));

  const linksCancelRequest = (cancelType, cancelMessage) => dispatch(linksOperations.linksCancelRequest(cancelType, cancelMessage));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    linkGetRequest,
    linkPutRequest,
    linksCancelRequest,
    notificationEnqueue
  };
};

const LinkEditContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(LinkEditComponent));

export default LinkEditContainer;
