import { withRouter } from "react-router";
import { connect } from 'react-redux';
import SubTaskEditComponent from './SubTaskEditComponent';
import { entityKey as subTasksSliceKey } from '../../redux/entities/subTasksKeys';
import { default as subTasksOperations } from '../../redux/entities/subTasksOperations';
import { entityKey as tasksSliceKey } from '../../redux/entities/tasksKeys';
import { default as tasksOperations } from '../../redux/entities/tasksOperations';
import { entityKey as usersSliceKey } from '../../redux/entities/usersKeys';
import { default as usersOperations } from '../../redux/entities/usersOperations';
import { notificationsOperations } from "../../redux/notifications";
import { requestsSelectors } from "../../redux/entities/requests";
import { metaKeys, uiOperations } from "../../redux/ui";

const mapStateToProps = state => {
  const isFetching = requestsSelectors.checkIfFetching(state.entities, subTasksSliceKey) || requestsSelectors.checkIfFetching(state.entities, tasksSliceKey);
  const { isAdmin, isInstructor } = state.authentication;
  const { errors, byId, allIds } = state.entities[subTasksSliceKey];
  const tasksById = state.entities[tasksSliceKey].byId;
  const usersById = state.entities[usersSliceKey].byId;

  return {
    isFetching,
    isAdmin,
    isInstructor,
    errors,
    byId,
    allIds,
    tasksById,
    usersById
  };
};

const mapDispatchToProps = dispatch => {
  const subTaskGetRequest = id => dispatch(subTasksOperations.subTaskGetRequest(id));
  const subTasksGetByTaskAndNumberRequest = (taskId, subTaskNumber) => dispatch(subTasksOperations.subTasksGetByTaskAndNumberRequest(taskId, subTaskNumber));

  const subTaskPutRequest = (id, data, formikBag, appendData) => dispatch(subTasksOperations.subTaskPutRequest(id, data, formikBag, appendData));

  const subTasksCancelRequest = (cancelType, cancelMessage) => dispatch(subTasksOperations.subTasksCancelRequest(cancelType, cancelMessage));

  const taskWithoutRelationsGetRequest = id => dispatch(tasksOperations.taskWithoutRelationsGetRequest(id));

  const usersGetRequest = () => dispatch(usersOperations.usersGetRequest());

  const titleChange = value => dispatch(uiOperations.metaChangeOperationGeneric(metaKeys.TITLE, value));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    subTaskGetRequest,
    subTaskPutRequest,
    subTasksCancelRequest,
    taskWithoutRelationsGetRequest,
    usersGetRequest,
    notificationEnqueue,
    subTasksGetByTaskAndNumberRequest,
    titleChange,
  };
};

const SubTaskEditContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SubTaskEditComponent));

export default SubTaskEditContainer;