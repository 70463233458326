import { getAuthHeader } from "../../../utils/auth";
import config from "../../../utils/config";
import axios from "axios";
import { normalize } from 'normalizr';
import * as R from "ramda";
import request, { handleError, handleSuccess } from "../../../utils/axios";
import { keys as genericKeys, type as genericType, action as genericAction } from '../../generics';

const { intents, status } = genericKeys;

const buildRequestUrl = (settings, urlParameters) => {
  // create request url and append optional query parameter
  let requestUrl = settings.requestPath;
  const urlParameterStrings = Object.keys(urlParameters).map((parameterKey) => parameterKey + "=" + urlParameters[parameterKey]);
  if (urlParameterStrings.length) {
    requestUrl += "?" + urlParameterStrings.join("&");
  }

  return requestUrl;
};

const buildRequestConfig = (settings, requestUrl, params, cancelToken) => {
  const requestConfig = {
    url: requestUrl,
    method: settings.requestType,
    cancelToken: null !== cancelToken ? cancelToken.token : null
  };

  if (settings.authenticate && !R.isEmpty(settings.authToken)) {
    requestConfig.headers = settings.authToken;
  }

  if (!R.isEmpty(settings.params)) {
    requestConfig.params = params.params;
  }

  if (!R.isEmpty(settings.data)) {
    requestConfig.data = params.data;
  }

  if (settings.transformRequest && ('put' === settings.requestType || 'post' === settings.requestType || 'patch' === settings.requestType)) {
    requestConfig.transformRequest = [settings.transformRequest]; // TODO: TEST
  }

  if (settings.transformResponse) {
    // https://github.com/axios/axios/issues/430#issuecomment-296279932
    requestConfig.transformResponse = [].concat(
      axios.defaults.transformResponse,
      settings.transformResponse // TODO: TEST
    );
  }

  return requestConfig;
};

const requestsOperationGeneric = (paramsObj, settingsObj, urlParameters = {}) => {
  const params = {
    id: null,
    data: null,
    params: null,
    formikBag: null,
    ...paramsObj
  };

  const settings = {
    duckType: null,
    authenticate: true,
    authToken: null,
    requestType: 'get',
    requestPath: null,
    clearEntityData: false,
    idParamRequired: false,
    requestCancelling: true,
    transformRequest: null,
    transformResponse: null,
    normalizeSchema: null,
    fetchRelations: null,
    appendOnSuccess: null,
    appendOnError: null,
    ...settingsObj
  };

  return (dispatch, getState) => {
    if (!settings.duckType || !settings.requestPath) {
      return Promise.reject(config.MESSAGE_MISSINGSETTINGS);
    }

    const duckType = {
      ...settings.duckType
    };

    if (settings.authenticate) {
      settings.authToken = getAuthHeader();
      if (R.isEmpty(settings.authToken)) {
        dispatch(genericAction(genericType(duckType.entity, duckType.name, duckType.intent, status.FAILURE), config.MESSAGE_NOTAUTHORIZED));
        return Promise.reject(config.MESSAGE_NOTAUTHORIZED);
      }
    }

    if (settings.idParamRequired && !params.id) {
      dispatch(genericAction(genericType(duckType.entity, duckType.name, duckType.intent, status.FAILURE), config.MESSAGE_MISSINGID));
      return Promise.reject(config.MESSAGE_MISSINGID);
    }

    if (settings.requestCancelling) {
      const reduxSlice = getState().entities[settings.duckType.entity];
      if (reduxSlice) {
        const cancelTokens = reduxSlice.cancelTokens[settings.duckType.group];
        if (cancelTokens && 0 < cancelTokens.length) {
          if (params.id) {
            cancelTokens.forEach(item => {
              if (item.id === params.id) {
                item.token.cancel();
              }
            });
          } else {
            cancelTokens[0].token.cancel();
          }
        }
      }
    }

    const cancelToken = axios.CancelToken.source();
    const cancelPayload = {
      token: cancelToken,
      id: params.id
    };

    dispatch(genericAction(genericType(duckType.entity, duckType.name, duckType.intent, status.STARTED), cancelPayload));

    // create request url and append optional query parameter
    let requestUrl = buildRequestUrl(settings, urlParameters);
    const requestConfig = buildRequestConfig(settings, requestUrl, params, cancelToken);

    /*
        // NOTE: Maybe interesting for some use cases... probably needs its own instance to avoid being applied to all requests!
        request.interceptors.response.use(response => {
            return response;
        }, error => {
            return error;
        });
        */

    return request(requestConfig)
      .then(response => {
        const data = handleSuccess(response, params.formikBag);
        let result = data;

        // add fetch infos to data
        const applyFetchInfo = entity => {
          entity.fetchInfos = {
            url: requestUrl,
            urlParameters: urlParameters,
          };
        };
        if (Array.isArray(result.result)) {
          result.result.forEach(entity => applyFetchInfo(entity));
        } else if (result.result){
          applyFetchInfo(result.result);
        }

        if (data.result) {
          result = (settings.normalizeSchema) ? normalize(data.result, settings.normalizeSchema) : data.result;
          result.clearEntityData = settings.clearEntityData;
        } else if (duckType.intent === intents.DELETE || duckType.intent === intents.RESTORE) {
          result = {
            deleteId: params.id
          };
        }

        if ("x-total-count" in response.headers) {
          result.totalCount = parseInt(response.headers["x-total-count"]);
        }

        if (settings.appendOnSuccess) {
          settings.appendOnSuccess(dispatch, data);
        }

        dispatch(genericAction(genericType(duckType.entity, duckType.name, duckType.intent, status.SUCCESS), result));

        if (settings.fetchRelations && !R.isEmpty(settings.fetchRelations)) {
          fetchRelations(dispatch, getState, duckType.entity, settings.fetchRelations, data.result);
        }

        return data;
      }, error => {
        const handledError = handleError(dispatch, error, params.formikBag);
        console.error("error in requestsOperationGeneric - " + handledError.message + " Url:" + requestUrl);
        dispatch(genericAction(genericType(duckType.entity, duckType.name, duckType.intent, status.FAILURE), handledError));
        if (settings.appendOnError) {
          settings.appendOnError(dispatch, handledError);
        }
        return error;
      });
  };
};

const requestsFilterOptionsGeneric = (paramsObj, settingsObj, urlParameters = {}) => {
  const params = {
    id: null,
    data: null,
    params: null,
    ...paramsObj
  };

  const settings = {
    duckType: null,
    authenticate: true,
    authToken: null,
    requestType: 'get',
    requestPath: null,
    ...settingsObj
  };

  return (dispatch) => {
    const duckType = {
      ...settings.duckType
    };

    if (settings.authenticate) {
      settings.authToken = getAuthHeader();
      if (R.isEmpty(settings.authToken)) {
        dispatch(genericAction(genericType(duckType.entity, duckType.name, duckType.intent, status.FAILURE), config.MESSAGE_NOTAUTHORIZED));
        return Promise.reject(config.MESSAGE_NOTAUTHORIZED);
      }
    }

    // create request url and append optional query parameter
    let requestUrl = buildRequestUrl(settings, urlParameters);
    const requestConfig = buildRequestConfig(settings, requestUrl, params, null);

    return request(requestConfig)
      .then(response => {
        const data = response.data.result;

        dispatch(genericAction(genericType(duckType.entity, duckType.name, duckType.intent, genericKeys.intents.FETCH_FILTER_OPTIONS), data));

        return data;
      }, error => {
        const handledError = handleError(dispatch, error, params.formikBag);
        console.error("error in requestsFilterOptionsGeneric - " + handledError.message + " Url:" + requestUrl);
        dispatch(genericAction(genericType(duckType.entity, duckType.name, duckType.intent, status.FAILURE), handledError));
        if (settings.appendOnError) {
          settings.appendOnError(dispatch, handledError);
        }
        return error;
      });
  };
};

// NOTE: Not sure if this is needed/useful to individually access fetchRelations from containers etc.
/* const requestRelationsGeneric = (entityKey, settings) => {
    if (!entityKey || !settings || R.isEmpty(settings)) {
        return false;
    }
    return (dispatch, getState) => {
        fetchRelations(dispatch, getState, entityKey, settings);
    }
};*/

const fetchRelations = (dispatch, getState, entityKey, settings, resultData) => {
  if (!settings || R.isEmpty(settings)) {
    return false;
  }
  const { byId } = getState().entities[entityKey];

  settings.forEach(setting => {
    fetchRelationsBySubresources(dispatch, byId, setting, resultData);
  });
};

const fetchRelationsBySubresources = (dispatch, byId, setting, parentResultData) => {
  const parentResultDataArray = Array.isArray(parentResultData) ? parentResultData : [parentResultData];
  parentResultDataArray.forEach((parentResultEntry) => {
    const assocIdProperty = setting.relationResponseKey;
    const isNullOrEmpty = (obj) => {
      return null === obj || (Array.isArray(obj) && 0 === obj.length);
    };

    if (!(assocIdProperty in parentResultData) || !isNullOrEmpty(parentResultData[assocIdProperty])) {
      dispatch(setting.operation(parentResultEntry.id));
    }
  });
};

const requestsCancellation = (sliceKey, cancelType, cancelMessage) => {
  return (dispatch, getState) => {
    const cancelTokens = getState().entities[sliceKey].cancelTokens[cancelType];
    if (cancelTokens && 0 < cancelTokens.length) {
      cancelTokens[0].token.cancel(cancelMessage);
    } else {
      return Promise.resolve();
    }
  };
};

export default {
  requestsOperationGeneric,
  requestsCancellation,
  requestsFilterOptionsGeneric,
};
