import { schema } from 'normalizr';
import { requestsOperations } from "./requests";
import { entityKey as usersSliceKey } from './usersKeys';
import { default as usersOperations } from './usersOperations';
import { entityKey, duckTypes } from "./notificationsKeys";

const taskSchema = new schema.Entity(entityKey);
const taskListSchema = new schema.Array(taskSchema);

const fetchRelations = [{
  relationResponseKey: 'user',
  relationSliceKey: usersSliceKey,
  operation: usersOperations.usersByNotificationGetRequest,
  noRefetch: false,
  isSingle: true,
}];

const notificationsGetRequest = () => {
  const settings = {
    duckType: duckTypes.NOTIFICATIONS_GET,
    requestPath: '/notifications',
    clearEntityData: true,
    normalizeSchema: taskListSchema,
    fetchRelations
  };
  return requestsOperations.requestsOperationGeneric(null, settings);
};

const notificationGetRequest = id => {
  const params = { id };
  const settings = {
    duckType: duckTypes.TASK_GET,
    requestPath: `/notifications/${id}`,
    normalizeSchema: taskSchema,
    fetchRelations
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

const notificationPutReadFlagRequest = (id) => {
  const params = {
    id: id,
    data: null,
  };
  const settings = {
    duckType: duckTypes.NOTIFICATION_PUT_READ_FLAG,
    requestType: 'put',
    requestPath: `/notifications/${id}/read`,
    idParamRequired: true,
    normalizeSchema: taskSchema,
  };
  return dispatch => {
    const promise = dispatch(requestsOperations.requestsOperationGeneric(params, settings));
    if (promise) {
      promise.catch(error => console.error(error));
    }
    return promise;
  };
};

export default {
  notificationsGetRequest,
  notificationGetRequest,
  notificationPutReadFlagRequest,
};