import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import config from '../../utils/config';
import routes from '../../utils/routes';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        if (sessionStorage.getItem(`${config.APP_NAME}-token`)) {
            if (rest.roles.length === 0) {
                return <Component {...props} />
            }
            if (!rest.profile) {
                return null;
            } else if (rest.roles.some(role => rest.profile.roles.some(userRole => userRole === role))) {
                return <Component {...props} />
            }
            rest.rolesFallbackNotification();
            const redirectToPath = routes[rest.parent] ? routes[rest.parent].path : routes.HOME.path;
            return <Redirect to={{ pathname: redirectToPath }} />
        }

        return <Redirect to={{ pathname: routes.LOGIN.path, state: { from: props.location } }} />
    }} />
);
