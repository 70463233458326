import { withRouter } from "react-router";
import { connect } from "react-redux";
import DashboardApprenticeDetailsComponent from "./DashboardApprenticeDetailsComponent";
import { default as tasksOperations } from "../../redux/entities/tasksOperations";
import { entityKey as tasksSliceKey } from "../../redux/entities/tasksKeys";
import { default as competenceChecksOperations } from "../../redux/entities/competenceChecksOperations";
import { entityKey as competenceChecksSliceKey } from "../../redux/entities/competenceChecksKeys";

const mapStateToProps = state => {
  const tasksById = state.entities[tasksSliceKey].byId;
  const competenceChecksById = state.entities[competenceChecksSliceKey].byId;

  return {
    tasksById,
    competenceChecksById
  };
};

const mapDispatchToProps = dispatch => {
  const tasksGetRequest = urlParameters => dispatch(tasksOperations.tasksGetRequest(urlParameters));
  const competenceChecksGetRequest = (urlParameters) => dispatch(competenceChecksOperations.competenceChecksGetRequest(urlParameters));

  return {
    tasksGetRequest,
    competenceChecksGetRequest,
  };
};

const DashboardApprenticeDetailsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardApprenticeDetailsComponent));

export default DashboardApprenticeDetailsContainer;
