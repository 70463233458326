import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { duckTypes } from "../../redux/entities/fieldsOfActionKeys";
import { default as FieldOfActionForm } from "./FieldOfActionFormComponent";
import { responseFormikValidator } from "../../utils/validations";

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: 640,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
});

class FieldOfActionNewComponent extends React.Component {
    handleSubmit = (values, formikBag) => {
        const data = {
            name: values.name || '',
            description: values.description || '',
            image: values.media.length ? values.media[0] : null
        };
        this.props.fieldOfActionPostRequest(data, formikBag).then((axiosResponse) => {
            responseFormikValidator(axiosResponse, formikBag);
        });
    };

    handleReset = () => {
        this.props.fieldsOfActionCancelRequest(duckTypes.FIELDOFACTION_POST.group);
        this.props.history.goBack();
    };

    render() {
        const { classes } = this.props;

        return (
            <Paper elevation={0} className={classes.paper}>
                <FieldOfActionForm submitHandler={ this.handleSubmit } resetHandler={ this.handleReset } />
            </Paper>
        );
    }
}

export default withStyles(styles)(FieldOfActionNewComponent);