import { keys as genericKeys, type as genericType } from "../generics";

const { intents } = genericKeys;

export const nameKeys = {
  USERS: 'USERS',
  USER: 'USER',
};
export const entityKey = nameKeys.USERS.toLowerCase();
export const duckTypes = {
  USERS_GET: genericType(entityKey, nameKeys.USERS, intents.GET),
  USER_GET: genericType(entityKey, nameKeys.USER, intents.GET),
  USER_PUT: genericType(entityKey, nameKeys.USER, intents.PUT),
  USER_POST: genericType(entityKey, nameKeys.USER, intents.POST),
  USER_DELETE: genericType(entityKey, nameKeys.USER, intents.DELETE),
  USER_RESTORE: genericType(entityKey, nameKeys.USER, intents.RESTORE),
  USER_FILTER_OPTIONS: genericType(entityKey, nameKeys.USER, intents.FETCH_FILTER_OPTIONS),
};