import { withRouter } from "react-router";
import { connect } from 'react-redux';
import ProfessionEditComponent from './ProfessionEditComponent';
import { entityKey as professionsSliceKey } from '../../redux/entities/professionsKeys';
import { default as professionsOperations } from '../../redux/entities/professionsOperations';
import { entityKey as fieldsOfActionSliceKey } from '../../redux/entities/fieldsOfActionKeys';
import { default as fieldsOfActionOperations } from '../../redux/entities/fieldsOfActionOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { isAdmin } = state.authentication;
  const { errors, byId } = state.entities[professionsSliceKey];
  const fieldsOfActionById = state.entities[fieldsOfActionSliceKey].byId;

  return {
    isAdmin,
    errors,
    byId,
    fieldsOfActionById
  };
};

const mapDispatchToProps = dispatch => {
  const professionGetRequest = id => dispatch(professionsOperations.professionGetRequest(id));
  const professionPutRequest = (id, data, formikBag) => dispatch(professionsOperations.professionPutRequest(id, data, formikBag));

  const professionsCancelRequest = (cancelType, cancelMessage) => dispatch(professionsOperations.professionsCancelRequest(cancelType, cancelMessage));

  const fieldsOfActionGetRequest = () => dispatch(fieldsOfActionOperations.fieldsOfActionGetRequest());
  const fieldOfActionGetRequest = id => dispatch(fieldsOfActionOperations.fieldOfActionGetRequest(id));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    professionGetRequest,
    professionPutRequest,
    professionsCancelRequest,
    fieldsOfActionGetRequest,
    fieldOfActionGetRequest,
    notificationEnqueue
  };
};

const ProfessionEditContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfessionEditComponent));

export default ProfessionEditContainer;