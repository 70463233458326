import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper/Paper";
import withStyles from '@material-ui/core/styles/withStyles';
import PortfolioIcon from "@material-ui/icons/LibraryBooks";
import FileIcon from "@material-ui/icons/AttachFile";
import routes from "../../utils/routes";
import PortalToolbarMenu from "../common/navigation/PortalToolbarMenu";
import { labelsMuiDatatables } from "../../utils/labels";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import DetailsIcon from "@material-ui/icons/ExitToApp";
import EditIcon from "@material-ui/icons/Edit";
import MUIDataTable from "mui-datatables";
import { Avatar, Chip } from "@material-ui/core";
import competenceCheckStatus from "../../utils/competenceCheckStatus";
import { compareByKey2Sort, formatDate } from "../../utils/helper";
import Alert from "@material-ui/lab/Alert";

const styles = theme => ({
  paper: {
    margin: theme.spacing(3),
  },
  paperTitle: {
    alignItems: "center",
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.disabled,
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
  },
  dates: {
    whiteSpace: "nowrap"
  },
  evalCounter: {
    margin: 0,
    padding: 0,

    '& li': {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      '&:first-child': {
        marginLeft: 0,
      },
    },

    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',

      '& li': {
        marginLeft: 0,
        '&:first-child': {
          marginTop: 0,
        },
      },
    },
  },
});

class DashboardApprenticeComponent extends React.Component {
  componentDidMount() {
    this.initData();
  }

  initData = () => {
    this.props.reportsGetRequest({
      pageSize: 5,
      page: 1,
      sort: "-dateTo"
    });

    this.props.tasksGetRequest({
      sort: "-updatedAt",
      archived: 0,
      master: 0,
    });

    this.props.competenceChecksGetRequest({
      sort: "-updatedAt",
      archived: 0,
      master: 0,
    });
  };

  handleRoute(evt, route) {
    if (evt) {
      evt.preventDefault();
    }
    if (route) {
      this.props.history.push(route);
    }
  }

  render() {
    const { classes, reportsById, tasksById, competenceChecksById } = this.props;

    const portalToolbarMenuData = [
      {
        "title": "Neuen Eintrag anlegen",
        "action": () => this.handleRoute(null, routes.REPORT_NEW.path.replace(":fieldOfActionId", "")),
        "icon": PortfolioIcon,
        "visible": true,
        "disabled": false
      },
      {
        "title": "Neue Datei hinzufügen",
        "action": () => this.handleRoute(null, routes.FILE_NEW.path),
        "icon": FileIcon,
        "visible": true,
        "disabled": false
      }
    ];

    const tableOptions = {
      selectableRows: "none",
      elevation: 0,
      responsive: "stacked",
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      search: false,
      pagination: false,
      sort: false,
      textLabels: labelsMuiDatatables
    };

    const reportsTableColumns = [
      {
        name: "title",
        label: "Titel",
      },
      {
        name: "dateFrom",
        label: "Von",
      },
      {
        name: "dateTo",
        label: "Bis",
      },
      {
        name: "duration",
        label: "Dauer",
      },
      {
        name: "learningPlaces",
        label: "Lernort",
      },
      {
        name: "fieldOfAction",
        label: "Aufgabenbereich",
      },
      {
        name: "timestamps",
        label: "Erstellt, geändert",
        options: {
          customBodyRender: timestamps => {
            return (
              <div className={classes.dates}>
                {formatDate(timestamps[0])}<br/>
                {formatDate(timestamps[1])}
              </div>
            )
          }
        }
      },
      {
        name: "actions",
        label: " ",
        options: {
          setCellProps: value => ({ align: "right" }),
          customBodyRender: id => {
            return (
              <ButtonGroup color="primary">
                <Tooltip title="Details" aria-label="Details">
                  <Button onClick={evt => this.handleRoute(evt, routes.REPORT.path.replace(":id", id))}>
                    <DetailsIcon/>
                  </Button>
                </Tooltip>
                <Tooltip title="Bearbeiten" aria-label="Bearbeiten">
                  <Button onClick={evt => this.handleRoute(evt, routes.REPORT_EDIT.path.replace(":id", id))}>
                    <EditIcon/>
                  </Button>
                </Tooltip>}
              </ButtonGroup>
            );
          },
          filter: false
        }
      }
    ];

    const reportsTableData = !reportsById ? [] : Object.keys(reportsById)
      .sort((a, b) => compareByKey2Sort(reportsById[a], reportsById[b], "dateTo", true))
      .slice(0, 5)
      .map(itemId => {
        const item = reportsById[itemId];

        if (!item) {
          return [];
        }

        const { id, title, createdAt, updatedAt, dateFrom, dateTo, duration, learningPlaceName, fieldOfActionName } = item;

        const timestamps = [
          createdAt,
          updatedAt
        ];

        return [title, formatDate(dateFrom, "EEE dd.MM.yy"), formatDate(dateTo, "EEE dd.MM.yy"), duration, learningPlaceName || "", fieldOfActionName || "", timestamps, id];
      });

    const tasksTableColumns = [
      {
        name: "title",
        label: "Titel"
      },
      {
        name: "fieldOfAction",
        label: "Aufgabenbereich"
      },
      {
        name: "apprentices",
        label: "Auszubildende"
      },
      {
        name: "subTaskCounter",
        label: "TA (offen)"
      },
      {
        name: "timestamps",
        label: "Erstellt, geändert",
        options: {
          customBodyRender: timestamps => {
            return (
              <div className={classes.dates}>
                {formatDate(timestamps[0])}<br/>
                {formatDate(timestamps[1])}
              </div>
            )
          }
        }
      },
      {
        name: "actions",
        label: " ",
        options: {
          setCellProps: value => ({ align: "right" }),
          customBodyRender: taskKey => {
            return (
              <ButtonGroup color="primary">
                <Tooltip title="Details" aria-label="Details">
                  <Button onClick={evt => this.handleRoute(evt, routes.TASK_DETAILS.path.replace(":id", taskKey))}>
                    <DetailsIcon/>
                  </Button>
                </Tooltip>
              </ButtonGroup>
            );
          }
        }
      }
    ];

    const tasksTableData = !tasksById ? [] : Object.keys(tasksById)
      .filter(itemId =>
        tasksById[itemId] &&
        !tasksById[itemId].master &&
        !tasksById[itemId].isArchived &&
        !tasksById[itemId].deleted &&
        tasksById[itemId].status === 10)
      .sort((a, b) => compareByKey2Sort(tasksById[a], tasksById[b], "updatedAt", true))
      .map(itemId => {
        const item = tasksById[itemId];

        if (!item) {
          return [];
        }

        const { title, createdAt, updatedAt, fieldOfActionName, apprenticeNames, subTaskCount, subTaskOpenCount, taskKey } = item;

        const timestamps = [
          createdAt,
          updatedAt
        ];

        const nameListConvert = (names) => {
          if (!names || (names.length === 1 && undefined === names[0])) {
            return "-";
          }
          const namesArray = [];
          if (names.length > 0) {
            names
              .sort((a, b) => a.firstName.localeCompare(b.firstName))
              .forEach(name => {
                namesArray.push(`${name.firstName} ${name.lastName}`);
              });
          }

          return (namesArray.length > 0) ? namesArray.join(", ") : "-";
        };

        const apprenticesList = nameListConvert(apprenticeNames);

        const subTasksData = `${subTaskCount} (${subTaskOpenCount})`;

        return [title, fieldOfActionName, apprenticesList, subTasksData, timestamps, taskKey];
      });

    const competenceChecksTableColumns = [
      {
        name: "title",
        label: "Titel",
      },
      {
        name: 'owner',
        label: 'Ersteller'
      },
      {
        name: "evalutationsApprentice",
        label: "Einschätzungen Auszubildende*r",
        options: {
          customBodyRender: values => {
            const evals = values.split('|');
            return (
              <ul className={classes.evalCounter}>
                <Chip component="li" size="small" variant={evals[0] > 0 ? 'default' : 'outlined'} avatar={<Avatar>{evals[0] || '-'}</Avatar>} label="offen" />
                <Chip component="li" size="small" variant={evals[1] > 0 ? 'default' : 'outlined'} avatar={<Avatar>{evals[1] || '-'}</Avatar>} label="eingereicht" />
              </ul>
            );
          }
        }
      },
      {
        name: "evalutationsInstructor",
        label: "Einschätzungen Ausbilder*in",
        options: {
          customBodyRender: values => {
            const evals = values.split('|');
            return (
              <ul className={classes.evalCounter}>
                <Chip component="li" size="small" variant={evals[0] > 0 ? 'default' : 'outlined'} avatar={<Avatar>{evals[0] || '-'}</Avatar>} label="offen" />
                <Chip component="li" size="small" variant={evals[1] > 0 ? 'default' : 'outlined'} avatar={<Avatar>{evals[1] || '-'}</Avatar>} label="eingereicht" />
              </ul>
            );
          }
        }
      },
      {
        name: "status",
        label: "Status",
        options: {
          customBodyRender: (value) => {
            return (
              <Alert
                severity={'Einschätzungen eingereicht' === value ? 'success' : 'warning'}
                color={'Einschätzungen eingereicht' === value ? 'success' : 'warning'}
              >{value}</Alert>
            );
          },
        },
      },
      {
        name: "timestamps",
        label: "Erstellt, geändert",
        options: {
          customBodyRender: timestamps => {
            return (
              <div className={classes.dates}>
                {formatDate(timestamps[0])}<br/>
                {formatDate(timestamps[1])}
              </div>
            )
          }
        }
      },
      {
        options: {
          setCellProps: value => ({ align: "right" }),
          customBodyRender: id => {
            const item = competenceChecksById[id];
            return (
              <ButtonGroup color="primary">
                <Tooltip title="Details" aria-label="Details">
                  <Button onClick={evt => this.handleRoute(evt, routes.COMPETENCECHECK_DETAILS.path.replace(":id", item.id))}>
                    <DetailsIcon/>
                  </Button>
                </Tooltip>
              </ButtonGroup>
            );
          }
        }
      }
    ];

    const competenceChecksTableData = !competenceChecksById ? [] : Object.keys(competenceChecksById)
      .filter(itemId =>
        competenceChecksById[itemId] &&
        !competenceChecksById[itemId].isArchived &&
        competenceChecksById[itemId].status < 40)
      .sort((a, b) => compareByKey2Sort(competenceChecksById[a], competenceChecksById[b], "updatedAt", true))
      .map(itemId => {
        const item = competenceChecksById[itemId];

        if (!item) {
          return [];
        }

        const { id, title, createdAt, updatedAt, ownerName, activeApprenticeEvaluations, activeInstructorEvaluations, submittedApprenticeEvaluations, submittedInstructorEvaluations } = item;

        const timestamps = [
          createdAt,
          updatedAt
        ];

        let statusCode = 10;
        if (activeApprenticeEvaluations || activeInstructorEvaluations) {
          statusCode = 20;
        } else if (submittedApprenticeEvaluations > 0 && submittedApprenticeEvaluations === submittedInstructorEvaluations) {
          statusCode = 30;
        }

        return [title, ownerName, `${activeApprenticeEvaluations}|${submittedApprenticeEvaluations}`, `${activeInstructorEvaluations}|${submittedInstructorEvaluations}`, competenceCheckStatus[statusCode], timestamps, id];
      });

    return (
      <React.Fragment>
        <PortalToolbarMenu menuData={portalToolbarMenuData}/>
        <Paper elevation={2} className={classes.paper}>
          <div className={classes.paperTitle}>
            <Typography component="h2" variant="h6" color="primary">Meine letzten Berichtsheft-Einträge</Typography>
            <Button variant="outlined" size="small" color="primary" onClick={evt => this.handleRoute(evt, routes.PORTFOLIO.path)}>Berichts&shy;heft</Button>
          </div>
          <MUIDataTable options={tableOptions} columns={reportsTableColumns} data={reportsTableData}/>
        </Paper>
        <Paper elevation={2} className={classes.paper}>
          <div className={classes.paperTitle}>
            <Typography component="h2" variant="h6" color="primary">Meine aktiven Aufgaben</Typography>
            <Button variant="outlined" size="small" color="primary" onClick={evt => this.handleRoute(evt, routes.TASKS.path)}>Alle Aufgaben</Button>
          </div>
          <MUIDataTable options={tableOptions} columns={tasksTableColumns} data={tasksTableData}/>
        </Paper>
        <Paper elevation={2} className={classes.paper}>
          <div className={classes.paperTitle}>
            <Typography component="h2" variant="h6" color="primary">Meine aktiven Kompetenzchecks</Typography>
            <Button variant="outlined" size="small" color="primary" onClick={evt => this.handleRoute(evt, routes.COMPETENCECHECKS.path)}>Alle Kompetenz&shy;checks</Button>
          </div>
          <MUIDataTable options={tableOptions} columns={competenceChecksTableColumns} data={competenceChecksTableData}/>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DashboardApprenticeComponent);
