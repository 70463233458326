const competenceRatingLabels = {
  0: {
    apprentice: 'Es wurde noch keine Einschätzung durchgeführt',
    instructor: 'Es wurde noch keine Einschätzung durchgeführt',
  },
  1: {
    apprentice: 'Ich kann das noch nicht und brauche umfassende Unterstützung',
    instructor: 'Der*die Auszubildende kann das noch nicht und braucht umfassende Unterstützung',
  },
  2: {
    apprentice: 'Ich kann das erst ein bisschen und brauche noch relativ viel Unterstützung',
    instructor: 'Der*die Auszubildende kann das erst ein bisschen und braucht noch relativ viel Unterstützung',
  },
  3: {
    apprentice: 'Ich kann das schon relativ gut und brauche nur noch wenig Unterstützung',
    instructor: 'Der*die Auszubildende kann das schon relativ gut und braucht nur noch wenig Unterstützung',
  },
  4: {
    apprentice: 'Ich kann das sehr gut und brauche keine weitere Unterstützung',
    instructor: 'Der*die Auszubildende kann das sehr gut und braucht keine weitere Unterstützung',
  }
};

export default competenceRatingLabels;
