import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import LoginIcon from '@material-ui/icons/LockOpen';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { default as LoginForm } from "./LoginFormComponent";
import routes from "../../utils/routes";

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    }
});

class LoginComponent extends React.Component {

    handleSubmit = (values, formikBag) => {
        this.props.authPostRequest(values.email, values.password, formikBag);
    };

    handleRecovery = () => {
        this.props.history.push(routes.PASSWORD_RECOVERY.path);
    };

    render() {
        const { classes } = this.props;

        return (
            <Paper elevation={0} className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LoginIcon />
                </Avatar>
                <Typography component="h1" variant="h5" gutterBottom>Anmeldung</Typography>
                <LoginForm submitHandler={ this.handleSubmit } recoveryHandler={ this.handleRecovery } />
            </Paper>
        );
    }
}

export default withStyles(styles)(LoginComponent);