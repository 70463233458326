import axios from "axios";
import request, { handleError, handleSuccess } from '../../utils/axios';
import { getAuthHeader } from '../../utils/auth';
import { notificationsOperations, notificationsFlavors } from '../notifications';
import { viewKeys, uiOperations } from '../ui';
import types from './types';
import Creators from './actions';
import * as R from "ramda";
import config from "../../utils/config";

const feedbackPostStarted = Creators.feedbackPostStarted;
const feedbackPostSuccess = Creators.feedbackPostSuccess;
const feedbackPostFailure = Creators.feedbackPostFailure;

const feedbackPostRequest = (data, formikBag) => {
  return (dispatch, getState) => {
    const token = getAuthHeader();
    if (R.isEmpty(token)) {
      dispatch(feedbackPostFailure(config.MESSAGE_NOTAUTHORIZED));
      return Promise.reject(config.MESSAGE_NOTAUTHORIZED);
    }
    const cancelTokens = getState().other.cancelTokens[types.FEEDBACK_POST];
    if (cancelTokens && 0 < cancelTokens.length) {
      cancelTokens[0].token.cancel();
    }
    const cancelToken = axios.CancelToken.source();
    const requestConfig = {
      headers: token,
      cancelToken: cancelToken.token
    };
    const requestData = {
      ...data
    };
    dispatch(feedbackPostStarted(cancelToken));
    return request.post('/feedbacks', requestData, requestConfig).then(response => {
      const data = handleSuccess(response, formikBag);
      dispatch(feedbackPostSuccess(data));

      // dispatch(notificationsOperations.notificationEnqueue('Ihr Feedback wurde erfolgreich abgeschickt.', notificationsFlavors.SUCCESS));
      dispatch(uiOperations.viewChangeOperationGeneric(viewKeys.FEEDBACK.key, viewKeys.FEEDBACK.values.SUCCESS));
    }, error => {
      const handledError = handleError(dispatch, error, formikBag);
      dispatch(feedbackPostFailure(handledError));
      dispatch(notificationsOperations.notificationEnqueue(handledError.message, notificationsFlavors.ERROR));
    });
  };
};

const otherCancelRequest = (cancelType, cancelMessage) => {
  return (dispatch, getState) => {
    const cancelTokens = getState().other.cancelTokens[cancelType];
    if (cancelTokens && 0 < cancelTokens.length) {
      cancelTokens[0].token.cancel(cancelMessage);
    } else {
      return Promise.resolve();
    }
  };
};

export default {
  feedbackPostRequest,
  otherCancelRequest
};


