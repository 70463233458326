import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { duckTypes } from "../../redux/entities/tasksKeys";
import { default as TaskEditForm } from "./TaskEditFormComponent";
import roles from "../../utils/roles";
import {compareByKey2Sort} from "../../utils/helper";
import routes from "../../utils/routes";
import { responseFormikValidator } from "../../utils/validations";

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: 640,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
});

class TaskEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            taskKey: null
        };
    }

    componentDidMount() {
        this.initData();
        this.updateMeta();
    }

    componentWillUnmount() {
        this.props.titleChange(null);
        this.props.parentRouteChange(null);
    }

    initData = () => {
        this.props.professionsGetRequest();
        this.props.usersGetRequest();

        const { match } = this.props;
        if (match.params.id) {
            this.setState({
                taskKey: match.params.id
            }, () => {
                this.updateMeta();
                this.props.taskGetRequest(this.state.taskKey).then(() => {
                    const task = this.getCurrentTask();
                    this.props.fieldOfActionsByProfessionGetRequest(task.job);
                    this.updateMeta();
                });
            });
        }
    };

    // TODO: Include new parentRouteChange to enable correct back button route (see CompetenceCheckEditComponent)
    updateMeta = () => {
        const { byId } = this.props;
        this.props.titleChange(null);
        this.props.parentRouteChange(null);

        Object.keys(byId).some(id => {
            if (byId[id].taskKey === this.state.taskKey) {
                const item = byId[id];
                this.props.titleChange(`Aufgabe "${item.taskKey}" bearbeiten`);
                const parentRoute = item.isArchived ? routes.ARCHIVED_TASKS : item.master ? routes.MASTER_TASKS : routes.TASKS;
                this.props.parentRouteChange(parentRoute);
                return true;
            }
            return false;
        });
    };

    handleTaskEditSubmit = (values, formikBag) => {
        const id = values.id; // NOTE: API does not like taskKey as PUT id

        const data = {
            title: values.title,
            fieldOfAction: values.fieldOfAction,
            description: values.description,
            apprentices: values.apprentices === "" ? [] : values.apprentices,
            cooperators: values.cooperators === "" ? [] : values.cooperators,
            media: values.media,
            links: values.links,
            status: values.status
        };
        this.props.taskPutRequest(id, data, formikBag).then((axiosResponse) => {
            responseFormikValidator(axiosResponse, formikBag);
        });
    };

    handleTaskEditReset = () => {
        this.props.tasksCancelRequest(duckTypes.TASK_PUT.group);
        this.props.history.push(routes.TASK_DETAILS.path.replace(':id', this.state.taskKey));
    };

    getCurrentTask() {
        const { byId } = this.props;

        for (const id of Object.keys(byId)) {
            if (byId[id].taskKey === this.state.taskKey) {
                return byId[id];
            }
        }

        return null;
    }

    render() {
        const { classes, isAdmin, isInstructor, usersById, fieldsOfActionById, filesById } = this.props;

        let item = this.getCurrentTask();

        if (null === item) {
            return null;
        }

        const fieldsOfAction = Object.keys(fieldsOfActionById)
            .filter(key => fieldsOfActionById[key].fetchInfos && fieldsOfActionById[key].fetchInfos.url.indexOf(item.job) >= 0)
            .sort((a, b) => compareByKey2Sort(fieldsOfActionById[a], fieldsOfActionById[b], 'name'))
            .reduce((obj, key) => {
                obj[key] = fieldsOfActionById[key];
                return obj;
            }, {});

        const apprentices = Object.keys(usersById)
            .filter(key => usersById[key].roles.includes(roles.APPRENTICE))
            .sort((a, b) => compareByKey2Sort(usersById[a], usersById[b], 'firstName'))
            .reduce((obj, key) => {
                obj[key] = usersById[key];
                return obj;
            }, {});

        const cooperators = Object.keys(usersById)
            .filter(key => usersById[key].roles.includes(roles.INSTRUCTOR) && usersById[key].id !== item.owner) // NOTE: owner cannot be cooperator
            .sort((a, b) => compareByKey2Sort(usersById[a], usersById[b], 'firstName'))
            .reduce((obj, key) => {
                obj[key] = usersById[key];
                return obj;
            }, {});

        return (
            <React.Fragment>
                <Paper elevation={0} className={classes.paper}>
                    <TaskEditForm
                        isAdmin={isAdmin}
                        isInstructor={isInstructor}
                        item={item}
                        fieldsOfAction={fieldsOfAction}
                        apprentices={apprentices}
                        cooperators={cooperators}
                        filesById={filesById}
                        submitHandler={ this.handleTaskEditSubmit }
                        resetHandler={ this.handleTaskEditReset }
                    />
                </Paper>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(TaskEditComponent);