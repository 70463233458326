import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import clsx from "clsx";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import SubmitIcon from '@material-ui/icons/AssignmentTurnedIn';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import ReopenIcon from '@material-ui/icons/Replay';
import CopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import TaskIcon from '@material-ui/icons/Assignment';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import taskPhases from "../../utils/taskPhases";
import { default as SubTaskCard } from "./SubTaskCardComponent";
import taskStatus from "../../utils/taskStatus";
import routes from "../../utils/routes";
import ConfirmDialog from '../common/ConfirmDialog';
import RemovalDialog from '../common/RemovalDialog';
import config from "../../utils/config";
import ListFiles from "../common/ListFiles";
import ListUsers from "../common/ListUsers";
import ListLinks from "../common/ListLinks";
import { compareByKey2Sort } from "../../utils/helper";
import Chip from '@material-ui/core/Chip';
import SendTaskDialog from '../common/SendTaskDialog';
import ShowMoreHtml from "../common/ShowMoreHtml";
import ReactHtmlParser from "react-html-parser";
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import PortalToolbarMenu from '../common/navigation/PortalToolbarMenu';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnArchiveIcon from '@material-ui/icons/Unarchive';
import Badge from '@material-ui/core/Badge';
import Paper from "@material-ui/core/Paper/Paper";
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";
import RestoreDialog from "../common/RestoreDialog";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignTaskDialog from "../common/AssignTaskDialog";
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
  box: {
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },
  tabsHeader: {
    padding: theme.spacing(2, 2, 0),
  },
  tabsBox: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 2, 2),
    },
  },
  tabs: {
    backgroundColor: theme.palette.grey[50],
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    borderWidth: '0 0 1px',
    justifyContent: "center"
  },
  tabsScroller: {
    flexGrow: "0"
  },
  tabBadge: {
    margin: theme.spacing(1),
  },
  tabPanel: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(2),
  },
  phaseGrid: {
    borderStyle: 'dashed',
    borderColor: theme.palette.grey[300],
    borderWidth: '0 1px 0 0',
    '&:last-child': {
      borderRightWidth: 0,
    },
  },
  phaseHeader: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),

  },
  phaseHeaderBadge: {
    marginRight: theme.spacing(2),
  },
  dropzone: {
    minHeight: theme.spacing(4),
  },
  isDraggingOver: {
    backgroundImage: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFElEQVQYlWNgYGAwZiABjCqmk2IAlxIB/6ehrKgAAAAASUVORK5CYII=')",
    backgroundRepeat: 'repeat',
    margin: theme.spacing(-1),
    padding: theme.spacing(1),
  },
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = (source && source.length) ? Array.from(source) : [];
  const destClone = (destination && destination.length) ? Array.from(destination) : [];
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};


class TaskComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copyDialogOpen: false,
      removalDialogOpen: false,
      submitDialogOpen: false,
      reopenDialogOpen: false,
      sendDialogOpen: false,
      restoreDialogOpen: false,
      assignDialogOpen: false,
      taskKey: null,
      taskId: null,
      subTaskPhaseId: null,
      subTaskArraysByPhase: {},
      tabIndex: 0
    };
  }

  componentDidMount() {
    this.initData();
    this.updateMeta();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
        this.initData();
    }

    if (prevProps.subTasksById !== this.props.subTasksById) {
      this.setSubTaskArraysByPhase();
    }
  }

  componentWillUnmount() {
    this.props.titleChange(null);
    this.props.parentRouteChange(null);
  }

  initData = () => {
    this.props.usersGetRequest();

    const { match } = this.props; // https://reacttraining.com/react-router/web/api/match
    if (match.params.id) {
      this.setState({
        taskKey: match.params.id
      }, () => {
        this.props.taskGetRequest(this.state.taskKey).then(() => {
          this.updateMeta();
        });
      });
    }
  };

  // TODO: Include new parentRouteChange to enable correct back button route (see CompetenceCheckComponent)
  updateMeta = () => {
    this.props.titleChange(null);
    this.props.parentRouteChange(null);

    const task = this.getRequestedTask();
    if (null !== task) {
      this.setState({
        taskId: task.id
      }, () => {
        this.props.titleChange(task && task.title ? 'Aufgabe: ' + task.title : null);
        const parentRoute = this.getParentRoute();
        this.props.parentRouteChange(parentRoute);

        this.setSubTaskArraysByPhase();
      });
    }
  };

  getRequestedTask() {
    const { byId } = this.props;

    for (const task of Object.values(byId)) {
      if (task.taskKey === this.state.taskKey) {
        return task;
      }
    }

    return null;
  }

  setSubTaskArraysByPhase() {
    const task = this.getRequestedTask();

    if (null === task) {
      return;
    }

    const { subTasksById } = this.props;

    const subTaskArraysByPhase = {};

    Object.keys(subTasksById)
      .filter(key => task.subTasks.includes(key))
      .forEach(key => {
        const subTask = subTasksById[key];
        if (!Array.isArray(subTaskArraysByPhase[subTask.phase])) {
          subTaskArraysByPhase[subTask.phase] = [];
        }
        subTaskArraysByPhase[subTask.phase].push(subTask);
      });

    Object.keys(subTaskArraysByPhase).forEach(key => {
      subTaskArraysByPhase[key].sort((a, b) => compareByKey2Sort(a, b, 'position'));
    });

    this.setState({
      subTaskArraysByPhase: subTaskArraysByPhase
    });
  }

  getParentRoute() {
    const task = this.getRequestedTask();
    if (null === task) {
      return null;
    }

    return task.isArchived ? routes.ARCHIVED_TASKS : task.master ? routes.MASTER_TASKS : routes.TASKS;
  }

  handleDownload = (evt, id) => {
    evt.preventDefault();
    if (id) {
      const filename = this.props.filesById[id].filename;
      window.open(config.DOWNLOAD_URL + `/uploads/${filename}`);
    }
  };

  handleTaskEdit = () => {
    this.props.history.push(routes.TASK_EDIT.path.replace(':id', this.state.taskKey));
  };

  handleTaskRemove = (id) => {
    this.props.taskDeleteRequest(id, this.getParentRoute().path);
  };

  handleTaskRestore (id) {
    this.props.taskRestoreRequest(id, this.getParentRoute().path);
  };

  handleTaskSend = (id, recipientId) => {
    this.props.taskSendRequest(id, recipientId); // Note: taskSendRequest redirects to task list
  };

  handleTaskCopy = id => {
    this.props.taskCopyRequest(id);
  };

  handleSubTaskNew = phaseId => {
    this.props.history.push(routes.SUBTASK_NEW.path.replace(':id', this.state.taskKey), {
      subTaskPhaseId: phaseId
    });
  };

  handleTaskSubmit(item) {
    this.props.taskSubmitRequest(item.id).then(() => this.initData());
  }

  handleTaskReopen(item) {
    this.props.taskReopenRequest(item.id).then(() => this.initData());
  }

  handleTaskAssign(task, apprenticeId) {
    const newTaskData = {
      apprentices: [ ...task.apprentices, apprenticeId ]
    };

    this.props.taskPutRequest(task.id, newTaskData, null);
  }

  toggleArchived(item) {
    const func = item.isArchived ? this.props.taskUnarchiveRequest : this.props.taskArchiveRequest;

    func(item.id).then(() => this.initData());
  }

  handleTabChange = (evt, newValue) => {
    this.setState({
      tabIndex: newValue
    });
  };

  handleTabChangeIndex = index => {
    this.setState({
      tabIndex: index
    });
  };

  updateSubTaskOrder = (id, data) => {
    const { taskId } = this.state;

    if (null === taskId) {
      return;
    }

    this.props.subTaskPutRequest(id, data).then(() => this.props.subTasksByTaskGetRequest(taskId));
  };

  handleDragSort = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const { subTaskArraysByPhase } = this.state;

    const sourcePhaseId = parseInt(source.droppableId);
    const destinationPhaseId = parseInt(destination.droppableId);

    const sourceSubTasks = subTaskArraysByPhase[sourcePhaseId] || null;
    const draggedSubTask = sourceSubTasks[source.index] || null;

    if (!draggedSubTask || !draggedSubTask.id) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      const movedSubTasks = move(
        subTaskArraysByPhase[sourcePhaseId],
        subTaskArraysByPhase[destinationPhaseId],
        source,
        destination
      );

      this.setState(prevState => ({
        subTaskArraysByPhase: {
          ...prevState.subTaskArraysByPhase,
          [sourcePhaseId]: [
            ...movedSubTasks[sourcePhaseId],
          ],
          [destinationPhaseId]: [
            ...movedSubTasks[destinationPhaseId]
          ]
        }
      }), () => {
        const data = {
          phase: destinationPhaseId,
          position: destination.index + 1
        };
        this.updateSubTaskOrder(draggedSubTask.id, data);
      });
    } else if (source.index !== destination.index) {
      const reorderedSubTasks = reorder(
        subTaskArraysByPhase[sourcePhaseId],
        source.index,
        destination.index
      );

      this.setState(prevState => ({
        subTaskArraysByPhase: {
          ...prevState.subTaskArraysByPhase,
          [sourcePhaseId]: [
            ...reorderedSubTasks
          ]
        }
      }), () => {
        const data = {
          position: destination.index + 1
        };
        this.updateSubTaskOrder(draggedSubTask.id, data);
      });
    }
  };

  handleClickSort = (subTask, sourceIndex) => delta => {
    const sourcePhaseId = subTask.phase || null;

    const destinationIndex = sourceIndex + delta;

    if (!sourcePhaseId) {
      return;
    }

    const { subTaskArraysByPhase } = this.state;

    const reorderedSubTasks = reorder(
      subTaskArraysByPhase[sourcePhaseId],
      sourceIndex,
      destinationIndex
    );

    this.setState(prevState => ({
      subTaskArraysByPhase: {
        ...prevState.subTaskArraysByPhase,
        [sourcePhaseId]: [
          ...reorderedSubTasks
        ]
      }
    }), () => {
      const data = {
        position: destinationIndex + 1
      };
      this.updateSubTaskOrder(subTask.id, data);
    });
  };

  render() {
    const { classes, width, isAdmin, usersById, fieldsOfActionById, filesById, linksById, history } = this.props;
    const { copyDialogOpen, sendDialogOpen, removalDialogOpen, tabIndex, submitDialogOpen, reopenDialogOpen, restoreDialogOpen, subTaskArraysByPhase, assignDialogOpen } = this.state;

    const item = this.getRequestedTask();

    if (!item) {
      return null;
    }

    const hasDescription = item.description && item.description.trim().length > 0;
    const hasMediaItems = item.media && item.media.length > 0;
    const hasLinks = item.links && item.links.length > 0;
    const hasApprentices = item.apprentices && item.apprentices.length > 0;
    const hasCooperators = item.cooperators && item.cooperators.length > 0;

    const { canEdit, canCopy, canDelete, canSend, canSubmit, canRestore, canAssign, canReopen } = item.permissions;

    const portalToolbarMenuData = [{
        "title": "Einreichen",
        "action": () => this.setState({ submitDialogOpen: true }),
        "icon": SubmitIcon,
        "visible": canSubmit && item.subTaskCountOpen === 0,
        "disabled": false
      }, {
        "title": "Aufgabe erneut öffnen",
        "action": () => this.setState({ reopenDialogOpen: true }),
        "icon": ReopenIcon,
        "visible": canReopen,
        "disabled": false
      }, {
        "title": "Bearbeiten",
        "action": () => this.handleTaskEdit(),
        "icon": EditIcon,
        "visible": canEdit,
        "disabled": false
      }, {
        "title": "Kopieren",
        "action": () => this.setState({ copyDialogOpen: true }),
        "icon": CopyIcon,
        "visible": canCopy,
        "disabled": false
      }, {
        "title": "Zuweisen",
        "action": () => this.setState({ assignDialogOpen: true }),
        "icon": AssignmentIndIcon,
        "visible": canAssign,
        "disabled": false
      }, {
        "title": "Senden",
        "action": () => this.setState({ sendDialogOpen: true }),
        "icon": SendIcon,
        "visible": canSend,
        "disabled": false
      }, {
        "title": "Löschen",
        "action": () => this.setState({ removalDialogOpen: true }),
        "icon": DeleteIcon,
        "visible": canDelete,
        "disabled": false
      }, {
        "title": "Wiederherstellen",
        "action": () => this.setState({ restoreDialogOpen: true }),
        "icon": RestoreFromTrashIcon,
        "visible": canRestore,
        "disabled": false
      }, {
        "title": item.isArchived ? 'Archivierung aufheben' : 'Archivieren',
        "action": () => this.toggleArchived(item),
        "icon": item.isArchived ? UnArchiveIcon : ArchiveIcon,
        "visible": !item.deleted,
        "disabled": false
      }
    ];

    const renderToolbarMenu = () => {
      return isAdmin ? null : (
        <React.Fragment>
          <ConfirmDialog
            open={copyDialogOpen}
            title={`Aufgabe kopieren`}
            content={`Möchten Sie die Aufgabe "${item.taskKey}: ${item.title}" kopieren?`}
            onClose={confirm => {
              this.setState({ copyDialogOpen: false });

              if (confirm === true) {
                this.handleTaskCopy(item.id);
              }
            }}
          />
          <SendTaskDialog
            open={sendDialogOpen}
            onClose={(success, recipientId) => {
              this.setState({ sendDialogOpen: false });

              if (success && recipientId) {
                this.handleTaskSend(item.id, recipientId);
              }
            }}
          />
          <AssignTaskDialog
            task={item}
            open={assignDialogOpen}
            onClose={(success, apprenticeId) => {
              this.setState({ assignDialogOpen: false });

              if (success && apprenticeId) {
                this.handleTaskAssign(item, apprenticeId);
              }
            }}
          />
          <RemovalDialog
            open={removalDialogOpen}
            subject={`${item.taskKey}: ${item.title}`}
            onClose={(remove) => {
              this.setState({ removalDialogOpen: false });

              if (remove === true) {
                this.handleTaskRemove(item.id);
              }            }}
          />
          <RestoreDialog
            open={restoreDialogOpen}
            subject={`${item.taskKey}: ${item.title}`}
            onClose={(restore) => {
              this.setState({ restoreDialogOpen: false });

              if (restore) {
                this.handleTaskRestore(item.id);
              }
            }}
          />
          <ConfirmDialog
            open={submitDialogOpen}
            title={`Aufgabe einreichen`}
            content={`Möchten Sie die aktuelle Aufgabe einreichen?`}
            onClose={confirm => {
              this.setState({...this.state, submitDialogOpen: false });

              if (confirm === true) {
                this.handleTaskSubmit(item);
              }
            }}
          />
          <ConfirmDialog
            open={reopenDialogOpen}
            title={`Aufgabe erneut öffnen`}
            content={`Möchten Sie die aktuelle Aufgabe erneut öffnen?`}
            onClose={confirm => {
              this.setState({...this.state, reopenDialogOpen: false });

              if (confirm === true) {
                this.handleTaskReopen(item);
              }
            }}
          />
        </React.Fragment>   
      )
    };

    const newSubTaskButton = phaseId => {
      const phase = taskPhases[phaseId];
      return (
        <Tooltip title={`Neue Teilaufgabe für Phase "${phase.label}" anlegen`} aria-label={`Neue Teilaufgabe für Phase "${phase.label}" anlegen`}>
          <Button variant="contained" size="small" color="primary" startIcon={<AddIcon/>} onClick={evt => this.handleSubTaskNew(phaseId)}>
            Neue Teilaufgabe
          </Button>
        </Tooltip>
      )
    };

    const newSmallSubTaskButton = phaseId => {
      const phase = taskPhases[phaseId];
      return (
        <Tooltip title={`Neue Teilaufgabe für Phase "${phase.label}" anlegen`} aria-label={`Neue Teilaufgabe für Phase "${phase.label}" anlegen`}>
          <IconButton
            aria-label={`Neue Teilaufgabe für Phase "${phase.label}" anlegen`}
            variant="contained"
            size="small"
            onClick={evt => this.handleSubTaskNew(phaseId)}
            style={{marginRight: 0, marginLeft: 'auto'}}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      )
    };

    if (item.deleted) {
      return <Paper elevation={0} className={classes.paper}>
        {renderToolbarMenu()}
        <PortalToolbarMenu menuData={portalToolbarMenuData}/>
        <Typography component="h2" variant="h4" gutterBottom>Aufgabe wurde gelöscht</Typography>
      </Paper>
    }

    return (
      <React.Fragment>
        {renderToolbarMenu()}
        <PortalToolbarMenu menuData={portalToolbarMenuData}/>
        <Grid container>
          <Grid item xs={12} sm={12} md={8}>
            <Box className={classes.box}>
              <Typography component="h2" variant="overline" gutterBottom>Aufgabe Nr., Titel</Typography>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Chip
                    color="primary"
                    icon={item.master ? <AssignmentReturnIcon/> : <TaskIcon/>}
                    label={item ? item.taskKey : '-'}
                  />
                </Grid>
                <Grid item xs>
                  <Typography component="h1" variant="h5" gutterBottom>{item ? item.title : '-'}</Typography>
                </Grid>
              </Grid>
              <Divider/>
              <Typography component="h2" variant="overline" gutterBottom>Beschreibung</Typography>
              {hasDescription ? (
                <ShowMoreHtml maxHeight={250}>
                  {ReactHtmlParser(item.description)}
                </ShowMoreHtml> 
              ) : (
                <Typography component="p" variant="body1" gutterBottom>-</Typography>
              )}
              <Divider/>
              <ShowMoreHtml maxHeight={60}>
                <Typography component="h2" variant="overline" gutterBottom>
                  Dateien {hasMediaItems && <>({item.media.length})</>}
                </Typography>
                {hasMediaItems ? (
                    <><ListFiles ids={item.media} byId={filesById} actionHandler={this.handleDownload}/><br/><br/></>
                ) : (
                  <Typography component="p" variant="body1" gutterBottom>-</Typography>
                )}
              </ShowMoreHtml>
              <Divider/>
              <ShowMoreHtml maxHeight={60}>
                <Typography component="h2" variant="overline" gutterBottom>
                  Links {hasLinks && <>({item.links.length})</>}
                </Typography>
                {hasLinks ? (
                  <><ListLinks ids={item.links} byId={linksById}/><br/><br/></>
                ) : (
                  <Typography component="p" variant="body1" gutterBottom>-</Typography>
                )}
              </ShowMoreHtml>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box elevation={0} className={classes.box}>
              <Typography component="h2" variant="overline" gutterBottom>Status</Typography>
              <Typography component="p" variant="body1" gutterBottom>{item ? taskStatus[item.status] : '-'}</Typography>
              <Divider/>
              <Typography component="h2" variant="overline" gutterBottom>Aufgabenbereich</Typography>
              {item && item.fieldOfAction && fieldsOfActionById[item.fieldOfAction] ? (
                <Typography component="p" variant="body1"
                            gutterBottom>{fieldsOfActionById[item.fieldOfAction].name}</Typography>
              ) : (
                <Typography component="p" variant="body1" gutterBottom>-</Typography>
              )}
              <Divider/>
              <ShowMoreHtml maxHeight={60}>
                <Typography component="h2" variant="overline" gutterBottom>
                  Auszubildende {hasApprentices && <>({item.apprentices.length})</>}
                </Typography>
                {hasApprentices ? (
                   <><ListUsers ids={item.apprentices} byId={usersById} filesById={filesById}/><br/><br/></>
                ) : (
                  <Typography component="p" variant="body1" gutterBottom>-</Typography>
                )}
              </ShowMoreHtml>
              <Divider/>
              <ShowMoreHtml maxHeight={60}>
                <Typography component="h2" variant="overline" gutterBottom>
                  Mitwirkende Ausbilder*innen {hasCooperators && <>({item.cooperators.length})</>}
                </Typography>
                {hasCooperators ? (
                  <><ListUsers ids={item.cooperators} byId={usersById} filesById={filesById}/><br/><br/></>
                ) : (
                  <Typography component="p" variant="body1" gutterBottom>-</Typography>
                )}
              </ShowMoreHtml>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.tabsHeader}>
          <Divider/>
          <Typography component="h2" variant="overline" gutterBottom>Teilaufgaben</Typography>
        </Box>
        {isWidthUp('md', width) ? (
          <Grid container>
            <DragDropContext onDragEnd={this.handleDragSort}>
              {Object.keys(taskPhases).map(phaseId => {
                const phase = taskPhases[phaseId];
                const subTasksArray = subTaskArraysByPhase[phaseId] || [];
                return (
                  <Grid key={`subTaskGrid-${phaseId}`} item xs={12} sm={6} md={3} className={classes.phaseGrid}>
                    <Box className={classes.box}>
                      <header className={classes.phaseHeader}>
                        <Badge color="secondary" badgeContent={subTasksArray.length} className={classes.phaseHeaderBadge}>
                          {React.createElement(phase.icon)}
                        </Badge>
                        <Typography component="h2" variant="button">{phase.label}</Typography>
                        {canEdit && newSmallSubTaskButton(phaseId)}
                      </header>
                      <Droppable droppableId={`${phaseId}`} isDropDisabled={!canEdit}>
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef} className={clsx(classes.dropzone, {
                            [classes.isDraggingOver]: snapshot.isDraggingOver,
                          })}>
                            {subTasksArray.map((subTask, subTaskIndex) => (
                              <Draggable
                                key={subTask.id}
                                draggableId={subTask.id}
                                isDragDisabled={!canEdit}
                                index={subTaskIndex}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <SubTaskCard
                                      history={history}
                                      taskKey={this.state.taskKey}
                                      item={subTask}
                                      usersById={usersById}
                                      canEdit={canEdit}
                                      isDragging={snapshot.isDragging}
                                      isFirst={subTaskIndex === 0}
                                      isLast={subTaskIndex === subTasksArray.length - 1}
                                      sortHandler={this.handleClickSort(subTask, subTaskIndex)}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                      {canEdit && newSubTaskButton(phaseId)}
                    </Box>
                  </Grid>
                );
              })}
            </DragDropContext>
          </Grid>
        ) : (
          <Box className={classes.tabsBox}>
            <Tabs value={tabIndex}
                  onChange={this.handleTabChange}
                  aria-label="Teilaufgaben"
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  classes={{
                    root: classes.tabs,
                    scroller: classes.tabsScroller // https://github.com/mui-org/material-ui/issues/10235#issuecomment-531505399
                  }}
            >
              {Object.keys(taskPhases).map(phaseId => {
                const phase = taskPhases[phaseId];
                const subTasksArray = subTaskArraysByPhase[phaseId] || [];
                return (
                  <Tab key={`subTaskTab-${phaseId}`}
                       id={`subTask-tab-${phaseId}`}
                       label={phase.label}
                       icon={<Badge color="secondary" badgeContent={subTasksArray.length} className={classes.tabBadge}>
                         {React.createElement(phase.icon)}
                       </Badge>}
                       aria-controls={`subTask-tabpanel-${phaseId}`} />
                );
              })}
            </Tabs>
            <SwipeableViews
              index={tabIndex}
              onChangeIndex={this.handleTabChangeIndex}
            >
              {Object.keys(taskPhases).map((phaseId, phaseIndex) => {
                const subTasksArray = subTaskArraysByPhase[phaseId] || [];
                return (
                  <Box key={`subTaskTabPanel-${phaseId}`} id={`subTask-tabpanel-${phaseId}`} role="tabpanel" aria-labelledby={`subTask-tab-${phaseId}`} value={tabIndex} hidden={tabIndex !== phaseIndex} className={classes.tabPanel}>
                    {subTasksArray.map((subTask, subTaskIndex) => {
                      return (
                        <SubTaskCard
                          key={subTask.id}
                          history={history}
                          taskKey={this.state.taskKey}
                          item={subTask}
                          usersById={usersById}
                          canEdit={canEdit}
                          isFirst={subTaskIndex === 0}
                          isLast={subTaskIndex === subTasksArray.length - 1}
                          sortHandler={this.handleClickSort(subTask, subTaskIndex)}
                        />
                      )
                    })}
                    {canEdit && newSubTaskButton(phaseId)}
                  </Box>
                );
              })}
            </SwipeableViews>
          </Box>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withWidth()(TaskComponent));
