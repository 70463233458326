import React from "react";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import { duckTypes } from "../../redux/entities/reportsKeys";
import { default as ReportForm } from "./ReportFormComponent";
import { withRouter } from "react-router-dom";
import { responseFormikValidator } from "../../utils/validations";
import routes from "../../utils/routes";

const styles = theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      width: 640,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  }
});

class ReportNewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldOfActionId: null
    };
  }

  componentDidMount() {
    this.initData();
  }

  componentWillUnmount() {
    this.props.parentRouteChange(null);
  }

  initData = () => {
    const { match, fieldsOfActionGetRequest, fieldOfActionGetRequest, facilitiesGetRequest } = this.props;

    if (match.params.fieldOfActionId) {
      this.setState({
        fieldOfActionId: match.params.fieldOfActionId
      }, () => {
        fieldOfActionGetRequest(this.state.fieldOfActionId).then(() => {
          this.updateMeta();
        });
        facilitiesGetRequest();
      });
    } else {
      fieldsOfActionGetRequest();
      facilitiesGetRequest();
    }
  };

  updateMeta() {
    const { parentRouteChange } = this.props;
    const { fieldOfActionId } = this.state;

    parentRouteChange(null);

    if (!fieldOfActionId) {
      return;
    }

    const parentRoute = {
      ...routes.REPORTS
    };
    const parentPath = parentRoute.path;
      parentRoute.path = parentPath.replace(':fieldOfActionId', fieldOfActionId);

    parentRouteChange(parentRoute);
  }

  handleSubmit = (values, formikBag) => {
    const data = {
      dateFrom: values.dateFrom,
      dateTo: values.dateTo,
      duration: values.duration,
      title: values.title || "",
      text: values.text || "",
      fieldOfActions: [ values.fieldOfActionId ] || [],
      learningPlaces: [ values.learningPlaceId ] || [],
      description: values.description || "",
      media: values.media || [],
    };
    this.props.reportPostRequest(data, formikBag).then((axiosResponse) => {
      responseFormikValidator(axiosResponse, formikBag);
    });
  };

  handleReset = () => {
    this.props.reportCancelRequest(duckTypes.REPORT_POST.group);
    this.props.history.goBack();
  };

  render() {
    const { classes, fieldsOfActionById, facilitiesAllIds, facilityById } = this.props;
    const { fieldOfActionId } = this.state;

    const fieldOfAction = fieldOfActionId ? fieldsOfActionById[fieldOfActionId] : null;
    const fieldsOfAction = Object.keys(fieldsOfActionById).map((id) => fieldsOfActionById[id]);
    const facilities = facilitiesAllIds.map((id) => facilityById[id]);

    return <>
      <Paper elevation={0} className={classes.paper}>
        {fieldOfAction ? (
          <ReportForm
            fieldOfAction={fieldOfAction}
            submitHandler={this.handleSubmit}
            resetHandler={this.handleReset}
            fieldsOfActionLocked={true}
            facilities={facilities}
          />
        ) : (
          <ReportForm
            fieldsOfAction={fieldsOfAction}
            submitHandler={this.handleSubmit}
            resetHandler={this.handleReset}
            fieldsOfActionLocked={false}
            facilities={facilities}
          />
        )}
      </Paper>
    </>;
  }
}

export default withRouter(withStyles(styles)(ReportNewComponent));
