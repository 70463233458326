import axios from "axios";
import request, { handleError, handleSuccess } from '../../utils/axios';
import { getAuthHeader, setAuth } from '../../utils/auth';
import routes from '../../utils/routes';
import { viewKeys, uiOperations } from '../ui';
import { notificationsOperations, notificationsFlavors } from '../notifications';
import types from './types';
import Creators from './actions';
import * as R from "ramda";
import config from "../../utils/config";
import getHistory, { getLocation } from '../../utils/history';
import { action as genericAction, keys as genericKeys, type as genericType } from "../generics";
import { nameKeys as usersNameKeys, entityKey as usersEntityKey } from "../entities/usersKeys";
import { normalize, schema } from "normalizr";
import businessesOperations from "../entities/businessesOperations";
import { responseFormikValidator } from "../../utils/validations";
import { default as filesOperations } from '../entities/filesOperations';

const registrationPostStarted = Creators.registrationPostStarted;
const registrationPostSuccess = Creators.registrationPostSuccess;
const registrationPostFailure = Creators.registrationPostFailure;
const activationPostStarted = Creators.activationPostStarted;
const activationPostSuccess = Creators.activationPostSuccess;
const activationPostFailure = Creators.activationPostFailure;

const authPostStarted = Creators.authPostStarted;
const authPostSuccess = Creators.authPostSuccess;
const authPostFailure = Creators.authPostFailure;
const authRemoveSuccess = Creators.authRemoveSuccess;

const recoveryPostStarted = Creators.recoveryPostStarted;
const recoveryPostSuccess = Creators.recoveryPostSuccess;
const recoveryPostFailure = Creators.recoveryPostFailure;
const resetPostStarted = Creators.resetPostStarted;
const resetPostSuccess = Creators.resetPostSuccess;
const resetPostFailure = Creators.resetPostFailure;

const meGetStarted = Creators.meGetStarted;
const meGetSuccess = Creators.meGetSuccess;
const meGetFailure = Creators.meGetFailure;

const registrationPostRequest = (data, formikBag) => {
  return (dispatch, getState) => {
    const token = getAuthHeader();
    if (R.isEmpty(token)) {
      dispatch(registrationPostFailure(config.MESSAGE_NOTAUTHORIZED));
      return Promise.reject(config.MESSAGE_NOTAUTHORIZED);
    }
    const cancelTokens = getState().authentication.cancelTokens[types.REGISTRATION_POST];
    if (cancelTokens && 0 < cancelTokens.length) {
      cancelTokens[0].token.cancel();
    }
    const cancelToken = axios.CancelToken.source();
    const requestConfig = {
      headers: token,
      cancelToken: cancelToken.token
    };
    const requestData = {
      ...data,
      confirmationUrl: config.BASE_URL + routes.USER_ACTIVATION.path
    };
    dispatch(registrationPostStarted(cancelToken));
    return request.post('/users/register', requestData, requestConfig).then(response => {
      const data = handleSuccess(response, formikBag);
      dispatch(registrationPostSuccess(data));
      dispatch(uiOperations.viewChangeOperationGeneric(viewKeys.REGISTRATION.key, viewKeys.REGISTRATION.values.SUCCESS));

      return true;
    }, error => {
      const handledError = handleError(dispatch, error, formikBag);
      dispatch(registrationPostFailure(handledError));
      responseFormikValidator(error, formikBag);
      dispatch(notificationsOperations.notificationEnqueue(handledError.message, notificationsFlavors.ERROR));

      return false;
    });
  };
};

const activationPostRequest = (data, formikBag) => {
  return (dispatch, getState) => {
    const cancelTokens = getState().authentication.cancelTokens[types.ACTIVATION_POST];
    if (cancelTokens && 0 < cancelTokens.length) {
      cancelTokens[0].token.cancel();
    }
    const cancelToken = axios.CancelToken.source();
    const requestConfig = {
      cancelToken: cancelToken.token
    };
    const requestData = {
      ...data
    };
    dispatch(activationPostStarted(cancelToken));
    request.post('/users/confirm', requestData, requestConfig)
      .then(response => {
        const data = handleSuccess(response, formikBag);
        dispatch(activationPostSuccess(data));
        dispatch(notificationsOperations.notificationEnqueue('Ihr Benutzerkonto wurde erfolgreich aktiviert.', notificationsFlavors.SUCCESS));
        getHistory().push(routes.LOGIN.path);
      }, error => {
        const handledError = handleError(dispatch, error, formikBag);
        dispatch(activationPostFailure(handledError));
        dispatch(notificationsOperations.notificationEnqueue(handledError.message, notificationsFlavors.ERROR));
      });
  };
};

const authPostRequest = (username, password, formikBag) => {
  return (dispatch, getState) => {
    const token = getAuthHeader();
    const cancelTokens = getState().authentication.cancelTokens[types.AUTH_POST];
    if (cancelTokens && 0 < cancelTokens.length) {
      cancelTokens[0].token.cancel();
    }
    const cancelToken = axios.CancelToken.source();
    const requestConfig = {
      headers: token,
      cancelToken: cancelToken.token
    };
    const requestData = {
      username: username,
      password: password
    };
    dispatch(authPostStarted(cancelToken));
    request.post('/users/login', requestData, requestConfig)
      .then(response => {
        const data = handleSuccess(response, formikBag);
        if (data.token) {
          setAuth(data.token);
        }
        dispatch(authPostSuccess(data));
        const getUser = dispatch(meGetRequest());
        if (getUser) {
          getUser.then(() => {
            // https://reacttraining.com/react-router/web/example/auth-workflow
            const { from } = getLocation().state || { from: { pathname: routes.DASHBOARD.path } };
            getHistory().push(from);
          }, error => {
            dispatch(notificationsOperations.notificationEnqueue(error.message, notificationsFlavors.ERROR));
          });
        }
      }, error => {
        const handledError = handleError(dispatch, error, formikBag);
        dispatch(authPostFailure(handledError));
        dispatch(notificationsOperations.notificationEnqueue(handledError.message, notificationsFlavors.ERROR));
      });
  };
};

const authRemoveRequest = notifiy => {
  return dispatch => {
    setAuth(null);
    dispatch(authRemoveSuccess());
    if (notifiy) {
      dispatch(notificationsOperations.notificationEnqueue('Sie wurden erfolgreich abgemeldet'));
    }

    // getHistory().push(routes.LOGIN.path); // Somewhat redundant because PrivateRoute will redirect to login as well
  };
};

const recoveryPostRequest = (data, formikBag) => {
  return (dispatch, getState) => {
    const cancelTokens = getState().authentication.cancelTokens[types.RECOVERY_POST];
    if (cancelTokens && 0 < cancelTokens.length) {
      cancelTokens[0].token.cancel();
    }
    const cancelToken = axios.CancelToken.source();
    const requestConfig = {
      cancelToken: cancelToken.token
    };
    const requestData = {
      ...data,
      confirmationUrl: config.BASE_URL + routes.PASSWORD_RESET.path
    };
    dispatch(recoveryPostStarted(cancelToken));
    request.post('/users/reset/password', requestData, requestConfig)
      .then(response => {
        const data = handleSuccess(response, formikBag);
        dispatch(recoveryPostSuccess(data));
        dispatch(uiOperations.viewChangeOperationGeneric(viewKeys.PASSWORD_RECOVERY.key, viewKeys.PASSWORD_RECOVERY.values.SUCCESS));
      }, error => {
        const handledError = handleError(dispatch, error, formikBag);
        dispatch(recoveryPostFailure(handledError));
        dispatch(notificationsOperations.notificationEnqueue(handledError.message, notificationsFlavors.ERROR));
      });
  };
};

const resetPostRequest = (data, formikBag) => {
  return (dispatch, getState) => {
    const cancelTokens = getState().authentication.cancelTokens[types.RESET_POST];
    if (cancelTokens && 0 < cancelTokens.length) {
      cancelTokens[0].token.cancel();
    }
    const cancelToken = axios.CancelToken.source();
    const requestConfig = {
      cancelToken: cancelToken.token
    };
    const requestData = {
      ...data
    };
    dispatch(resetPostStarted(cancelToken));
    request.post('/users/reset/password/confirm', requestData, requestConfig)
      .then(response => {
        const data = handleSuccess(response, formikBag);
        dispatch(resetPostSuccess(data));
        dispatch(notificationsOperations.notificationEnqueue('Ihr neues Passwort kann jetzt genutzt werden.', notificationsFlavors.SUCCESS));
        getHistory().push(routes.LOGIN.path);
      }, error => {
        const handledError = handleError(dispatch, error, formikBag);
        dispatch(resetPostFailure(handledError));
        dispatch(notificationsOperations.notificationEnqueue(handledError.message, notificationsFlavors.ERROR));
      });
  };
};

const cancelAuthRequest = (cancelType, cancelMessage) => {
  return (dispatch, getState) => {
    const cancelTokens = getState().authentication.cancelTokens[cancelType];
    if (cancelTokens && 0 < cancelTokens.length) {
      cancelTokens[0].token.cancel(cancelMessage);
    } else {
      return Promise.resolve();
    }
  };
};

const meGetRequest = () => {
  return (dispatch, getState) => {
    const token = getAuthHeader();
    if (R.isEmpty(token)) {
      dispatch(meGetFailure(config.MESSAGE_NOTAUTHORIZED));
      return Promise.reject(config.MESSAGE_NOTAUTHORIZED);
    }
    const cancelTokens = getState().authentication.cancelTokens[types.ME_GET];
    if (cancelTokens && 0 < cancelTokens.length) {
      cancelTokens[0].token.cancel();
    }
    const cancelToken = axios.CancelToken.source();
    const requestConfig = {
      headers: token,
      cancelToken: cancelToken.token
    };
    dispatch(meGetStarted(cancelToken));
    return request.get('/me', requestConfig)
      .then(response => {
        const data = handleSuccess(response);
        dispatch(meGetSuccess(data.result));
        if (data.result) {
          const { intents, status } = genericKeys;
          const userSchema = new schema.Entity(usersEntityKey);

          dispatch(genericAction(genericType(usersEntityKey, usersNameKeys.USER, intents.GET, status.SUCCESS), normalize(data.result, userSchema)));
          const companyId = data.result.company;
          if (companyId) {
            dispatch(businessesOperations.businessGetRequest(companyId));
          }

          if (data.result.avatar) {
            dispatch(filesOperations.filesByAvatarUsersGetRequest(data.result.id));
          }
        }
      }, error => {
        const handledError = handleError(dispatch, error);
        dispatch(meGetFailure(handledError));
      });
  };
};

export default {
  registrationPostRequest,
  activationPostRequest,
  authPostRequest,
  authRemoveRequest,
  recoveryPostRequest,
  resetPostRequest,
  cancelAuthRequest,
  meGetRequest
};


