import React from 'react';
import { Field, Form, Formik } from "formik";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField } from 'formik-material-ui';
import FormHelperText from '@material-ui/core/FormHelperText';
import RichTextField from '../common/RichTextField';
import FilesSelection from "../common/FilesSelectionContainer";
import FormControl from '@material-ui/core/FormControl';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  wrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class FieldOfActionFormComponent extends React.Component {
  render() {
    const { classes, item, submitHandler, resetHandler } = this.props;

    let name = '';
    let description = '';
    let media = [];
    if (item) {
      name = item.name || '';
      description = item.description || '';
      media = item.image ? [item.image] : [];
    }

    return (
      <Formik
        initialValues = {{
          name: name,
          description: description,
          media: media,
        }}
        enableReinitialize={true}
        validate = {values => {
          let errors = {};
          if ('' === values.name) {
            errors.name = 'Dies ist ein Pflichtfeld';
          }
          if (1 < values.media.length) {
            errors.media = 'Maximal ein Bild';
          }
          return errors;
        }}
        onSubmit = {(values, formikBag) => {
          submitHandler(values, formikBag);
        }}>
        {({ status, isSubmitting, handleSubmit, handleReset, setFieldValue, values, errors }) => (
          <Form
            autoComplete="off"
            onSubmit={evt => {
              evt.stopPropagation();
              handleSubmit(evt);
            }}
            onReset={evt => {
              evt.stopPropagation();
              handleReset(evt);
              resetHandler();
            }}
            className={classes.form}
          >
            {status && <FormHelperText error>{status}</FormHelperText>}
            <Field component={TextField}
              type="text"
              id="name"
              name="name"
              label="Titel"
              required
              fullWidth
              margin="normal"/>
            <Field component={RichTextField}
              type="text"
              id="description"
              name="description"
              label="Beschreibung"
              fullWidth
              margin="normal"/>
            <FormControl fullWidth margin="normal" error={!!errors.media}>
              <Field component={FilesSelection}
                id="media"
                name="media"
                label="Bild"
                value={values.media}
                maxNumberOfFiles={1}
                allowedFileTypes={['image/*']}
                labelAddFiles="Bild hinzufügen"
                labelNoFiles="Kein Bild ausgewählt"
                labelAllFiles="Alle Bilder"
                onChange={setFieldValue}
              />
              {(!!errors.media) &&
                <FormHelperText>{errors.media}</FormHelperText>
              }
            </FormControl>
            <div className={classes.wrapper}>
              <Button type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}>
                Speichern
              </Button>
              {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress} />}
            </div>
            <div className={classes.wrapper}>
              <Button type="reset" color="primary">Abbrechen</Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(FieldOfActionFormComponent);
