import React from 'react';
import { Field, Form, Formik } from "formik";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ReactSelect from "../common/ReactSelect";

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    wrapper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
});

class ProfessionFormComponent extends React.Component {
    render() {
        const { classes, item, fieldsOfAction, submitHandler, resetHandler } = this.props;

        let setName = '';
        let setFoa = [];
        let setTaskKey = '';
        if (item) {
            setName = item.name || '';
            setFoa = [ ...item.fieldsOfAction ];
            setTaskKey = item.taskKey || '';
        }

        const fieldsOfActionOptions = Object.keys(fieldsOfAction).map(fieldOfActionKey => {
            const fieldOfAction = fieldsOfAction[fieldOfActionKey];
            return {
                value: fieldOfAction.id,
                label: fieldOfAction.name
            };
        }, []);

        return (
            <Formik
                initialValues = {{
                    name: setName,
                    fieldsOfAction: [ ...setFoa ],
                    taskKey: setTaskKey
                }}
                enableReinitialize={true}
                validate = {values => {
                    let errors = {};
                    if (values.name === '') {
                        errors.name = 'Dies ist ein Pflichtfeld';
                    }
                    if (values.fieldsOfAction.length === 0) {
                        errors.fieldsOfAction = 'Bitte mindestens eine Auswahl treffen';
                    }
                    if (values.taskKey === '') {
                        errors.taskKey = 'Dies ist ein Pflichtfeld';
                    } else if (!/^[A-Z]*$/gm.test(values.taskKey)) {
                        errors.taskKey = 'Nur Großbuchstaben ohne Leer-/Sonderzeichen';
                    } else if (values.taskKey.length > 9) {
                        errors.taskKey = 'Maximal 9 Zeichen';
                    }
                    return errors;
                }}
                onSubmit = {(values, formikBag) => {
                    submitHandler(values, formikBag);
                }}>
                {({ status, values, touched, errors, setFieldValue, setFieldTouched, isSubmitting, handleSubmit, handleReset }) => (
                    <Form
                        autoComplete="off"
                        onSubmit={evt => {
                            evt.stopPropagation();
                            handleSubmit(evt);
                        }}
                        onReset={evt => {
                            evt.stopPropagation();
                            handleReset(evt);
                            resetHandler();
                        }}
                        className={classes.form}
                    >
                        {status &&
                            <FormHelperText error>{status}</FormHelperText>
                        }
                        <Field component={TextField}
                            type="text"
                            id="name"
                            name="name"
                            label="Berufsbezeichnung"
                            required
                            fullWidth
                            margin="normal"/>
                        <FormControl fullWidth margin="normal" error={!!errors.fieldsOfAction && touched.fieldsOfAction}>
                            <ReactSelect name="fieldsOfAction"
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                value={values.fieldsOfAction}
                                error={errors.fieldsOfAction}
                                touched={touched.fieldsOfAction}
                                options={fieldsOfActionOptions}
                                placeholder="Aufgabenbereiche auswählen..."
                                label="Aufgabenbereiche"
                                isMulti={true}
                                isClearable={true}/>
                            {(!!errors.fieldsOfAction && touched.fieldsOfAction) &&
                                <FormHelperText>{errors.fieldsOfAction}</FormHelperText>
                            }
                        </FormControl>
                        <Field component={TextField}
                            type="text"
                            id="taskKey"
                            name="taskKey"
                            label="Aufgabenschlüssel"
                            helperText="Großbuchstaben ohne Leer-/Sonderzeichen"
                            readOnly={item && item.taskKey}
                            required
                            fullWidth
                            margin="normal"/>
                        <div className={classes.wrapper}>
                            <Button type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}>
                                Speichern
                            </Button>
                            {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress} />}
                        </div>
                        <div className={classes.wrapper}>
                            <Button type="reset" color="primary">Abbrechen</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withStyles(styles)(ProfessionFormComponent);
