import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PdfDialog = ({ open, title, content, onClose, showDownload, isCreatingPdf, onCreate, onDownload }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-confirm-title"
      aria-describedby="alert-dialog-confirm-description"
    >
      <DialogTitle id="alert-dialog-confirm-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-confirm-description">
          {content}
        </DialogContentText>
        {isCreatingPdf && <LinearProgress />}
        {showDownload && <DialogContentText>PDF wurde erstellt!</DialogContentText>}
      </DialogContent>
      <DialogActions>
        {!isCreatingPdf && <Button onClick={() => onClose()}>Abbrechen</Button>}
        {!showDownload && !isCreatingPdf && <Button onClick={() => onCreate()} color="primary" autoFocus>PDF erstellen</Button>}
        {showDownload && !isCreatingPdf && <Button onClick={() => onDownload()} color="primary" autoFocus>PDF herunterladen</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default PdfDialog;
