import { withRouter } from "react-router";
import { connect } from 'react-redux';
import FacilitiesComponent from './FacilitiesComponent';
import { entityKey as facilitiesSliceKey } from '../../redux/entities/facilitiesKeys';
import { default as facilitiesOperations } from '../../redux/entities/facilitiesOperations';
import { notificationsOperations } from "../../redux/notifications";
import { entityKey as usersSliceKey } from "../../redux/entities/usersKeys";

const mapStateToProps = state => {
  const { isAdmin, isInstructor } = state.authentication;
  const { errors, byId, allIds } = state.entities[facilitiesSliceKey];
  const usersById = state.entities[usersSliceKey].byId;

  return {
    isAdmin,
    isInstructor,
    errors,
    byId,
    allIds,
    usersById
  };
};

const mapDispatchToProps = dispatch => {
  const facilitiesGetRequest = () => dispatch(facilitiesOperations.facilitiesGetRequest());
  const facilityDeleteRequest = id => dispatch(facilitiesOperations.facilityDeleteRequest(id));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    facilitiesGetRequest,
    facilityDeleteRequest,
    notificationEnqueue
  };
};

const FacilitiesContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FacilitiesComponent));

export default FacilitiesContainer;
