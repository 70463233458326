import { withRouter } from "react-router";
import { connect } from 'react-redux';
import PasswordResetComponent from './PasswordResetComponent';
import { authenticationOperations } from '../../redux/authentication';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { errors, isAuthenticated } = state.authentication;

  return {
    errors,
    isAuthenticated
  };
};

const mapDispatchToProps = dispatch => {
  const resetPostRequest = (data, formikBag) => dispatch(authenticationOperations.resetPostRequest(data, formikBag));

  const cancelAuthRequest = (cancelType, cancelMessage) => dispatch(authenticationOperations.cancelAuthRequest(cancelType, cancelMessage));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    resetPostRequest,
    cancelAuthRequest,
    notificationEnqueue
  };
};

const PasswordResetContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordResetComponent));

export default PasswordResetContainer;