import { withRouter } from "react-router";
import { connect } from 'react-redux';
import ProfessionsComponent from './ProfessionsComponent';
import { entityKey as professionsSliceKey } from '../../redux/entities/professionsKeys';
import { default as professionsOperations } from '../../redux/entities/professionsOperations';
import { notificationsOperations } from "../../redux/notifications";
import { entityKey as fieldsOfActionSliceKey } from "../../redux/entities/fieldsOfActionKeys";

const mapStateToProps = state => {
  const { isAdmin } = state.authentication;
  const { errors, byId, allIds } = state.entities[professionsSliceKey];
  const fieldsOfActionById = state.entities[fieldsOfActionSliceKey].byId;

  return {
    isAdmin,
    errors,
    byId,
    allIds,
    fieldsOfActionById
  };
};

const mapDispatchToProps = dispatch => {
  const professionsGetRequest = () => dispatch(professionsOperations.professionsGetRequest());

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    professionsGetRequest,
    notificationEnqueue
  };
};

const ProfessionsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfessionsComponent));

export default ProfessionsContainer;