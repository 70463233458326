import Creators from './actions';

const notificationEnqueue = Creators.notificationEnqueue;
const notificationRemove = Creators.notificationRemove;

export default {
  notificationEnqueue,
  notificationRemove
};


