import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { duckTypes } from "../../redux/entities/tasksKeys";
import { default as TaskNewForm } from "./TaskNewFormComponent";
import roles from "../../utils/roles";
import { compareByKey2Sort } from "../../utils/helper";
import { responseFormikValidator } from "../../utils/validations";

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: 640,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    }
});

class TaskNewComponent extends React.Component {

    componentDidMount() {
        this.initData();
    }

    initData = () => {
        // NOTE: Currently fetching all items to get new items (may be overkill)
        this.props.professionsGetRequest();
        this.props.usersGetRequest();
    };

    handleSubmit = (values, formikBag) => {
        const user = this.props.usersById[this.props.myId];
        if (user) {
            const data = {
                owner: this.props.myId,
                job: user.job,
                title: values.title || '',
                fieldOfAction: values.fieldOfAction || '',
                description: values.description || '',
                media: values.media || [],
                links: values.links || [],
                cooperators: values.cooperators || [],
                master: values.cooperators.length === 0 && values.master ? 1 : 0
            };
            this.props.taskPostRequest(data, formikBag).then((axiosResponse) => {
                responseFormikValidator(axiosResponse, formikBag);
            });
        }
    };

    handleReset = () => {
        this.props.tasksCancelRequest(duckTypes.TASK_POST.group);
        this.props.history.goBack();
    };

    render() {
        const { classes, professionsById, fieldsOfActionById, usersById, myId, match } = this.props;

        const professionId = usersById[myId] && usersById[myId].job ? usersById[myId].job : null;
        const profession = professionsById[professionId];

        const fieldsOfActionAllowed = (profession && profession.fieldsOfAction) ? profession.fieldsOfAction : [];

        const fieldsOfAction = Object.keys(fieldsOfActionById)
            .filter(key => fieldsOfActionAllowed.includes(key))
            .sort((a, b) => compareByKey2Sort(fieldsOfActionById[a], fieldsOfActionById[b], 'name'))
            .reduce((obj, key) => {
                obj[key] = fieldsOfActionById[key];
                return obj;
            }, {});

        const cooperators = Object.keys(usersById)
            .filter(key => usersById[key].roles.includes(roles.INSTRUCTOR) && usersById[key].id !== myId) // NOTE: myId filtered b/c user cannot create new task AND be cooperator
            .sort((a, b) => compareByKey2Sort(usersById[a], usersById[b], 'firstName'))
            .reduce((obj, key) => {
                obj[key] = usersById[key];
                return obj;
            }, {});

        return (
            <React.Fragment>
                <Paper elevation={0} className={classes.paper}>
                    <TaskNewForm
                        fieldsOfAction={fieldsOfAction}
                        cooperators={cooperators}
                        submitHandler={ this.handleSubmit }
                        resetHandler={ this.handleReset }
                        defaultMaster={match.params.master === '1'}
                    />
                </Paper>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(TaskNewComponent);
