import { withRouter } from "react-router";
import { connect } from 'react-redux';
import FileEditComponent from './FileEditComponent';
import { entityKey as filesSliceKey } from '../../redux/entities/filesKeys';
import { default as filesOperations } from '../../redux/entities/filesOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { isAdmin, isInstructor } = state.authentication;
  const { errors, byId } = state.entities[filesSliceKey];

  return {
    isAdmin,
    isInstructor,
    errors,
    byId
  };
};

const mapDispatchToProps = dispatch => {
  const fileGetRequest = id => dispatch(filesOperations.fileGetRequest(id));
  const filePutRequest = (id, data, formikBag) => dispatch(filesOperations.filePutRequest(id, data, formikBag));

  const filesCancelRequest = (cancelType, cancelMessage) => dispatch(filesOperations.filesCancelRequest(cancelType, cancelMessage));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    fileGetRequest,
    filePutRequest,
    filesCancelRequest,
    notificationEnqueue
  };
};

const FileEditContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FileEditComponent));

export default FileEditContainer;
