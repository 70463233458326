import React from 'react';
import {
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    ListSubheader,
    Avatar,
    IconButton,
    Badge,
    Drawer,
    SwipeableDrawer,
    Tooltip,
    withStyles
  } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import {
    Feedback as FeedbackIcon,
    Mail as MailIcon,
    PowerSettingsNew as LogoutIcon,
    Settings as SettingsIcon,
} from '@material-ui/icons';
import routes from '../../../utils/routes';
import { isIOS } from "react-device-detect";
import FeedbackDialog from '../FeedbackDialogContainer';
import AdapterLink from "./AdapterLink";

const styles = theme => ({
  drawerPaper: {
    height: '100vh',
    minWidth: '10rem',
    position: 'relative',
  },
  toolbarSpacer: theme.mixins.toolbar,
  nested: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4),
    },
  },
  item: {
    whiteSpace: 'nowrap',
    '&.active': {
      backgroundColor: theme.palette.grey[100]
    },
  },
});

class ITLListItemRaw extends React.Component {
  render() {
    const { classes, route, nested, disabled } = this.props;
    const { key, path, title, shortTitle, icon } = route;
    return (
      <ListItem key={key} component={AdapterLink} exact to={path} className={nested ? classes.nested : ''} classes={{
        root: classes.item,
      }} button disabled={disabled}>
        <Tooltip title={title} aria-label={title}>
          <ListItemIcon children={React.createElement(icon)}/>
        </Tooltip>
        <ListItemText primary={shortTitle ? shortTitle : title} />
      </ListItem>
    );
  }
}
const ITLListItem = withStyles(styles)(ITLListItemRaw);

class DrawerNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackDialogOpen: false
    };
  }

  handleFeedbackDialog = () => {
    this.setState({
      feedbackDialogOpen: true
    });
  };

  render() {
    const { classes, width, isAuthenticated, isAdmin, isInstructor, drawerOpen, toggleDrawerHandler, logoutHandler, profile, avatarUrl, unreadNotificationsCount } = this.props;
    const { feedbackDialogOpen } = this.state;

    let role = 'Auszubildende*r';
    if (isInstructor) {
      role = 'Ausbilder*in';
    } else if (isAdmin) {
      role = 'Admin';
    }

    const drawer = (
      <React.Fragment>
        <List>
          {isAuthenticated ? (
            <React.Fragment>
              {profile &&
                <React.Fragment>
                  <ListItem>
                    <ListItemAvatar>
                      <Tooltip title={`${profile.username} (${role})`} aria-label={`${profile.username} (${role})`}>
                        {avatarUrl ? (
                          <Avatar src={avatarUrl} alt={`${profile.firstName} ${profile.lastName}`} />
                        ) : (
                          <Avatar>{profile.firstName.charAt(0).toUpperCase()}{profile.lastName.charAt(0).toUpperCase()}</Avatar>
                        )}
                      </Tooltip>
                    </ListItemAvatar>
                    <ListItemText primary={profile.firstName} secondary={profile.lastName}/>
                    <ListItemSecondaryAction>
                      <Tooltip title="Profil bearbeiten" aria-label="Profil bearbeiten">
                        <IconButton key={routes.PROFILE_EDIT.key} component={AdapterLink} exact to={routes.PROFILE_EDIT.path} edge="end" aria-label="Profil bearbeiten">
                          <SettingsIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem key={routes.NOTIFICATIONS.key} component={AdapterLink} exact to={routes.NOTIFICATIONS.path} classes={{
                    root: classes.item,
                  }} button>
                    <ListItemIcon>
                      <Badge badgeContent={unreadNotificationsCount} color="secondary">
                        <MailIcon />
                      </Badge>
                    </ListItemIcon>
                    <ListItemText primary={routes.NOTIFICATIONS.title} />
                  </ListItem>
                </React.Fragment>   
              }
              <ITLListItem route={routes.DASHBOARD} />
              {(isAdmin || isInstructor) &&
                <React.Fragment>
                  <Divider />
                  <ListSubheader disableSticky>Benutzer</ListSubheader>
                  <ITLListItem route={routes.USERS}/>
                </React.Fragment>
              }
              {!isAdmin &&
                <React.Fragment>
                  <Divider />
                  <ListSubheader disableSticky>Aufgaben</ListSubheader>
                  <ITLListItem route={routes.TASKS} />
                  {isInstructor &&
                    <ITLListItem route={routes.MASTER_TASKS} />
                  }
                  <ITLListItem route={routes.ARCHIVED_TASKS} />
                </React.Fragment>
              }
              {!isAdmin && !isInstructor &&
                <React.Fragment>
                  <Divider />
                  <ListSubheader disableSticky>Berichtsheft</ListSubheader>
                  <ITLListItem route={routes.PORTFOLIO}/>
                </React.Fragment>
              }
              {!isAdmin &&
                <React.Fragment>
                  <Divider />
                  <ListSubheader disableSticky>Kompetenzchecks</ListSubheader>
                  <ITLListItem route={routes.COMPETENCECHECKS} />
                  {isInstructor &&
                    <ITLListItem route={routes.COMPETENCECHECKS_TEMPLATES} />
                  }
                  <ITLListItem route={routes.COMPETENCECHECKS_ARCHIVE} />
                </React.Fragment>
              }
              {!isAdmin &&
                <React.Fragment>
                  <Divider />
                  <ListSubheader disableSticky>Tools</ListSubheader>
                  <ITLListItem route={routes.FILES} />
                  <ITLListItem route={routes.LINKS} />
                  <ITLListItem route={routes.FACILITIES}/>
                  {/* <ITLListItem route={routes.CONTACTS} />*/}
                </React.Fragment>
              }
              {isAdmin &&
                <React.Fragment>
                  <Divider />
                  <ListSubheader disableSticky>Grunddaten</ListSubheader>
                  <ITLListItem route={routes.BUSINESSES} />
                  <ITLListItem route={routes.FIELDSOFACTION} />
                  <ITLListItem route={routes.PROFESSIONS} />
                </React.Fragment>
              }
              <Divider />
            </React.Fragment>
          ) : ( 
            <ITLListItem route={routes.LOGIN} />
          )}
          <ITLListItem route={routes.HELP} />
          {isAuthenticated && 
            <ListItem button onClick={this.handleFeedbackDialog}>
              <ListItemIcon>
                <FeedbackIcon />
              </ListItemIcon>
              <ListItemText primary="Feedback" />
            </ListItem>  
          }
          {isAuthenticated && (
            <ListItem classes={{
              root: classes.item,
            }} button onClick={logoutHandler}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Abmelden" />
            </ListItem>
          )}
        </List>
        {isAuthenticated && 
          <FeedbackDialog
            open={feedbackDialogOpen}
            onClose={() => {
              this.setState({ feedbackDialogOpen: false });
            }}
          />
        }
      </React.Fragment>
    );

    return (
      <nav>
        {isWidthUp('lg', width) ? (
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open>
            <div className={classes.toolbarSpacer} />
            {drawer}
          </Drawer>
        ) : (
          <SwipeableDrawer
            disableBackdropTransition={!isIOS}
            disableDiscovery={isIOS}
            open={drawerOpen}
            onClose={() => toggleDrawerHandler(false)}
            onOpen={() => toggleDrawerHandler(true)}
            >
            {drawer}
          </SwipeableDrawer>
        )}
      </nav>
    );
  }
}

export default withStyles(styles)(withWidth()(DrawerNav));

