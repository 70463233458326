import { combineReducers } from "redux";
import { keys as genericKeys } from '../generics';
import { uiEntityKeys } from './keys';

const { intents } = genericKeys;

const INITIAL_STATE = {};

const uiReducerGeneric = sliceKey => (state = INITIAL_STATE, action) => {
  const type = action.type;
  const isCurrentSlice = (sliceKey === type.entity);

  if (type.intent === intents.CHANGE && isCurrentSlice) {
    return {
      ...state,
      [type.name]: action.payload
    };
  }

  return state;
};

const uiReducerViews = uiReducerGeneric(uiEntityKeys.views);
const uiReducerMeta = uiReducerGeneric(uiEntityKeys.meta);

const uiReducers = combineReducers({
  [uiEntityKeys.views]: uiReducerViews,
  [uiEntityKeys.meta]: uiReducerMeta
});

export default uiReducers;
