import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { labelsMuiDatatables } from "../../utils/labels";
import { compareByKey2Sort } from "../../utils/helper";
import MUIDataTable from "mui-datatables";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { DateTime } from "luxon";
import IntegratedIcon from '@material-ui/icons/ArtTrack';
import AttachedIcon from '@material-ui/icons/Attachment';
import Tooltip from "@material-ui/core/Tooltip";

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.grey[50]
  },
  dialogTitle: {
    margin: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 !important',
  },
  dialogActions: {
    justifyContent: 'center',
    margin: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  filesLoading: {
    padding: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  tableTitle: {
    display: "flex",
    alignItems: "center"
  },
  tableCellFiles: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  tableCellFilesInfo: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(0, 0.5),
  },
  tableCellFilesInfoIcon: {
    marginRight: theme.spacing(0.5),
  },
});

class PortfolioPdfFilterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEntries: {},
      selectedAll: false,
      // array length = column length in table; see https://github.com/gregnb/mui-datatables/issues/689
      filterList: [[], [], [], [], [], [], [], []],
      filesDialogOpen: {}
    };

    this.itemsInView = []; // not in state b/c onTableChange -> setState -> maximum update depth exceeded
  }

  componentDidMount() {
    this.initData();

    this.props.tableServerDataProvider.init(this.props.reportsGetRequest);
  }

  initData() {
    //this.props.reportsGetRequest();
    this.props.fieldsOfActionGetRequest();
    this.props.facilitiesGetRequest();
    this.props.reportsFilterOptionsRequest({});
  }

  handleFilesDialogOpen = (reportId) => {
    const { filesDialogOpen } = this.state;
    const selectedEntries = this.extendSelectedEntries(reportId);
    const initialized = (true === filesDialogOpen[reportId] || false === filesDialogOpen[reportId]);
    this.setState({
      selectedEntries: selectedEntries,
      filesDialogOpen: {
        ...filesDialogOpen,
        [reportId]: true
      }
    }, () => {
      if (!initialized) {
        this.props.filesByReportGetRequest(reportId);
      }
    });
  };

  handleFilesDialogClose = (reportId) => {
    this.setState({
      filesDialogOpen: {
        ...this.state.filesDialogOpen,
        [reportId]: false
      }
    });
  };

  /**
   * @param {object} evt
   * @param {string} reportId
   */
  handleToggleSelection(evt, reportId) {
    const selectedEntries = this.extendSelectedEntries(reportId);
    selectedEntries[reportId].selected = evt.target.checked;

    this.setState({
      selectedEntries: selectedEntries,
    }, () => {
      this.props.onChangeHandler(this.state.selectedEntries);
    });
  }

  /**
   * @param {object} evt
   * @param {string} reportId
   * @param {string} mediaId
   */
  handleMediaToggleSelection(evt, reportId, mediaId, checkType) {
    const selectedEntries = this.extendSelectedEntries(reportId);
    selectedEntries[reportId].media[mediaId][checkType] = evt.target.checked;
    this.setState({
      selectedEntries: selectedEntries,
    }, () => {
      this.props.onChangeHandler(this.state.selectedEntries);
    });
  }

  /**
   * @param {object} evt
   */
  handleToggleAllSelection(evt) {
    let allEntries = {};
    this.itemsInView.forEach(reportId => {
      const extendedEntries = this.extendSelectedEntries(reportId);
      extendedEntries[reportId].selected = evt.target.checked;
      allEntries = {
        ...allEntries,
        ...extendedEntries
      };
    });

    this.setState({
      selectedEntries: allEntries,
      selectedAll: evt.target.checked,
    }, () => {
      this.props.onChangeHandler(this.state.selectedEntries);
    });
  }

  extendSelectedEntries(reportId) {
    const selectedEntries = { ...this.state.selectedEntries };
    const { byId } = this.props;
    const report = byId[reportId];

    if (!(reportId in selectedEntries)) {
      const mediaEntries = {};
      report.media.forEach(mediaId => {
        mediaEntries[mediaId] = {
          id: mediaId,
          attach: false,
          integrate: true
        }
      });
      selectedEntries[reportId] = {
        id: reportId,
        media: mediaEntries,
        selected: false
      };
    }

    return selectedEntries;
  }

  /**
   * @param {object} report
   * @return {*}
   */
  renderMediaItem(reportId) {
    const { filesDialogOpen, selectedEntries } = this.state;
    const { classes, fullScreen, byId, fileById } = this.props;

    const report = byId[reportId];

    if (report.media.length === 0) {
      return "-";
    }

    let mediaItemsAttached = 0;
    let mediaItemsIntegrated = 0;

    const mediaAttachedSelected = (reportId, mediaId) => reportId in selectedEntries &&
      selectedEntries[reportId].media[mediaId] &&
      selectedEntries[reportId].media[mediaId].attach;

    const mediaIntegrateSelected = (reportId, mediaId) => reportId in selectedEntries &&
      selectedEntries[reportId].media[mediaId] &&
      selectedEntries[reportId].media[mediaId].integrate;

    const mediaItems = report.media.map(mediaId => {
      const file = fileById[mediaId];

      if (!file) {
        return null;
      }

      if (mediaAttachedSelected(reportId, file.id)) {
        mediaItemsAttached++;
      }
      if (mediaIntegrateSelected(reportId, file.id)) {
        mediaItemsIntegrated++;
      }

      return file;
    }).filter(file => file);

    return (
      <React.Fragment>
        <div className={classes.tableCellFiles}>
          <Tooltip title="Angehängte Dateien" aria-label="Angehängte Dateien">
            <Typography variant="body2" color="textSecondary" noWrap className={classes.tableCellFilesInfo}>
              <AttachedIcon className={classes.tableCellFilesInfoIcon}/> {mediaItemsAttached}
            </Typography>
          </Tooltip>
          <Tooltip title="Integrierte Dateien" aria-label="Integrierte Dateien">
            <Typography variant="body2" color="textSecondary" noWrap className={classes.tableCellFilesInfo}>
              <IntegratedIcon className={classes.tableCellFilesInfoIcon}/> {mediaItemsIntegrated}
            </Typography>
          </Tooltip>
          <Typography variant="caption" color="textSecondary" noWrap>{report.media.length} verfügbar</Typography>
        </div>
        <Button variant="outlined" size="small" onClick={() => this.handleFilesDialogOpen(reportId)}>
          Auswahl
        </Button>
        <Dialog
          open={filesDialogOpen[reportId] || false}
          fullScreen={fullScreen}
          keepMounted
          onClose={() => this.handleFilesDialogClose(reportId)}
        >
          <DialogTitle disableTypography classes={{
            root: classes.dialogTitle
          }}>
            <Typography variant="h6">Dateiauswahl</Typography>
            <IconButton aria-label="Close" className={classes.closeButton} onClick={() => this.handleFilesDialogClose(reportId)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent classes={{
            root: classes.dialogContent
          }}>
            {mediaItems.length ? (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      Anhängen
                    </TableCell>
                    <TableCell align="center">
                      Integrieren
                    </TableCell>
                    <TableCell>
                      Datei
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mediaItems.map(media =>
                    <TableRow key={media.id}>
                      <TableCell align="center">
                        <Checkbox
                          checked={mediaAttachedSelected(report.id, media.id)}
                          onChange={(evt) => this.handleMediaToggleSelection(evt, report.id, media.id, "attach")}
                          value={media.id}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox
                          checked={mediaIntegrateSelected(report.id, media.id)}
                          onChange={(evt) => this.handleMediaToggleSelection(evt, report.id, media.id, "integrate")}
                          value={media.id}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" display="block">
                          {media.title}
                        </Typography>
                        <Typography variant="caption" display="block">
                          {media.extension.length > 0 && `${media.extension.toUpperCase()}, `}
                          {DateTime.fromISO(media.updatedAt).setLocale('de').toLocaleString(DateTime.DATETIME_SHORT)} Uhr
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            ) : (
              <div className={classes.filesLoading}>
                <CircularProgress color="primary" />
              </div>
            )}
          </DialogContent>
          <DialogActions classes={{
            root: classes.dialogActions
          }}>
            <Button onClick={() => this.handleFilesDialogClose(reportId)} color="primary" variant="contained">
              Dateiauswahl übernehmen
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  render() {
    const { byId, allIds, classes, totalCount, isFetching, filterOptions } = this.props;
    const { selectedEntries, selectedAll } = this.state;

    const itemSelected = (itemId) => itemId in selectedEntries && selectedEntries[itemId].selected;

    const tableOptions = {
      selectableRows: "none",
      elevation: 0,
      responsive: "stacked",
      filter: true,
      filterType: 'dropdown',
      print: false,
      download: false,
      viewColumns: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 20, 50],
      textLabels: labelsMuiDatatables,
      serverSide: true,
      count: totalCount,
      serverSideFilterList: [],
      onTableChange: (action, tableState) => {
        this.itemsInView = tableState.displayData.map(entry => entry.data[0]); // not in state b/c setState -> maximum update depth exceeded
      },
    };

    this.props.tableServerDataProvider.bindToTableOptions(tableOptions);

    const tableColumns = [{
      name: "id",
      label: "Id",
      options: {
        filter: false,
        display: false
      }
    },
    {
      name: "choose",
      label: "Auswahl",
      options: {
        filter: false,
        customBodyRender: (item) => {
          return (
            <Checkbox
              checked={itemSelected(item.id)}
              onChange={(evt) => this.handleToggleSelection(evt, item.id)}
              value={item.id}
              color="primary"
            />
          );
        }
      }
    },
    {
      name: "title",
      label: "Titel",
      options: {
        filter: false
      }
    },
    {
      name: "duration",
      label: "Dauer",
      options: {
        filter: false
      }
    },
    {
      name: "dateFrom",
      label: "Datum",
      options: {
        filter: true,
      }
    },
    {
      name: "dateTo",
      label: "Datum",
      options: {
        filter: true,
        display: 'false',
      }
    },
    {
      name: "learningPlaces",
      label: "Lernort",
      options: {
        filter: true,
      }
    },
    {
      name: "fieldOfActions",
      label: "Aufgabenbereich",
      options: {
        filter: true,
      }
    },
    {
      name: "media",
      label: "Dateien",
      options: {
        filter: false,
        customBodyRender: id => {
          return this.renderMediaItem(id);
        }
      }
    },
    /* virtual column for date filter not mapped to real data */
    {
      name: "dateFromFrom",
      label: "Start Datum von",
      options: {
        filter: true,
        display: false,
      }
    },
    /* virtual column for date filter not mapped to real data */
    {
      name: "dateFromTo",
      label: "Start Datum bis",
      options: {
        filter: true,
        display: false,
      }
    }];

    this.props.tableServerDataProvider.bindTableFilters(filterOptions, tableColumns);

    const tableData = allIds
      .sort((a, b) => compareByKey2Sort(byId[a], byId[b], "updatedAt", true))
      .map(itemId => {
        const item = byId[itemId];

        if (!item) {
          return [];
        }

        const learningPlaceName = item.learningPlaceName ? item.learningPlaceName : "";
        const fieldOfActionName = item.fieldOfActionName ? item.fieldOfActionName : "";

        const { id, title, duration, dateFrom } = item;

        return [id, item, title, duration, dateFrom, dateFrom, learningPlaceName, fieldOfActionName, id];
      });

    const selectedCount = Object.values(selectedEntries).filter(report => report.selected).length;
    // TODO: Make this work again, move selected count to dialog title
    const tableTitle = <div className={classes.tableTitle}>
      <FormControlLabel
        control={
          <Checkbox
            id="toggleAllReports"
            checked={selectedAll}
            onChange={(evt) => this.handleToggleAllSelection(evt)}
            value="all"
            color="primary"
          />
        }
        label="Alle auswählen"
      />
      <Typography variant="body2" display="inline">(ausgewählte Einträge: {selectedCount})</Typography>
    </div>;

    return (
      <React.Fragment>
        <MUIDataTable title={tableTitle} options={tableOptions} columns={tableColumns} data={tableData}/>
        <Backdrop className={classes.backdrop} open={isFetching || false}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </React.Fragment>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(PortfolioPdfFilterComponent));
