import { withRouter } from "react-router";
import { connect } from 'react-redux';
import TaskNewComponent from './TaskNewComponent';
import { entityKey as tasksSliceKey } from '../../redux/entities/tasksKeys';
import { default as tasksOperations } from '../../redux/entities/tasksOperations';
import { entityKey as professionsSliceKey } from '../../redux/entities/professionsKeys';
import { default as professionsOperations } from '../../redux/entities/professionsOperations';
import { entityKey as usersSliceKey } from '../../redux/entities/usersKeys';
import { default as usersOperations } from '../../redux/entities/usersOperations';
import { entityKey as fieldsOfActionSliceKey } from '../../redux/entities/fieldsOfActionKeys';
import { notificationsOperations } from "../../redux/notifications";


const mapStateToProps = state => {
  const { myId, isAdmin } = state.authentication;
  const { errors, byId } = state.entities[tasksSliceKey];
  const professionsById = state.entities[professionsSliceKey].byId;
  const fieldsOfActionById = state.entities[fieldsOfActionSliceKey].byId;
  const usersById = state.entities[usersSliceKey].byId;

  return {
    myId,
    isAdmin,
    errors,
    byId,
    professionsById,
    fieldsOfActionById,
    usersById
  };
};

const mapDispatchToProps = dispatch => {
  const taskPostRequest = (data, formikBag) => dispatch(tasksOperations.taskPostRequest(data, formikBag));

  const tasksCancelRequest = (cancelType, cancelMessage) => dispatch(tasksOperations.tasksCancelRequest(cancelType, cancelMessage));

  const professionsGetRequest = () => dispatch(professionsOperations.professionsGetRequest());

  const usersGetRequest = () => dispatch(usersOperations.usersGetRequest());

  const userGetRequest = () => dispatch(usersOperations.userGetRequest());

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    taskPostRequest,
    tasksCancelRequest,
    professionsGetRequest,
    usersGetRequest,
    userGetRequest,
    notificationEnqueue
  };
};

const TaskNewContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskNewComponent));

export default TaskNewContainer;