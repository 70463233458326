import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import RegistrationIcon from '@material-ui/icons/PersonAdd';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { authenticationTypes } from "../../redux/authentication";
import { default as UserRegistrationForm } from "./UserRegistrationFormComponent";

const styles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: 640,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  }
});

class UserRegistrationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      created: false,
    };
  }

  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(prevProps) {
  }

  initData() {
    if (this.props.isAdmin) {
      this.props.businessesGetRequest();
    } else if (this.props.isInstructor && this.props.profile) {
      this.props.businessGetRequest(this.props.profile.company);
    }

    this.props.professionsGetRequest();
    this.props.facilitiesGetRequest();
  }

  handleSubmit = (values, formikBag) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      username: values.username,
      company: values.company,
      job: values.profession,
      role: values.role
    };

    if ("facilities" in values) {
      data.apprenticeLearningPlaces = values.facilities;
    }

    this.props.registrationPostRequest(data, formikBag).then(isUserCreated => {
      this.setState({
        created: isUserCreated,
      });
    });
  };
  handleReset = () => {
    this.props.cancelAuthRequest(authenticationTypes.REGISTRATION_POST);
    this.props.history.goBack();
  };

  render() {
    const { classes, isAdmin, isInstructor, profile, businesses, professionsById, facilityById } = this.props;
    const { created } = this.state;

    if (created) {
      return (
        <Paper elevation={0} className={classes.paper}>
          <Avatar className={classes.avatar}>
            <RegistrationIcon/>
          </Avatar>
          <Typography component="h1" variant="h5" gutterBottom>Benutzer anlegen</Typography>
          <Typography variant="body1" paragraph>Eine E-Mail mit einem Bestätigungslink wurde an die angegebene E-Mail
            Adresse verschickt. Über diesen Bestätigungslink kann das Benutzerkonto aktiviert werden.</Typography>
        </Paper>
      );
    }

    return (
      <Paper elevation={0} className={classes.paper}>
        <Avatar className={classes.avatar}>
          <RegistrationIcon/>
        </Avatar>
        <Typography component="h1" variant="h5" gutterBottom>Benutzer anlegen</Typography>
        <UserRegistrationForm isAdmin={isAdmin} isInstructor={isInstructor} profile={profile} businesses={businesses}
                              professionsById={professionsById} facilityById={facilityById} submitHandler={this.handleSubmit}
                              resetHandler={this.handleReset}/>
      </Paper>
    );
  }
}

export default withStyles(styles)(UserRegistrationComponent);
