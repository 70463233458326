import { withRouter } from "react-router";
import { connect } from 'react-redux';
import FileNewComponent from './FileNewComponent';
import { entityKey as filesSliceKey } from '../../redux/entities/filesKeys';
import { default as filesOperations } from '../../redux/entities/filesOperations';
import { notificationsOperations } from "../../redux/notifications";

const mapStateToProps = state => {
  const { isAdmin, isInstructor } = state.authentication;
  const { errors, byId } = state.entities[filesSliceKey];

  return {
    isAdmin,
    isInstructor,
    errors,
    byId
  };
};

const mapDispatchToProps = dispatch => {
  const filePutRequest = (id, data, formikBag) => dispatch(filesOperations.filePutRequest(id, data, formikBag));

  const filesCancelRequest = (cancelType, cancelMessage) => dispatch(filesOperations.filesCancelRequest(cancelType, cancelMessage));

  const filePostSuccess = data => dispatch(filesOperations.filePostSuccess(data));
  const filePostError = handledError => dispatch(filesOperations.filePostError(dispatch, handledError));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    filePutRequest,
    filePostSuccess,
    filePostError,
    filesCancelRequest,
    notificationEnqueue
  };
};

const FileNewContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FileNewComponent));

export default FileNewContainer;
