import { withRouter } from "react-router";
import { connect } from 'react-redux';
import TasksComponent from './TasksComponent';
import { notificationsOperations } from "../../redux/notifications";
import { entityKey as tasksSliceKey } from '../../redux/entities/tasksKeys';
import { default as tasksOperations } from '../../redux/entities/tasksOperations';
import { entityKey as fieldsOfActionSliceKey } from "../../redux/entities/fieldsOfActionKeys";
import { entityKey as usersSliceKey } from "../../redux/entities/usersKeys";
import { entityKey as subTasksSliceKey } from "../../redux/entities/subTasksKeys";
import { withTableServerDataProvider } from "../../utils/tableServerDataProvider";

const mapStateToProps = state => {
  const { isAdmin, isInstructor } = state.authentication;
  const { errors, byId, allIds, totalCount, filterOptions } = state.entities[tasksSliceKey];
  const fieldsOfActionById = state.entities[fieldsOfActionSliceKey].byId;
  const usersById = state.entities[usersSliceKey].byId;
  const subTasksById = state.entities[subTasksSliceKey].byId;

  return {
    isAdmin,
    isInstructor,
    errors,
    byId,
    allIds,
    fieldsOfActionById,
    usersById,
    subTasksById,
    totalCount,
    filterOptions
  };
};

const mapDispatchToProps = dispatch => {
  const tasksGetRequest = (parameters) => dispatch(tasksOperations.tasksGetRequest(parameters));
  const taskCopyRequest = id => dispatch(tasksOperations.taskCopyRequest(id));
  const tasksFilterOptionsRequest = parameters => dispatch(tasksOperations.tasksFilterOptionsRequest(parameters));

  const notificationEnqueue = (message, flavor) => dispatch(notificationsOperations.notificationEnqueue(message, flavor));

  return {
    tasksGetRequest,
    notificationEnqueue,
    taskCopyRequest,
    tasksFilterOptionsRequest
  };
};

const TasksContainer = withTableServerDataProvider(withRouter(connect(mapStateToProps, mapDispatchToProps)(TasksComponent)));

export default TasksContainer;