import React, {Fragment} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from "@material-ui/core/Tooltip";
import Button from '@material-ui/core/Button';
import ContactPersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneIcon from '@material-ui/icons/Phone';
import WebsiteIcon from '@material-ui/icons/Link';
import AreaIcon from '@material-ui/icons/Map';
import AddressIcon from '@material-ui/icons/Place';
import ReactHtmlParser from "react-html-parser";
import ShowMoreHtml from "../common/ShowMoreHtml";
import PeopleIcon from '@material-ui/icons/People';
import routes from "../../utils/routes";
import RemovalDialog from "../common/RemovalDialog";

const styles = theme => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        height: "100%",
    },
    cardHeader: {
        alignItems: 'flex-start',
        flexGrow: 1,
    },
    cardContent: {
        padding: 0,
    },
    cardActions: {
        justifyContent: 'space-between'
    },
});

class FacilityComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            removalDialogOpen: false,
        };
    }

    openRemovalDialog = () => {
        this.setState({
            removalDialogOpen: true,
        });
    }

    handleClick = (evt, route) => {
        evt.preventDefault();
        if (route) {
            this.props.history.push(route);
        }
    };

    render() {
        const { classes, item, isApprentice, deleteHandler } = this.props;

        const itemDescription = (
            <ShowMoreHtml maxHeight={96}>
                {ReactHtmlParser(item.description)}
            </ShowMoreHtml>
        );

        return (
            <Card className={classes.card}>
                <CardHeader
                    title={item.name}
                    titleTypographyProps={{
                        variant: 'h6',
                        gutterBottom: true
                    }}
                    subheader={itemDescription}
                    subheaderTypographyProps={{variant: 'body2'}}
                    className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                    <List dense>
                        <ListItem>
                            <Tooltip title="Ansprechpartner" aria-label="Ansprechpartner">
                                <ListItemIcon>
                                    <ContactPersonIcon />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={item.contactPerson}/>
                        </ListItem>
                        <ListItem>
                            <Tooltip title="E-Mail" aria-label="E-Mail">
                                <ListItemIcon>
                                    <EmailIcon />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={item.email}/>
                        </ListItem>
                        <ListItem>
                            <Tooltip title="Telefon" aria-label="Telefon">
                                <ListItemIcon>
                                    <PhoneIcon />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={item.phone}/>
                        </ListItem>
                        <ListItem>
                            <Tooltip title="Website" aria-label="Website">
                                <ListItemIcon>
                                    <WebsiteIcon />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={item.website}/>
                        </ListItem>
                        <ListItem>
                            <Tooltip title="Gebietsbezeichnung" aria-label="Gebietsbezeichnung">
                                <ListItemIcon>
                                    <AreaIcon />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={item.area}/>
                        </ListItem>
                        <ListItem>
                            <Tooltip title="Adresse" aria-label="Adresse">
                                <ListItemIcon>
                                    <AddressIcon />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText
                                primary={item.street}
                                secondary={`${item.zip} ${item.city}`}
                                secondaryTypographyProps={{color: 'textPrimary'}}
                            />
                        </ListItem>
                        {!isApprentice &&
                          <ListItem>
                              <Tooltip title="Auszubildende" aria-label="Auszubildende">
                                  <ListItemIcon>
                                      <PeopleIcon />
                                  </ListItemIcon>
                              </Tooltip>
                              <ListItemText
                                primary={item.apprenticesCount > 0 ?
                                  (item.apprenticesCount === 1 ? '1 Auszubildende*r' : `${item.apprenticesCount} Auszubildende` )
                                  : 'keine Auszubildende'}
                                secondaryTypographyProps={{color: 'textPrimary'}}
                              />
                          </ListItem>
                        }
                    </List>
                </CardContent>
                <CardActions className={classes.cardActions}>
                    {item.permissions && item.permissions.canEdit && (
                        <Button size="small" variant="outlined" color="primary" onClick={evt => this.handleClick(evt, routes.FACILITY_EDIT.path.replace(':id', item.id))}>Bearbeiten</Button>
                    )}
                    {item.permissions && item.permissions.canDelete && item.apprenticesCount === 0 && (
                        <Fragment>
                            <Button size="small" onClick={() => this.openRemovalDialog()}>Löschen</Button>
                            <RemovalDialog
                              open={this.state.removalDialogOpen}
                              subject={item.name}
                              onClose={(remove) => {
                                  this.setState({ removalDialogOpen: false });
                                  if (remove === true) {
                                      deleteHandler(item?.id ?? null);
                                  }
                              }}
                            />
                        </Fragment>
                    )}
                </CardActions>
            </Card>
        );
    }
}

export default withStyles(styles)(FacilityComponent);
