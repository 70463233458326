import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { duckTypes } from "../../redux/entities/linksKeys";
import { default as LinkForm } from "./LinkFormComponent";
import flavors from "../../redux/notifications/flavors";

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: 640,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
});

class LinkEditComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            linkId: null
        };
    }

    componentDidMount() {
        this.initData();
    }

    initData = () => {
        const { match } = this.props; // https://reacttraining.com/react-router/web/api/match
        if (match.params.id) {
            this.setState({
                linkId: match.params.id
            }, () => {
                this.props.linkGetRequest(this.state.linkId);
            });
        }
    };

    handleSubmit = (values, formikBag) => {
        const { linkId } = this.state;
        const data = {
            url: values.url || '',
            title: values.title || '',
            description: values.description || '',
            hiddenFromApprentice: values.hidden === '1'
        };
        if (linkId) {
            this.props.linkPutRequest(linkId, data, formikBag);
        } else {
            this.props.notificationEnqueue('Es ist ein Fehler aufgetreten', flavors.ERROR);
        }
    };

    handleReset = () => {
        this.props.linksCancelRequest(duckTypes.LINK_PUT.group);
        this.props.history.goBack();
    };

    render() {
        const { classes, isAdmin, isInstructor, byId } = this.props;

        const isApprentice = !isAdmin && !isInstructor;

        const item = byId[this.state.linkId];

        return (
            <React.Fragment>
                <Paper elevation={0} className={classes.paper}>
                    <LinkForm item={item} submitHandler={ this.handleSubmit } resetHandler={ this.handleReset } isApprentice={isApprentice} />
                </Paper>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(LinkEditComponent);
