import React from "react";
import Select from "react-select";
import clsx from "clsx";
import { emphasize, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import Avatar from '@material-ui/core/Avatar';
import FaceIcon from '@material-ui/icons/Face';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
        minWidth: 290,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
        '.MuiDialogContent-root &': {
          marginTop: 0,
          position: 'relative',
        },
    },
    divider: {
        height: theme.spacing(2),
    },
});

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { classes, TextFieldProps },
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
                fontStyle: props.isDisabled ? 'italic' : '',
                color: props.isDisabled ? props.theme.colors.neutral40 : '',
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    const { selectProps, innerProps = {}, children } = props;
    return (
        <Typography
            color="textSecondary"
            className={selectProps.classes.placeholder}
            {...innerProps}
        >
            {children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={clsx(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function MultiValueAvatar(props) {
    return (
        <Chip
            tabIndex={-1}
            avatar={props.data.avatarUrl ? <Avatar alt={props.data.label} src={props.data.avatarUrl} /> : null}
            icon={props.data.avatarUrl ? null : <FaceIcon />}
            label={props.children}
            className={clsx(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

// https://material-ui.com/demos/autocomplete/#react-select
// https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8#gistcomment-2887706

class ReactSelect extends React.Component {
    handleChange = option => {
        let value = '';
        if (option) {
            value = this.props.isMulti ? option.map(item => item.value) : option.value;
        }
        this.props.onChange(this.props.name, value);
    };

    handleBlur = () => {
        this.props.onBlur(this.props.name, true);
    };

    render() {
        const { namespace, name, value, options, placeholder, label, isMulti, isClearable, classes, theme, useAvatar, isDisabled } = this.props;

        const idPrefix = `rctslct${namespace}` || "rctslct";

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
        };

        const textFieldProps = {
            label: label,
            InputLabelProps: {
                htmlFor: `${idPrefix}${name}`,
                shrink: true,
            },
            disabled: isDisabled
        };

        const getValue = () => {
            if (options && options.length > 0) {
                const selected = isMulti
                  ? options.filter(option => value.indexOf(option.value) > -1)
                  : options.find(option => option.value === value);
                return selected || null;
            } else {
                return isMulti ? [] : null;
            }
        };

        if (useAvatar) {
            components.MultiValue = MultiValueAvatar;
        }

        return (
            <Select
                options={options}
                isMulti={isMulti}
                isClearable={isClearable}
                value={getValue()}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                placeholder={placeholder}
                components={components}
                classes={classes}
                styles={selectStyles}
                inputId={`${idPrefix}${name}`}
                TextFieldProps={textFieldProps}
                noOptionsMessage={() => "Keine Auswahlmöglichkeiten"}
                isDisabled={isDisabled}
            />
        );
    }
}

export default withStyles(styles, {withTheme: true})(ReactSelect);
