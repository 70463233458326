import { withRouter } from "react-router";
import { connect } from "react-redux";
import ReportNewComponent from "./ReportNewComponent";
import { entityKey as reportsSliceKey } from "../../redux/entities/reportsKeys";
import { default as reportsOperations } from "../../redux/entities/reportsOperations";
import { entityKey as fieldsOfActionSliceKey } from "../../redux/entities/fieldsOfActionKeys";
import { default as fieldsOfActionOperations } from "../../redux/entities/fieldsOfActionOperations";
import { entityKey as facilitiesSliceKey } from "../../redux/entities/facilitiesKeys";
import { default as facilitiesOperations } from "../../redux/entities/facilitiesOperations";
import { metaKeys, uiOperations } from "../../redux/ui";

const mapStateToProps = state => {
  const { myId, isAdmin } = state.authentication;
  const { errors, byId } = state.entities[reportsSliceKey];
  const fieldsOfActionById = state.entities[fieldsOfActionSliceKey].byId;
  const facilitiesAllIds = state.entities[facilitiesSliceKey].allIds;
  const facilityById = state.entities[facilitiesSliceKey].byId;

  return {
    myId,
    isAdmin,
    errors,
    byId,
    fieldsOfActionById,
    facilitiesAllIds,
    facilityById
  };
};

const mapDispatchToProps = dispatch => {
  const reportPostRequest = (data, formikBag) => dispatch(reportsOperations.reportPostRequest(data, formikBag));
  const reportCancelRequest = (cancelType, cancelMessage) => dispatch(reportsOperations.reportCancelRequest(cancelType, cancelMessage));
  const fieldsOfActionGetRequest = () => dispatch(fieldsOfActionOperations.fieldsOfActionGetRequest());
  const fieldOfActionGetRequest = (id) => dispatch(fieldsOfActionOperations.fieldOfActionGetRequest(id));
  const facilitiesGetRequest = () => dispatch(facilitiesOperations.facilitiesGetRequest());

  const parentRouteChange = value => dispatch(uiOperations.metaChangeOperationGeneric(metaKeys.PARENTROUTE, value));

  return {
    reportPostRequest,
    reportCancelRequest,
    fieldsOfActionGetRequest,
    fieldOfActionGetRequest,
    facilitiesGetRequest,
    parentRouteChange
  };
};

const ReportNewContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportNewComponent));

export default ReportNewContainer;
