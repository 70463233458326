import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Tooltip from "@material-ui/core/Tooltip";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import MUIDataTable from "mui-datatables";

import CustomToolbarSelect from "./CustomToolbarSelect";
import flavors from "../../redux/notifications/flavors";
import {labelsMuiDatatables} from "../../utils/labels";
import {default as LinkForm} from "../links/LinkFormComponent";
import {duckTypes} from "../../redux/entities/linksKeys";
import ShowMoreText from "./ShowMoreText";

const styles = theme => ({
    container: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    list: {
        border: `1px solid ${theme.palette.divider}`,
        marginBottom: theme.spacing(1),
    },
    dialogTitle: {
        margin: 0,
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(2),
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 !important',
    },
    dialogActions: {
        justifyContent: 'center',
        margin: 0,
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    linkFormContainer: {
        backgroundColor: theme.palette.grey[50],
        padding: theme.spacing(0, 4, 2),
    },
});

class LinksSelectionComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: false,
            tabNav: 0
        };
    }

    componentDidMount() {
        this.initData();
    }

    initData = () => {
        this.props.linksGetRequest();
    };

    handleDialogOpen = () => {
        this.setState({
            dialogOpen: true
        });
    };

    handleDialogClose = () => {
        this.setState({
            dialogOpen: false
        });
    };

    handleTabChange = (evt, value) => {
        this.setState({
            tabNav: value
        });
    };

    handleSelected = ids => {
        if (!ids || ids.length === 0) {
            return;
        }
        const { name, value } = this.props.field;

        const newIds = ids.filter(id => {
            if (value.indexOf(id) === -1) {
                return true;
            } else {
                this.props.notificationEnqueue(`Link '${this.props.byId[id].title}' wurde bereits hinzugefügt`, flavors.WARNING);
                return false;
            }
        });

        const newValue = value.length > 0 ? [ ...value, ...newIds ] : [...newIds];
        this.props.onChange(name, newValue);
        this.handleDialogClose();
    };

    handleDelete = id => {
        const { name, value } = this.props.field;
        value.splice(value.indexOf(id), 1);
        const newValue = [ ...value ];
        this.props.onChange(name, newValue);
    };

    handleNewSubmit = (values, formikBag) => {
        const data = {
            user: this.props.myId,
            url: values.url || '',
            title: values.title || '',
            description: values.description || '',
            hiddenFromApprentice: values.hidden === '1'
        };
        const promise = this.props.linkPostRequest(data, formikBag);
        if (promise) {
            promise.then(data => {
                const newId = data.result ? data.result.id : null;

                if (!newId) {
                    return;
                }

                const { name, value } = this.props.field;

                const newValue = value.length > 0 ? [ ...value, newId ] : [ newId ];
                this.props.onChange(name, newValue);
                this.handleDialogClose();
            });
        }
    };

    handleNewReset = () => {
        this.props.linksCancelRequest(duckTypes.LINK_POST.group);
        this.handleDialogClose();
    };

    render() {
        const { classes, isAdmin, isInstructor, fullScreen, byId, allIds, value } = this.props;
        const { tabNav } = this.state;

        const isApprentice = !isAdmin && !isInstructor;

        const tableOptions = {
            selectableRows: 'multiple',
            elevation: 0,
            responsive: "scrollMaxHeight",
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 20, 50],
            textLabels: labelsMuiDatatables,
            customSort: (data, colIndex, order) => {
                return data.sort((a, b) => {
                    let valA = a.data[colIndex];
                    let valB = b.data[colIndex];
                    return (valA < valB ? -1: 1 ) * (order === 'desc' ? -1 : 1);
                });
            },
            customSearch: (searchQuery, currentRow, columns) => {
                let isFound = false;
                currentRow.forEach(col => {
                    if (col !== null) {
                        let cellContent = '';
                        if (Array.isArray(col)) {
                            col.forEach(index => {
                                const value = col[index] || '';
                                cellContent += `${value.toString().toLowerCase()} `;
                            });
                        } else {
                            cellContent = col.toString();
                        }
                        if (cellContent.indexOf(searchQuery.toLowerCase()) >= 0) {
                            isFound = true;
                        }
                    }
                });
                return isFound;
            },
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedHandler={this.handleSelected} />
            ),
        };

        const tableColumns = [{
            name: "id",
            label: " ",
            options: {
                display: "excluded"
            }
        }, {
            name: "meta",
            label: "Titel, Beschreibung",
            options: {
                sort: false,
                customBodyRender: value => {
                    return (
                        <React.Fragment>
                            {value[0]}
                            {value[1] && 
                                <React.Fragment>
                                    <br/>
                                    <ShowMoreText maxLength={64}>{value[1]}</ShowMoreText>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    )
                }
            }
        }, {
            name: "url",
            label: "URL",
        }, {
            name: "hidden",
            label: " ",
            options: {
                display: !isApprentice,
                sort: false,
                customBodyRender: value => value ? (
                    <Tooltip title="Ohne Beschränkung" aria-label="Ohne Beschränkung">
                        <LockOpenIcon color="disabled"/>
                    </Tooltip>
                ) : (
                    <Tooltip title="Kein Zugriff für Auszubildende" aria-label="Kein Zugriff für Auszubildende">
                        <LockIcon color="action"/>
                    </Tooltip>
                )
            }
        }];

        const tableData = allIds.map(itemId => {
            const item = byId[itemId];
            if (!item) {
                return [];
            }
            const meta = [
                item.title,
                item.description
            ];
            return [
                item.id,
                meta,
                item.url,
                item.hiddenFromApprentice
            ];
        });
    
        return (
            <Box className={classes.container}>
                <List
                    dense
                    classes={{
                        root: classes.list,
                    }}
                >
                    {value.length > 0 ? value.map((id, key) => {
                        const link = byId[id];
                        if (!link) {
                            return null;
                        }
                        return (
                            <ListItem key={key}>
                                <ListItemAvatar>
                                    <Avatar><LinkIcon/></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={link.title} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" onClick={evt => this.handleDelete(id)} aria-label="Link entfernen">
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    }) : (
                        <ListItem>
                            <ListItemText secondary="Keine Links zugeordnet" />
                        </ListItem>
                    )}
                </List>
                <Button size="small" variant="outlined" onClick={this.handleDialogOpen}>
                    Link(s) hinzufügen
                </Button>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.state.dialogOpen}
                    onClose={this.handleDialogClose}
                >
                    <DialogTitle disableTypography classes={{
                        root: classes.dialogTitle
                    }}>
                        <Typography variant="h6">Link(s) hinzufügen</Typography>
                        <IconButton aria-label="Close" className={classes.closeButton} onClick={evt => this.handleDialogClose()}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent classes={{
                        root: classes.dialogContent
                    }}>
                        {tabNav === 0 &&
                            <MUIDataTable
                                title={"Alle Links"}
                                options={tableOptions}
                                columns={tableColumns}
                                data={tableData}
                            />
                        }
                        {tabNav === 1 &&
                            <Box className={classes.linkFormContainer}>
                                <LinkForm submitHandler={ this.handleNewSubmit } resetHandler={ this.handleNewReset } isApprentice={isApprentice} />
                            </Box>
                        }
                    </DialogContent>
                    <DialogActions classes={{
                        root: classes.dialogActions
                    }}>
                        <BottomNavigation
                            value={tabNav}
                            onChange={this.handleTabChange}
                            showLabels
                        >
                            <BottomNavigationAction label="Linksammlung" icon={<LinkIcon />} />
                            <BottomNavigationAction label="Neuer Link" icon={<AddIcon />} />
                        </BottomNavigation>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(LinksSelectionComponent));
