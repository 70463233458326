import types from './types';
import { updateCancelTokens } from '../../utils/helper';

const INITIAL_STATE = {
  cancelTokens: {},
  errors: {},
};

const otherReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case types.FEEDBACK_POST_STARTED: {
    const cancelProps = {
      type: types.FEEDBACK_POST,
      token: action.payload.token,
      id: action.payload.id
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens
    };
  }

  case types.FEEDBACK_POST_SUCCESS: {
    const cancelProps = {
      type: types.FEEDBACK_POST
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens,
      errors: {}
    };
  }

  case types.FEEDBACK_POST_FAILURE: {
    const cancelProps = {
      type: types.FEEDBACK_POST
    };
    const updatedCancelTokens = updateCancelTokens(state.cancelTokens, cancelProps);
    return {
      ...state,
      cancelTokens: updatedCancelTokens,
      errors: {
        ...action.payload
      }
    };
  }

  default:
    return state;
  }
};

export default otherReducer;
