import { keys as genericKeys, type as genericType } from "../generics";

const { intents } = genericKeys;

const nameKeys = {
  SUBTASKS: 'SUBTASKS',
  SUBTASK: 'SUBTASK',
};
export const entityKey = nameKeys.SUBTASKS.toLowerCase();
export const duckTypes = {
  SUBTASKS_GET: genericType(entityKey, nameKeys.SUBTASKS, intents.GET),
  SUBTASK_GET: genericType(entityKey, nameKeys.SUBTASK, intents.GET),
  SUBTASK_PUT: genericType(entityKey, nameKeys.SUBTASK, intents.PUT),
  SUBTASK_POST: genericType(entityKey, nameKeys.SUBTASK, intents.POST),
  SUBTASK_DELETE: genericType(entityKey, nameKeys.SUBTASK, intents.DELETE)
};