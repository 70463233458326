import React, { Component } from 'react';
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  CircularProgress,
  withStyles,
  FormControl,
  FormHelperText
} from "@material-ui/core";
import {
  Edit as EditIcon,
} from "@material-ui/icons";
import {
  Field,
  Form,
  Formik
} from "formik";
import { TextField } from 'formik-material-ui';
import competenceSpecificationTypes from "../../utils/competenceSpecificationTypes";
import ReactSelect from '../common/ReactSelect';
import clsx from "clsx";

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    overflow: 'visible',
    width: "100%",
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
    },
  },
  cardHeader: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      flex: '0 0 30%',
    },
  },
  cardHeaderAvatar: {
    alignSelf: 'flex-start',
  },
  cardHeaderAvatarCircle: {
    backgroundColor: theme.palette.primary.main,
  },
  cardContent: {
    [theme.breakpoints.up('md')]: {
      flex: '1 1 auto',
    },
  },
  cardActions: {
    padding: theme.spacing(1, 2, 2),
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-end',
      justifyContent: 'flex-end',
      flex: '1 0 14rem',
    },
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class CompetenceSpecificationFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeOptions: [],
    };
  }

  componentDidMount() {
    const specTypes = Object.keys(competenceSpecificationTypes)
      .map(id => {
        return {
          value: id,
          label: competenceSpecificationTypes[id].label
        };
      }, [])
      .sort((a, b) => {
        return (a.value < b.value) ? 1 : -1;
      });
    this.setState({
      typeOptions: specTypes
    });
  }

  render() {
    const { classes, isInstructor, phaseId, item, submitHandler, resetHandler } = this.props;
    const { typeOptions } = this.state;

    let specType = '';
    let description = '';

    if (item) {
      specType = item.field ? item.field.toString() : '';
      description = item.description || '';
    }

    return (
      <Formik
        initialValues = {{
          specType: specType,
          description: description,
        }}
        enableReinitialize={true}
        validate = {values => {
          let errors = {};
          if ('' === values.specType) {
            errors.specType = 'Bitte Auswahl treffen';
          }
          if ('' === values.description) {
            errors.description = 'Dies ist ein Pflichtfeld';
          }
          return errors;
        }}
        onSubmit = {(values, formikBag) => {
          submitHandler(values, formikBag);
        }}>
        {({ status, isSubmitting, handleSubmit, handleReset, setFieldValue, setFieldTouched, values, touched, errors }) => (
          <Form
            autoComplete="off"
            onSubmit={evt => {
              evt.stopPropagation();
              handleSubmit(evt);
            }}
            onReset={evt => {
              evt.stopPropagation();
              handleReset(evt);
              resetHandler();
            }}
            className={classes.form}
          >
            <Card className={classes.card}>
              <CardHeader
                avatar={<Avatar className={clsx({
                  [classes.cardHeaderAvatarCircle]: item,
                })}><EditIcon/></Avatar>}
                title="Kompetenzbeschreibung"
                titleTypographyProps={{
                  color: 'textSecondary'
                }}
                subheader={item ? "bearbeiten" : "hinzufügen"}
                subheaderTypographyProps={{
                  color: 'textPrimary'
                }}
                classes={{
                  root: classes.cardHeader,
                  avatar: classes.cardHeaderAvatar
                }}
              />
              <CardContent className={classes.cardContent}>
                {status && <FormHelperText error>{status}</FormHelperText>}
                <FormControl fullWidth margin="none" error={!!errors.specType && touched.specType}>
                  <ReactSelect
                    namespace={phaseId}
                    name="specType"
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    value={values.specType}
                    error={errors.specType}
                    touched={touched.specType}
                    options={typeOptions}
                    placeholder="Kompetenzbereich auswählen..."
                    label="Kompetenzbereich"
                    isMulti={false}
                    isClearable={true}/>
                  {(!!errors.specType && touched.specType) &&
                    <FormHelperText>{errors.specType}</FormHelperText>
                  }
                </FormControl>
                <Field component={TextField}
                  type="text"
                  id={`${phaseId}description`}
                  name="description"
                  label={isInstructor ? "Auszubildende*r kann..." : "Ich kann..."}
                  required
                  fullWidth
                  margin="normal"/>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <div className={classes.wrapper}>
                  <Button type="reset" size="small">Abbrechen</Button>
                </div>
                <div className={classes.wrapper}>
                  <Button type="submit"
                    fullWidth
                    variant="outlined"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}>
                    Speichern
                  </Button>
                  {isSubmitting && <CircularProgress size={24} color="primary" className={classes.progress} />}
                </div>
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(CompetenceSpecificationFormComponent);
